import { FalconRate } from '../rate';
import { RatePlanHelper } from '../rateplan';
import { FalconHotel } from './falcon-hotel';
import { HotelOverviewInfo, SimplifiedHotelDetail } from './hotel.models';
export class HotelInfoHelper {
  static simplifyHotelStatic(
    hotelStatic: FalconHotel.HotelStaticDetailModel
  ): SimplifiedHotelDetail {
    const {
      Description,
      PolicyDescription,
      HotelID,
      MainHotelID,
      StarRating,
      Name,
      Address,
      CityName,
      Longitude,
      Latitude,
      ImportNotice,
      CountryCode,
      RoomContentList,
      MandatoryFeesDescription,
      FeesDescription,
      FacilitySetting,
      DestinationID,
      HotelAttributeList,
    } = hotelStatic;

    let hotelImageList = hotelStatic.HotelImageList;
    if (hotelImageList?.length) {
      const hotelListSet = new Set();
      hotelImageList = hotelImageList
        .map((item) => {
          if (!item.ImageUrl_HD) {
            item.ImageUrl_HD = item.ImageUrl;
          }
          if (!item.ImageUrl) {
            item.ImageUrl = item.ImageUrl_HD;
          }
          return item;
        })
        .filter((item) => {
          if (hotelListSet.has(item.ImageUrl)) {
            return false;
          }
          hotelListSet.add(item.ImageUrl);
          return true;
        });
    }

    const roomContentMap = (RoomContentList || []).reduce((result, item) => {
      const hasImage = item.Images?.length > 0;
      if (hasImage) {
        item.Images = item.Images.sort((a, b) =>
          !a.IsDefault && b.IsDefault ? 1 : -1
        );
      }
      const hasProperty = [
        'Floor',
        'HasWifi',
        'HasWindow',
        'Size',
        'MaxOccupancy',
      ].some((key) => !!item[key]);
      if (hasImage || hasProperty) {
        item.HasProperty = true;
        result[item.DidaRoomTypeID] = item;
      }
      return result;
    }, {});

    return {
      HotelID,
      MainHotelID,
      Name,
      StarRating,
      Longitude,
      Latitude,
      CountryCode,
      Address: `${Address}, ${CityName}`,
      // TripAdvisorRating: CustomizedSetting
      //   ? CustomizedSetting.TripAdvisorRating
      //   : 0,
      Description,
      PolicyDescription,
      hotelImage: hotelImageList?.length > 0 && hotelImageList[0].ImageUrl,
      hotelImageList,
      roomContentMap,
      MandatoryFeesDescription: MandatoryFeesDescription || FeesDescription,
      FacilitySetting,
      DestinationID,
      HotelAttributeList,
      ImportNotice,
    };
  }

  static getHotelOverview(
    ratePrice: FalconRate.HotelRatePlanPriceModel
  ): HotelOverviewInfo {
    const {
      Hotel: hotel,
      PriceSummary,
      SpecialPromotionList,
      ChannelPriceInfo,
    } = ratePrice;
    const imgSrc = hotel.HotelImageList[0]?.ImageUrl_HD;

    const result: HotelOverviewInfo = {
      hotelId: hotel.HotelID,
      name: hotel.Name,
      address: hotel.Address + ', ' + hotel.DestinationName,
      location: {
        Latitude: hotel.Latitude,
        Longitude: hotel.Longitude,
      },
      imgSrc: imgSrc && encodeURI(imgSrc),
      // TripAdvisorRating:
      //   hotel.CustomizedSetting && hotel.CustomizedSetting.TripAdvisorRating,
      starRating: hotel.StarRating,
      facilitySetting: hotel.FacilitySetting,
    };
    if (PriceSummary) {
      result.priceInfo = RatePlanHelper.simplifyRatePlanPrice(
        {
          ...ratePrice,
          RatePlanPriceList: [
            {
              RoomNum: 0,
              PriceSummary: ratePrice.PriceSummary,
              ReferencePriceSummary: ratePrice.ReferencePriceSummary,
              ChannelPriceInfo: ratePrice.ChannelPriceInfo,
              CancellationList: [],
              CancellationNeedReGet: null,
              RatePlan: {} as any,
              RateList: [],
              AllowGuestNameInChinese: null,
              TimeZoneInfo: null,
              BankCardInputConditions: null,
            },
          ],
        },
        null,
        null
      );
      if (ChannelPriceInfo) {
        result.priceInfo.channelPriceInfo = ChannelPriceInfo;
      }
    }
    return result;
  }
}
