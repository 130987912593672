import { NationalityInfoModel } from '@b2c/services/nation';
import { useContext, useMemo } from 'react';
import { CountryInfoContext } from './country-info.context';

export function useNationalityInfo(code: string): NationalityInfoModel {
  const nationInfoList = useContext(CountryInfoContext).countryInfoList;
  const nationalityInfo = useMemo(() => {
    const nation = nationInfoList.find((item) => item.code === code);
    if (nation) {
      return {
        code: nation.code,
        name: nation.nationality || nation.name,
      };
    }
    return {
      code,
      name: 'loading',
    };
  }, [nationInfoList, code]);

  return nationalityInfo;
}
