import { useLocale, useQueryParams } from '@b2c/hooks';
import { FalconBooking, HotelBookingService } from '@b2c/services/booking';
import {
  AnalyticsHelper,
  BookingDetailQueryParams,
} from '@b2c/services/common';
import { FalconPayment } from '@b2c/services/pay-channel';
import { Warning } from '@react-vant/icons';
import { useState } from 'react';
import { Cell, Field, Flex, NoticeBar, Typography } from 'react-vant';
import { MessageService } from '../message.service';
import { ModalService } from '../modal.service';
import { PopupDialog } from '../popup-dialog';
import { TypographyPrice } from '../typography-price';

export function BookingCancel(props: {
  bookingDetail: FalconBooking.BookingDetailModel;
  children(options: { onClick(): any }): React.ReactNode;
  onSuccess(): void;
}) {
  const t = useLocale();
  const bookingDetail = props.bookingDetail;
  const isPayLater =
    props.bookingDetail?.PriceSummary.RatePaymentType !==
    FalconPayment.RatePaymentTypeEnum.PrePay;

  const [cancelReason, setCancellReason] = useState('');
  const [bookingCancelResult, setBookingCancelResult] =
    useState<FalconBooking.PreCancelResult>(null);

  const { queryParams } = useQueryParams<BookingDetailQueryParams>();

  const preCancellBooking = () => {
    const loadingReturn = MessageService.loading();

    HotelBookingService.preCancel(
      bookingDetail.BookingNumber,
      queryParams.cancelToken
    )
      .then((resp) => {
        setBookingCancelResult(resp);
        setCancelDialogVisible(true);
      })
      .catch(function (err) {
        ModalService.error(err.message);
      })
      .then(function () {
        loadingReturn.clear();
      });
  };

  const confirmCancel = () => {
    const loadingReturn = MessageService.loading();
    HotelBookingService.cancelConfirm(
      bookingCancelResult,
      cancelReason?.trim(),
      queryParams.cancelToken
    )
      .then((resp) => {
        AnalyticsHelper.refund(bookingDetail, resp);
        ModalService.alert(t('Text_BookingCancelSuccess')).then(() => {
          setCancelDialogVisible(false);
          props.onSuccess?.call(null);
          // loadBookingDetail(bookingCancelResult.BookingNumber);
        });
      })
      .catch(function (err) {
        ModalService.error(err.message);
      })
      .finally(function () {
        loadingReturn.clear();
      });
  };
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  return (
    <>
      {props.children({
        onClick: () => preCancellBooking(),
      })}

      <PopupDialog
        position="bottom"
        visible={cancelDialogVisible}
        onCancel={() => setCancelDialogVisible(false)}
        onConfirm={confirmCancel}
        title={t('Button_CancelBooking')}
      >
        {!!bookingCancelResult && (
          <>
            {isPayLater && bookingCancelResult.ChargeAmount > 0 && (
              <NoticeBar
                scrollable={false}
                wrapable
                leftIcon={<Warning />}
                text={t('Notice_PayLater_Cancell')}
              ></NoticeBar>
            )}
            <Cell className="mb-2 mt-2">
              <Flex>
                <Flex.Item span="10">
                  <Typography.Text strong>
                    {t('Label_BookingNumber')}：
                  </Typography.Text>
                </Flex.Item>
                <Flex.Item>{bookingCancelResult.BookingNumber}</Flex.Item>
              </Flex>
              <Flex>
                <Flex.Item span="10">
                  <Typography.Text strong>
                    {t('Label_BookingPrice')}：
                  </Typography.Text>
                </Flex.Item>
                <Flex.Item>
                  <TypographyPrice
                    value={bookingDetail.PriceSummary}
                  ></TypographyPrice>
                </Flex.Item>
              </Flex>
              <Flex>
                <Flex.Item span="10">
                  <Typography.Text type="danger" strong>
                    {t('Label_ChargeAmount')}：
                  </Typography.Text>
                </Flex.Item>
                <Flex.Item>
                  <TypographyPrice
                    value={bookingCancelResult}
                    priceKey="ChargeAmount"
                    type="danger"
                  ></TypographyPrice>
                </Flex.Item>
              </Flex>
            </Cell>
            <div className="mb-2">
              <Field
                autoSize
                type="textarea"
                value={cancelReason}
                placeholder={t('Placeholder_CancelBookingReason')}
                maxLength={1000}
                showWordLimit
                onChange={(event) => setCancellReason(event)}
              />
            </div>
          </>
        )}
      </PopupDialog>
    </>
  );
}
