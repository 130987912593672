import { useCancellationPolicyInfo, useLocale } from '@b2c/hooks';
import { FalconBooking } from '@b2c/services/booking';
import { FalconCommon } from '@b2c/services/common';
import { Warning } from '@react-vant/icons';
import { FunctionComponent, useState } from 'react';
import { Cell, Flex, Space, Typography, TypographyType } from 'react-vant';
import { Container } from './container';
import { PopupDialog } from './popup-dialog';
import { Table } from './table';

export interface CancellationPolicyItem {
  dateDesc: string;
  content: string;
  contentTheme: string;
  isActivated?: boolean;
  desc: string;
}

export const CancellationPolicy: FunctionComponent<{
  cancellationList: FalconCommon.DayCancellation[];
  timeZoneInfo: FalconCommon.TimeZoneInfo;
  priceSummary: FalconBooking.PriceSummary;
  showDetail?: boolean;
}> = ({ cancellationList, timeZoneInfo, priceSummary, showDetail }) => {
  const t = useLocale();

  const [detailVisible, setDetailVisble] = useState(false);

  const { currentCancellationRuleInfo, cancellationRuleInfoList, timeZone } =
    useCancellationPolicyInfo({
      cancellationList,
      priceSummary,
      timeZoneInfo,
    });
  const isChinaStandardTime = timeZone.offsetHour === 8;

  const cancellationListView = (
    <Space block direction="vertical">
      <div>
        <Typography.Text strong size="lg">
          {t('Label_CancelPolicy')}
        </Typography.Text>
        {!isChinaStandardTime && (
          <Flex align="center">
            <Warning style={{ flexShrink: 0 }} />
            &nbsp;
            <Typography.Text>
              {t('Notice_CancellationTimeZone')}
            </Typography.Text>
            {/* </Flex.Item> */}
          </Flex>
        )}
      </div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.Cell>
              {isChinaStandardTime ? (
                t('Label_BeijinTime')
              ) : (
                <>{t('Label_HotelTime')}</>
              )}
            </Table.Cell>
            <Table.Cell>{t('Label_CancellationAndChangeFee')}</Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {cancellationRuleInfoList.map((cancellation, index) => (
            <Table.Row
              theme={
                cancellation.isCurrent ? cancellation.displayInfo.theme : ''
              }
              key={index}
            >
              <Table.Cell>{cancellation.dateDesc}</Table.Cell>
              <Table.Cell>
                <Typography.Text color={cancellation.displayInfo.theme}>
                  {cancellation.priceDesc}
                </Typography.Text>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Space>
  );
  if (showDetail) {
    return cancellationListView;
  }
  return (
    <>
      <Cell
        style={{ padding: 0 }}
        title={
          <Typography.Text
            type={
              currentCancellationRuleInfo?.displayInfo.theme as TypographyType
            }
          >
            {currentCancellationRuleInfo?.desc}
          </Typography.Text>
        }
        isLink
        onClick={() => setDetailVisble(true)}
      ></Cell>

      <PopupDialog
        position="bottom"
        visible={detailVisible}
        onConfirm={() => setDetailVisble(false)}
        showCancel={false}
      >
        <div style={{ margin: '20px 0' }}>
          <Container>{cancellationListView}</Container>
        </div>
      </PopupDialog>
    </>
  );
};
