import { EnvironmentModel } from '@b2c/services/common';

export const environment: EnvironmentModel = {
  production: true,
  serviceEndPoint: 'https://webapi-test.hotelwhales.com',
  analytics: {
    google: {
      measureId: 'G-JW8VL2BKG9',
      adLabels: [],
    },
  },
};
