import { CoreHotelBooking } from '@b2c/core';
import { Divider, Flex, Input } from 'react-vant';

interface GuestInputProps {
  value?: CoreHotelBooking.Guest;
  lastNamePlaceholder: string;
  firstNamePlaceholder: string;
  onChange?(guest: CoreHotelBooking.Guest): void;
  onBlur?(): void;
}

export const GuestInput = (props: GuestInputProps) => {
  function firstNameChange(value: string) {
    props.onChange({
      ...props.value,
      FirstName: value?.trim(),
    });
  }
  function lastNameChange(value: string) {
    props.onChange({
      ...props.value,
      LastName: value?.trim(),
    });
  }
  function onBlur() {
    props.onBlur?.call(null);
  }
  return (
    <Flex gutter={10}>
      <Flex.Item flex="1">
        <Input
          onChange={($event) => firstNameChange($event)}
          maxLength={50}
          placeholder={props.firstNamePlaceholder}
          value={props.value?.FirstName}
          clearable
          onClear={() => firstNameChange('')}
          onBlur={() => onBlur()}
        />
      </Flex.Item>
      <Flex.Item>
        <Divider type="vertical"></Divider>
      </Flex.Item>
      <Flex.Item flex="1">
        <Input
          onChange={($event) => lastNameChange($event)}
          maxLength={50}
          clearable
          onClear={() => lastNameChange('')}
          placeholder={props.lastNamePlaceholder}
          value={props.value?.LastName}
          onBlur={() => onBlur()}
        />
      </Flex.Item>
    </Flex>
  );
};
