import { Dialog } from 'react-vant';

interface ModalProps {
  message?: string | React.ReactNode;
  title?: string;
  confirmText?: string;
}
export class ModalService {
  static alert(message: string | ModalProps, title?: string) {
    let options: ModalProps;
    if (typeof message === 'string') {
      options = {
        message,
        title,
      };
    } else if (message) {
      options = message;
    } else {
      options = {};
    }

    return Dialog.alert({
      message: options.message,
      title: options.title,
      confirmButtonText: options.confirmText || 'OK',
      showCancelButton: false,
    });
  }

  static error(
    message:
      | string
      | {
          message?: string | React.ReactNode;
          title?: string;
          confirmText?: string;
        },
    title?: string
  ) {
    let options: ModalProps;
    if (typeof message === 'string') {
      options = {
        message,
        title,
      };
    } else if (message) {
      options = message;
    } else {
      options = {};
    }

    return Dialog.alert({
      message: options.message || 'Netwrok busy, Please try again later',
      title: options.title,
      confirmButtonText: options.confirmText || 'OK',
      showCancelButton: false,
    });
  }

  static confirm(props: {
    confirmText?: string;
    cancelText?: string;
    title?: string | React.ReactNode;
    message?: string | React.ReactNode;
  }) {
    return new Promise<boolean>((resolve) => {
      Dialog.alert({
        confirmButtonText: props.confirmText || 'OK',
        cancelButtonText: props.cancelText || 'Cancel',
        showCancelButton: true,
        title: props.title,
        message: props.message,
        onConfirm() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  }
}
