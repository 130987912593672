import { AppConfigModel } from '@b2c/hooks';

export const AppConfig: AppConfigModel = {
  brandName: 'HotelWhales',
  customerService: {
    email: 'cs@hotelwhales.com',
    tels: [
      {
        code: 'UK',
        number: '+44-2039538398',
      },
      {
        code: 'USA',
        number: '+1-2129015378',
      },
      {
        code: 'UAE',
        number: '+971-800085231031',
      },
    ],
  },
};
