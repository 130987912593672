import { SimplifiedRatePlanPrice } from '@b2c/services/rateplan';
import { useState } from 'react';

export interface RatePlanFilterOptions {
  breakfast: boolean;
  refundable: boolean;
  immediately: boolean;
  bedType: string;
  roomTypeId?: string;
  paymentType?: number;
}

export const DefaultRatePlanFilter: RatePlanFilterOptions = Object.freeze({
  refundable: false,
  immediately: false,
  breakfast: false,
  bedType: null,
  paymentType: null,
});

export function isMatchFilterOptions(
  ratePlan: SimplifiedRatePlanPrice,
  filter: RatePlanFilterOptions
) {
  if (filter.bedType && filter.bedType !== ratePlan.bedTypeDesc) {
    return false;
  }
  if (filter.immediately && ratePlan.confirmInMinutes > 0) {
    return false;
  }
  if (filter.refundable && !ratePlan.isRefundable) {
    return false;
  }
  if (filter.breakfast && !ratePlan.withBreakfast) {
    return false;
  }
  if (filter.paymentType > 0 && ratePlan.paymentType !== filter.paymentType) {
    return false;
  }

  return true;
}

export function useRatePlanFilter() {
  const [ratePlanFilter, setRatePlanFilter] = useState<RatePlanFilterOptions>({
    ...DefaultRatePlanFilter,
  });

  return {
    ratePlanFilter,
    setRatePlanFilter,
    resetRatePlanFilter() {
      setRatePlanFilter({ ...DefaultRatePlanFilter });
    },
  };
}
