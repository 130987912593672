import classNames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styles from './panel.module.less';

const PanelTitle: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div {...props} className={classNames(className, styles.panelTitle)}>
      {children}
    </div>
  );
};

const PanelBase: FunctionComponent<
  Omit<HTMLAttributes<HTMLDivElement>, 'title'> &
    Partial<{
      title: ReactNode;
      desc: ReactNode;
      type: 'transparent' | 'grey';
      size: 'small';
      bodyStyle: React.CSSProperties;
    }>
> = (props) => {
  const { title, desc, className, bodyStyle, ...restProps } = props;
  return (
    <div
      {...restProps}
      className={classNames(styles.panel, props.className, {
        [styles.themeGrey]: props.type === 'grey',
        [styles.themeTransparent]: props.type === 'transparent',
        [styles.sizeSmall]: props.size === 'small',
      })}
    >
      {props.title && (
        <div className={classNames(styles.panelHeader)}>
          <PanelTitle>{props.title}</PanelTitle>
          {props.desc && <div className={styles.panelDesc}>{props.desc}</div>}
        </div>
      )}
      <div className={classNames(styles.panelBody)} style={bodyStyle}>
        {props.children}
      </div>
    </div>
  );
};

export const Panel = Object.assign(PanelBase, {
  Title: PanelTitle,
});
