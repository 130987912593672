import { FalconBooking } from '@b2c/services/booking';
import {
  AnalyticsConfigModel,
  GoogleAnalyticsConfig,
} from './models/analytics.models';
import { UrlHelper } from './url.helper';

export class AnalyticsHelper {
  private static _config: AnalyticsConfigModel;

  static init(config: AnalyticsConfigModel) {
    this._config = this.fullfillConfig(config);
    this._initGoogleAnalytics(this._config.google);
  }

  private static fullfillConfig(config: AnalyticsConfigModel) {
    if (!config) {
      config = {};
    }

    if (!config.google) {
      config.google = {
        measureId: null,
        adLabels: [],
      };
    }

    if (!config.skyscanner) {
      config.skyscanner = {
        parterId: null,
      };
    }
    return config;
  }

  private static _initGoogleAnalytics(gaConfig: GoogleAnalyticsConfig) {
    if (!gaConfig) {
      return;
    }

    const gtagId = gaConfig.measureId || gaConfig.adLabels[0]?.id;

    if (!gtagId) {
      return;
    }

    const script = document.createElement('script');
    // script.defer = true;
    script.async = true;
    // script.onload = () => {};
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
    document.head.append(script);

    // Warning: 直接写会导致ga不发送统计
    eval(`
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    window.gtag = gtag
    `);
    if (gaConfig.measureId) {
      gtag('config', gaConfig.measureId, {
        debug_mode: process.env.NODE_ENV === 'development',
      });
    }

    if (gaConfig.adLabels?.length > 0) {
      gaConfig.adLabels.map((item) => {
        gtag('config', item.id, {
          group: 'google_ads',
          send_page_view: false,
        });
      });
    }
  }

  /**
   *
   * @param name
   * @param category
   * @param label
   * @returns
   */
  static event(
    name: string,
    eventParams: Gtag.CustomParams & Gtag.ControlParams & Gtag.EventParams = {}
  ) {
    if (!('gtag' in window)) {
      return Promise.resolve();
    }
    if (!eventParams.send_to) {
      eventParams.send_to = ['default'];
    }
    return new Promise((resolve) => {
      gtag('event', name, {
        ...(eventParams || {}),
        event_callback() {
          resolve(null);
        },
      });
    });
  }

  // 自定义事件，只发送给谷歌分析
  static eventCustom(
    name: string,
    eventParams: Gtag.CustomParams & Gtag.ControlParams & Gtag.EventParams = {}
  ) {
    if (!eventParams.send_to) {
      eventParams.send_to = ['default'];
    }
    return this.event(name, eventParams);
  }

  // static click(
  //   eventParams?: Gtag.CustomParams & Gtag.ControlParams & Gtag.EventParams,
  // ) {
  //   return this.event('click', eventParams);
  // }

  static setUserId(userId: string) {
    this.setConfig({
      user_id: userId,
    });
  }

  static setConfig(config: object) {
    if (!this._config.google.measureId) {
      console.warn('measure id required!');
      return;
    }
    gtag('config', this._config.google.measureId, config);
  }

  static exception(description: string, fatal?: boolean) {
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    const resizeObserverLoopErrRe = /^[^(ResizeObserver loop limit exceeded)]/;
    if (resizeObserverLoopErrRe.test(description)) {
      return Promise.resolve();
    }
    return this.event('exception', {
      description,
      fatal,
      send_to: ['default'],
    });
  }

  static login() {
    return this.event('login');
  }

  static signUp() {
    return this.event('sign_up', {
      method: 'Musetrip',
    });
  }

  static selectContent(contentType: any, itemId) {
    return this.event('select_content', {
      content_type: contentType,
      item_id: itemId,
    });
  }

  static search(searchTerm: string) {
    return this.event('search', {
      search_term: searchTerm,
    });
  }

  static createBooking(bookingSnapshot: FalconBooking.BookingInfoBase) {
    return this.event('generate_lead', {
      currency: bookingSnapshot.PriceSummary.Currency,
      value: bookingSnapshot.PriceSummary.Price,
    });
  }

  static beginCheckout(bookingSnapshot: FalconBooking.BookingInfoBase) {
    return this.event('begin_checkout', {
      currency: bookingSnapshot.PriceSummary.Currency,
      value: bookingSnapshot.PriceSummary.Price,
      items: [
        {
          name: bookingSnapshot.StaticInfo.RoomTypeName,
          quantity: bookingSnapshot.RoomCount,
          price: bookingSnapshot.PriceSummary.Price / bookingSnapshot.RoomCount,
        },
      ],
    });
  }

  static purchase(bookingDetail: FalconBooking.BookingInfoBase) {
    const option = {
      send_to: ['default'],
      transaction_id: bookingDetail.BookingNumber,
      currency: bookingDetail.PriceSummary.Currency,
      value: bookingDetail.PriceSummary.Price,
      items: [
        {
          id: bookingDetail.StaticInfo.HotelID,
          start_date: bookingDetail.CheckInDate.slice(0, 10),
          end_date: bookingDetail.CheckOutDate.slice(0, 10),
        } as any,
      ],
    };

    if (this._config.google.adLabels.length > 0) {
      this.event('conversion', {
        ...option,
        send_to: this._config.google.adLabels.map(
          (item) => `${item.id}/${item.label}`
        ),
      });
    }

    return this.event('purchase', option);
  }

  static sendSkyscannerConversion(
    fclid: string,
    hotelId: string,
    bookingDetail: FalconBooking.BookingInfoBase
  ) {
    if (!this._config.skyscanner?.parterId) {
      return;
    }

    const trackingPixel = UrlHelper.addParams(
      'https://t.skyscnr.com/hotels/track/booking',
      {
        partner: this._config.skyscanner?.parterId,
        fclid: fclid,
        hotel_id: hotelId,
        order_id: bookingDetail.BookingNumber,
        hotel_name: bookingDetail.StaticInfo.HotelName,
        hotel_city: bookingDetail.StaticInfo.HotelDestinationName,
        hotel_country: bookingDetail.StaticInfo.HotelCountryName,
        checkin: bookingDetail.CheckInDate.slice(0, 10),
        checkout: bookingDetail.CheckOutDate.slice(0, 10),
        guests: bookingDetail.GuestNameList.split('/').length,
        rooms: bookingDetail.RoomCount,
        amount: bookingDetail.PriceSummary.Price,
        currency: bookingDetail.PriceSummary.Currency,
      }
    );
    const img = new Image();
    img.src = trackingPixel;
    img.width = 1;
    img.height = 1;
    img.style.borderStyle = 'none';
    img.style.position = 'absolute';
    window.document.body.append(img);
  }

  // static conversion() {
  //   return this.event('conversion', {});
  // }

  static refund(
    bookingDetail: FalconBooking.BookingDetailModel,
    bookingCancelResult: FalconBooking.CancelResult
  ) {
    const refund =
      bookingDetail.PriceSummary.Price - bookingCancelResult.ChargeAmount;
    if (refund > 0) {
      let itemInfo;
      if (bookingCancelResult.ChargeAmount !== 0) {
        itemInfo = {
          item_name: bookingDetail.StaticInfo.RoomTypeName,
          price:
            (bookingDetail.PriceSummary.Price -
              bookingCancelResult.ChargeAmount) /
            bookingDetail.RoomCount,
          quantity: bookingDetail.RoomCount,
        };
      }
      this.event('refund', {
        transaction_id: bookingCancelResult.BookingNumber,
        currency: bookingCancelResult.Currency,
        value: bookingCancelResult.ChargeAmount,
        items: itemInfo && [itemInfo],
      });
    }
  }

  //#region custom event

  static switchAccountOperation(type: string) {
    return this.eventCustom('switch_account_opera', {
      type: type,
    });
  }

  static openAccountOperation(type: string) {
    return this.eventCustom('open_account_opera', {
      type: type,
    });
  }

  static selectRatingStar(stars: number[]) {
    return this.eventCustom('select_rating_star', {
      stars: stars?.join('_'),
    });
  }

  static selectPaymentType(types: number[]) {
    return this.eventCustom('select_payment_type', {
      type: types?.join('_'),
    });
  }

  static selectBudget(min, max) {
    return this.eventCustom('select_budget_range', {
      budget_min: min,
      budget_max: max,
    });
  }

  static selectSortType(sortTypeName, sortType) {
    return this.eventCustom('select_hotel_sort', {
      sort_type_name: sortTypeName,
      sort_type: sortType,
    });
  }

  static viewHotelContent(contentType: string, from: string) {
    return this.eventCustom('view_hotel_content', {
      content_type: contentType.toLocaleLowerCase().replace(' ', '_'),
      trigger_by: from,
    });
  }

  static clickRoomContent(contentType: string) {
    return this.eventCustom('click_room_content', {
      content_type: contentType,
    });
  }

  static changeRatePlanFilter(filterType: string, remark?: string) {
    return this.eventCustom('change_rateplan_filter', {
      filter_type: filterType,
      filter_remark: remark,
    });
  }

  static clickBackTop() {
    return this.eventCustom('click_backtop');
  }

  static chooseHotelInList(type: string) {
    return this.eventCustom('choose_hotel_list', {
      type,
    });
  }
  //#endregion
}
