export enum LogLevel {
  Trace = 0,
  Debug,
  Info,
  Warn,
  Error,
  Fatal,
}

export interface FalconFontendLogModel {
  level: number;
  message: string;
  trace: string;
  location: string;
  userAgent: string;
  extra: object;
}
