import { CoreCommon, CoreHotelBooking } from '@b2c/core';
import { FalconCommon } from '@b2c/services/common';
import { DateTimeWindow, IPaginateOption } from '../common';
import { FalconPayment } from '../pay-channel';
import { FalconRate } from '../rate';

export namespace FalconBooking {
  export interface StaticInfo {
    BookingNumber: string;

    HotelID: number;
    HotelName: string;
    HotelAddress: string;
    HotelCountryCode: string;
    HotelCountryName: string;
    HotelStarRating: number;
    HotelZipCode: string;
    HotelPhone: string;
    HotelEmail: string;

    HotelDestinationID: number;
    HotelDestinationName: string;

    RoomTypeName: string;

    BreakfastType: CoreCommon.BreakfastType;

    BedType: FalconCommon.BedTypeModel;

    IsBedTypeAssured: boolean;

    IsDirectContract: boolean;
    RatePaymentType: FalconPayment.RatePaymentTypeEnum;
  }
  export interface ContractInfo {
    ContactFirstName: string;
    ContactLastName: string;
    ContactPhone: string;
    ContactEmail: string;
  }
  export interface BookingInfoBase {
    BookingNumber: string;

    ClientID: string;

    BookingStatus: number;
    ErrorType: CoreHotelBooking.ErrorType;
    PayChannelID: number;
    CheckInDate: string;
    CheckOutDate: string;
    OrderTime: string;
    BookingCancelTime: string;

    Nationality: string;
    RoomCount: number;
    RoomNightCount: number;

    StaticInfo: StaticInfo;

    PriceSummary: PriceSummary;

    IsHourlyRoom: boolean;
    IsPreBookOnRequest: boolean;
    IsPostBookOnRequest: boolean;
    OnRequestLimitTimeMinutes: number;

    ContractInfo: ContractInfo;

    OrderClientIP: string;
    OrderSessionID: string;
    IsTestBooking: boolean;
    CustomerRequest: string;

    // HotelConfirmationCode: string;
    GuestNameList: string;
    BankCardInputConditions?: FalconPayment.BankCardInputConditions;
  }

  export interface SnapshotModel extends BookingInfoBase {
    BookingToken: string;
  }

  export enum FeeTypeEnum {
    Others = 0,
  }

  export interface FalconFeeType {
    ID: FeeTypeEnum;
    Name: string;
  }

  export interface FalconFeeModel {
    FeeChargeType: FalconFeeType;
    Currency: string;
    Amount: number;
  }

  export interface PriceSummary {
    Currency: string;
    Price: number;
    TaxAndFee: number;
    PriceBeforeTax: number;
    Supplement?: number;
    RatePaymentType: FalconPayment.RatePaymentTypeEnum;
    /**
     * Price包含的费用
     */
    IncludedFees?: FalconFeeModel[];
    /**
     * Price不包含的费用
     */
    ExcludedFees?: FalconFeeModel[];
  }
  export interface DailyInfo {
    StayDate: string;

    PriceSummary: PriceSummary;

    MealInfo: MealInfoModel;
  }
  export interface RoomInfo {
    BookingNumber: string;

    RoomNumber: number;

    Occupancy: RoomOccupancyInfo;

    RoomPriceSummary: PriceSummary;

    RateList: DailyInfo[];
  }

  export interface RoomOccupancyInfo {
    AdultCount: number;

    ChildCount: number;

    ChildAgeList: number[];

    AdultList: RoomGuestInfo[];

    ChildList: RoomGuestInfo[];
  }

  export interface RoomGuestInfo {
    GuestIndex: number;

    IsAdult: boolean;

    Age: number;

    FirstName: string;

    LastName: string;
  }

  export interface CancellationItem extends FalconCommon.DayCancellation {
    BookingNumber: string;
  }

  export interface CancelConfirmInfo {
    BookingNumber: string;

    CancelTime: string;
    /**0 - PreCancel, 1 - Canceled */
    ConfirmStatus: number;

    Currency: string;

    Amount: number;

    CancelReason: string;
  }

  export interface BookingDetailModel extends BookingInfoBase {
    RoomList: RoomInfo[];

    CancellationPolicies: CancellationItem[];

    CancelConfirmInfo: CancelConfirmInfo;

    ValueAddList: FalconRate.ValueAddModel[];

    SpecialPromotionList: FalconRate.PromotionModel[];

    TimeZoneInfo: FalconCommon.TimeZoneInfo;
    /**
     * 用于订单取消
     */
    BookingCancelToken: string;
    /**
     * 用于订单信息获取
     */
    BookingInfoToken: string;
    /**
     * 用于订单支付
     */
    BookingOrderToken: string;
  }

  export interface SearchOption extends IPaginateOption {
    BookingNumber: string;
    // FalconUserID: string;
    BookingStatusList: number[];
    HotelIDList: number[];
    ContactEmail: string;
    CheckInDateWindow: DateTimeWindow;
    CheckOutDateWindow: DateTimeWindow;
    OrderTimeWindow: DateTimeWindow;
    CancelTimeWindow: DateTimeWindow;

    WithFullInfo: boolean;
    // LanguageCode: string;
    PageNum: number;
    PageSize: number;
  }

  export interface PreCancelResult {
    BookingNumber: string;

    CancelConfirmID: string;

    Currency: string;

    ChargeAmount: number;

    __SessionID: string;
  }

  export interface CancelResult {
    BookingNumber: string;

    Currency: string;

    ChargeAmount: number;

    RefundHandled: boolean;
  }
}
