import {
  ApplicationContextService,
  FalconContext,
  SigningHelper,
} from '@b2c/services/auth';
import { useEffect, useState } from 'react';

export const useSecureContext = () => {
  const [secureContext, setSecureContext] =
    useState<FalconContext.SecurityContextEstablishResult>(null);

  useEffect(() => {
    if (secureContext?.ime) {
      const expiredSeconds = Math.min(
        secureContext.eas,
        secureContext.ime_vrm * 60
      );
      // expiredSeconds = 30;
      const fronEndOffsetSeconds = 10;
      const expiredTimestamp =
        Date.now() + (expiredSeconds - fronEndOffsetSeconds) * 1000;
      console.log('setup secure context timer');
      const timer = setInterval(() => {
        if (Date.now() > expiredTimestamp) {
          // setSecureContext(null);
          establishSecureTunnel(true);
        }
      }, 10000);
      return () => {
        console.log('clear secure context timer');
        clearInterval(timer);
      };
    }
  }, [secureContext]);

  function establishSecureTunnel(force = false) {
    if (!force && secureContext) {
      return;
    }
    SigningHelper.gernerateEstablishOption().then((option) => {
      ApplicationContextService.establishSecureTunnel(option).then(
        (resp) => {
          // setTimeout(() => {
          setSecureContext(resp);
          // }, 0);
        },
        (error) => {
          console.error(error);
        }
      );
    });
  }

  return {
    secureContext,
    getSecureContext: () => secureContext,
    clearSecureContext: () => setSecureContext(null),
    establishSecureTunnel,
  };
};

export default useSecureContext;
