import { CoreCommon } from '@b2c/core';
import { FalconBooking } from '../booking';
import { ArrayHelper, FalconCommon, ObjectHelper } from '../common';
import { HotelInfoHelper } from '../hotel';
import { FalconOrdering } from '../order/falcon-order';
import { FalconPayment } from '../pay-channel';
import { FalconRate } from '../rate';
import { CancellationHelper } from './cancellation.helper';
import { CancellationStateType } from './enums';
import { RatePlanExtraInfoType } from './enums/rate-plan-extra-info-type';
import {
  PriceChangeResultModel,
  SimplifiedRatePlanPrice,
} from './rateplan.models';

export class RatePlanHelper {
  static calculateOnRequestLimitTimeInHour(
    limitTimeMinutes?: number,
    defaultTimeHour: number = null
  ): number {
    if (!limitTimeMinutes) {
      return defaultTimeHour;
    }
    return Math.ceil((10 * limitTimeMinutes) / 60) / 10;
  }

  static getMealInfo(
    ratePlanList: FalconRate.RatePlanPriceModel[]
  ): CoreCommon.MealInfoModel | null {
    if (!ratePlanList || ratePlanList.length === 0) {
      return null;
    }

    // 多间房要拿份数最多的，产品说的！
    let mealInfo: CoreCommon.MealInfoModel | null = null;
    ratePlanList.forEach((r) => {
      if (
        mealInfo == null ||
        mealInfo.MealAmount < r.RatePlan.MealInfo.MealAmount
      ) {
        mealInfo = r.RatePlan.MealInfo;
      }
    });

    return mealInfo;
  }

  static sortRatePlanList(ratePlanList: SimplifiedRatePlanPrice[]) {
    ratePlanList.sort(this.isRatePlanPriceLower);
  }

  static isRatePlanPriceLower(
    a: SimplifiedRatePlanPrice,
    b: SimplifiedRatePlanPrice
  ): 1 | -1 {
    if (b.channelPriceInfo) {
      return 1;
    }
    if (a.channelPriceInfo) {
      return -1;
    }
    const aSummary = a.priceSummary || a.priceSummary;
    const bSummary = b.priceSummary || b.priceSummary;
    return aSummary.Price > bSummary.Price ? 1 : -1;
  }

  static getTotalChargeInfo(feeList: FalconBooking.FalconFeeModel[]) {
    const totalAmount = ArrayHelper.sum(feeList, 'Amount');

    let totalFee: FalconBooking.FalconFeeModel = null;

    if (totalAmount > 0) {
      totalFee = {
        Currency: feeList[0].Currency,
        Amount: totalAmount,
        FeeChargeType: null,
      };
    }

    return totalFee;
  }

  static simplifyRatePlanPrice(
    rawPrice: FalconRate.HotelRatePlanPriceModel,
    responseId: string,
    sessionId: string
  ): SimplifiedRatePlanPrice {
    const ratePlanPrice = rawPrice.RatePlanPriceList[0];
    const RatePlan = ratePlanPrice.RatePlan;
    const channelPriceInfo =
      rawPrice.ChannelPriceInfo || ratePlanPrice?.ChannelPriceInfo;

    // const dailyPriceMap: { [date: string]: number } = {};
    // let dailyPriceList: RateViewInfo[] = [];
    // if (rawPrice.RatePlanPriceList.length > 0) {
    //   rawPrice.RatePlanPriceList.forEach((room) => {
    //     room.RateList.forEach((rate) => {
    //       const dateStr = DateTimeHelper.getDateString(rate.StayDate);
    //       if (!dailyPriceMap[dateStr]) {
    //         dailyPriceMap[dateStr] = 0;
    //       }
    //       dailyPriceMap[dateStr] += rate.PriceSummary.Price;
    //     });
    //   });
    //   const roomCount = rawPrice.RatePlanPriceList.length;
    //   // 小数位处理
    //   // 这里务必使用向上取值，因为房价平均之后极端情况下会对不上 totalPrice，譬如3个房间住3天，除了第一天第三间房是34元外，其他都是33元，那么就会多出来1块无论怎么平均都对不上总价
    //   // 为避免用户较真，直接向上取让每晚房价加总后不会少于 totalPrice
    //   dailyPriceList = Object.getOwnPropertyNames(dailyPriceMap).map(
    //     (date) => ({
    //       stayDate: date,
    //       price: Math.ceil(dailyPriceMap[date] / roomCount),
    //     })
    //   );
    // }

    const roomDailyInfoList: FalconOrdering.RoomDailyInfo[] =
      rawPrice.RatePlanPriceList.map((rppl) => ({
        RoomNumber: rppl.RoomNum,
        PriceList: rppl.RateList.map((rate) => ({
          StayDate: rate.StayDate,
          Price: rate.PriceSummary.Price,
          MealTypeID: rate.MealInfo?.MealType.ID,
          MealAmount: rate.MealInfo?.MealAmount,
        })),
      }));

    const mealInfoList = rawPrice.RatePlanPriceList.sort(
      (a, b) => a.RoomNum - b.RoomNum
    ).map((r) => r.RatePlan.MealInfo);

    const rawCancellationList = ratePlanPrice.CancellationList;
    const cancellationList = CancellationHelper.normalize(
      ratePlanPrice.CancellationList,
      ratePlanPrice.TimeZoneInfo
    );

    let priceSummary: FalconBooking.PriceSummary = rawPrice.PriceSummary;
    let hotelPriceSummary: FalconBooking.PriceSummary = null;
    const [firstRatePlanPrice, ...restRatePlanPriceList] =
      rawPrice.RatePlanPriceList;
    if (firstRatePlanPrice.ReferencePriceSummary) {
      priceSummary = restRatePlanPriceList.reduce(
        (result, item) => {
          result.Price += item.ReferencePriceSummary.Price;
          result.PriceBeforeTax += item.ReferencePriceSummary.PriceBeforeTax;
          result.TaxAndFee += item.ReferencePriceSummary.TaxAndFee;
          result.ExcludedFees?.map((fee, idx) => {
            fee.Amount += item.ReferencePriceSummary.ExcludedFees[idx].Amount;
          });
          result.IncludedFees?.map((fee, idx) => {
            fee.Amount += item.ReferencePriceSummary.IncludedFees[idx].Amount;
          });
          return result;
        },
        { ...firstRatePlanPrice.ReferencePriceSummary }
      );

      hotelPriceSummary = rawPrice.PriceSummary;

      if (
        rawPrice.PriceSummary.Currency !==
        firstRatePlanPrice.ReferencePriceSummary.Currency
      ) {
        CancellationHelper.exchangeCurrency(
          cancellationList,
          hotelPriceSummary,
          priceSummary
        );
      }
    }

    const totalExcludedCharges = this.getTotalChargeInfo(
      priceSummary.ExcludedFees
    );

    const excludedFeeCurrencyNotMatchUserCurrency =
      totalExcludedCharges?.Amount > 0 &&
      totalExcludedCharges?.Currency !== priceSummary.Currency;

    const totalIncludedCharges = this.getTotalChargeInfo(
      priceSummary.IncludedFees
    );

    const totalHotelExcludedCharges = this.getTotalChargeInfo(
      hotelPriceSummary?.ExcludedFees
    );

    let valueAddDesc = '';
    if (RatePlan?.ValueAddList) {
      valueAddDesc = RatePlan?.ValueAddList.map((item) => item.Content).join(
        '/'
      );
    }

    const totalDiscount = -(RatePlan?.SpecialPromotionList || []).reduce(
      (accu, val) => accu + val.Amount,
      0
    );

    let totalPrice = priceSummary.Price;
    if (!excludedFeeCurrencyNotMatchUserCurrency && totalExcludedCharges) {
      totalPrice += totalExcludedCharges.Amount;
    }

    let excludedFeeCurrencyNotMatchHotelCurrency = false;
    let totalHotelPrice = null;
    if (hotelPriceSummary) {
      totalHotelPrice = hotelPriceSummary.Price;
      if (totalHotelExcludedCharges?.Amount > 0) {
        excludedFeeCurrencyNotMatchHotelCurrency =
          totalHotelExcludedCharges?.Currency !== hotelPriceSummary?.Currency;
      }

      if (
        !excludedFeeCurrencyNotMatchHotelCurrency &&
        totalHotelExcludedCharges
      ) {
        totalHotelPrice += totalHotelExcludedCharges.Amount;
      }
    }

    const isPayLater =
      rawPrice.PriceSummary.RatePaymentType >
      FalconPayment.RatePaymentTypeEnum.PrePay;

    // 房型是否匹配
    const isMatched = RatePlan.DidaRoomTypeID > 0;

    return {
      sessionId,
      responseId,
      hotelInfo: HotelInfoHelper.simplifyHotelStatic(rawPrice.Hotel),
      bookingNotice: rawPrice.BookingNotice,
      hasPrice: !!rawPrice,
      priceSummary,
      hotelPriceSummary,
      mealInfoList,
      // dailyPriceList,
      roomCount: rawPrice.RatePlanPriceList.length,
      roomDailyInfoList,
      channelPriceInfo: channelPriceInfo,
      paymentType: rawPrice.PriceSummary.RatePaymentType,
      isPayLater,
      totalExcludedCharges,
      totalIncludedCharges,
      totalHotelExcludedCharges,
      totalPrice,
      totalHotelPrice: totalHotelPrice,
      showCurrencyExchagneDesc:
        isPayLater && priceSummary.Currency !== hotelPriceSummary?.Currency,
      excludedFeeCurrencyNotMatchUserCurrency,
      excludedFeeCurrencyNotMatchHotelCurrency,
      nightCount: rawPrice.RatePlanPriceList[0].RateList.length,
      bankCardInputConditions:
        rawPrice.RatePlanPriceList[0].BankCardInputConditions,

      ratePlanName: RatePlan.RatePlanName,
      ratePlanMask: RatePlan.RatePlanMask,
      ratePlanId: RatePlan.DidaRatePlanID,
      mealInfo: RatePlan.MealInfo,
      didaRoomTypeName: isMatched ? RatePlan.DidaRoomTypeName : null,
      didaRoomTypeId: isMatched ? RatePlan.DidaRoomTypeID : -999,
      bedType: RatePlan.BedType,
      isBedTypeAsured: RatePlan.BedTypeAssured,
      bedTypeDesc: RatePlan.BedTypeAssured ? RatePlan.BedType.Name : null,
      // price: rate.PriceSummary.Price,
      // supplement: rate.PriceSummary.Supplement,
      // stayDate: rate.StayDate,
      rateList: ratePlanPrice.RateList,
      isNoSmoking: !!RatePlan.ExtraInfoIDList?.includes(
        RatePlanExtraInfoType.NoSmoking
      ),
      isNoWindow: !!RatePlan.ExtraInfoIDList?.includes(
        RatePlanExtraInfoType.NoWindow
      ),
      withBreakfast:
        RatePlan.BreakfastType?.ID === CoreCommon.Breakfasts.WithBreakfast,
      confirmInMinutes: RatePlan.IsOnRequest
        ? RatePlan.OnRequestLimitTimeMinutes
        : 0,
      isRefundable:
        cancellationList.length > 0 &&
        this.getCancellationStage({
          cancellationList,
          priceSummary,
        }) !== CancellationStateType.NoneRefundable,
      cancellationList,
      rawCancellationList,
      promotionList: RatePlan.SpecialPromotionList,
      isHourlyRoom: RatePlan.IsHourlyRoom,
      supplierSpecailRequests: RatePlan.SupportedSpecialRequestTypeList,
      valueAddList: RatePlan.ValueAddList,
      valueAddDesc,
      totalDiscount,
      originalPrice: 0,
      priceUnavailable: false,
      errMsg: '',
      allowGuestNameInChinese: ratePlanPrice.AllowGuestNameInChinese,
      timeZoneInfo: ratePlanPrice.TimeZoneInfo,
    };
  }

  private static getPriceChange(
    priceSummary1: FalconBooking.PriceSummary,
    priceSummary2: FalconBooking.PriceSummary
  ) {
    const isPriceEqual = ObjectHelper.isKeysEqual(
      priceSummary1,
      priceSummary2,
      ['Currency', 'Price']
    );
    // if (process.env.NODE_ENV === 'development') {
    //   isPriceEqual = false;
    // }
    if (!isPriceEqual) {
      return {
        from: priceSummary1,
        to: priceSummary2,
      };
    }
    return null;
  }

  private static getCancellationChange(
    preRatePlan: SimplifiedRatePlanPrice,
    curRatePlan: SimplifiedRatePlanPrice
  ) {
    let cancellationChangeType: 'info' | 'warning' = null;
    const currentState = this.getCancellationStage(curRatePlan);
    const previousState = this.getCancellationStage(preRatePlan);
    switch (previousState) {
      case CancellationStateType.FreeCancell:
        if (
          currentState === CancellationStateType.Pay2cancell ||
          currentState === CancellationStateType.NoneRefundable
        ) {
          cancellationChangeType = 'warning';
        }
        break;
      case CancellationStateType.Pay2cancell:
        if (currentState === CancellationStateType.NoneRefundable) {
          cancellationChangeType = 'warning';
        }
        break;
    }

    if (
      cancellationChangeType === null &&
      ![
        CancellationStateType.NoneRefundable,
        CancellationStateType.NeedReget,
      ].includes(previousState as number)
    ) {
      try {
        if (
          curRatePlan.cancellationList.length !==
          preRatePlan.cancellationList.length
        ) {
          throw null;
        }
        curRatePlan.cancellationList.map((item, index) => {
          const targetItem = preRatePlan.cancellationList[index];
          const compareKeys: (keyof FalconCommon.DayCancellation)[] = [
            'CancellationFromTime',
            'Amount',
          ];
          compareKeys.map((key) => {
            if (item[key] !== targetItem[key]) {
              throw null;
            }
          });
        });
      } catch {
        cancellationChangeType = 'info';
      }
    }

    // if (process.env.NODE_ENV === 'development') {
    //   cancellationChangeType = 'warning';
    // }
    switch (cancellationChangeType) {
      case 'info':
        // result.hasCancellationChange = true;
        return {
          themeType: cancellationChangeType,
          from: previousState,
          to: currentState,
        };
      case 'warning':
        return {
          themeType: cancellationChangeType,
          from: previousState,
          to: currentState,
        };
      default:
        return null;
    }
  }

  private static getMealInfoChange(
    preMealInfoList: CoreCommon.MealInfoModel[],
    curMealInfoList: CoreCommon.MealInfoModel[]
  ) {
    const count = Math.min(preMealInfoList.length, curMealInfoList.length);

    for (let i = 0; i < count; i++) {
      const last = preMealInfoList[i];
      const current = curMealInfoList[i];
      if (last.MealType.ID !== current.MealType.ID) {
        // 餐型变化
        return {};
      }

      if (
        current.MealType.ID !== CoreCommon.MealType.RoomOnly &&
        current.MealAmount !== last.MealAmount
      ) {
        // 餐型份数变化
        return {};
      }
    }

    return null;
  }

  /**
   * cancellationChangeType:
   * null 就是取消政策没有改变
   * info 就是细节改变，只需提示有变化
   * warning 是指从限时取消变为付费取消或不可退改，或者从付费取消变为不可退改
   *
   */
  static checkPriceChange(
    previousPrice: SimplifiedRatePlanPrice,
    currentPrice: SimplifiedRatePlanPrice
  ) {
    // 检查价格
    const priceChange = this.getPriceChange(
      previousPrice.priceSummary,
      currentPrice.priceSummary
    );

    // 检查取消政策
    const cancellationChange = this.getCancellationChange(
      previousPrice,
      currentPrice
    );

    // 检查餐型
    const mealInfoChange = this.getMealInfoChange(
      previousPrice.mealInfoList,
      currentPrice.mealInfoList
    );

    const result: PriceChangeResultModel = {
      priceChange,
      cancellationChange,
      mealInfoChange,
      hasChange: Boolean(priceChange || cancellationChange || mealInfoChange),
    };
    return result;
  }

  static getCancellationStage(
    ratePlanPrice: Pick<
      SimplifiedRatePlanPrice,
      'cancellationList' | 'priceSummary'
    >
  ) {
    const nowSeconds = +new Date() / 1000;
    if (
      nowSeconds <
      ratePlanPrice.cancellationList[0].CancellationFromTimestampSeconds
    ) {
      return CancellationStateType.FreeCancell;
    }

    const curRule = [...ratePlanPrice.cancellationList]
      .reverse()
      .find((item) => item.CancellationFromTimestampSeconds < nowSeconds);
    if (curRule.Amount >= ratePlanPrice.priceSummary.Price) {
      return CancellationStateType.NoneRefundable;
    } else {
      return CancellationStateType.Pay2cancell;
    }
  }
}
