import {
  AppConfigContext,
  AppRouteConfigContext,
  useNavigater,
} from '@b2c/hooks';
import { BarsOutlineIcon, UserFillIcon } from '@b2c/icons';
import { Sidebar } from '@b2c/ui';
import classNames from 'classnames';
import { useContext, useMemo, useState } from 'react';
import { Flex, Popup } from 'react-vant';
import { ReactComponent as LogoBlue } from './imgs/logo-blue.svg';
import { ReactComponent as LogoWhite } from './imgs/logo.svg';
import styles from './layout-header.module.less';

export function LayoutHeader() {
  const { navigateTo } = useNavigater();

  const [showPopup, setShowPopup] = useState(false);
  const routeData = useContext(AppRouteConfigContext);

  const customerService = useContext(AppConfigContext).customerService;

  const [LogoComponent, headerModifier] = useMemo(() => {
    switch (routeData.headerStyle) {
      case 'ghost':
        return [LogoWhite, styles.style_ghost];
      default:
        return [LogoBlue, null];
    }
  }, [routeData.headerStyle]);

  return (
    <div className={classNames(styles.layoutHeader, headerModifier)}>
      <div className={styles.layoutContainer}>
        <Flex justify="between" align="center">
          <div className={styles.headerIcon} onClick={() => setShowPopup(true)}>
            <BarsOutlineIcon />
          </div>
          {!routeData.hideHeaderLogo && (
            <LogoComponent
              className={styles.brand}
              width={120}
              height={20}
              onClick={() => navigateTo('/')}
            />
          )}
          <div
            onClick={() => navigateTo('/user')}
            className={styles.headerIcon}
          >
            <UserFillIcon />
          </div>
        </Flex>
      </div>

      <Popup
        visible={showPopup}
        position="left"
        style={{ width: '60%', height: '100%' }}
        closeable
        closeOnPopstate
        onClose={() => setShowPopup(false)}
      >
        <Sidebar config={customerService}></Sidebar>
      </Popup>
    </div>
  );
}
