import {
  CountryInfoContext,
  useLocale,
  usePaymentCardRules,
  usePaymentStateCodeOptions,
} from '@b2c/hooks';
import { FieldExposeMethod, StringHelper } from '@b2c/services/common';
import { getPropertyDisplayInfoList } from '@b2c/services/decorator';
import {
  BankCardType,
  FalconPayment,
  PaymentProcessingNetworkType,
} from '@b2c/services/pay-channel';
import { ValidateHelper } from '@b2c/services/validate';
import produce from 'immer';
import {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
} from 'react';
import { Flex, Form, Input, Space } from 'react-vant';
import { Select } from '../select/select';

export interface IPaymentInfoEditorProps {
  minExpiredDate: Date;
  value: FalconPayment.BankCardPaymentInfo;
  control: FalconPayment.BankCardInputConditions;
  onChange(value: FalconPayment.BankCardPaymentInfo): void;
}

export const PaymentInfoEditor = forwardRef<
  FieldExposeMethod,
  IPaymentInfoEditorProps
>((props, ref) => {
  const t = useLocale();
  const [formRef] = Form.useForm();

  const [paymentMethodList, bankCardTypeList] = useMemo(() => {
    if (!props.control) {
      return [];
    }
    const PaymentMethodList = getPropertyDisplayInfoList(
      PaymentProcessingNetworkType
    );
    const pml = PaymentMethodList.filter((item) =>
      props.control.RestrictedPPNs.includes(item.value)
    );

    const BankCardTypeList = getPropertyDisplayInfoList(BankCardType);
    const bctl = BankCardTypeList.filter((item) =>
      props.control.RestrictedCardTypes.includes(item.value)
    );
    return [pml, bctl];
  }, [props.control]);

  useImperativeHandle(ref, () => ({
    setTouched() {
      formRef.validateFields();
    },
    isValid() {
      return formRef.validateFields().then(
        () => {
          const fields = formRef.getFieldsError();
          return !fields.some((item) => item.errors.length > 0);
        },
        () => {
          return false;
        }
      );
    },
  }));

  const rules = usePaymentCardRules();

  const onContentChange = useCallback(
    (changedValue: object) => {
      props.onChange(
        produce(props.value, (draft) => {
          Object.keys(changedValue).forEach((key) => {
            let value = changedValue[key];
            if (!value && typeof value === 'object' && 'target' in value) {
              value = value.target.value;
            }
            if (key === 'CardNumber') {
              const newVal = value?.trim().replaceAll(' ', '').slice(0, 19);
              const cardIssuer = ValidateHelper.getCardIssuer(newVal);
              if (props.control?.RestrictedPPNs.includes(cardIssuer)) {
                formRef.setFieldValue('PaymentProcessingNetwork', cardIssuer);
                draft.PaymentProcessingNetwork = cardIssuer;
              }
              value = StringHelper.chunk(newVal, 4).join(' ');
              formRef.setFieldsValue({
                CardNumber: value,
              });
            } else if (key === 'PaymentCountryCode') {
              formRef.setFieldsValue({
                PaymentStateOrProvinceCode: null,
              });
              Reflect.set(draft, 'PaymentStateOrProvinceCode', null);
            } else if (key === 'ExpireDate') {
              value = (value as string)?.replace(/[^\d]/g, '').slice(0, 4);
              if (value.length === 1) {
                if (+value > 1) {
                  value = '0' + value;
                }
              } else if (value.length > 2) {
                value = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
              }
              formRef.setFieldValue('ExpireDate', value);
            }
            Reflect.set(draft, key, value);
          });
        })
      );
    },
    [props.value, props.onChange]
  );

  const countryList = useContext(CountryInfoContext).countryInfoList;

  const stateCodeList = usePaymentStateCodeOptions(
    props.control.StateOrProvinceCodeRequiredRegions?.includes(
      props.value.PaymentCountryCode
    ) && props.value.PaymentCountryCode
  ).map((item) => ({
    name: item,
    value: item,
  }));

  if (!props.control) {
    return null;
  }
  return (
    <Form
      onValuesChange={onContentChange}
      className="mu-form mu-form--vertical"
      layout="vertical"
      form={formRef}
    >
      <Space direction="vertical" block>
        {/* 银行卡类型 */}
        {props.control.RestrictedCardTypes.length > 1 && (
          <Form.Item isLink label={t('Label_CardType')} name="BankCardType">
            <Select
              value={props.value.BankCardType}
              options={bankCardTypeList.map((item) => ({
                value: item.value,
                name: t(item.name),
              }))}
            />
          </Form.Item>
        )}
        {/* 所属 */}
        <Form.Item
          required
          label={t('Label_CreditCardType')}
          rules={rules.required}
          name="PaymentProcessingNetwork"
        >
          <Select
            value={props.value.PaymentProcessingNetwork}
            options={paymentMethodList.map((item) => ({
              value: item.value,
              name: t(item.name),
            }))}
          />
        </Form.Item>
        {/* 卡号 */}
        <Form.Item
          required
          label={t('Label_CardNumber')}
          name="CardNumber"
          rules={rules.cardNumber(props.control.RestrictedPPNs)}
        >
          <Input
            placeholder="xxxx xxxx xxxx xxxx xxxx"
            maxLength={50}
            value={props.value.CardNumber}
          ></Input>
        </Form.Item>
        {/* 发卡银行 */}
        {props.control.IsNeedIssuingBank &&
          props.control.RestrictedIssuingBanks.length > 1 && (
            <Form.Item
              label={t('Label_CardIssueBank')}
              name="IssuingBank"
              rules={rules.required}
            >
              <Select
                value={props.value.IssuingBank}
                options={props.control.RestrictedIssuingBanks.map((item) => ({
                  value: item,
                  name: item,
                }))}
              />
            </Form.Item>
          )}
        {/* 持卡人名 */}
        <Form.Item
          required
          label={t('Label_CardHolderName')}
          name="CardHolderName"
          rules={rules.holderName}
        >
          <Input
            maxLength={50}
            value={props.value.CardHolderName}
            placeholder={t('Placeholder_CreditCard_HolderName')}
          ></Input>
        </Form.Item>
        {/* 持卡人邮箱 */}
        <Form.Item
          label={t('Label_CardHolderEmail')}
          name="CardHolderEmail"
          rules={rules.email}
        >
          <Input
            placeholder="name@sample.com"
            maxLength={100}
            value={props.value.CardHolderEmail}
          ></Input>
        </Form.Item>
        <Flex gutter={10}>
          <Flex.Item span="12">
            <Form.Item
              required
              label={t('Label_ExpireDate')}
              name="ExpireDate"
              rules={rules.expireDate(props.minExpiredDate)}
            >
              <Input
                maxLength={5}
                value={props.value.ExpireDate}
                placeholder="MM/YY"
              />
            </Form.Item>
            {/* 过期日期 */}
          </Flex.Item>
          <Flex.Item span="12">
            {/* CVV */}
            {props.value.PaymentProcessingNetwork ===
            PaymentProcessingNetworkType.AMERICAN_EXPRESS ? (
              <Form.Item
                label="CVV"
                tooltip={{
                  title: t('Notice_CID_Desc'),
                  confirmButtonText: t('Button_OK'),
                }}
                name="CVV"
                rules={rules.CID}
              >
                <Input
                  maxLength={4}
                  placeholder="xxxx"
                  value={props.value.CVV}
                ></Input>
              </Form.Item>
            ) : (
              <Form.Item
                label="CVV"
                tooltip={{
                  title: t('Notice_CVV_Desc'),
                  confirmButtonText: t('Button_OK'),
                }}
                name="CVV"
                rules={rules.CVV}
              >
                <Input
                  maxLength={3}
                  placeholder="xxx"
                  value={props.value.CVV}
                ></Input>
              </Form.Item>
            )}
          </Flex.Item>
        </Flex>
        {/* 支付所在地国家 */}
        {props.control.IsNeedPaymentCountry && (
          <Form.Item
            label={t('Label_Country')}
            name="PaymentCountryCode"
            required
            rules={rules.required}
          >
            <Select
              value={props.value.PaymentCountryCode}
              options={countryList.map((item) => ({
                value: item.code,
                name: item.code + ' ' + item.name,
              }))}
            />
          </Form.Item>
        )}

        {stateCodeList?.length > 0 && (
          <Form.Item
            label={t('Label_StateCode')}
            name="PaymentStateOrProvinceCode"
            required
            rules={rules.required}
          >
            <Select
              value={props.value.PaymentStateOrProvinceCode}
              options={stateCodeList}
            />
          </Form.Item>
        )}

        {/* 支付所在地城市 */}
        {props.control.IsNeedPaymentAddress && (
          <Form.Item
            label={t('Label_City')}
            name="PaymentCity"
            required
            rules={rules.required}
          >
            <Input value={props.value.PaymentCity}></Input>
          </Form.Item>
        )}
        {/* 支付所在地地址 */}
        {props.control.IsNeedPaymentAddress && (
          <Form.Item
            label={t('Label_Address')}
            required
            rules={rules.required}
            name="PaymentAddress"
          >
            <Input value={props.value.PaymentAddress}></Input>
          </Form.Item>
        )}
        {/* 支付所在地邮编 */}
        {props.control.IsNeedPaymentZipCode && (
          <Form.Item
            label={t('Label_ZipCode')}
            required
            name="PaymentZipCode"
            rules={rules.required}
          >
            <Input value={props.value.PaymentZipCode}></Input>
          </Form.Item>
        )}
      </Space>
    </Form>
  );
});
