import { FalconBooking } from '../booking';
import { FalconCommon } from '../common';
import { FalconPayment } from '../pay-channel';
import { FalconRate } from '../rate';

export namespace FalconOrdering {
  export interface OrderOption extends FalconRate.RatePlanRateSearchOption {
    /**可选项目（如果已经选择PayChannel，则直接尝试进行支付操作，否则只进行订单锁定booked） */
    PaymentInfo?: PaymentInfo;

    /**当首次执行预订时，需要传递关键校验参数，主要是预防变价 */
    ValidationFactors?: ValidationFactors;

    /**
     * 针对已经有订单号的订单
     * (提供单号之后无需再提供除PayChannel外的其它信息，因为这时候其它信息已经不能变更)
     */
    BookingNumber?: string;

    /**
     * 订单Token（用途：Ordering, 3小时有效)
     */
    BookingToken?: string;
  }

  export interface PaymentInfo {
    PayChannel: number;

    Currency: string;

    TotalAmount: number;

    /**用于支付成功后的跳转（前端赋值） */
    PaymentReturnUrl: string;

    /**用于不继续支付，点击页面内返回按钮 */
    PaymentBackUrl: string;

    /**用于支付通知的推送入口（后端赋值） */
    // PaymentNotifyUrl: string;

    BankCardInfo?: FalconPayment.BankCardPaymentInfo;
  }

  export interface ValidationFactors {
    Currency: string;
    /**RatePlanRate请求返回的价格 */
    TotalPrice: number;

    IsOnRequest: boolean;

    IsHourlyRoom: boolean;

    /**房间的每天的信息 */
    RoomDailyInfoList: RoomDailyInfo[];

    /**RatePlanRate请求返回的取消政策 */
    CancellationList: FalconCommon.DayCancellation[];

    ExcludedFeeList: FalconBooking.FalconFeeModel[];

    //#region 静态信息核对
    HotelName: string;
    DidaRoomTypeID: string;
    DidaRoomTypeName: string;
    BedTypeID: number;
    BedTypeName: string;
    IsBedTypeAsured: boolean;
    //#endregion
  }

  export interface RoomDailyInfo {
    RoomNumber: number;
    PriceList: DailyInfo[];
  }

  export interface DailyInfo {
    StayDate: Date | string;
    Price: number;
    MealTypeID: number;
    MealAmount: number;
  }

  export interface ResultModel {
    /**当前请求的处理结果 */
    Success: boolean;

    /**整体预订请求是否结束（一般来说，这里为False的情况是需要跳转到第三方支付渠道支付） */
    Finished: boolean;

    /**执行结果编号 */
    MessageCode: MessageCodeEnum;

    /**当前步骤 */
    Stage: StageEnum;

    /**预订主流程外部（包括Transaction, TigerCaller等）的细分错误代码，一般来说不用管 */
    ExternalCode: number;

    /**需要继续操作的信息 */
    ReqiredActionInfo: RequireActionInfo;

    /**预订价格结果（当变价时才有） */
    HotelRatePlanPrice: FalconRate.HotelRatePlanPriceModel;

    /**支付确认信息 */
    PayConfirmInfo: PayConfirmInfo;

    /**订单信息概要 */
    BookingSnapshot: FalconBooking.SnapshotModel;

    Message: string;
  }

  export interface RequireActionInfo {
    /**第三方支付需要提交的表单 */
    ThirdPartyFormHtml: string;

    /**第三方二维码ID（这里目前就是扫码支付使用） */
    ThirdPartyQRCodeID: string;

    /**需要选择支付渠道（用于prebook先于选择支付渠道） */
    PayChannelNeeded: boolean;
  }

  export interface PayConfirmInfo {
    ReturnContent: string;
    ReturnContentType: string;
  }

  export interface OrderStatusCheckOption {
    BookingNumber: string;
    /**
     * 用途为Ordering的Token
     */
    Token: string;
    /**
     * 是否需要获取全部信息（一般首次请求就需要）
     */
    NeedFullBookingInfo: boolean;
  }

  export enum MessageCodeEnum {
    //      #region [2xxxx] - 成功分类
    Success = 20000,
    // [Description("Success and action required")]
    Success_ActionRequired = 20001,
    // [Description("Success and pay confirm notice handled")]
    //Success_PayConfirmNoticeHandled = 20002,
    // [Description("Success and waiting booking confirm")]
    Success_WaitingBookingConfirm = 20003,
    // [Description("Success and booking process already finished")]
    Success_BookingProcessAlreadyFinished = 20004,
    // [Description("Success and need selecting a pay channel")]
    Success_PayChannelNeeded = 20005,
    // #endregion

    //      #region [4xxxx] - 错误分类
    // [Description("Unknown Error")]
    UnknownError = 40000,
    Error_RiskControl_HighRiskBehavior = 40001,

    //      #region [41xxx] - 处理流程的错误

    //      #region [410xx] - [Init]的错误
    // [Description("BookingUnknown Error      Error_Init_Unknown = 41000,
    // [Description("Booking not found      Error_Init_BookingNotFound = 41001,
    // [Description("Booking status is not valid      Error_Init_InvalidBookingStatus = 41002,
    // [Description("Booking has been processed already      Error_Init_BookingProcessedAlready = 41003,
    // [Description("Missing payment info      Error_Init_PaymentInfoMissing = 41004,
    // [Description("Invalid transaction status      Error_Init_InvalidTransactionStatus = 41005,
    // [Description("Order request not exist or expired [Init]")]
    Error_Init_OrderRequestNotExistOrExpired = 41006,
    // #endregion

    //      #region [411xx] - [PreBook]的错误
    // [Description("Unknown Error      Error_PreBook_Unknown = 41100,

    // [Description("Price not available")]
    Error_PreBook_PriceNotAvailable = 41101,
    // [Description("Price changed")]
    Error_PreBook_PriceChanged = 41102,
    // [Description("Rate plan info changed")]
    Error_PreBook_RatePlanInfoChanged = 41103,
    // [Description("Cancellation changed")]
    Error_PreBook_CancellationChanged = 41104,
    // [Description("Other info changed")]
    Error_PreBook_OtherInfoChanged = 41105,
    // [Description("Failed to create booking")]
    Error_PreBook_FailedToCreateBooking = 41106,
    // #endregion

    //      #region [412xx] - [Pay]的错误
    // [Description("[Pay] Unknown Error")]
    Error_Pay_Unknown = 41200,
    // [Description("[Pay] Not supported pay channel")]
    Error_Pay_NotSupportedPayChannel = 41201,
    // [Description("[Pay] Invalid pay option")]
    Error_Pay_InvalidPayOption = 41202,
    // [Description("[Pay] Invalid payment account")]
    Error_Pay_InvalidPaymentAccount = 41203,
    // [Description("[Pay] Process error")]
    Error_Pay_ProcessFailed = 41204,
    // [Description("[Pay] Payment amount does not match with booking (currency/amount)")]
    Error_Pay_IncorrectPaymentAmount = 41205,

    // --- add additional [Pay] error code above (code < 41299)
    // [Description("[Pay] Unexpected transaction response")]
    Error_Pay_UnexpectedMessageCode = 41299,
    // #endregion

    //#region [413xx] - [ConfirmPayment]的错误
    // [Description("[ConfirmPayment] Unknown Error")]
    //Error_ConfirmPayment_Unknown = 41300,
    // [Description("[ConfirmPayment] Not supported pay channel")]
    //Error_ConfirmPayment_NotSupportedPayChannel = 41301,
    // [Description("[ConfirmPayment] Invalid pay confirm option")]
    //Error_ConfirmPayment_InvalidPayConfirmOption = 41302,
    // [Description("[Pay] Invalid payment account")]
    //Error_ConfirmPayment_InvalidPaymentAccount = 41203,
    // [Description("[ConfirmPayment] Process error")]
    //Error_ConfirmPayment_ProcessFailed = 41304,

    // --- add additional [ConfirmPayment] error code above (code < 41399)       */
    // [Description("[ConfirmPayment] Unexpected transaction response")]
    //Error_ConfirmPayment_UnexpectedMessageCode = 41399,
    // #endregion

    //      #region [414xx] - [ConfirmBooking]的错误
    // [Description("Unknown Error      Error_ConfirmBooking_Unknown = 41400,

    // [Description("Unknown booking status")]
    Error_ConfirmBooking_UnknownBookingStatus = 41401,
    // [Description("Failed to confirm booking")]
    Error_ConfirmBooking_BookingFailed = 41402,
    // #endregion

    // #endregion

    // #endregion
  }

  export enum StageEnum {
    /**初始化       */
    Init = 0,

    /**预订（生成订单ID）       */
    Prebook = 1,

    /**支付       */
    Pay = 2,

    // Roman: 由于重构了transaction处理逻辑，`ConfirmPayment`可以省略，但为了不冲突，enum数值不变
    /**确认支付结果       */
    //ConfirmPayment = 3,

    /**确认订单        */
    ConfirmBooking = 4,
  }
}
