import { ValidateHelper } from '@b2c/services/validate';
import { FormInstance } from 'rc-field-form';
import { useMemo } from 'react';
import { useLocale } from './use-locale';

export function useFormRulesPreset(form?: FormInstance) {
  const t = useLocale();

  return useMemo(
    () => ({
      password: [
        { required: true, message: t('Error_ItemRequired') },
        { min: 8, message: t('Error_LengthLimitedWith', { len: 8 }) },
      ],
      confirmPassword: (passwordFieldName: string) => {
        if (!form) {
          throw 'form intance required';
        }
        return [
          { required: true, message: t('Error_ItemRequired') },
          { min: 8, message: t('Error_LengthLimitedWith', { len: 8 }) },
          {
            validator(_, newVal) {
              if (newVal !== form.getFieldValue(passwordFieldName)) {
                return Promise.reject(
                  new Error(t('Error_PasswordConfirmationNotMatched'))
                );
              }
              return Promise.resolve(true);
            },
          },
        ];
      },
      email: [
        { required: true, message: t('Error_ItemRequired') },
        {
          validator(_, newVal) {
            if (!ValidateHelper.isEmail(newVal)) {
              return Promise.reject(new Error(t('Error_EmailNotValid')));
            }
            return Promise.resolve(true);
          },
        },
      ],
      confirmCode: [
        { required: true, message: t('Error_ConfirmCodeRequired') },
        {
          validator(_, newVal) {
            if (ValidateHelper.isVerificationCode(newVal)) {
              return Promise.resolve(true);
            }

            return Promise.reject(
              new Error(t('Error_VerificationCodeInvalid'))
            );
          },
        },
      ],
    }),
    [t]
  );
}
