import { SimplifiedHotelDetail } from '@b2c/services/hotel';
import { useMemo } from 'react';
import { useLocale } from './use-locale';

type HotelAttributeModel = {
  id: number;
  desc: string;
};

export function useHotelPolicy(hotelStatic: SimplifiedHotelDetail) {
  const t = useLocale();

  const result = useMemo(() => {
    if (!hotelStatic) {
      return {};
    }
    const checkInOutPolicies: HotelAttributeModel[] = [];
    const petPolicies: HotelAttributeModel[] = [];
    const childPolicies: HotelAttributeModel[] = [];
    const paymentPolicies: HotelAttributeModel[] = [];
    const otherPolicies: HotelAttributeModel[] = [];
    const hotelAmenities: HotelAttributeModel[] = [];
    const roomAmenities: HotelAttributeModel[] = [];

    hotelStatic.HotelAttributeList?.map((attribute) => {
      const attr = {
        id: attribute.AttributeID,
        desc: attribute.AttributeDescription,
      };
      if (attribute.AppendTxt) {
        attr.desc += ' ' + attribute.AppendTxt;
      }

      switch (attribute.Type) {
        case 'Policy':
          switch (attribute.SubType) {
            case 'CheckInOut':
              checkInOutPolicies.push(attr);
              break;
            case 'Payment':
              paymentPolicies.push(attr);
              break;
            case 'Child':
              childPolicies.push(attr);
              break;
            case 'Pets':
              petPolicies.push(attr);
          }
          break;
        case 'Hotel Facilities':
        case 'PropertyAmenity':
          hotelAmenities.push(attr);
          break;
        case 'Room Facilities':
        case 'RoomAmenity':
          roomAmenities.push(attr);
          break;
        default:
          otherPolicies.push(attr);
          break;
      }
    });

    const toHtml = (
      title: string,
      rules: HotelAttributeModel[],
      sectionId?: string
    ) => {
      return `<div${sectionId ? ' ' + sectionId : ''}>
            <b>${title}</b>
            <ul>${rules.map((rule) => `<li>${rule.desc}</li>`).join('')}<ul>
            </div>`;
    };

    const mapTagName = (content: string) => {
      const replaceTagMap = { p: 'div' };

      const policyDesc = content?.replace(
        /<(\/)?(.+?)(\s\/)?>/g,
        (tag, slash, tagName, endSlash) => {
          if (tagName == 'br') {
            return tag;
          }
          return `<${slash || ''}${replaceTagMap[tagName] || tagName}>`;
        }
      );
      return policyDesc;
    };

    let extraPolicy = '';
    if (checkInOutPolicies.length > 0) {
      extraPolicy += toHtml(
        t('Title_HotelPolicy_CheckInAndOut'),
        checkInOutPolicies
      );
    }
    if (paymentPolicies.length > 0) {
      extraPolicy += toHtml(t('Title_HotelPolicy_Payment'), paymentPolicies);
    }
    if (childPolicies.length > 0) {
      extraPolicy += toHtml(t('Title_HotelPolicy_Child'), childPolicies);
    }
    if (petPolicies.length > 0) {
      extraPolicy += toHtml(t('Title_HotelPolicy_Pet'), petPolicies);
    }
    if (otherPolicies.length > 0) {
      extraPolicy += toHtml(t('Title_HotelPolicy_Others'), otherPolicies);
    }
    if (hotelStatic.PolicyDescription) {
      const policyDesc = mapTagName(hotelStatic.PolicyDescription);
      extraPolicy += policyDesc || '';
    }

    if (hotelStatic.ImportNotice) {
      extraPolicy += toHtml(t('Text_Order_SpecialReminder'), [
        {
          id: -999,
          desc: hotelStatic.ImportNotice,
        },
      ]);
    }

    return {
      policyDescription: extraPolicy,
      hotelAmenities,
      roomAmenities,
      description: hotelStatic.Description,
    };
  }, [hotelStatic, t]);

  return result;
}
