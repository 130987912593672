// import { createModel } from 'hox';
import { createContext, useCallback, useEffect, useState } from 'react';

const DestinationSearchHistory = 'HotelPageViewHistory';

const RememberSearchHistoryCount = 6;

export interface HotelPageViewHistoryModel {
  id: number;
  name: string;
  rating: number;
  img: string;
}

export const useHotelPageViewHistory = function () {
  const [historyList, setHistoryList] = useState<HotelPageViewHistoryModel[]>(
    []
  );

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const storageContent = localStorage.getItem(DestinationSearchHistory);
      const list = storageContent ? JSON.parse(storageContent) : [];

      setHistoryList(list.slice(0, RememberSearchHistoryCount));
    }
  }, []);

  function addHotelPageViewHistory(hotelInfo: HotelPageViewHistoryModel) {
    const newHistoryList = [
      hotelInfo,
      ...historyList.filter((item) => item.id !== hotelInfo.id),
    ].slice(0, RememberSearchHistoryCount);
    setHistoryList(newHistoryList);
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(
        DestinationSearchHistory,
        JSON.stringify(newHistoryList)
      );
    }
  }

  function clearHistoryList() {
    setHistoryList([]);
    localStorage.removeItem(DestinationSearchHistory);
  }

  return {
    addHotelPageViewHistory: useCallback(addHotelPageViewHistory, []),
    clearHistoryList: useCallback(clearHistoryList, []),
    historyList,
  };
};

export const HotelPageViewHistoryContext = createContext<
  Partial<ReturnType<typeof useHotelPageViewHistory>>
>({});
