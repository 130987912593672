import { CoreSuggestion } from '@b2c/core';
import { AnalyticsHelper } from '@b2c/services/common';
import {
  DestinationSearchHelper,
  DestinationType,
} from '@b2c/services/destinations';
import { SuggestionOption, SuggestionService } from '@b2c/services/suggestions';
import { useCallback, useEffect, useRef, useState } from 'react';

const MinKeywordLength = 2;
const MaxHotelCount = 4;
const MaxRegionCount = 4;

export function useDestinationSuggestion(parentRegionId?: string) {
  const [keyword, setKeyword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const abortCtlerRef = useRef<AbortController>();

  const [suggestionList, setSuggestionList] = useState<
    {
      keyword: string;
      type: DestinationType;
      options: SuggestionOption[];
    }[]
  >([]);

  const [noMatchingResult, setNoMatchingResult] = useState(false);

  const searchDestination = useCallback((newKeyword: string) => {
    newKeyword = newKeyword.trim();
    setKeyword(newKeyword);
    if (!newKeyword || newKeyword.length < MinKeywordLength) {
      setSuggestionList([]);
      setNoMatchingResult(false);
    } else {
      const payloads: CoreSuggestion.SuggestionQueryPayload[] = [];
      const size = MaxHotelCount + MaxRegionCount;
      if (parentRegionId) {
        // 混合搜索
        payloads.push(
          DestinationSearchHelper.createPayload(
            newKeyword,
            [],
            size,
            undefined,
            parentRegionId
          )
        );
      } else {
        // 分开搜索
        payloads.push(
          DestinationSearchHelper.createPayload(
            newKeyword,
            DestinationSearchHelper.REGION_SEARCH_CATEGORIES,
            size
          )
        );
        payloads.push(
          DestinationSearchHelper.createPayload(
            newKeyword,
            DestinationSearchHelper.HOTEL_SEARCH_CATEGORIES,
            size
          )
        );
      }

      if (abortCtlerRef?.current) {
        abortCtlerRef.current.abort();
        abortCtlerRef.current = null;
      }

      AnalyticsHelper.search(newKeyword);
      abortCtlerRef.current = new AbortController();

      setLoading(true);
      setNoMatchingResult(false);
      SuggestionService.multiplSearch(payloads, abortCtlerRef.current.signal)
        .then((result) => {
          result.forEach((r) => {
            if (r.Data) {
              r.Data.forEach((x) => (x.ID = x.IDString));
            }
          });
          return result;
        })
        .then((result) => {
          const data = result.map((r) => {
            if (r.Data) {
              r.Data.forEach((x) => (x.ID = x.IDString));
            }
            return r;
          });
          const newList = DestinationSearchHelper.toOptions(
            newKeyword,
            MaxHotelCount,
            MaxRegionCount,
            data
          );
          setSuggestionList(newList);
          setNoMatchingResult(!(newList?.length > 0));
        })
        .catch((err) => {
          setSuggestionList([]);
          err.keyword = newKeyword;
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    abortCtlerRef?.current?.abort();
  }, []);

  return {
    clearSuggetionList() {
      setNoMatchingResult(false);
      setSuggestionList([]);
    },
    suggestionList,
    keyword,
    setKeyword,
    searchDestination,
    loading,
    noMatchingResult,
  };
}
