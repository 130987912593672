import { HttpService } from '../http';
import { FalconHotel } from './falcon-hotel.d';

export class HotelStaticService {
  /**
   * 获取酒店全量静态信息
   *
   * **注意：该接口会返回全量信息，数据量会很大**
   *
   */
  static getHotelDetail(options: Partial<FalconHotel.HotelInfoQueryOption>) {
    return HttpService.get<
      FalconHotel.HotelStaticDetailModel,
      Partial<FalconHotel.HotelInfoQueryOption>
    >('/StaticContent/Hotel/GetInfo', options).then((item) => {
      if (
        item.HotelMeta &&
        item.HotelMeta.WebsiteUrl &&
        !item.HotelMeta.WebsiteUrl.startsWith('http')
      ) {
        item.HotelMeta.WebsiteUrl = 'http://' + item.HotelMeta.WebsiteUrl;
      }
      if (item.DestinationIDStr) {
        item.DestinationID = item.DestinationIDStr;
      }

      if (item.AliasList) {
        item.AliasList = item.AliasList.filter(
          ({ Alias, IsChecked }) => item.Name !== Alias && IsChecked
        );
      }
      return item;
    });
  }
}
