import { ArrowDown } from '@react-vant/icons';
import { useState } from 'react';
import { Input, Picker, Popup } from 'react-vant';
import { FormItemProps } from 'react-vant/es/form';

export function Select(
  props: {
    options: { value: any; name: string }[];
    placeholder?: string;
    onChange?(value: any): void;
    value?: string | number;
    title?: string;
  } & FormItemProps
) {
  const { value, onChange, name, title, options, ...fieldProps } = props;
  const [visible, setVisible] = useState(false);
  function onConfirm(value, selectRow, index: number) {
    onChange(props.options[index]?.value);
    onCancel();
  }

  function onCancel() {
    setVisible(false);
  }

  const columns = props.options.map((item) => item.name);
  const selectedOption = props.options.find((item) => item.value === value);

  return (
    <>
      <Input
        isLink
        readOnly
        name={name as string}
        {...fieldProps}
        value={selectedOption?.name || ''}
        onClick={() => setVisible(true)}
        suffix={<ArrowDown />}
      />
      <Popup position="bottom" round visible={visible} onClose={onCancel}>
        <Picker
          title={title}
          columns={columns}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </Popup>
    </>
  );
}
