import { useLocale } from '@b2c/hooks';
import { RatePlanHelper } from '@b2c/services/rateplan';
import { useMemo } from 'react';
import { Typography } from 'react-vant';
import { TypographyTextProps } from 'react-vant/es/typography/PropsType';

export function TypographyConfirmTime({
  delay,
  ...props
}: { delay: number } & Omit<TypographyTextProps, 'type'>) {
  const t = useLocale();

  const delayHour = useMemo(() => {
    return (
      delay && RatePlanHelper.calculateOnRequestLimitTimeInHour(delay, 0.5)
    );
  }, [delay]);

  return (
    <Typography.Text type={delay ? 'warning' : 'success'} {...props}>
      {delay
        ? t('Label_RatePlan_OnRequest', {
            hours: delayHour,
          })
        : t('Label_RatePlan_ImmediateConfirm')}
    </Typography.Text>
  );
}
