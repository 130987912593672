import { CoreCommon } from '@b2c/core';
import { addMinutes, startOfDay, startOfMinute } from 'date-fns';
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import enUS from 'date-fns/locale/en-US';
import ja from 'date-fns/locale/ja';
import zhCN from 'date-fns/locale/zh-CN';

export class DateTimeHelper {
  static readonly DEFAULT_DATE_FORMAT = `yyyy-MM-dd`;
  static readonly DEFAULT_DATE_TIME_FORMAT = `yyyy-MM-dd HH:mm:ss`;

  static convertToLocalTime(timeString: string) {
    if (timeString.includes('+') || timeString.includes('-')) {
      return new Date(timeString);
    } else {
      return new Date(timeString + '+08:00');
    }
  }

  static getDate(date?: any) {
    if (
      typeof date === 'string' &&
      /^\d{2,}-\d{1,2}-\d{1,2}$/.test(date.trim())
    ) {
      // Date解析是如果只有时间部分会解析为标准时间，可能导致format输出问题
      date += 'T00:00:00';
    }
    const tmp = new Date(date || new Date());
    return tmp;
  }

  /**
   *
   * @param date
   * @param formatStr 日期格式，默认yyyy-MM-dd HH:mm:ss
   */
  static getString(
    date: any,
    formatStr = DateTimeHelper.DEFAULT_DATE_TIME_FORMAT,
    locale?: CoreCommon.LanguageCode
  ) {
    const tmp = DateTimeHelper.getDate(date);
    return format(tmp, formatStr, {
      locale:
        {
          'zh-CN': zhCN,
          'en-US': enUS,
          ja: ja,
        }[locale] || enUS,
    });
  }

  /**
   *
   * @param date 日期
   * @returns 日期字符串
   */
  static getDateString(date?: any) {
    return DateTimeHelper.getString(date, DateTimeHelper.DEFAULT_DATE_FORMAT);
  }

  static getDateTimeString(date?: any) {
    return DateTimeHelper.getString(date);
  }

  static getRelateDate(offset = 0, date?: any) {
    return addDays(DateTimeHelper.getDate(date), offset);
  }

  static getDateDiff(date1: any, date2: any) {
    return differenceInDays(
      DateTimeHelper.getDate(date2),
      DateTimeHelper.getDate(date1)
    );
  }

  static getTimezone() {
    if (typeof Intl === 'undefined') {
      return null;
    }
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  static isChinaTimezone() {
    return this.getTimezone() === 'Asia/Shanghai';
  }

  static getDateInfo(date: Date) {
    const dateDiff = this.getDateDiff(startOfDay(new Date()), date);

    return {
      value: date,
      isToday: dateDiff === 0,
      isTomorrow: dateDiff === 1,
    };
  }

  static roundSeconds(date: Date) {
    const seconds = date.getSeconds();
    if (seconds === 0) {
      return date;
    }
    let result = startOfMinute(seconds);
    if (seconds > 50) {
      result = addMinutes(result, 1);
    }
    return result;
  }
}
