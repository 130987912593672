export enum ErrorCode {
  BuildNumberNotCorrect = -2,
  FormValidationError = -1,
  UnknownError = 40000,
  ProcessError = 40001,
  DataBaseError = 41000,
  DataError = 410001,
  RequestParamError = 42000,
  InternalServiceError = 43000,
  RemoteServiceError = 44000,
  PermissionError = 45000,
  AuthenticationError = 45100,
  AuthenticationError_Failure = 45101,
  AuthenticationError_UserLocked = 45102,
  AuthenticationError_ClientLocked = 45103,
  AuthenticationError_EmailNotConfirmed = 45104,
  UserNameAlreadyExistError = 46001,
  EmailAlreadyExistError = 46002,
  EmailInvalidError = 46003,
  EmailFormatInvalidError = 460004,
  UnknownTransactionError = 47000,
  InvailedClientError = 47101,
  InvailedExecutorError = 47102,
  ZeroTransactionAmountError = 47103,
  InCorrectTransactionReasonError = 47104,
  InCorrectTransactionReferenceError = 47105,
  InvalidTransactionIDError = 47106,
  TargetAccountFrozenError = 47201,
  NotEnoughBalanceError = 47202,
  ExceedDailyTransactionAmountError = 47203,
  ExceedMaxTopUpAmountError = 47204,
  ExceedDailyMaxTopUpAmountError = 47205,
  IncorrectPaymentAmountError = 47206,
  IncorrectTransactionStatusError = 47207,
  IncorrectRefundAmountError = 47208,
  DuplicateTransactionError = 47300,
  BookingProcessUnknownError = 48000,
  BookingProcessPriceConfirmError = 48100,
  BookingProcessError = 48200,
  BookingProcessIncorrectReferenceNoError = 48201,
  BookingProcessBookingConfirmedError = 48202,
  BookingProcessBookingCanceledError = 48203,
  BookingProcessPendingBookingError = 48204,
  BookingProcessIncorrectStatusError = 48205,
  BookingProcessBookingExpiredError = 48206,
  BookingProcessIncorrectBookingInformationError = 48207,
  BookingProcessDuplicateBookingError = 48208,
  BookingProcessNoContactInfoError = 48209,
  BookingProcessFailedToConfirmBookingError = 48210,
  BookingProcessPayError = 48300,
  BookingProcessConfirmError = 48400,
  BookingProcessConfirmExecuting = 48401,
  BookingProcessStatusNotMatch = 48500,
  UserPoints_LoginRedeemError = 49100,
}
