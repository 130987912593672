// import { Md5 } from 'ts-md5/dist/md5';
// import { enc, AES, mode, pad} from 'crypto-js';
import { FalconContext } from './falcon-context';

export class SigningHelper {
  static async gernerateEstablishOption() {
    // let sm_str =
    //   'w5fQeVVm4pxG1CuemGy7LieWEs_b6ubM2m-pG1swib7cE7LT_GlAYRTPynBv0tKA8xSlWF8BxRr6cM7g3ypy1M2YQFRlxEwsPU2vos4mBINI_cNCbBJ6TjpnAga244et5XxMpLTpN_otfBygUbwWky5VHLWi1bQq3zwOoGxxLKetxapffQNDCCK9i14w1e-JVobX0FnqgPY0I1yuRDXQJJeBtlGyWFOISgsfk7QBCYL3O28_WK81np7MJ7xrDNfAro08sMVl3sPpf8rzEdHcQv2-Okf58g4RvEVRR3VQ0i8dZnDC74bot4Txf5c7E0dZ0fSMnH3wBNkE5f-KLvQE7vWyUVdMI0-UXy5SsuxmQetW7ZYT8BHEyHfPpn7d8V_KKuBth-bv7rKBVGhH2G92k7UB6OmXM697tGypNLUzVXXieSW67a7aK--WAWWnq6PVgcFaEgOjtxYWLWwHCVrb2kEo_o1ipMxxrniul40lmTFrYaZcxJkO_kT2LyqgloDQTZ2zW4H8cDBRQKdZGNxrzsy9f04yKKDiQov95Ny836nVwPiA0rsgYxq-6rYy8ODniaL-Q8ozHeO7I1POmg74bN-IqCVPn8Bt-Q4DNbPzz-9MwHliogWyshkwHq-xnBVnQ4E_UvaPLOyZmDr6h2XiiQnRcxb2S92g';
    // let security_matrix = new Array(7);

    // for (let i = 0, j = sm_str.length / security_matrix.length; i < security_matrix.length; i++) {
    //   security_matrix[i] = sm_str.substr(i * j, j);
    // }
    // let encryptLogic = {
    //   name: 'AES-CBC',
    //   length: 256
    // };
    // let input = 'pwd4roman@browser';
    // let dow = new Date().getDay();
    const alg_prefix = window.crypto.getRandomValues(new Uint8Array(8));
    const curveType = {
      name: 'ECDH',
      namedCurve: 'P-256',
    };

    const clientKeyPair = await window.crypto.subtle.generateKey(
      curveType,
      true,
      ['deriveKey']
    );

    const clientPk = await window.crypto.subtle.exportKey(
      'raw',
      clientKeyPair.publicKey
    );

    const toClientPk = (clientPk: Uint8Array) => {
      const actualBytes = new Uint8Array(64 + 8);
      actualBytes.set(alg_prefix);
      for (
        let i = 1, j = alg_prefix.byteLength;
        i < clientPk.byteLength;
        i++, j++
      ) {
        actualBytes[j] = clientPk[i];
      }
      return actualBytes;
    };

    // let serverPk = await window.crypto.subtle.importKey('raw', loadServerKey(security_matrix[dow]), curveType, false, []);

    // let derivedKey = await window.crypto.subtle.deriveKey({
    //   ...curveType,
    //   public: serverPk
    // }, clientKeyPair.privateKey, encryptLogic, true, ['encrypt']);
    // let derivedKeyBytes = await window.crypto.subtle.exportKey('raw', derivedKey);

    // let encryptKeyBuffer = await window.crypto.subtle.digest('SHA-256', derivedKeyBytes);
    // let encryptKey = await window.crypto.subtle.importKey('raw', encryptKeyBuffer, encryptLogic, false, ['encrypt']);

    const iv = window.crypto.getRandomValues(new Uint8Array(16));

    // let encodedInput = new TextEncoder().encode(input);
    // let encryptedByteBuffer = await window.crypto.subtle.encrypt({
    //   ...encryptLogic,
    //   iv
    // }, encryptKey, encodedInput);

    // let encrypted = this.toTransferContent(encryptedByteBuffer);
    const iv_val = this.toTransferContent(iv);
    const clientKey = this.toTransferContent(
      toClientPk(new Uint8Array(clientPk))
    );

    return {
      Vec: iv_val,
      Pku: clientKey,
    } as FalconContext.SecurityContextEstablishOption;
  }

  // function _base64ToArray(base64: string) {
  //   let binary_string = window.atob(base64);
  //   let len = binary_string.length;
  //   let bytes = new Uint8Array(len);
  //   for (let i = 0; i < len; i++) {
  //     bytes[i] = binary_string.charCodeAt(i);
  //   }
  //   return bytes;
  // }

  // static urlBase64ToBase64(str: string) {
  //   let r = str % 4;
  //   if (2 === r) {
  //     str += '==';
  //   } else if (3 === r) {
  //     str += '=';
  //   }
  //   return str.replace(/-/g, '+').replace(/_/g, '/');
  // }

  // static loadServerKey(serverKey: string) {
  //   // 1. decode base64
  //   var actualBytes = _base64ToArray(urlBase64ToBase64(serverKey));
  //   // var rawBase64 = new TextDecoder().decode(rawBytes);
  //   // var actualBytes = _base64ToArray(rawBase64);
  //   // 2. skip magic number
  //   var bytes = new Uint8Array(64 + 1);
  //   bytes[0] = 4; // magic number in browser key
  //   for (var i = 1, j = alg_prefix.byteLength; i < actualBytes.byteLength; i++, j++) {
  //     bytes[i] = actualBytes[j];
  //   }
  //   return bytes;
  // }

  static toTransferContent(array: Uint8Array | ArrayBuffer) {
    const rawBase64 = this._arrayToBase64(array);
    return this.base64ToUrlBase64(rawBase64);
  }

  static _arrayToBase64(array: Uint8Array | ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(array);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  static base64ToUrlBase64(str: string) {
    return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }
}
