import { CoreHotel } from '@b2c/core';
import {
  FacilityAirportShuttleIcon,
  FacilityGymIcon,
  FacilityMeetingRoomIcon,
  FacilityParkingIcon,
  FacilityRestaurantIcon,
  FacilitySwimmingPoolIcon,
  FacilityWifiIcon,
} from '@b2c/icons';
import { t } from '@b2c/services/decorator';
import { useMemo } from 'react';

export interface FacilityInfoModel {
  name: string;
  icon: (props: any) => JSX.Element;
  filterKey: keyof CoreHotel.HotelFacilitySetting;
}

const FacilityInfoList: FacilityInfoModel[] = [
  {
    name: t('Facilicty_FreeWifi'),
    filterKey: 'Wifi',
    icon: FacilityWifiIcon,
  },
  {
    name: t('Facility_FreeParking'),
    filterKey: 'Parking',
    icon: FacilityParkingIcon,
  },
  {
    name: t('Facility_SwimmingPool'),
    filterKey: 'Pool',
    icon: FacilitySwimmingPoolIcon,
  },

  {
    name: t('Facility_AirportTransfer'),
    filterKey: 'AirportTransfer',
    icon: FacilityAirportShuttleIcon,
  },

  {
    name: t('Facility_FitnessCentre'),
    filterKey: 'Gym',
    icon: FacilityGymIcon,
  },

  {
    name: t('Facility_Restaurant'),
    filterKey: 'Restaurant',
    icon: FacilityRestaurantIcon,
  },

  {
    name: t('Facility_MettingRoom'),
    filterKey: 'MeetingRoom',
    icon: FacilityMeetingRoomIcon,
  },
];

export function useHotelFacilities(
  facilitySetting: CoreHotel.HotelFacilitySetting
) {
  const facilityInfoList = useMemo(() => {
    if (process.env.NODE_ENV === 'development') {
      return FacilityInfoList;
    }

    let result = [];

    if (facilitySetting) {
      result = FacilityInfoList.filter(
        (item) => !!facilitySetting[item.filterKey]
      );
    }

    return result;
  }, [facilitySetting]);

  return facilityInfoList;
}
