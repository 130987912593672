import { FalconBooking } from '@b2c/services/booking';
import { useMemo } from 'react';
import { Space } from 'react-vant';
import { TypographyGuestName } from '../typography-guest-name';

export function BookingGuestInfo({
  bookingDetail,
}: {
  bookingDetail: FalconBooking.BookingDetailModel;
}) {
  const guestList = useMemo(() => {
    return bookingDetail?.RoomList.reduce((r, room) => {
      room.Occupancy.AdultList.forEach((guest) => {
        if (guest.FirstName && guest.LastName) {
          r.push(guest);
        }
      });
      room.Occupancy.ChildList?.forEach((guest) => {
        if (guest.FirstName && guest.LastName) {
          r.push(guest);
        }
      });
      return r;
    }, []);
  }, [bookingDetail]);
  return (
    <Space divider="|" wrap>
      {guestList.map((item, index) => (
        <TypographyGuestName value={item} key={index}></TypographyGuestName>
      ))}
    </Space>
  );
}
