import { useLocale } from '@b2c/hooks';
import { MembershipService } from '@b2c/services/membership';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Form, Input, Toast } from 'react-vant';
import { MessageService } from '../message.service';
import { ModalService } from '../modal.service';
import { PopupDialog } from '../popup-dialog';
export function BookingBinding(
  props: PropsWithChildren<{
    onSuccess(): void;
  }>
) {
  const t = useLocale();

  const [modalOpen, setModalOpen] = useState(false);

  const [formInstance] = Form.useForm();

  useEffect(() => {});
  function bindHotel() {
    const bookingNumber = formInstance.getFieldValue('bookingNumber');
    if (!bookingNumber) {
      return;
    }
    const loadingReturn = MessageService.loading(null, true);

    MembershipService.BindHotelBooking(bookingNumber)
      .then(
        () => {
          Toast.success({
            message: t('Title_AddSuccess'),
            duration: 2000,
            onClose() {
              setModalOpen(false);
              formInstance.setFieldValue('bookingNumber', null);
              props.onSuccess();
            },
          });
        },
        (err) => {
          if (err?.code === 0 || !err?.message) {
            ModalService.error(t('Error_TryAgainLater'));
          } else {
            ModalService.error(err.message);
          }
        }
      )
      .finally(() => {
        loadingReturn.clear();
      });
  }

  return (
    <>
      <span onClick={() => setModalOpen(true)}>{props.children}</span>

      <PopupDialog
        visible={modalOpen}
        position="bottom"
        title={t('Title_AddBooking')}
        confirmButtonText={t('Button_AddBooking')}
        closeOnClickOverlay
        onCancel={() => {
          setModalOpen(false);
        }}
        onConfirm={async () => {
          try {
            await formInstance.validateFields();
            bindHotel();
          } catch {}
        }}
      >
        <div style={{ margin: '20px 0' }}>
          <Form form={formInstance}>
            <Form.Item
              name="bookingNumber"
              rules={[{ required: true, message: t('Error_ItemRequired') }]}
            >
              <Input
                clearable
                autoFocus
                placeholder={t('Placecholder_FillinBookingNumber')}
                maxLength={100}
              ></Input>
            </Form.Item>
          </Form>
        </div>
      </PopupDialog>
    </>
  );
}
