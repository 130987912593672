import { CoreHotelBooking } from '@b2c/core';
import { useLocale } from '@b2c/hooks';
import { FalconBooking } from '@b2c/services/booking';
import { DateTimeHelper } from '@b2c/services/common';
import { FalconPayment } from '@b2c/services/pay-channel';
import { FunctionComponent, HTMLAttributes } from 'react';
import { Divider, Flex, Space, Tag, Typography } from 'react-vant';
import { TypographyCancellation } from '../typography-cancellation';
import { TypographyDateRange } from '../typography-date-range';
import { TypographyDateTime } from '../typography-date-time';
import { TypographyPrice } from '../typography-price';
import { BookingGuestInfo } from './booking-guest-Info';
import { TypographyBookingStatus } from './typography-booking-status';

export const BookingListItem: FunctionComponent<
  HTMLAttributes<HTMLDivElement> & {
    bookingDetail: FalconBooking.BookingDetailModel;
  }
> = ({ bookingDetail, ...props }) => {
  const t = useLocale();

  return (
    <Space direction="vertical" block gap={1} divider={<Divider></Divider>}>
      <Space block wrap direction="vertical" gap={4}>
        <Flex align="start" justify="between">
          <Flex.Item>
            <Typography.Text strong size="lg">
              {bookingDetail.StaticInfo.HotelName}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item style={{ flexShrink: 0 }}>
            <TypographyBookingStatus value={bookingDetail.BookingStatus}>
              {(displayInfo) => (
                <Tag type={displayInfo.theme as any}>{t(displayInfo.name)}</Tag>
              )}
            </TypographyBookingStatus>
          </Flex.Item>
        </Flex>
        <TypographyDateRange
          value={{
            From: DateTimeHelper.getDate(bookingDetail.CheckInDate),
            To: DateTimeHelper.getDate(bookingDetail.CheckOutDate),
          }}
        ></TypographyDateRange>
        <Typography.Text>
          {bookingDetail.StaticInfo.RoomTypeName || t('Label_Others')}
          &nbsp;&times;&nbsp;
          {t('Text_RoomWithCount', { count: bookingDetail.RoomCount })}
        </Typography.Text>
        {bookingDetail.BookingStatus !==
          CoreHotelBooking.StatusType.Canceled && (
          <TypographyCancellation
            ratePlanPrice={{
              cancellationList: bookingDetail.CancellationPolicies,
              priceSummary: bookingDetail.PriceSummary,
              timeZoneInfo: bookingDetail.TimeZoneInfo,
            }}
            contentType="desc"
          ></TypographyCancellation>
        )}
      </Space>
      <Space direction="vertical" block gap={8}>
        <Flex>
          <Flex.Item span={8}>
            <Typography.Text type="secondary">
              {t('Label_TotalPrice')}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item span={16}>
            <TypographyPrice
              type="primary"
              value={bookingDetail.PriceSummary}
            ></TypographyPrice>

            {bookingDetail.PriceSummary.RatePaymentType >
              FalconPayment.RatePaymentTypeEnum.PrePay && (
              <Typography.Text type="secondary">
                &nbsp;({t('Label_PayAtHotel')})
              </Typography.Text>
            )}
          </Flex.Item>
        </Flex>
        {/* {viewInfo.isPayLater && (
          <Flex>
            <Flex.Item span={8}>
              <Typography.Text strong>{t('Label_PaymentType')}</Typography.Text>
            </Flex.Item>
            <Flex.Item span={16}>{t(viewInfo.paymentTypeInfo.name)}</Flex.Item>
          </Flex>
        )} */}
        {/* <Flex>
            <Flex.Item span={8}>
              客户订单号
            </Flex.Item>
            <Flex.Item span={16}>
              {bookingDetail.ClientReference || '(无)'}
            </Flex.Item>
          </Flex> */}
        {/* <Flex>
          <Flex.Item span={8}>
            <Typography.Text type='secondary'>{t('Label_CheckInOutDay')}</Typography.Text>
          </Flex.Item>
          <Flex.Item span={16}>
            {t('Text_CheckinToCheckout', {
              from: DateTimeHelper.getDateString(bookingDetail.CheckInDate),
              to: DateTimeHelper.getDateString(bookingDetail.CheckOutDate),
            })}
          </Flex.Item>
        </Flex> */}
        <Flex>
          <Flex.Item span={8}>
            <Typography.Text type="secondary">
              {t('Label_Guests')}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item span={16}>
            <BookingGuestInfo bookingDetail={bookingDetail}></BookingGuestInfo>
          </Flex.Item>
        </Flex>
        {bookingDetail.BookingStatus ===
          CoreHotelBooking.StatusType.Canceled && (
          <Flex>
            <Flex.Item span={8}>
              <Typography.Text type="secondary">
                {t('Label_CancelTime')}
              </Typography.Text>
            </Flex.Item>
            <Flex.Item span={16}>
              <TypographyDateTime
                value={bookingDetail.BookingCancelTime}
                type="warning"
              ></TypographyDateTime>
            </Flex.Item>
          </Flex>
        )}
        <Flex>
          <Flex.Item span={8}>
            <Typography.Text type="secondary">
              {t('Label_OrderTime')}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item span={16}>
            <TypographyDateTime
              value={bookingDetail.OrderTime}
            ></TypographyDateTime>
          </Flex.Item>
        </Flex>
        <Flex>
          <Flex.Item span={8}>
            <Typography.Text type="secondary">
              {t('Label_BookingNumber')}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item span={16}>{bookingDetail.BookingNumber}</Flex.Item>
        </Flex>
      </Space>
    </Space>
  );
};
