import { useLocale } from '@b2c/hooks';
import { DateTimeHelper } from '@b2c/services/common';
import { useCallback } from 'react';
import { Typography, TypographyProps } from 'react-vant';
export function TypographyDate({
  value,
  format,
  ...props
}: { value: Date; format?: string } & TypographyProps) {
  const t = useLocale();

  const getWeekDayDesc = useCallback(
    (dateInfo: ReturnType<typeof DateTimeHelper.getDateInfo>) => {
      return dateInfo.isToday
        ? t('Label_Today')
        : dateInfo.isTomorrow
        ? t('Label_Tomorrow')
        : DateTimeHelper.getString(dateInfo.value, 'EEE', t.locale);
    },
    [t]
  );

  if (t.locale === 'zh-CN') {
    const fromDateInfo = DateTimeHelper.getDateInfo(value);
    return (
      <div>
        <Typography.Text strong {...props}>
          {DateTimeHelper.getString(value, format || t('Text_Format_MonthDay'))}
        </Typography.Text>
        <Typography.Text type="secondary" {...props}>
          ({getWeekDayDesc(fromDateInfo)})
        </Typography.Text>
      </div>
    );
  } else {
    return (
      <Typography.Text strong {...props}>
        {DateTimeHelper.getString(
          value,
          format || t('Text_Format_MonthDayWeek'),
          t.locale
        )}
      </Typography.Text>
    );
  }
}
