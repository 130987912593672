import { CoreCommon } from '@b2c/core';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export function useLocale() {
  const intl = useIntl();

  const translateFunc = useCallback(
    (id: string, values?: Record<string, string | number>) => {
      if (!id) {
        console.error('translation id required');
        return '';
      }
      return intl.formatMessage({ id }, values);
    },
    [intl]
  );
  const formatPrice = useCallback(
    <T extends { Currency: string }>(priceInfo: T, priceKey?: keyof T) => {
      const value = priceKey ? priceInfo[priceKey] : priceInfo['Price'];
      return intl.formatNumber(value, {
        style: 'currency',
        currencyDisplay: 'code',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        currency: priceInfo.Currency,
      });
    },
    [intl]
  );
  const simpleTranslate = Object.assign(translateFunc, {
    locale: intl.locale as CoreCommon.LanguageCode,
    formatPrice,
  });
  return simpleTranslate;
}
