import { AnalyticsHelper } from '@b2c/services/common';
import { FalconMembership, MembershipService } from '@b2c/services/membership';
import { FormInstance } from 'rc-field-form';
import { useContext, useState } from 'react';
import { AppContextInfoContext } from './app-context-info.context';
import { useBookingTokenInSession } from './use-booking-token-in-session';
import { useEmailConfirmation } from './use-email-comfirmation';
import { useFormRulesPreset } from './use-form-rules-preset';
import { useLocale } from './use-locale';
import { useNavigater } from './use-navigater';

export function useAccountRegister(props: {
  form: FormInstance<any>;
  onError: (message: string, title?: string) => void;
  onAlert: (message: string, title?: string) => void;
}) {
  const t = useLocale();
  const form = props.form;
  const { loadAppContext } = useContext(AppContextInfoContext);
  const [submiting, setSubmiting] = useState(false);

  const { navigateReplace, navigateTo } = useNavigater();
  const { waitSeconds, sending, sendConfirmCode, setSendable, sendable } =
    useEmailConfirmation('register');

  const rules = useFormRulesPreset(form);

  const [bookingToken] = useBookingTokenInSession();

  function onSubmit($event: FalconMembership.UserRegisterPayload) {
    const payload = $event;
    if (bookingToken) {
      payload.BookingToken = bookingToken;
    }
    setSubmiting(true);
    MembershipService.register($event)
      .then((resp) => {
        AnalyticsHelper.signUp().then(() => {});
        navigateReplace('/');
        loadAppContext();
      })
      .catch(function (err) {
        props.onError(err.message, t('Error_RegisterFailed'));
      })
      .finally(() => {
        setSubmiting(false);
      });
  }

  function onSendClick() {
    const error = form.getFieldError('Email');
    if (error?.length > 0) {
      return;
    }

    const email = form.getFieldValue('Email')?.trim();
    if (!email) {
      return;
    }
    sendConfirmCode(email).then(
      () => {
        props.onAlert(t('Notice_ValidateEmailSendSuccess'), null);
      },
      (err) => {
        props.onAlert(err?.message);
      }
    );
  }

  function onEmailChange(value: string) {
    setTimeout(() => {
      // 等validator里的promise执行完再执行
      const errors = form.getFieldError('Email');
      setSendable(errors?.length < 1);
    }, 0);
  }

  function navigateToLogin() {
    const { origin, pathname, search } = window.location;
    navigateTo(`${origin}${pathname.replace('register', 'login')}${search}`);
  }

  return {
    submiting,
    onSendClick,
    waitSeconds,
    sending,
    setSendable,
    sendable,
    onEmailChange,
    rules,
    onSubmit,
    navigateToLogin,
  };
}
