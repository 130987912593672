import { CoreCommon } from '@b2c/core';
import { useContext, useMemo } from 'react';
import {
  AppConfigContext,
  AppContextInfoContext,
} from './app-context-info.context';

export const AppAvailLocaleList: {
  name: string;
  langCode: CoreCommon.LanguageCode;
  langCodeShort: string;
  translationLoader: () => Promise<Record<string, string>>;
}[] = [
  {
    name: 'English',
    langCode: 'en-US',
    langCodeShort: 'en-US',
    translationLoader: () =>
      import('locales/en-US.json').then((m) => m.default),
  },
  {
    name: '日本語',
    langCode: 'ja-JP',
    langCodeShort: 'ja',
    translationLoader: () =>
      import('locales/ja-JP.json').then((m) => m.default),
  },
  {
    name: '简体中文',
    langCode: 'zh-CN',
    langCodeShort: 'zh-CN',
    translationLoader: () =>
      import('locales/zh-CN.json').then((m) => m.default),
  },
];

export function useLocaleList() {
  const { appContext } = useContext(AppContextInfoContext);
  const appConfig = useContext(AppConfigContext);
  const localeList = appContext?.localeList || [
    appConfig.defaultLocale || 'en-US',
  ];
  return useMemo(() => {
    return AppAvailLocaleList.filter((item) =>
      localeList.some((i) => i == item.langCode)
    );
  }, [localeList]);
}
