import { useContext, useEffect, useRef } from 'react';
import { AppContextInfoContext } from './app-context-info.context';

type AuthenticationChangeCallback = (isAuthenticated: boolean) => void;
export function useAuthenticaitonChange(cb: AuthenticationChangeCallback) {
  const { appContext } = useContext(AppContextInfoContext);

  const flagRef = useRef<boolean>(null);

  const callBackRef = useRef<AuthenticationChangeCallback>(cb);
  callBackRef.current = cb;

  const isAuthenticated = appContext?.userIdentityInfo.isAuthenticated;

  useEffect(() => {
    if (flagRef.current !== null) {
      callBackRef.current(isAuthenticated);
    }
    flagRef.current = isAuthenticated;
  }, [isAuthenticated]);
}
