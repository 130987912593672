import { CoreCommon } from '@b2c/core';
import { useMealTypeDisplayInfo } from '@b2c/hooks';
import { TablewareIcon } from '@b2c/icons';
import { QuestionO } from '@react-vant/icons';
import { Flex, Typography } from 'react-vant';
import { TypographyTextProps } from 'react-vant/es/typography/PropsType';
import { ModalService } from './modal.service';

export function TypographyMealtype({
  value,
  showIcon,
  ...props
}: {
  value: CoreCommon.MealInfoModel;
  showIcon?: boolean;
} & Omit<TypographyTextProps, 'type'>) {
  const [mealTypeName, mealTypeDesc] = useMealTypeDisplayInfo(value);
  return (
    <Typography.Text
      {...props}
      type={
        value.MealType.ID === CoreCommon.MealType.RoomOnly ? null : 'success'
      }
    >
      <Flex align="center">
        {showIcon && (
          <>
            <TablewareIcon />
            &nbsp;
          </>
        )}
        <span>{mealTypeName}</span>
        {mealTypeDesc && (
          <>
            &nbsp;
            <QuestionO
              onClick={(event) => {
                event.stopPropagation();
                ModalService.alert(mealTypeDesc, mealTypeName);
              }}
            />
          </>
        )}
        {value.MealAmount > 0 && (
          <span>&nbsp;&times;&nbsp;{value.MealAmount}</span>
        )}
      </Flex>
    </Typography.Text>
  );
}
