import { CoreCommon, CoreSuggestion } from '@b2c/core';
import {
  DateRangeModel,
  DateTimeHelper,
  HotelDetailQueryPayload,
  HotelListQueryPayload,
  PriceFilterQueryParams,
  UrlHelper,
} from '@b2c/services/common';
import {
  HotelInfoHelper,
  HotelOverviewInfo,
  HotelStaticService,
} from '@b2c/services/hotel';
import { NationalityInfoModel } from '@b2c/services/nation';
import { FalconHotelRate, RateSearchService } from '@b2c/services/rate';
import { SuggestionOption, SuggestionService } from '@b2c/services/suggestions';
import produce from 'immer';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  AppConfigContext,
  AppContextInfoContext,
} from './app-context-info.context';
import { SecureContextInfoContext } from './secure-context-info.context';
import { useAuthenticaitonChange } from './use-authentication-change';
import { useDateRange } from './use-date-range';
import { useDestinationInfo } from './use-destination-info';
import { useNationalitySetting } from './use-nationality-settting';
import { useNavigater } from './use-navigater';
import {
  OccupancySettingModel,
  useOccupancySetting,
} from './use-occupancy-setting';
import { usePageScroll } from './use-page-scroll';
import { usePriceFilter } from './use-price-filter';
import { useQueryParams } from './use-query-params';
import { SortTypeInfo, useSortTypeList } from './use-sort-type-list';

const ScrollThreadhold = 300;
export interface HotelListSearchResult
  extends CoreCommon.PaginationWrapper<HotelOverviewInfo> {
  // Success: boolean;
  // ErrorCode: number;

  // ReferenceNo: string;
  // TotalCount: number;
  HoistedCount: number;
  // ResponseID: string;
  // SessionID: string;
}

export interface HotelSearchPayload {
  destinationInfo: SuggestionOption;
  dateRange: DateRangeModel;
  priceFilter?: PriceFilterQueryParams;
  occupancySetting: OccupancySettingModel;
  nationalityInfo?: NationalityInfoModel;
}

export function useHotelSearch(options: { onSearchHotelError: (err) => void }) {
  const [finished, setFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [error, setError] = useState(null);
  const [searchOptions, setSearchOptions] =
    useState<Partial<FalconHotelRate.SearchOption>>();

  const { queryParams, updateQueryParams } =
    useQueryParams<HotelListQueryPayload>();

  const [searchResult, setSearchResult] = useState<HotelListSearchResult>();

  const { navigateReplace } = useNavigater();

  const [searchOptionVisible, setSearchOptionVisible] = useState(false);
  // let [showDestination, setShowDestination] = useState(false);
  // let [showCityList, setShowCityList] = useState(false);

  const [priceFilter, setPriceFilter] = usePriceFilter();

  const [dateRange, setDateRange] = useDateRange();
  const { appContext } = useContext(AppContextInfoContext);
  const appConfig = useContext(AppConfigContext);
  const currencyInfo = appContext?.currencyInfo;
  const { destinationInfo, setDestinationInfo } = useDestinationInfo();
  const [nationalityInfo, setNationalityInfo] = useNationalitySetting();

  const [searcherVisible, setSearcherVisible] = useState(true);
  const [occupancySetting, setOccupancySetting] = useOccupancySetting();

  const secureContext = useContext(SecureContextInfoContext);

  const SortTypeList = useSortTypeList();

  // const [sortInfo, setSortInfo] = useState(SortTypeList[0]);
  const sortInfo = useMemo(() => {
    return (
      SortTypeList.find((item) => item.value === queryParams.pst) ||
      SortTypeList[0]
    );
  }, [queryParams]);

  useAuthenticaitonChange(() => {
    location.reload();
  });

  const localRef = useRef(appContext?.locale);

  useEffect(() => {
    if (localRef.current && appContext?.locale !== localRef.current) {
      init();
    }
    localRef.current = appContext?.locale;
  }, [appContext?.locale]);

  function init() {
    const option: Partial<FalconHotelRate.SearchOption> = {
      BatchID: secureContext?.bid,
      SupplierIDList: [],
      CheckInDate: DateTimeHelper.getDateString(dateRange.From),
      CheckOutDate: DateTimeHelper.getDateString(dateRange.To),
      Nationality: nationalityInfo.code,
      SortFilter: {
        SortBy: sortInfo.sortType,
        IsAscending: sortInfo.isAscending,
      },
      PriceSearchFilter: {
        StarRating: priceFilter.rsl || [],
        MinPrice: Math.round(priceFilter.min / currencyInfo.Ratio),
        MaxPrice: priceFilter.max
          ? Math.round(priceFilter.max / currencyInfo.Ratio)
          : undefined,
      },
      RatePaymentTypeList: priceFilter.rpt,
      PageNum: 1,
      CountPerPage: 10,
      Currency: currencyInfo.Currency,
      HoistingHotelIDList: queryParams.hid ? [+queryParams.hid] : null,
      DestinationID: queryParams.rid || '178236',
      RealTimeFilter: {
        IsRealTime: false,
        RoomCount: 1,
        OccupancySetting: {
          AdultCount: occupancySetting.roomList[0].adultCount,
          ChildCount: occupancySetting.roomList[0].childCount,
          ChildAgeList: occupancySetting.roomList[0].childAgeList,
        },
      },
      Source: queryParams.sor_id,
      SourceInfo: queryParams.sor_i,
      SourceSite: queryParams.sor_st,
      IsPriceOnly: false,
    };

    if (!queryParams.rid && !queryParams.hid) {
      navigateReplace('/');
    }

    (queryParams.rid
      ? searchHotel(option)
      : SuggestionService.serachRegionByHotelId(queryParams.hid).then(
          (resp) => {
            setDestinationInfo({
              id: resp?.ItemObject.DestinationID,
              name: resp?.ItemObject.DestinationName,
            } as any);
            return searchHotel({
              ...option,
              DestinationID: resp?.ItemObject.DestinationID,
              HoistingHotelIDList: [+queryParams.hid],
            });
          }
        )
    ).catch((err) => {
      options.onSearchHotelError?.call(null, err);
    });
  }

  useEffect(() => {
    init();
  }, []);

  usePageScroll(({ scrollTop, isUp }) => {
    if (scrollTop < ScrollThreadhold) {
      setSearcherVisible(true);
    } else {
      setSearcherVisible(isUp);
    }
  });

  function onSearchPayloadChange(mainOptionsPayload: HotelSearchPayload) {
    const { dateRange, occupancySetting, destinationInfo, nationalityInfo } =
      mainOptionsPayload;

    setDestinationInfo(destinationInfo);
    setDateRange(dateRange);
    setOccupancySetting(occupancySetting);
    if (nationalityInfo) {
      setNationalityInfo(nationalityInfo.code);
    }

    const options: Partial<FalconHotelRate.SearchOption> = {
      CheckInDate: DateTimeHelper.getDateString(dateRange.From),
      CheckOutDate: DateTimeHelper.getDateString(dateRange.To),

      HoistingHotelIDList: null,
      DestinationID: destinationInfo.id,
      Nationality: nationalityInfo?.code,

      RealTimeFilter: {
        IsRealTime: false,
        RoomCount: 1,
        OccupancySetting: {
          AdultCount: occupancySetting.roomList[0].adultCount,
          ChildCount: occupancySetting.roomList[0].childCount,
          ChildAgeList: occupancySetting.roomList[0].childAgeList,
        },
      },
    };

    if (
      destinationInfo.category === CoreSuggestion.SuggestionItemCategory.Hotel
    ) {
      options.HoistingHotelIDList = [destinationInfo.id as any];
      options.DestinationID = destinationInfo.destinationID;
    }
    searchHotel(options);
  }

  function onPriceFilterChange(data: PriceFilterQueryParams) {
    setSearcherVisible(true);
    setPriceFilter(data);

    const pricesearchFilter: Partial<FalconHotelRate.PriceSearchFilter> = {
      StarRating: data.rsl || [],
      MinPrice: Math.round(data.min / currencyInfo.Ratio) || null,
      MaxPrice: data.max ? Math.round(data.max / currencyInfo.Ratio) : null,
    };

    searchHotel({
      PriceSearchFilter: pricesearchFilter,
      RatePaymentTypeList: data.rpt,
    });
  }

  function onSortTypeChange(sortInfo: SortTypeInfo) {
    updateQueryParams({
      pst: sortInfo.value,
    });

    searchHotel({
      SortFilter: {
        SortBy: sortInfo.sortType,
        IsAscending: sortInfo.isAscending,
      },
    });
  }

  function navigateToDetail(hotelInfo: HotelOverviewInfo) {
    const params: HotelDetailQueryPayload = {
      rid: destinationInfo.destinationID,
      ci: DateTimeHelper.getDateString(dateRange.From),
      co: DateTimeHelper.getDateString(dateRange.To),
      rc: occupancySetting.roomCount,
      rl: occupancySetting.roomList.map((room) => ({
        ac: room.adultCount,
        cc: room.childCount,
        cal: room.childAgeList,
      })),
      na: nationalityInfo.code,
      hid: hotelInfo.hotelId,
      _rpt: hotelInfo.priceInfo?.priceSummary.RatePaymentType,
      sor_id: queryParams.sor_id,
      sor_clid: queryParams.sor_clid,
      cur: queryParams.cur,
    };
    if (String(hotelInfo.hotelId) === String(queryParams.hid)) {
      params.fclid = queryParams.fclid;
      params.hotel_id = queryParams.hotel_id;
    }
    if (hotelInfo.priceInfo?.channelPriceInfo) {
      params.sor = hotelInfo.priceInfo.channelPriceInfo.Source;
      params.sor_id = hotelInfo.priceInfo.channelPriceInfo.Source;
      params.sor_c = hotelInfo.priceInfo.channelPriceInfo.Currency;
      params.sor_p = hotelInfo.priceInfo.channelPriceInfo.TotalPrice;
      params.sor_rt = hotelInfo.priceInfo.channelPriceInfo.RoomTypeID;
      params.sor_rp = hotelInfo.priceInfo.channelPriceInfo.RatePlanTypeID;
      params.sor_st = hotelInfo.priceInfo.channelPriceInfo.SourceSite;
      params.sor_p0 = hotelInfo.priceInfo.channelPriceInfo.TotalIncludedFee;
      params.sor_p1 = hotelInfo.priceInfo.channelPriceInfo.TotalExcludedFee;
    }

    const targetUrl = UrlHelper.addParams(
      UrlHelper.addPrefix('/hotel/detail', appConfig.pathBase),
      UrlHelper.createPayloadParam(params)
    );
    window.open(targetUrl);
  }

  function loadHoistedHotel(
    hotelId: number,
    previousResult: HotelListSearchResult
  ) {
    return HotelStaticService.getHotelDetail({
      HotelID: hotelId,
      DefaultHotelImageOnly: true,
      WithHotelImageList: true,
    }).then(
      (resp) => {
        const hotelItem: HotelOverviewInfo = {
          hotelId: resp.HotelID,
          imgSrc: resp.HotelImageList && resp.HotelImageList[0]?.ImageUrl_HD,
          name: resp.Name,
          address: resp.Address + ', ' + resp.DestinationName,
          // TripAdvisorRating: number | null;
          starRating: resp.StarRating,
          location: null,
          priceInfo: null,
          facilitySetting: resp.FacilitySetting,
        };

        setSearchResult(
          produce(previousResult, (draft) => {
            draft.HoistedCount++;
            draft.Data.unshift(hotelItem);
            draft.TotalAmount++;
          })
        );
      },
      () => {
        //
      }
    );
  }

  async function searchHotel(options: Partial<FalconHotelRate.SearchOption>) {
    if (searchOptions) {
      options = produce(searchOptions, (draft) => {
        Object.assign(
          draft,
          {
            PageNum: 1,
          },
          options
        );
      });
    }

    setSearchOptions(options);
    setLoading(true);

    try {
      const resp = await RateSearchService.searchHotelPrice(
        options,
        queryParams.sor_id,
        null,
        {
          source: options.Source,
          sourceInfo: options.SourceInfo,
          sor_st: options.SourceSite,
        }
      );

      setError(null);
      const newHotels: HotelOverviewInfo[] = resp.Data.map((item) =>
        HotelInfoHelper.getHotelOverview(item)
      );

      const result: HotelListSearchResult = {
        ...resp,
        Data: newHotels,
      };

      setFinished(result.PageSize > result.Data.length);
      setFailed(false);

      if (options.PageNum === 1) {
        window.scrollTo({ top: 0 });
        setSearchResult(result);

        if (options.HoistingHotelIDList?.length > 0 && !result.HoistedCount) {
          return loadHoistedHotel(options.HoistingHotelIDList[0], result);
        }
      } else {
        const existIdSet = new Set(searchResult.Data.map((i) => i.hotelId));
        setSearchResult({
          ...result,
          TotalAmount: result.TotalAmount,
          // 这个可能是假的，不能被覆盖掉
          HoistedCount: searchResult.HoistedCount,
          Data: searchResult.Data.concat(
            result.Data.filter((i) => !existIdSet.has(i.hotelId))
          ),
        });
      }
    } catch (err) {
      // ModalService.error(err?.message || t('Error_TryAgainLater'));
      setError(err);
      setFailed(true);
      console.error(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  function searchNext() {
    return searchHotel({
      PageNum: (searchResult?.PageNum || 0) + 1,
    });
  }

  function research() {
    return searchHotel({
      PageNum: 1,
    });
  }

  return {
    searchOptions,
    searchHotel,
    searchResult,
    loading,
    failed,
    error,
    finished,
    searchNext,
    research,

    priceFilter,
    navigateToDetail,
    searcherVisible,
    setSearchOptionVisible,
    destinationInfo,
    dateRange,
    currencyInfo,
    onPriceFilterChange,
    nationalityInfo,
    sortInfo,
    SortTypeList,
    onSortTypeChange,
    occupancySetting,
    searchOptionVisible,
    onSearchPayloadChange,
  };
}
