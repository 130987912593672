import { useBedtypeDesc } from '@b2c/hooks';
import { BedFillIcon } from '@b2c/icons';
import { FalconCommon } from '@b2c/services/common';
import { Flex, Typography } from 'react-vant';
import { TypographyTextProps } from 'react-vant/es/typography/PropsType';

export function TypographyBedtype({
  value,
  showIcon,
  isAssured,
  ...props
}: {
  value: FalconCommon.BedTypeModel;
  showIcon?: boolean;
  isAssured: boolean;
} & TypographyTextProps) {
  const content = useBedtypeDesc(value, isAssured);
  return (
    <Typography.Text {...props}>
      {showIcon ? (
        <Flex align="center">
          <BedFillIcon />
          &nbsp;
          <span>{content}</span>
        </Flex>
      ) : (
        <span>{content}</span>
      )}
    </Typography.Text>
  );
}
