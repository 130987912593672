import { CoreCommon } from '@b2c/core';
import { getDisplayInfo } from '@b2c/services/decorator';
import { MealTypeModel } from '@b2c/services/meal-info';
import { useMemo } from 'react';
import { useLocale } from './use-locale';

export function useMealTypeDisplayInfo(mealInfo: CoreCommon.MealInfoModel) {
  const t = useLocale();
  return useMemo(() => {
    const displayInfo = getDisplayInfo(MealTypeModel, mealInfo.MealType.ID);

    const name = displayInfo?.name
      ? t(displayInfo.name)
      : mealInfo.MealType.Name;
    const desc = displayInfo?.desc && t(displayInfo?.desc);
    return [name, desc];
  }, [mealInfo, t]);
}
