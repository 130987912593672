import {
  DefaultRatePlanFilter,
  RatePlanFilterOptions,
  useBEMBlock,
  useLocale,
} from '@b2c/hooks';
import { getPropertyDisplayInfoList } from '@b2c/services/decorator';
import { RatePaymentType } from '@b2c/services/pay-channel';
import { ArrowDown } from '@react-vant/icons';
import classNames from 'classnames';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { ActionSheet, Flex, Space, Typography } from 'react-vant';
import { Panel } from '../panel';
import { PopupDialog } from '../popup-dialog';
import './hotel-rateplan-filter.less';

export const HotelRatePlanFilter: FunctionComponent<{
  bedTypeList: string[];
  filter: RatePlanFilterOptions;
  paymentTypeList: number[];
  onFilterChange: (filter: RatePlanFilterOptions) => void;
  round?: boolean;
}> = ({
  bedTypeList,
  paymentTypeList,
  filter,
  onFilterChange,
  ...restProps
}) => {
  const t = useLocale();

  const blockCls = useBEMBlock('hotel-rateplan-filter');
  const [bedtypeActionVisible, setBedtypeActionVisible] = useState(false);
  const [payOptionActionVisible, setPayOptionActionVisible] = useState(false);

  const [popupVisible, setPopupVisible] = useState(false);

  const [tempFilter, setTempFilter] = useState(filter);

  useEffect(() => {
    setTempFilter(filter);
  }, [filter]);

  function toggleBreakfast() {
    onFilterChange({
      ...filter,
      breakfast: !filter.breakfast,
    });
  }

  function toggleImmediately() {
    onFilterChange({
      ...filter,
      immediately: !filter.immediately,
    });
  }
  function toggleRefundable() {
    onFilterChange({
      ...filter,
      refundable: !filter.refundable,
    });
  }

  function toggleTempFilter(key: keyof RatePlanFilterOptions, value) {
    setTempFilter({
      ...tempFilter,
      [key]: value,
    });
  }

  const paymentTypeActions = useMemo(() => {
    if (paymentTypeList?.length > 0) {
      return getPropertyDisplayInfoList(RatePaymentType).filter((item) =>
        paymentTypeList.includes(item.value)
      );
    }
    return [];
  }, [paymentTypeList]);

  const curPaymnetTypeName = useMemo(() => {
    const curPaymentTypeAction = paymentTypeActions.find(
      (item) => item.value === filter.paymentType
    );
    return curPaymentTypeAction ? t(curPaymentTypeAction.name) : '';
  }, [filter.paymentType, paymentTypeActions, t]);

  return (
    <div
      className={classNames(blockCls, {
        [blockCls.mo('round')]: restProps.round,
      })}
    >
      <Flex align="center">
        <div className={blockCls.el('scroll-container')}>
          <Space>
            {paymentTypeActions.length > 1 && (
              <>
                <div
                  className={classNames(blockCls.el('item'), {
                    [blockCls.el('item', 'active')]: filter.paymentType,
                  })}
                  onClick={() => setPayOptionActionVisible(true)}
                >
                  {curPaymnetTypeName || t('Label_PaymentOptions')} &nbsp;{' '}
                  <ArrowDown />
                </div>

                <ActionSheet
                  actions={paymentTypeActions.map(({ name, value }) => ({
                    name: t(name),
                    value,
                  }))}
                  visible={payOptionActionVisible}
                  onSelect={(item, index) => {
                    onFilterChange({
                      ...filter,
                      paymentType: paymentTypeActions[index].value,
                    });
                  }}
                  onCancel={() => {
                    setPayOptionActionVisible(false);
                    onFilterChange({
                      ...filter,
                      paymentType: null,
                    });
                  }}
                  closeOnClickAction
                  closeOnClickOverlay
                  cancelText={t('Button_Cancel')}
                />
              </>
            )}
            {bedTypeList.length > 1 && (
              <>
                <div
                  className={classNames(blockCls.el('item'), {
                    [blockCls.el('item', 'active')]: filter.bedType,
                  })}
                  onClick={() => setBedtypeActionVisible(true)}
                >
                  {filter.bedType || t('Label_Bedtype')} &nbsp; <ArrowDown />
                </div>

                <ActionSheet
                  actions={bedTypeList.map((item) => ({ name: item }))}
                  visible={bedtypeActionVisible}
                  onSelect={(item, index) => {
                    const value = bedTypeList[index];
                    const bedType = value === filter.bedType ? '' : value + '';
                    onFilterChange({
                      ...filter,
                      bedType,
                    });
                  }}
                  onCancel={() => {
                    setBedtypeActionVisible(false);
                    onFilterChange({
                      ...filter,
                      bedType: null,
                    });
                  }}
                  closeOnClickAction
                  closeOnClickOverlay
                  cancelText={t('Button_Cancel')}
                />
              </>
            )}
            <div
              className={classNames(blockCls.el('item'), {
                [blockCls.el('item', 'active')]: filter.refundable,
              })}
              onClick={() => toggleRefundable()}
            >
              {t('Label_RateFilter_Refundable')}
            </div>
            <div
              className={classNames(blockCls.el('item'), {
                [blockCls.el('item', 'active')]: filter.breakfast,
              })}
              onClick={() => toggleBreakfast()}
            >
              {t('Label_RateFilter_IncludeBreakfast')}
            </div>
            <div
              className={classNames(blockCls.el('item'), {
                [blockCls.el('item', 'active')]: filter.immediately,
              })}
              onClick={() => toggleImmediately()}
            >
              {t('Label_RateFilter_ConfrimInstantly')}
            </div>
          </Space>
        </div>
        <div
          className={blockCls.el('heading')}
          onClick={() => setPopupVisible(true)}
        >
          <ArrowDown></ArrowDown>
        </div>
      </Flex>
      <PopupDialog
        visible={popupVisible}
        title={t('Label_Filter')}
        position="bottom"
        closeable
        onClose={() => setPopupVisible(false)}
        cancelButtonText={t('Button_ClearAll')}
        onCancel={() => {
          setTempFilter({ ...DefaultRatePlanFilter });
        }}
        onConfirm={() => {
          onFilterChange(tempFilter);
          setPopupVisible(false);
        }}
      >
        <Panel>
          {paymentTypeActions.length > 1 && (
            <div className={blockCls.el('section')}>
              <Typography.Title className={blockCls.el('title').toString()}>
                {t('Label_PaymentOptions')}
              </Typography.Title>
              <Space wrap>
                {paymentTypeActions.map(({ name, value }) => (
                  <div
                    key={value}
                    className={classNames(blockCls.el('item'), {
                      [blockCls.el('item', 'active')]:
                        tempFilter.paymentType === value,
                    })}
                    onClick={() => toggleTempFilter('paymentType', value)}
                  >
                    {t(name)}
                  </div>
                ))}
              </Space>
            </div>
          )}
          {bedTypeList.length > 1 && (
            <div className={blockCls.el('section')}>
              <Typography.Title className={blockCls.el('title').toString()}>
                {t('Label_Bedtype')}
              </Typography.Title>
              <Space wrap>
                {bedTypeList.map((item) => (
                  <div
                    key={item}
                    className={classNames(blockCls.el('item'), {
                      [blockCls.el('item', 'active')]:
                        tempFilter.bedType === item,
                    })}
                    onClick={() => {
                      const bedType =
                        item === tempFilter.bedType ? '' : item + '';
                      toggleTempFilter('bedType', bedType);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </Space>
            </div>
          )}
          <div className={blockCls.el('section')}>
            <Typography.Title className={blockCls.el('title').toString()}>
              {t('Label_HotelPolicy')}
            </Typography.Title>
            <Space wrap>
              <div
                className={classNames(blockCls.el('item'), {
                  [blockCls.el('item', 'active')]: tempFilter.refundable,
                })}
                onClick={() =>
                  toggleTempFilter('refundable', !tempFilter.refundable)
                }
              >
                {t('Label_RateFilter_Refundable')}
              </div>
              <div
                className={classNames(blockCls.el('item'), {
                  [blockCls.el('item', 'active')]: tempFilter.immediately,
                })}
                onClick={() =>
                  toggleTempFilter('immediately', !tempFilter.immediately)
                }
              >
                {t('Label_RateFilter_ConfrimInstantly')}
              </div>
            </Space>
          </div>
          <div className={blockCls.el('section')}>
            <Typography.Title className={blockCls.el('title').toString()}>
              {t('Label_MealType')}
            </Typography.Title>
            <div
              className={classNames(blockCls.el('item'), {
                [blockCls.el('item', 'active')]: tempFilter.breakfast,
              })}
              onClick={() =>
                toggleTempFilter('breakfast', !tempFilter.breakfast)
              }
            >
              {t('Label_RateFilter_IncludeBreakfast')}
            </div>
          </div>
        </Panel>
      </PopupDialog>
    </div>
  );
};
