import { AuthenticationService } from '@b2c/services/auth';
import { MembershipService } from '@b2c/services/membership';
import { useCallback, useState } from 'react';
export function useEmailConfirmation(
  type: 'register' | 'reset-password' | 'reset-email'
) {
  const [sending, setSending] = useState(false);
  const [sendable, setSendable] = useState(false);
  const [waitSeconds, setWaitSeconds] = useState(0);

  const countdown = useCallback(function (seconds: number) {
    setWaitSeconds(seconds);
    const timer = setInterval(() => {
      setWaitSeconds(--seconds);
      if (seconds < 1) {
        clearInterval(timer);
      }
    }, 1000);
  }, []);

  const sendConfirmCode = useCallback(
    function (email: string) {
      setSending(true);
      let promise;
      switch (type) {
        case 'register':
          promise = MembershipService.getRegisterToken(email);
          break;

        case 'reset-password':
          promise = AuthenticationService.requestPasswordResetToken(email);
          break;

        case 'reset-email':
          promise = MembershipService.requestChangeEmailToken(email);
          break;
        default:
          break;
      }

      return promise
        .then(() => {
          countdown(60);
        })
        .finally(() => {
          setSending(false);
        });
    },
    [type]
  );

  return {
    sending,
    waitSeconds,
    sendable,
    setSendable,
    sendConfirmCode,
  };
}
