export enum ValidateErrorType {
  required,
  maxLength,
  minLength,
  pattern,
  email,
  phone,
  noSpace,
  requireTrue,
  maxRepeatStr,
  maxContinuousStr,
  compareFieldEquals,
}

export type PatternConfigType = [string | RegExp | (string | RegExp)[], string];

export interface PatternConfigExtractInfo {
  patterns: RegExp[];
  errMessage: string;
}

export interface ValidateOptions {
  required?: [boolean, string];
  maxLength?: [number, string];
  minLength?: [number, string];
  pattern?: PatternConfigType;
  patterns?: PatternConfigType[];
  email?: [boolean, string];
  phone?: [boolean, string];
  noSpace?: [boolean, string];
  requireTrue?: [boolean, string];
  maxRepeatStr?: {
    length: number;
    errMessage: string;
  };
  maxContinuousStr?: {
    length: number;
    direction: null | 'forward' | 'reverse';
    errMessage: string;
  };
  /**
   *
   * 0: field paths, 1: expected equals, 2: errMessage
   *
   * @type {[string[], boolean, string]}
   * @memberof ValidateOptions
   */
  compareFieldEquals?: [string[], boolean, string];
}

export interface ValidateError {
  errorType: ValidateErrorType;
  errorMessage: string;
  errorPath: string[];
}
