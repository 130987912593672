import { CoreHotelBooking } from '@b2c/core';
import { Display, DisplayName, Enumerable, t } from '../decorator';

export class BookingErrorType {
  @DisplayName(t('Text_BookingError_BookingPaymentExpired'))
  static BookingPaymentExpired =
    CoreHotelBooking.ErrorType.BookingPaymentExpired;
}

export class HotelBookingStatusModel {
  @Display({
    name: t('Label_All'),
    theme: 'default',
  })
  static All = 0;

  @Display({
    name: t('Label_BookingStatus_Booked'),
    theme: 'warning',
    desc: t('Label_OrderStep_Process'),
  })
  static Booked = 1;

  @Display({
    name: t('Label_BookingStatus_Confirmed'),
    theme: 'success',
    desc: t('Label_OrderStep_PayFinish'),
  })
  static Confirmed = 2;

  @Display({
    name: t('Label_BookingStatus_Cancelled'),
    theme: 'default',
    desc: t('Label_OrderStep_Cancelled'),
  })
  static Canceled = 3;

  @Display({
    name: t('Label_BookingStatus_Failed'),
    theme: 'default',
    desc: t('Label_OrderStep_Fail'),
  })
  static Failed = 4;

  @Display({
    name: t('Label_BookingStatus_Pending'),
    theme: 'warning',
    desc: t('Label_OrderStep_Process'),
  })
  static Pending = 5;

  @Display({
    name: t('Label_BookingStatus_OnRequest'),
    theme: 'warning',
    desc: t('Label_OrderStep_Process'),
  })
  @Enumerable(false)
  static OnRequest = 6;

  // @Display({

  // })
  // static Unsettled = 99
}
