import produce from 'immer';
import {UrlHelper} from '../common';
import {HttpService} from '../http';
import {FalconPayment} from '../pay-channel';
import {FalconHotelRate} from './falcon-hotel-rate';
import {FalconRate} from './falcon-rate';

/**
 * 价格数据相关接口
 *
 * @export
 * @class RateSearchService
 */
export class RateSearchService {
  /**
   * 获取酒店价格
   *
   * @param {FalconHotelRate.SearchOption} options 酒店价格查询参数
   * @returns {Promsie<HotelPriceSearchResultModel>}
   * @memberof RateSearchService
   */
  static searchHotelPrice(
    options: Partial<FalconHotelRate.SearchOption>,
    appId: string,
    signal?: AbortSignal,
    queryParams?: {
      source?: string;
      sourceInfo?: string;
      sor_st?: string;
      index?: number;
    }
  ) {
    let url = '/Rate/HotelRate/Search';
    if (queryParams) {
      url = UrlHelper.addParams(url, queryParams);
    }

    options = produce(options, (draft) => {
      if (
        !draft.RatePaymentTypeList ||
        draft.RatePaymentTypeList.length === 0
      ) {
        draft.RatePaymentTypeList = [
          FalconPayment.RatePaymentTypeEnum.PrePay,
          FalconPayment.RatePaymentTypeEnum.PayAtPropertyWithCreditCard,
          FalconPayment.RatePaymentTypeEnum.PayAtPropertyWithoutCreditCard,
        ];
      }
      if (draft.PriceSearchFilter?.StarRating?.includes(2)) {
        draft.PriceSearchFilter.StarRating.unshift(1);
      }
    });

    if (process.env.NODE_ENV === 'development') {
      // supplierMaskGroups = [['26']];
      // options.RatePaymentTypeList.shift();
    }

    return HttpService.post<FalconRate.HotelPriceSearchResult>(url, options, {
      signal,
      headers: {
        'fcn-ext-app': appId,
      },
    }).then((resp) => {
      if (!resp.HotelPriceList) {
        resp.HotelPriceList = [];
      }
      resp.HotelPriceList.map((price) =>
        this.normalizeHotelRatePlanPrice(price)
      );
      resp.Data = resp.HotelPriceList;
      resp.TotalAmount = resp.TotalCount;
      resp.PageNum = options.PageNum;
      resp.PageSize = options.CountPerPage;
      return resp;
    });
  }

  /**
   * 获取RatePlan实时价格
   */
  static searchRatePlanPrice(
    options: Partial<FalconRate.RatePlanRateSearchOption>,
    forOrder = false,
    sessionId = '',
    appId = ''
  ) {
    // options = produce(options, (draft) => {
    //   draft.RoomOccupancyList.map((item) => {
    //     if (item.ChildrenCount === 0) {
    //       item.Children = null;
    //     }
    //   });
    // });
    const headers: HeadersInit = {};
    if (sessionId) {
      headers['fcn-ss'] = sessionId;
    }
    if (appId) {
      headers['fcn-ext-app'] = appId;
    }

    let url = '/Rate/RatePlanRate/Search';
    if (forOrder) {
      url += 'ForOrder';
    }
    if (options.ChannelPriceInfo?.SourceSite) {
      url = UrlHelper.addParams(url, {sor_st: options.ChannelPriceInfo.SourceSite});
    }
    return HttpService.post<FalconRate.RatePlanPriceSearchResult>(
      url,
      options,
      {
        headers,
      }
    ).then((resp) => {
      resp.Data = resp.HotelPriceList;
      if (resp.Success) {
        resp.Data.forEach((price) => {
          this.normalizeHotelRatePlanPrice(price);
        });
      }
      return resp;
    });
  }

  private static normalizeHotelRatePlanPrice(
    price: FalconRate.HotelRatePlanPriceModel
  ) {
    if (!price.RatePlanPriceList) {
      price.RatePlanPriceList = [];
      return;
    }
    price.RatePlanPriceList.forEach((ratePlanPrice) => {
      ratePlanPrice.CancellationList.sort((a, b) => {
        return a.CancellationFromTimestampSeconds >
        b.CancellationFromTimestampSeconds
          ? 1
          : -1;
      });

      if (ratePlanPrice.RatePlan.ExtraInfoIDList) {
        const extraInfoString =
          (ratePlanPrice.RatePlan.ExtraInfoIDList as any) || '';
        ratePlanPrice.RatePlan.ExtraInfoIDList = extraInfoString
          .split('|')
          .map((item) => parseInt(item, 10));
      } else {
        ratePlanPrice.RatePlan.ExtraInfoIDList = [];
      }
      if ('BandCardInputConditions' in ratePlanPrice) {
        ratePlanPrice.BankCardInputConditions = ratePlanPrice[
          'BandCardInputConditions'
          ] as any;
      }
    });
  }
}
