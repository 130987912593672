import { PriceFilterQueryParams } from '@b2c/services/common';
import { useCallback, useContext, useMemo } from 'react';
import { AppConfigContext } from './app-context-info.context';
import { useQueryParams } from './use-query-params';

export const usePriceFilter = function () {
  const { queryParams, updateQueryParams } =
    useQueryParams<PriceFilterQueryParams>();

  const appConfig = useContext(AppConfigContext);

  const priceFilter = useMemo<PriceFilterQueryParams>(() => {
    return {
      min: queryParams.min || 0,
      max: queryParams.max || null,
      rsl: queryParams.rsl || [],
      rpt: queryParams.rpt || appConfig.ratePaymentTypeList || [],
    };
  }, [queryParams]);

  const setPriceFilter = useCallback((newFilter: PriceFilterQueryParams) => {
    updateQueryParams<PriceFilterQueryParams>({ ...newFilter });
  }, []);

  return [priceFilter, setPriceFilter] as [
    PriceFilterQueryParams,
    (newRange: PriceFilterQueryParams) => void
  ];
};
