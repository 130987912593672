import { useLocale } from '@b2c/hooks';
import { DateRangeModel, DateTimeHelper } from '@b2c/services/common';
import { Space, Typography, TypographyProps } from 'react-vant';
import { TypographyDate } from './typography-date';

export function TypographyDateRange({
  value,
  hideSummary,
  ...props
}: {
  value: DateRangeModel;
  hideSummary?: boolean;
} & TypographyProps) {
  const t = useLocale();
  return (
    <Space gap={4}>
      <TypographyDate {...props} value={value.From}></TypographyDate>
      <div>-</div>
      <div>
        <TypographyDate {...props} value={value.To}></TypographyDate>
      </div>
      {!hideSummary && (
        <Typography.Text type="secondary" {...props}>
          {t('Text_NightCountDesc', {
            nightCount: DateTimeHelper.getDateDiff(value.From, value.To),
          })}
        </Typography.Text>
      )}
    </Space>
  );
}
