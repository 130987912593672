import { NationalitySettingQueryParams } from '@b2c/services/common';
import { useContext } from 'react';
import { AppConfigContext } from './app-context-info.context';
import { useNationalityInfo } from './country-info';
import { useQueryParams } from './use-query-params';

export function useNationalitySetting() {
  const { queryParams, updateQueryParams } =
    useQueryParams<NationalitySettingQueryParams>();

  const appConfig = useContext(AppConfigContext);

  const nationality = useNationalityInfo(
    queryParams.na || appConfig.defaultNationality || 'US'
  );

  function setNationality(nationalityCode: string) {
    updateQueryParams({
      na: nationalityCode,
    });
  }

  return [nationality, setNationality] as [
    typeof nationality,
    typeof setNationality
  ];
}
