import {
  HotelDetailQueryPayload,
  OrderFillinQueryParams,
  UrlHelper,
} from '@b2c/services/common';
import { FalconRate, RateSearchService } from '@b2c/services/rate';
import {
  PriceChangeResultModel,
  RatePlanHelper,
  SimplifiedRatePlanPrice,
} from '@b2c/services/rateplan';
import { useContext, useEffect, useState } from 'react';
import { AppConfigContext } from './app-context-info.context';
import { useLocale } from './use-locale';
import { useNavigater } from './use-navigater';
import { useOrderOption } from './use-order-option';
import { useQueryParams } from './use-query-params';

export function getRatePlanPriceCacheKey(rprsid: string) {
  // return `ratePlanPrice_${rprsid}}_${currencyCode}`;
  return `ratePlanPrice_${rprsid}`;
}

export function getRatePlanPriceCache(rprsid: string) {
  const ratePlanPriceCache = sessionStorage.getItem(
    getRatePlanPriceCacheKey(rprsid)
  );
  if (ratePlanPriceCache) {
    return JSON.parse(ratePlanPriceCache) as SimplifiedRatePlanPrice;
  }
  return null;
}

export function setRatePlanPriceCache(
  ratePlanPrice: SimplifiedRatePlanPrice,
  key?: string
) {
  sessionStorage.setItem(
    getRatePlanPriceCacheKey(key || ratePlanPrice.ratePlanMask),
    JSON.stringify(ratePlanPrice)
  );
}

export function useRatePlanPrice() {
  const { queryParams, updateQueryParams } =
    useQueryParams<OrderFillinQueryParams>();

  const t = useLocale();

  const [ratePlanPrice, _setRatePlanPrice] =
    useState<SimplifiedRatePlanPrice>();

  const [changeResult, setChangeResult] = useState<PriceChangeResultModel>();
  const [processing, setProcessing] = useState(false);

  const { orderOption, setOrderOption } = useOrderOption();

  const { navigateReplace } = useNavigater();

  const appConfig = useContext(AppConfigContext);

  useEffect(() => {
    // const ratePlanPriceCache = getRatePlanPriceCache(queryParams.rprsid);
    // if (
    //   ratePlanPriceCache &&
    //   ratePlanPriceCache.priceSummary.Currency === orderOption.Currency
    // ) {
    //   console.log('use rate plan price form session cache');
    //   _setRatePlanPrice(ratePlanPriceCache);
    //   return;
    // }
    // searchRatePlanPrice(orderOption);
  }, []);

  function navigateBackToHotel(hotelId: number, destinationId: string) {
    const pathName = UrlHelper.addPrefix('/hotel/detail', appConfig.pathBase);
    navigateReplace<HotelDetailQueryPayload>(pathName, {
      hid: hotelId,
      rid: destinationId,
      ci: orderOption.CheckIn,
      co: orderOption.CheckOut,
      rc: orderOption.RoomCount,
      rl: orderOption.RoomOccupancyList.map((room) => ({
        ac: room.AdultsCount,
        cc: room.ChildrenCount,
        cal: room.Children.map((item) => item.Age),
      })),
      na: orderOption.Nationality,
      sor_id: queryParams.sor_id,
      sor_clid: queryParams.sor_clid,
      fclid: queryParams.fclid,
      hotel_id: queryParams.hotel_id,
      cur: queryParams.cur,
    });
  }

  function searchRatePlanPrice(
    options: Partial<FalconRate.RatePlanRateSearchOption>,
    useCache: boolean
  ) {
    if (useCache) {
      const ratePlanPriceCache = getRatePlanPriceCache(queryParams.rpm);
      if (
        ratePlanPriceCache &&
        ratePlanPriceCache.priceSummary.Currency === options.Currency
      ) {
        console.debug(
          location.pathname,
          'use rate plan price from session cache'
        );
        // setTimeout(() => {
        // }, 0);
        return (
          new Promise<{
            ratePlanPrice: SimplifiedRatePlanPrice;
            priceChangeResult: PriceChangeResultModel;
          }>((resolve) => {
            setTimeout(() => {
              resolve({
                ratePlanPrice: ratePlanPriceCache,
                priceChangeResult: null,
              });
            }, 0);
          })
            // return Promise.resolve({
            //   ratePlanPrice: ratePlanPriceCache,
            //   priceChangeResult: null,
            // })
            .then((resp) => {
              setRatePlanPrice(resp.ratePlanPrice);
              return resp;
            })
            .finally(() => {
              setProcessing(false);
            })
        );
      }
    } else {
      console.debug(
        location.pathname,
        'ignore rate plan price in session cache'
      );
    }
    setProcessing(true);
    return RateSearchService.searchRatePlanPrice(
      options,
      true,
      ratePlanPrice?.sessionId,
      queryParams.sor_id
    )
      .then((resp) => {
        if (resp.Success) {
          const price = RatePlanHelper.simplifyRatePlanPrice(
            resp.Data[0],
            resp.ResponseID,
            resp.SessionID
          );
          const priceChangeResult = ratePlanPrice
            ? RatePlanHelper.checkPriceChange(ratePlanPrice, price)
            : null;
          setChangeResult(priceChangeResult);
          setRatePlanPrice(price, options);
          return {
            ratePlanPrice,
            priceChangeResult,
          };
        } else {
          const error = {
            code: resp.ErrorCode,
            message: '',
          };
          switch (resp.ErrorCode) {
            case FalconRate.SearchRespCodeEnum.Error_RateSearch_NoAvailableRoom:
              error.message = t('Error_RateSearch_NoAvailableRoom');
              break;
            case FalconRate.SearchRespCodeEnum
              .Error_RateSearch_IncorrectRoomCount:
              error.message = t('Error_InvetoryNotAvailable');
              break;
            default:
              error.message = t('Error_RatePlanNotAvailable');
          }
          return Promise.reject(error);
        }
      })
      .finally(function () {
        setProcessing(false);
      });
  }

  function setRatePlanPrice(
    newPrice: SimplifiedRatePlanPrice,
    _orderOption = orderOption
  ) {
    _setRatePlanPrice(newPrice);
    const priceSummary = newPrice.hotelPriceSummary || newPrice.priceSummary;
    setOrderOption({
      ..._orderOption,
      ValidationFactors: {
        Currency: priceSummary.Currency,
        /**RatePlanRate请求返回的价格 */
        TotalPrice: priceSummary.Price,

        IsOnRequest: newPrice.confirmInMinutes > 0,

        IsHourlyRoom: newPrice.isHourlyRoom,

        /**房间的每天的信息 */
        RoomDailyInfoList: newPrice.roomDailyInfoList,

        /**RatePlanRate请求返回的取消政策 */
        CancellationList: newPrice.rawCancellationList,

        /**RatePlanRate请求返回的额外费用信息 */
        ExcludedFeeList: priceSummary.ExcludedFees,

        HotelName: newPrice.hotelInfo.Name,
        DidaRoomTypeID: newPrice.didaRoomTypeId.toString(),
        DidaRoomTypeName: newPrice.didaRoomTypeName,
        BedTypeID: newPrice.bedType.ID,
        BedTypeName: newPrice.bedType.Name,
        IsBedTypeAsured: newPrice.isBedTypeAsured,
      },
    });

    setRatePlanPriceCache(newPrice, queryParams.rpm);
    return newPrice;
  }
  return {
    ratePlanPrice,
    setRatePlanPrice,
    searchRatePlanPrice,
    orderOption,
    setOrderOption,
    // updateRatePlanPrice,
    navigateBackToHotel,
    changeResult,
    processing,
  };
}
