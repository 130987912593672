import { getDisplayInfo } from '@b2c/services/decorator';
import {
  CancellationStateType,
  PriceChangeResultModel,
} from '@b2c/services/rateplan';
import { useCallback } from 'react';
import { useLocale } from './use-locale';

export function usePriceChangeHelper() {
  const t = useLocale();

  const getNoticeList = useCallback(
    (priceChangeResult: PriceChangeResultModel) => {
      const noticeList: string[] = [];
      if (!priceChangeResult) {
        return noticeList;
      }
      if (priceChangeResult.priceChange) {
        noticeList.push(
          t('Text_Order_TotalPriceChanged', {
            0: t.formatPrice(priceChangeResult.priceChange.from),
            1: t.formatPrice(priceChangeResult.priceChange.to),
          })
        );
      }
      if (priceChangeResult.cancellationChange) {
        if (priceChangeResult.cancellationChange.themeType === 'info') {
          noticeList.push(t('Text_Notice_CancellationChangeInfo'));
        } else {
          noticeList.push(
            t('Text_Notice_CancellationChangeWarning', {
              0: t(
                getDisplayInfo(
                  CancellationStateType,
                  priceChangeResult.cancellationChange.from
                ).name
              ),
              1: t(
                getDisplayInfo(
                  CancellationStateType,
                  priceChangeResult.cancellationChange.to
                ).name
              ),
            })
          );
        }
      }
      if (priceChangeResult.mealInfoChange) {
        noticeList.push(t('Text_MealTypeChanged'));
      }

      return noticeList;
    },
    [t]
  );

  return {
    getNoticeList,
  };
}
