export class KeywordHelper {
  static getMatchs(
      source: string,
      keyword: string,
      ignoreCase = false
  ): { word: string; key: string }[] {
      let result: { word: string; key: string }[] = []
      if (source && keyword) {
          result = []
          const keys = keyword
              .replace(/[^\u4e00-\u9fa5a-zA-Z0-9\s]/gi, '')
              .trim()
              .split(/\s+/)
              .sort((x, y) => {
                  return y.length - x.length
              })
          const words = source.trim().split(/\s+/)
          const temp: { [key: string]: string } = {}
          keys.forEach(key => {
              words.forEach(word => {
                  if (!temp[word]) {
                      if (
                          (ignoreCase &&
                              word.toLowerCase().indexOf(key.toLowerCase()) >
                                  -1) ||
                          word.indexOf(key) > -1
                      ) {
                          temp[word] = key
                      }
                  }
              })
          })
          words.forEach(word => {
              result.push({ word: word, key: temp[word] })
          })
      }
      return result
  }

  static hasMatch(source: string, keyword: string, ignoreCase = true) {
    source = source.trim();
    keyword = keyword && keyword.trim();
    if (source && keyword) {
      const keys = keyword.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\s]/ig, '').split(/\s+/).sort((x, y) => {
        return y.length - x.length;
      });
      const words = source.split(/\s+/);
      return !!keys.find(key =>
        !!words.find(word =>
            ignoreCase ? word.toLowerCase().includes(key.toLowerCase()) : word.includes(key))
      );
    }
    return false;
  }
}
