import { DateTimeHelper } from '@b2c/services/common';
import { FalconPayment } from '@b2c/services/pay-channel';
import { ValidateHelper } from '@b2c/services/validate';
import type { Rule } from 'rc-field-form/lib/interface';
import { useMemo } from 'react';
import { useLocale } from './use-locale';

export function usePaymentCardRules() {
  const t = useLocale();

  const rules = useMemo(() => {
    const requiredRules = [
      { required: true, message: t('Error_ItemRequired') },
    ];
    const emailRule: Rule[] = [
      { required: true, message: t('Error_ItemRequired') },
      {
        validator(_, newVal) {
          if (!ValidateHelper.isEmail(newVal)) {
            return Promise.reject(new Error(t('Error_EmailNotValid')));
          }
          return Promise.resolve(true);
        },
      },
    ];

    const CVVRule: Rule[] = [
      { required: true, message: t('Error_ItemRequired') },
      {
        validator(_, newVal) {
          if (!/^\d{3}$/.test(newVal)) {
            return Promise.reject(
              new Error(t('Error_CVVNotValid', { count: 3 }))
            );
          }
          return Promise.resolve(true);
        },
      },
    ];

    const CIDRule: Rule[] = [
      { required: true, message: t('Error_ItemRequired') },
      {
        validator(_, newVal) {
          if (!/^\d{4}$/.test(newVal)) {
            return Promise.reject(
              new Error(t('Error_CVVNotValid', { count: 4 }))
            );
          }
          return Promise.resolve(true);
        },
      },
    ];

    const holderNameRule: Rule[] = [
      { required: true, message: t('Error_ItemRequired') },

      {
        validator(_, newVal) {
          const val = newVal.trim();
          if (val && !ValidateHelper.isCreditCardHolderName(val)) {
            return Promise.reject(new Error(t('Error_InvalidHolderName')));
          }
          return Promise.resolve(true);
        },
      },
    ];

    const cardNumberRule: (
      issuerTypeList: FalconPayment.PaymentProcessingNetworkTypeEnum[]
    ) => Rule[] = (issuerTypeList) => [
      { required: true, message: t('Error_ItemRequired') },
      {
        validator(_, newVal) {
          if (!ValidateHelper.isCreditCardNumber(newVal)) {
            return Promise.reject(
              new Error(t('Error_CreditCardNumberNotValid'))
            );
          }

          const issuerType = ValidateHelper.getCardIssuer(newVal);
          if (issuerType && !issuerTypeList.includes(issuerType)) {
            return Promise.reject(new Error(t('Error_CardTypeNotSupport')));
          }

          return Promise.resolve(true);
        },
      },
    ];

    const expireDateRule = (expireDate: Date) => {
      const expireDateStr = DateTimeHelper.getString(expireDate, 'yyMM');
      const rules: Rule[] = [
        { required: true, message: t('Error_ItemRequired') },
        {
          validator(_, newVal) {
            const val = newVal.split('/').reverse().join('');
            if (val.length < 4 || expireDateStr > val) {
              return Promise.reject(new Error(t('Error_InvalidExpiredDate')));
            }
            return Promise.resolve(true);
          },
        },
      ];
      return rules;
    };

    return {
      email: emailRule,
      CVV: CVVRule,
      CID: CIDRule,
      cardNumber: cardNumberRule,
      required: requiredRules,
      holderName: holderNameRule,
      expireDate: expireDateRule,
    };
  }, [t]);

  return rules;
}
