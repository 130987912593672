import { CoreCommon } from '@b2c/core';
import { Display, t } from '../decorator';

export interface IRoomNightMealInfoModel {
  stayDate: Date;
  mealInfo: CoreCommon.MealInfoModel;
}

export interface IRoomMealInfoModel {
  roomNumber: number;
  guestAmount: number;
  meals: IRoomNightMealInfoModel[];
}

export class OrderMealInfoModel {
  hasBreakfast: boolean;
  roomOnly: boolean;
  rooms: IRoomMealInfoModel[];
}

export class MealTypeModel {
  @Display({
    name: t('Label_Meal_None'),
  })
  static RoomOnly = CoreCommon.MealType.RoomOnly;

  @Display({
    name: t('Label_Meal_Breakfast'),
  })
  static Breakfast = CoreCommon.MealType.Breakfast;

  @Display({
    name: t('Label_Meal_HalfBoard'),
    desc: t('Text_Meal_HalfBoard'),
  })
  static HalfBoard = CoreCommon.MealType.HalfBoard;

  @Display({
    name: t('Label_Meal_FullBoard'),
    desc: t('Text_Meal_FullBoard'),
  })
  static FullBoard = CoreCommon.MealType.FullBoard;

  @Display({
    name: t('Label_Meal_AllInclusive'),
    desc: t('Text_Meal_AllInclusive'),
  })
  static AllInclusive = CoreCommon.MealType.AllInclusive;

  @Display({
    name: t('Label_Meal_Dinner'),
  })
  static Dinner = CoreCommon.MealType.Dinner;

  @Display({
    name: t('Label_Meal_BreakfastDinner'),
  })
  static BreakfastAndDinner = CoreCommon.MealType.BreakfastAndDinner;

  @Display({
    name: t('Label_Meal_BreakfastLunch'),
  })
  static BreakfastAndLunch = CoreCommon.MealType.BreakfastAndLunch;
}
