import { CoreHotelBooking } from '@b2c/core';
import { ArrowDown } from '@react-vant/icons';
import { Divider, Flex, Input } from 'react-vant';
import CallingCodePicker from './calling-code-picker/calling-code-picker';

type PhoneNumberInputProps = {
  value?: CoreHotelBooking.Contact;
  onChange?: (value: CoreHotelBooking.Contact) => void;
};

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value = {},
  onChange,
}) => {
  const trigger = (changedValue: Partial<CoreHotelBooking.Contact>) => {
    onChange?.({ ...value, ...changedValue });
  };

  return (
    <CallingCodePicker
      value={value?._PhonePrefix}
      onSelect={(prefix) => {
        trigger({
          ...value,
          _PhonePrefix: prefix,
        });
      }}
    >
      {(countryInfo, actions) => (
        <Flex align="center" gutter={10}>
          <Flex.Item onClick={() => actions.open()}>
            <Flex
              align="center"
              style={{
                color: 'var(--rv-input-text-color)',
                lineHeight: '24px',
              }}
            >
              +{countryInfo?.callingCode}
              <ArrowDown />
            </Flex>
          </Flex.Item>
          <Divider type="vertical"></Divider>
          <Flex.Item flex={1}>
            <Input
              value={value.Phone}
              onChange={(phone) => trigger({ ...value, Phone: phone })}
            />
          </Flex.Item>
        </Flex>
      )}
    </CallingCodePicker>
  );
};
