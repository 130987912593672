import { FalconBooking } from '@b2c/services/booking';
import { DeepLinkQueryParams } from '@b2c/services/common';
import { RoomViewInfo } from '@b2c/services/hotel';
import { FalconHotelRate, RateSearchService } from '@b2c/services/rate';
import { RatePlanHelper } from '@b2c/services/rateplan';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { SecureContextInfoContext } from './secure-context-info.context';
import { useChannelPriceInfo } from './use-channel-price-info';
import { useQueryParams } from './use-query-params';
export function useRateSearch() {
  const secureContext = useContext(SecureContextInfoContext);

  const { queryParams } = useQueryParams<DeepLinkQueryParams>();
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [bedTypeList, setBedTypeList] = useState<string[]>([]);
  const [paymentTypeList, setPaymentTypeList] = useState<number[]>([]);
  const [roomList, setRoomList] = useState<RoomViewInfo[]>([]);
  const [channelPriceInfo] = useChannelPriceInfo();

  const requestSignalListRef = useRef<AbortController[]>([]);

  const multiPrice = useCallback((price: number, nights: number) => {
    const rawPrice = price * nights;
    return parseFloat(rawPrice.toFixed(2));
  }, []);

  const multiPriceSummary = useCallback(
    (priceSummary: FalconBooking.PriceSummary, nights: number) => {
      if (!priceSummary) {
        return null;
      }
      const priceKeys = ['Price', 'PriceBeforeTax', 'TaxAndFee'];
      priceKeys.forEach((key) => {
        priceSummary[key] = multiPrice(priceSummary[key], nights);
      });
      priceSummary.ExcludedFees?.map((fee) => {
        fee.Amount = multiPrice(fee.Amount, nights);
      });
      priceSummary.IncludedFees?.map((fee) => {
        fee.Amount = multiPrice(fee.Amount, nights);
      });
      return priceSummary;
    },
    []
  );

  useEffect(() => {
    return () => {
      requestSignalListRef.current.map((item) => {
        item.abort();
      });
    };
  }, []);

  function loadHotelRatePrice(
    searchOption: Partial<FalconHotelRate.SearchOption>,
    cpi = channelPriceInfo
  ) {
    const bedTypeSet = new Set<string>();
    const paymentTypeSet = new Set<number>();
    const roomMap = new Map<number, RoomViewInfo>();

    let finnishCount = 0;
    setRoomList([]);

    // let priceReplaced = false;
    const supplierMaskGroups = secureContext.ml_G;
    requestSignalListRef.current.map((item) => {
      item.abort();
    });
    requestSignalListRef.current.splice(0, requestSignalListRef.current.length);

    if (!(supplierMaskGroups.length > 0)) {
      setPercentage(100);
      setLoading(false);
      return;
    }

    setLoading(true);
    supplierMaskGroups.map((supplierList, index) => {
      const abortController = new AbortController();
      requestSignalListRef.current.push(abortController);
      searchOption = {
        ...searchOption,
        SupplierIDList: supplierList,
        BatchID: secureContext.bid,
        SearchIdx: index,
        ChannelPriceInfo: index == 0 ? cpi : undefined,
      };
      const params = { index };

      if (index == 0 && cpi?.SourceSite) {
        params['sor_st'] = cpi.SourceSite;
      }
      RateSearchService.searchHotelPrice(
        searchOption,
        queryParams.sor_id,
        abortController.signal,
        params
      )
        .then(
          (resp) => {
            resp.Data.map((hotelPrice) => {
              if (!(hotelPrice.RatePlanPriceList?.length > 0)) {
                return;
              }

              const roomCount = searchOption.RealTimeFilter.RoomCount;
              // console.log('prcice found', price.RatePlanPriceList);

              hotelPrice.RatePlanPriceList.map((ratePlanPrice) => {
                const fakeRatePlanPrice: typeof hotelPrice = {
                  ...hotelPrice,
                  PriceSummary: ratePlanPrice.PriceSummary,
                  RatePlanPriceList: [ratePlanPrice],
                };

                if (roomCount > 1) {
                  fakeRatePlanPrice.PriceSummary = multiPriceSummary(
                    { ...fakeRatePlanPrice.RatePlanPriceList[0].PriceSummary },
                    roomCount
                  );

                  fakeRatePlanPrice.RatePlanPriceList = new Array(roomCount)
                    .fill('')
                    .map((item, idx) => ({
                      ...ratePlanPrice,
                      RoomNum: idx,
                      CancellationList: ratePlanPrice.CancellationList.map(
                        (item) => {
                          item.Amount = multiPrice(item.Amount, roomCount);
                          return { ...item };
                        }
                      ),
                    }));
                }

                const newRate = RatePlanHelper.simplifyRatePlanPrice(
                  fakeRatePlanPrice,
                  resp.ResponseID,
                  resp.SessionID
                );

                if (newRate.totalDiscount) {
                  newRate.originalPrice =
                    newRate.priceSummary.Price + newRate.totalDiscount;
                }
                if (newRate.bedTypeDesc) {
                  bedTypeSet.add(newRate.bedTypeDesc);
                }
                paymentTypeSet.add(newRate.paymentType);

                let room: RoomViewInfo;
                if (roomMap.has(newRate.didaRoomTypeId)) {
                  room = roomMap.get(newRate.didaRoomTypeId);
                } else {
                  room = {
                    didaRoomTypeName: newRate.didaRoomTypeName,
                    didaRoomTypeId: newRate.didaRoomTypeId,
                    // rateList: [],
                    priceList: [],
                    isShowRateList: false,
                    isShowAll: false,
                    // lowestPrice: Infinity,
                  } as RoomViewInfo;
                  roomMap.set(newRate.didaRoomTypeId, room);
                }

                if (
                  !room.priceList.some((r) =>
                    r.ratePlanId
                      ? r.ratePlanId === newRate.ratePlanId
                      : r.ratePlanMask === newRate.ratePlanMask
                  )
                ) {
                  room.priceList.push(newRate);
                  room.priceList.sort(RatePlanHelper.isRatePlanPriceLower);
                }
              });
            });

            const newRoomList = Array.from(roomMap.values());
            // console.log(newRoomList);

            newRoomList.sort((a, b) =>
              RatePlanHelper.isRatePlanPriceLower(
                a.priceList[0],
                b.priceList[0]
              )
            );
            setRoomList(newRoomList);
            if (bedTypeSet.size > bedTypeList.length) {
              setBedTypeList(Array.from(bedTypeSet));
            }
            setPaymentTypeList(Array.from(paymentTypeSet));
          },
          () => {
            // console.log(err);
          }
        )
        .catch(console.log)
        .finally(() => {
          finnishCount++;
          setPercentage(
            Math.ceil((finnishCount / supplierMaskGroups.length) * 100)
          );
          const loading = finnishCount !== supplierMaskGroups.length;
          // if (!loading && !priceReplaced && channelPriceInfo) {
          //   // TODO: populate fallback rate plan
          // }
          setLoading(loading);
        });
    });
    // setTimeout(() => {
    //   RequestSignalList.map((item) => {
    //     item.abort();
    //   });
    // }, 0);
  }

  return {
    bedTypeList,
    paymentTypeList,
    loading,
    percentage,
    roomList,
    loadHotelRatePrice,
  };
}
