import { Footer } from '@b2c/ui';
import classNames from 'classnames';
import { useMemo } from 'react';
import { LayoutHeader } from './layout-header';
import styles from './layout.module.less';

export function Layout({ children, theme }) {
  const layoutModifier = useMemo(() => {
    return {
      white: styles.theme_white,
    }[theme];
  }, [theme]);

  return (
    <div className={classNames(styles.layout, layoutModifier)}>
      <div className={styles.layoutBody}>
        <div className={styles.layoutContainer}>
          <LayoutHeader></LayoutHeader>
          {children}
        </div>
      </div>
      <div className={styles.layoutFooter}>
        <div className={styles.layoutContainer}>
          <Footer
            brandName="HotelWhales"
            WebSiteName="HotelWhales.com"
          ></Footer>
        </div>
      </div>
    </div>
  );
}
