import { DeepLinkQueryParams } from './models';

const DP_LINK_PARAM_FIELD_HOLDER: {
  [key in keyof Required<DeepLinkQueryParams>]: boolean;
} = {
  sor_id: true,
  sor_clid: true,
  sor_i: true,
  sor_st: true,
  hotel_id: true,
  fclid: true,
  sor_ms: false,
};

export class UrlHelper {
  static PrivilegeParamList = ['hid'];

  static toAvailableBackgroundUrl(url: string) {
    return encodeURI(url);
  }

  static addParams(url: string, params: { [key: string]: any }) {
    if (!params) {
      return url;
    }
    const keys = Object.keys(params);
    if (keys.length < 1) {
      return url;
    }

    const queryStr = keys
      .filter((key) => typeof params[key] !== 'undefined')
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    const seperater = url.indexOf('?') > 0 ? '&' : '?';

    return url + seperater + queryStr;
  }

  static createPayloadParam(params: any) {
    const privilegeParams = {};
    const payloadParams = {};
    const extraParams = {};
    Object.keys(params).forEach((i) => {
      if (typeof params[i] === 'undefined') {
        return;
      }
      if (i.startsWith('_')) {
        extraParams[i] = params[i];
      } else if (
        // typeof params[i] === 'string' ||
        this.PrivilegeParamList.includes(i)
      ) {
        privilegeParams[i] = params[i];
      } else {
        payloadParams[i] = params[i];
      }
    });

    let payloadParamsStr;
    if (Object.keys(payloadParams).length > 0) {
      payloadParamsStr = JSON.stringify(payloadParams);
      payloadParamsStr = window.btoa(payloadParamsStr);
      payloadParamsStr = window.encodeURIComponent(payloadParamsStr);
    }
    return {
      ...privilegeParams,
      payload: payloadParamsStr,
      ...extraParams,
    };
  }

  static extracPayloadParam() {
    const payloadStr = new URLSearchParams(location.search).get('payload');
    const payload = this.parsePayloadParam(payloadStr);
    return payload;
  }

  static parsePayloadParam(paramsString: string) {
    if (!paramsString) {
      return {};
    }
    paramsString = window.decodeURIComponent(paramsString);
    paramsString = window.atob(paramsString);
    try {
      const payload = JSON.parse(paramsString);
      // console.debug('extract payload param', payload);
      return payload;
    } catch {
      // console.error('extract payload faild', paramsString);
      return {};
    }
  }

  static parseQueryParams<T>(search: string) {
    const rawParams: { [key: string]: any } = {};
    const params = new URLSearchParams(search);
    params.forEach((value, key) => {
      if (key in rawParams) {
        rawParams[key] = params.getAll(key);
      } else {
        rawParams[key] = value;
      }
    });
    const { payload, ...restParams } = rawParams;
    const payloadParams = UrlHelper.parsePayloadParam(payload) as T;
    return Object.assign(restParams, payloadParams);
  }

  static mergeQueryParams<T = any>(partialPayload: T) {
    const payload = this.parseQueryParams(location.search);
    console.log('update payload params', payload, partialPayload);
    // navigateReplace(location.pathname, {
    //   ...payload,
    //   ...partialPayload,
    // });
    return {
      ...payload,
      ...partialPayload,
    };
  }

  static buildExtConnectionParams(deeplinkQueryParam: DeepLinkQueryParams) {
    const param_arr = <string[]>[];
    Object.keys(DP_LINK_PARAM_FIELD_HOLDER)
      .sort()
      .forEach((k) => {
        if (!DP_LINK_PARAM_FIELD_HOLDER[k]) {
          return;
        }
        const v = deeplinkQueryParam[k];
        if (typeof v !== 'undefined') {
          param_arr.push(`${k}=${v}`);
        }
      });
    return param_arr.join('&');
  }

  static addPrefix(url: string, prefix: string) {
    if (!prefix || url.startsWith(prefix + '/')) {
      return url;
    }
    return prefix + url;
  }
}
