import { CoreCommon, CoreHotel } from '@b2c/core';
import { FalconBooking } from '../booking';
import { FalconCommon } from '../common';
import { Display, t } from '../decorator';
import { CancellationItem, SimplifiedHotelDetail } from '../hotel';
import { FalconOrdering } from '../order/falcon-order';
import { FalconPayment } from '../pay-channel';
import { FalconRate } from '../rate';
import { CancellationStateType } from './enums/cancellation-stages.enum';

export interface SimplifiedRatePlanPrice {
  bookingNotice: FalconRate.BookingNotice;
  hotelInfo: SimplifiedHotelDetail;

  priceSummary: FalconBooking.PriceSummary;
  hotelPriceSummary: FalconBooking.PriceSummary;

  showCurrencyExchagneDesc: boolean;
  hasPrice: boolean;

  /**
   * 每晚房价
   * 多间房的情况下为每晚房价的平均价格
   */
  // dailyPriceList: RateViewInfo[];

  roomCount: number;

  /**
   * 每个房间的具体餐型，如果有三个房间那么就有三个元素
   */
  mealInfoList: CoreCommon.MealInfoModel[];

  /**
   * 在继续支付的场景时，这里就会有值
   * */
  // bookingId: string;
  // dailyInfoListByRoomNumber: { [key: number]: FalconOrdering.DailyInfo[] };
  roomDailyInfoList: FalconOrdering.RoomDailyInfo[];

  /// <summary>
  /// 渠道价格信息
  /// </summary>
  channelPriceInfo?: FalconRate.ThirdPartyRateInfo;
  paymentType: FalconPayment.RatePaymentTypeEnum;
  isPayLater: boolean;
  excludedFeeCurrencyNotMatchUserCurrency: boolean;
  excludedFeeCurrencyNotMatchHotelCurrency: boolean;
  totalExcludedCharges: FalconBooking.FalconFeeModel;
  totalIncludedCharges: FalconBooking.FalconFeeModel;
  totalHotelExcludedCharges: FalconBooking.FalconFeeModel;
  totalPrice: number;
  totalHotelPrice: number;

  nightCount: number;
  sessionId: string;
  bankCardInputConditions?: FalconPayment.BankCardInputConditions;

  didaRoomTypeId: number;
  didaRoomTypeName: string;
  // supplierId: string;
  // ratePlanId: string;
  ratePlanName: string;
  ratePlanMask: string;
  ratePlanId: string;

  mealInfo: CoreCommon.MealInfoModel;
  withBreakfast: boolean;

  bedType: FalconCommon.BedTypeModel;
  bedTypeDesc: string;
  isBedTypeAsured: boolean;
  responseId: string;
  // price: number;
  // supplement: number;
  // stayDate: string;
  rateList: FalconRate.RatePlanRateModel[];
  isNoSmoking: boolean;
  isNoWindow: boolean;
  confirmInMinutes: number;
  isHourlyRoom: boolean;
  allowGuestNameInChinese: boolean;
  timeZoneInfo: FalconCommon.TimeZoneInfo;

  isRefundable: boolean;
  cancellationList: FalconCommon.DayCancellation[];
  rawCancellationList: FalconCommon.DayCancellation[];

  valueAddList?: FalconRate.ValueAddModel[];
  valueAddDesc?: string;
  promotionList?: FalconRate.PromotionModel[];
  supplierSpecailRequests?: number[];

  /**
   * 总折扣（数值为正数），且已经算好整订单折扣（非单间房）
   *
   * @type {number}
   * @memberof RatePlanViewInfo
   */
  totalDiscount: number;
  // totalPrice: number;
  originalPrice: number;
  // currency: string;
  // totalExcludedCharges: number;

  /**
   * @todo move to simlifiedRatePlanPrice
   */
  priceUnavailable: boolean;
  checking?: boolean;
  errMsg: string;
}

/**
 * 用于变价比较
 */
export interface SimplifiedPriceInfo {
  currency: string;
  totalPrice: number;
  mealInfoList: CoreCommon.MealInfoModel[];
  cancellationState: CancellationStateType;
  cancellationList: CancellationItem[];
}

export interface PriceChangeResultModel {
  hasChange: boolean;
  mealInfoChange?: {
    // desc: string;
  };
  priceChange?: {
    from: FalconBooking.PriceSummary;
    to: FalconBooking.PriceSummary;
  };
  cancellationChange?: {
    themeType: 'info' | 'warning';
    from: CancellationStateType;
    to: CancellationStateType;
  };
}

export class BedTypeInfoModel {
  @Display({
    name: t('Text_None'),
  })
  static None = CoreHotel.BedTypes.None;

  @Display({
    name: t('Label_BedType_1'),
  })
  static OneSingle = CoreHotel.BedTypes.OneSingle;

  @Display({
    name: t('Label_BedType_2'),
  })
  static OneDouble = CoreHotel.BedTypes.OneDouble;

  @Display({
    name: t('Label_BedType_3'),
  })
  static OneTwin = CoreHotel.BedTypes.OneTwin;

  @Display({
    name: t('Label_BedType_4'),
  })
  static ThreeSingle = CoreHotel.BedTypes.ThreeSingle;

  @Display({
    name: t('Label_BedType_5'),
  })
  static OneDoubleOneSingle = CoreHotel.BedTypes.OneDoubleOneSingle;

  @Display({
    name: t('Label_BedType_6'),
  })
  static TwoDouble = CoreHotel.BedTypes.TwoDouble;

  @Display({
    name: t('Label_BedType_7'),
  })
  static OneDoubleOneTwin = CoreHotel.BedTypes.OneDoubleOneTwin;

  @Display({
    name: t('Label_BedType_8'),
  })
  static TwoTwin = CoreHotel.BedTypes.TwoTwin;

  @Display({
    name: t('Label_BedType_9'),
  })
  static DoubleOrTwin = CoreHotel.BedTypes.DoubleOrTwin;

  @Display({
    name: t('Label_BedType_10'),
  })
  static OneDoubleOneSofa = CoreHotel.BedTypes.OneDoubleOneSofa;

  @Display({
    name: t('Label_BedType_11'),
  })
  static OneSingleOneSofa = CoreHotel.BedTypes.OneSingleOneSofa;

  @Display({
    name: t('Label_BedType_14'),
  })
  static TwoSingleOneSofa = CoreHotel.BedTypes.TwoSingleOneSofa;

  @Display({
    name: t('Label_BedType_15'),
  })
  static ThreeSingleOneSofa = CoreHotel.BedTypes.ThreeSingleOneSofa;

  @Display({
    name: t('Label_BedType_16'),
  })
  static FourSingleOneSofa = CoreHotel.BedTypes.FourSingleOneSofa;

  @Display({
    name: t('Label_BedType_17'),
  })
  static TwoDoubleOneSofa = CoreHotel.BedTypes.TwoDoubleOneSofa;

  @Display({
    name: t('Label_BedType_18'),
  })
  static OneDoubleTwoSofa = CoreHotel.BedTypes.OneDoubleTwoSofa;

  @Display({
    name: t('Label_BedType_21'),
  })
  static OneDoubleOneSingleOneSofa =
    CoreHotel.BedTypes.OneDoubleOneSingleOneSofa;

  @Display({
    name: t('Label_BedType_22'),
  })
  static OneDoubleTwoSingleOneSofa =
    CoreHotel.BedTypes.OneDoubleTwoSingleOneSofa;

  @Display({
    name: t('Label_BedType_23'),
  })
  static OneDoubleTwoSingleOneDoubleSofa =
    CoreHotel.BedTypes.OneDoubleTwoSingleOneDoubleSofa;

  @Display({
    name: t('Label_BedType_24'),
  })
  static OneSofa = CoreHotel.BedTypes.OneSofa;

  @Display({
    name: t('Label_BedType_25'),
  })
  static OneSofaOneBunk = CoreHotel.BedTypes.OneSofaOneBunk;

  @Display({
    name: t('Label_BedType_26'),
  })
  static OneDoubleTwoBunk = CoreHotel.BedTypes.OneDoubleTwoBunk;

  @Display({
    name: t('Label_BedType_27'),
  })
  static OneDoubleOneSingleOneBunk =
    CoreHotel.BedTypes.OneDoubleOneSingleOneBunk;

  @Display({
    name: t('Label_BedType_28'),
  })
  static OneDoubleOneBunk = CoreHotel.BedTypes.OneDoubleOneBunk;

  @Display({
    name: t('Label_BedType_31'),
  })
  static OneBunk = CoreHotel.BedTypes.OneBunk;

  @Display({
    name: t('Label_BedType_32'),
  })
  static TwoBunk = CoreHotel.BedTypes.TwoBunk;

  @Display({
    name: t('Label_BedType_35'),
  })
  static ThreeBunk = CoreHotel.BedTypes.ThreeBunk;

  @Display({
    name: t('Label_BedType_36'),
  })
  static FourBunk = CoreHotel.BedTypes.FourBunk;

  @Display({
    name: t('Label_BedType_37'),
  })
  static TwoDoubleOneBunk = CoreHotel.BedTypes.TwoDoubleOneBunk;

  @Display({
    name: t('Label_BedType_38'),
  })
  static TwoSingleOneBunk = CoreHotel.BedTypes.TwoSingleOneBunk;

  @Display({
    name: t('Label_BedType_39'),
  })
  static TwoSingleTwoBunk = CoreHotel.BedTypes.TwoSingleTwoBunk;

  @Display({
    name: t('Label_BedType_40'),
  })
  static OneSingleOneBunk = CoreHotel.BedTypes.OneSingleOneBunk;

  @Display({
    name: t('Label_BedType_41'),
  })
  static ThreeSingleOneBunk = CoreHotel.BedTypes.ThreeSingleOneBunk;

  @Display({
    name: t('Label_BedType_42'),
  })
  static OneDoubleTwoSingleOneBunk =
    CoreHotel.BedTypes.OneDoubleTwoSingleOneBunk;

  @Display({
    name: t('Label_BedType_43'),
  })
  static OneDoubleFourBunk = CoreHotel.BedTypes.OneDoubleFourBunk;

  @Display({
    name: t('Label_BedType_44'),
  })
  static TwoDoubleOneSingle = CoreHotel.BedTypes.TwoDoubleOneSingle;

  @Display({
    name: t('Label_BedType_45'),
  })
  static TwoDoubleTwoSingle = CoreHotel.BedTypes.TwoDoubleTwoSingle;

  @Display({
    name: t('Label_BedType_46'),
  })
  static OneDoubleThreeSingle = CoreHotel.BedTypes.OneDoubleThreeSingle;

  @Display({
    name: t('Label_BedType_47'),
  })
  static OneDoubleFourSingle = CoreHotel.BedTypes.OneDoubleFourSingle;

  @Display({
    name: t('Label_BedType_48'),
  })
  static ThreeDouble = CoreHotel.BedTypes.ThreeDouble;

  @Display({
    name: t('Label_BedType_49'),
  })
  static FourDouble = CoreHotel.BedTypes.FourDouble;

  @Display({
    name: t('Label_BedType_52'),
  })
  static TwoSingle = CoreHotel.BedTypes.TwoSingle;

  @Display({
    name: t('Label_BedType_53'),
  })
  static FourSingle = CoreHotel.BedTypes.FourSingle;

  @Display({
    name: t('Label_BedType_54'),
  })
  static SemiDouble = CoreHotel.BedTypes.SemiDouble;
}
