import { t } from '@b2c/services/decorator/display';
import { lazy } from 'react';

export default [
  {
    path: '',
    component: lazy(() => import('./hotel/index/hotel-index')),
    title: t('Title_Nav_HotelIndex'),
    data: {
      headerStyle: 'ghost',
      theme: 'white',
    },
    guardOption: {
      auth: false,
      secure: false,
      context: false,
    },
  },
  {
    path: 'hotel/list',
    component: lazy(() => import('./hotel/list/hotel-list')),
    title: t('Title_Nav_HotelList'),
    guardOption: {
      auth: false,
      secure: true,
    },
  },
  {
    path: 'hotel/detail',
    component: lazy(() => import('./hotel/detail/hotel-detail')),
    title: t('Title_Nav_HotelDetails'),
    data: {
      headerStyle: 'ghost',
      // theme: 'white',
    },
    guardOption: {
      auth: false,
      secure: true,
    },
  },
  {
    path: 'hotel/detail/:hotelId',
    component: lazy(() => import('./hotel/detail/hotel-detail')),
    title: t('Title_Nav_HotelDetails'),
    guardOption: {
      auth: false,
      secure: true,
    },
  },
  {
    path: 'order/fillin',
    component: lazy(() => import('./order/fillin/order-fillin')),
    title: t('Title_Nav_OrderFillin'),
    data: {
      headerStyle: 'ghost',
    },
    guardOption: {
      auth: false,
      secure: true,
    },
  },
  {
    path: 'order/pay',
    component: lazy(() => import('./order/pay/order-pay')),
    title: t('Title_Nav_OrderPay'),
    data: {
      headerStyle: 'ghost',
    },
    guardOption: {
      auth: false,
      secure: false,
    },
  },
  {
    path: 'order/result',
    component: lazy(() => import('./order/result/order-result')),
    title: t('Title_Nav_BookingResult'),
    guardOption: {
      auth: false,
      secure: false,
    },
  },
  {
    path: 'booking/detail',
    component: lazy(() => import('./booking/booking-detail')),
    title: t('Title_Nav_BookingDetails'),
    guardOption: {
      secure: false,
    },
    data: {
      hideHeaderLogo: true,
    },
  },
  {
    path: 'booking/list',
    component: lazy(() => import('./booking/booking-list')),
    title: t('Title_Nav_BookingList'),
    guardOption: {
      auth: true,
      secure: false,
    },
  },
  {
    path: 'user',
    component: lazy(() => import('./user/user-index')),
    title: t('Title_Nav_UserCenter'),
    data: {
      // theme: 'white',
    },
    guardOption: {
      auth: true,
      secure: false,
    },
  },
  {
    path: 'user/change-password',
    component: lazy(() => import('./user/change-password')),
    title: t('Title_Nav_ChangePassword'),
    data: {
      theme: 'white',
    },
    guardOption: {
      auth: true,
      secure: false,
    },
  },
  {
    path: 'user/change-email',
    component: lazy(() => import('./user/change-email')),
    title: t('Title_Nav_ChangeEmail'),
    data: {
      theme: 'white',
    },
    guardOption: {
      auth: true,
      secure: false,
    },
  },
  {
    path: 'account/login',
    component: lazy(() => import('./account/account-login')),
    title: t('Title_Nav_Login'),
    data: {
      headerStyle: 'ghost',
    },
    guardOption: {
      auth: false,
      secure: false,
    },
  },
  {
    path: 'account/register',
    component: lazy(() => import('./account/account-register')),
    title: t('Title_Nav_RegisterAccount'),
    data: {
      theme: 'white',
    },
    guardOption: {
      auth: false,
      secure: false,
    },
  },
  {
    path: 'account/reset-password',
    component: lazy(() => import('./account/reset-password')),
    title: t('Title_Nav_ResetPassword'),
    data: {
      theme: 'white',
    },
    guardOption: {
      auth: false,
      secure: false,
    },
  },
  {
    path: 'faq',
    component: lazy(() => import('./faq/index/faq-index')),
    title: t('Title_FAQ'),
    data: {
      theme: 'white',
    },
  },
  { path: 'doc/:name', component: lazy(() => import('./doc/doc')) },
  { component: lazy(() => import('./404')) },
];
