import { CoreCommon } from '@b2c/core';
export namespace FalconContext {
  export interface AppIdentityInfo {
    isAuthenticated: boolean;
    profile: IUserProfileModel;
  }

  export interface AppContextInfo {
    userID: string;
    userIdentityInfo: AppIdentityInfo;
    locale: CoreCommon.LanguageCode;
    localeList: CoreCommon.LanguageCode[];
    currencyList: string[];
    currencyInfo: AppCurrencyInfo;
    icp: string;

    // versions

    /**
     * 前端发布状态
     *
     * @type {boolean}
     * @memberof DidaApplicationUserContextModel
     */
    fe_deploying?: boolean;
    /**
     * 前端版本
     *
     * @type {string}
     * @memberof DidaApplicationUserContextModel
     */
    fe_version?: string;

    /**
     * 前端版本不对称时，是否强制刷新页面
     *
     * @type {boolean}
     * @memberof DidaApplicationUserContextModel
     */
    fe_reload?: boolean;

    /**
     * ICP备案信息
     *
     * @type {string}
     * @memberof DidaApplicationUserContextModel
     */
    icp?: string;

    /**
     * 在service中记录application context的返回时间
     */
    __responseTime__: Date;
  }
  export class SecurityContextEstablishOption {
    Pku: string;

    Vec: string;
  }

  export class SecurityContextEstablishResult {
    /**
     * @description SecurityMatrix
     */
    sm: string;

    /**
     * @description BatchID
     */
    bid: string;
    /**
     * @description BatchID Expire After Seconds
     */
    eas: number;

    /**
     * @description Info Mask Enabled
     */
    ime: boolean;
    /**
     * @description Info Mask Valid Remaining Minutes
     */
    ime_vrm: number;
    /**
     * @description SupplierMaskGroup
     */
    ml_G: string[][];
  }

  export interface AppCurrencyInfoBase {
    Currency: string;
    Ratio: number;
  }

  export interface AppCurrencyInfo extends AppCurrencyInfoBase {
    Currency: string;
    Ratio: number;
    Reference: AppCurrencyInfoBase;
  }
}
