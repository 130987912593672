import { UrlHelper } from '@b2c/services/common';
import { useCallback } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

export function useNavigater() {
  const navigate = useNavigate();

  const navigateTo = useCallback(
    <T = null>(
      url: string,
      params: T = null,
      option?: NavigateOptions & {
        queryParamHandle?: 'merge';
      }
    ) => {
      let search = '';
      if (option?.queryParamHandle === 'merge') {
        params = UrlHelper.mergeQueryParams(params);
      }
      if (params) {
        const payloadParam = UrlHelper.createPayloadParam(params);
        search = UrlHelper.addParams('', payloadParam);
      }
      if (url.startsWith('http')) {
        location.href = url;
      } else {
        navigate(
          {
            pathname: url,
            search,
          },
          option
        );
      }
    },
    []
  );

  const navigateReplace = useCallback(
    <T = null>(url: string, params: T = null) => {
      if (url.startsWith('http')) {
        location.replace(url);
      } else {
        navigateTo(url, params, {
          replace: true,
        });
      }
    },
    []
  );

  return {
    navigateTo,
    navigateReplace,
    navigateBack: () => window.history.back(),
  };
}
