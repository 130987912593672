import { CoreHotel } from '@b2c/core';
import { LanguageCode } from '../i18n';
export namespace FalconHotel {
  export interface HotelInfoLoadingOptions {
    WithMetadata: boolean;
    WithBookingNotice: boolean;
    WithRoomImages: boolean;
    WithHotelImageList: boolean;
    DefaultHotelImageOnly: boolean;

    WithDetailCollectionList: boolean;
    WithAttributeList: boolean;
    WithCustomizeDescriptionList: boolean;
    WithFacilitySetting: boolean;
    WithHotelLabel: boolean;
    WithSellPoint: boolean;

    WithImportNotice: boolean;
    WithMainHotelID: boolean;
    WithHotelAlias: boolean;

    Language: LanguageCode;
  }

  export interface HotelInfoQueryOption extends HotelInfoLoadingOptions {
    HotelID: number;

    WithRoomContent: boolean;
  }

  export interface MultiHotelInfoQueryOption extends HotelInfoLoadingOptions {
    HotelIDList: number[];
  }

  export interface HotelStaticDetailModel extends CoreHotel.HotelStaticDetail {
    RoomContentList: CoreHotel.RoomTypeContent[];

    BookingNotice: CoreHotel.BookingNotice;
  }
}
