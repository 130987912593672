import { BrowserHelper } from '@b2c/services/common';
import { LoggerService } from '@b2c/services/logging';
import { useEffect } from 'react';

export function useExceptionListener() {
  useEffect(() => {
    function unHandledReject(event: PromiseRejectionEvent) {
      // console.log('unhandledrejection', ...arguments);
      LoggerService.warn(`[unhandledrejection]: ${event.reason}`);
    }

    window.onerror = function (message, source, lineno, colno, error) {
      // console.log('onerror', ...arguments);

      if (error?.message) {
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        const resizeObserverLoopErrRe =
          /^[^(ResizeObserver loop limit exceeded)]/;
        if (resizeObserverLoopErrRe.test(error?.message)) {
          return Promise.resolve();
        }
        const isError = ['Cannot read properties of'].some((item) =>
          error.message.includes(item)
        );
        if (isError) {
          LoggerService.error(error);
        } else {
          LoggerService.warn(error);
        }
      }
    };

    // window.addEventListener('error', errorHandler);
    window.addEventListener('unhandledrejection', unHandledReject);
    return () => {
      // window.removeEventListener('error', errorHandler);
      window.removeEventListener('unhandledrejection', unHandledReject);
      window.onerror = null;
    };
  }, []);

  useEffect(() => {
    let instance = null;
    if (
      location.search.includes('&_console=true') &&
      BrowserHelper.isInWebView
    ) {
      const loadConsole = () => {
        const script = window.document.createElement('script');
        script.addEventListener('load', () => {
          instance = new window['VConsole']();
        });
        script.src =
          'https://cdn.bootcdn.net/ajax/libs/vConsole/3.8.1/vconsole.min.js';

        window.document.head.append(script);
      };

      if (!instance) {
        loadConsole();
      }
      return () => {
        instance?.destroy();
        instance = null;
      };
    }
  }, [location.search]);
}
