import { Display, t } from '../decorator';
import { BankCardNumberValidation } from '../decorator/bank-card-number-validation';
import { FalconPayment } from './falcon-payment.d';
export class RatePaymentType {
  @Display({
    name: t('Label_PrePay'),
  })
  static PrePay = FalconPayment.RatePaymentTypeEnum.PrePay;

  @Display({
    name: t('Label_PayAtPropertyWithCreditCard'),
  })
  static PayAtPropertyWithCreditCard =
    FalconPayment.RatePaymentTypeEnum.PayAtPropertyWithCreditCard;

  @Display({
    name: t('Label_PayAtPropertyWithoutCreditCard'),
  })
  static PayAtPropertyWithoutCreditCard =
    FalconPayment.RatePaymentTypeEnum.PayAtPropertyWithoutCreditCard;
}

export class BankCardType {
  @Display({
    name: t('Label_DebitCard'),
  })
  static DebitCard = FalconPayment.BankCardTypeEnum.DebitCard;

  @Display({
    name: t('Label_CreditCard'),
  })
  static CreditCard = FalconPayment.BankCardTypeEnum.CreditCard;
}

export class PaymentProcessingNetworkType {
  @BankCardNumberValidation({
    minLen: 13,
    maxLen: 19,
    startsWith: ['4'],
  })
  @Display({
    name: t('Label_DebitCard_VISA'),
  })
  static VISA = FalconPayment.PaymentProcessingNetworkTypeEnum.VISA;

  @BankCardNumberValidation({
    minLen: 16,
    maxLen: 16,
    startsWith: [
      [51, 55],
      [2221, 2720],
    ],
  })
  @Display({
    name: t('Label_DebitCard_MasterCard'),
  })
  static MASTER_CARD =
    FalconPayment.PaymentProcessingNetworkTypeEnum.MASTER_CARD;

  @BankCardNumberValidation({
    minLen: 16,
    maxLen: 19,
    startsWith: [
      [65, 65],
      [644, 649],
      [6011, 6011],
      [622126, 622925],
    ],
  })
  @Display({
    name: t('Label_DebitCard_Discover'),
  })
  static DISCOVER = FalconPayment.PaymentProcessingNetworkTypeEnum.DISCOVER;

  @BankCardNumberValidation({
    minLen: 15,
    maxLen: 15,
    startsWith: ['34', '37'],
  })
  @Display({
    name: t('Label_DebitCard_AmeracanExpress'),
  })
  static AMERICAN_EXPRESS =
    FalconPayment.PaymentProcessingNetworkTypeEnum.AMERICAN_EXPRESS;

  @BankCardNumberValidation({
    minLen: 16,
    maxLen: 16,
    startsWith: [
      [3088, 3094],
      [3096, 3102],
      [3112, 3120],
      [3158, 3159],
      [3337, 3349],
      [3528, 3589],
    ],
  })
  @Display({
    name: t('Label_DebitCard_JCB'),
  })
  static JCB = FalconPayment.PaymentProcessingNetworkTypeEnum.JCB;

  @BankCardNumberValidation({
    minLen: 16,
    maxLen: 19,
    startsWith: ['62'],
  })
  @Display({
    name: t('Label_DebitCard_UnionPay'),
  })
  static UNION_PAY = FalconPayment.PaymentProcessingNetworkTypeEnum.UNION_PAY;

  @BankCardNumberValidation({
    minLen: 16,
    maxLen: 19,
    startsWith: [[300, 305]],
  })
  @Display({
    name: t('Label_DebitCard_CarteBleue'),
  })
  static CARTE_BLEUE =
    FalconPayment.PaymentProcessingNetworkTypeEnum.CARTE_BLEUE;

  @BankCardNumberValidation({
    minLen: 14,
    maxLen: 19,
    startsWith: ['3090', '36', [38, 39]],
  })
  @Display({
    name: t('Label_CardType_8'),
  })
  static DINERS_CLUB_INTL =
    FalconPayment.PaymentProcessingNetworkTypeEnum.DINERS_CLUB_INTL;
}
