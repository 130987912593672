import { useLocale } from '@b2c/hooks';
import { DateTimeHelper } from '@b2c/services/common';
import { Typography, TypographyProps } from 'react-vant';
export function TypographyDateTime({
  value,
  format,
  ...props
}: { value: Date | string; format?: string } & TypographyProps) {
  const t = useLocale();
  return (
    <Typography.Text strong {...props}>
      {DateTimeHelper.getString(value, format, t.locale)}
    </Typography.Text>
  );
}
