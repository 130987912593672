import { CoreSuggestion } from '@b2c/core';
import {
  DateRangeModel,
  DateTimeHelper,
  HotelDetailQueryPayload,
  HotelListQueryPayload,
  OrderFillinQueryParams,
  UrlHelper,
} from '@b2c/services/common';
import { RoomViewInfo } from '@b2c/services/hotel';
import { LoggerService } from '@b2c/services/logging';
import { NationalityInfoModel } from '@b2c/services/nation';
import {
  FalconHotelRate,
  FalconRate,
  RateSearchService,
} from '@b2c/services/rate';
import {
  RatePlanHelper,
  SimplifiedRatePlanPrice,
} from '@b2c/services/rateplan';
import { SuggestionOption } from '@b2c/services/suggestions';
import produce from 'immer';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AppConfigContext,
  AppContextInfoContext,
} from './app-context-info.context';
import { SecureContextInfoContext } from './secure-context-info.context';
import { useAuthenticaitonChange } from './use-authentication-change';
import { useChannelPriceInfo } from './use-channel-price-info';
import { useDateRange } from './use-date-range';
import { useDestinationInfo } from './use-destination-info';
import { useHotelDetail } from './use-hotel-detail';
import { useHotelPageViewHistory } from './use-hotel-page-view-history';
import { useNationalitySetting } from './use-nationality-settting';
import { useNavigater } from './use-navigater';
import {
  OccupancySettingModel,
  useOccupancySetting,
} from './use-occupancy-setting';
import { usePageScroll } from './use-page-scroll';
import { usePriceChangeHelper } from './use-price-change-helper';
import { useQueryParams } from './use-query-params';
import {
  isMatchFilterOptions,
  useRatePlanFilter,
} from './use-rate-plan-filter';
import { setRatePlanPriceCache } from './use-rate-plan-price';
import { useRateSearch } from './use-rate-search';

const SCROLL_THREADHOLD = 400;
let prevScrollTop = 0;

export function useHotelOrder(config: {
  onSoldout(): void;
  onLoading(): void;
  onFinally(): void;
  onError(err: any): void;
  priceChangeConfirm(nl: string[]): Promise<boolean>;
}) {
  const { navigateTo, navigateReplace } = useNavigater();
  const [dateRange, setDateRange] = useDateRange();
  const [occupancySetting, setOccupancySetting] = useOccupancySetting();
  const [nationalityInfo, setNationalityInfo] = useNationalitySetting();
  const { destinationInfo, setDestinationInfo } = useDestinationInfo();

  const [isShowCurrencyPicker, toggleCurrencyPicker] = useState(false);

  const priceChangeHelper = usePriceChangeHelper();

  const secureContext = useContext(SecureContextInfoContext);

  const [_, setChannelPriceInfo] = useChannelPriceInfo();

  const appConfig = useContext(AppConfigContext);

  const {
    appContext: {
      currencyInfo: { Currency: currencyCode },
    },
  } = useContext(AppContextInfoContext);
  const {
    bedTypeList,
    paymentTypeList,
    loading: isLoadingPrice,
    percentage: progressPercent,
    roomList,
    loadHotelRatePrice,
  } = useRateSearch();
  const { ratePlanFilter, setRatePlanFilter, resetRatePlanFilter } =
    useRatePlanFilter();

  const params = useParams<{ hotelId: string }>();
  const { queryParams } = useQueryParams<HotelDetailQueryPayload>();
  const hotelId = parseInt(params.hotelId, 10) || +queryParams.hid;

  const { hotelDetail } = useHotelDetail(hotelId);

  const { addHotelPageViewHistory } = useHotelPageViewHistory();

  useAuthenticaitonChange(() => {
    location.reload();
  });

  useEffect(() => {
    if (!hotelDetail) {
      return;
    }
    if (hotelDetail.MainHotelID) {
      navigateReplace(
        location.pathname,
        UrlHelper.mergeQueryParams({ hid: hotelDetail.MainHotelID })
      );

      return;
    }
    document.title = hotelDetail.Name;
    addHotelPageViewHistory({
      id: hotelDetail.HotelID,
      name: hotelDetail.Name,
      rating: hotelDetail.StarRating,
      img: hotelDetail.hotelImage,
    });
  }, [hotelDetail]);

  const [searchOption, setSearchOption] = useState<
    Partial<FalconHotelRate.SearchOption>
  >({
    HotelIDList: [hotelId],
    RealTimeFilter: {
      IsRealTime: true,
      RoomCount: occupancySetting.roomCount,
      OccupancySetting: {
        AdultCount: occupancySetting.roomList[0].adultCount,
        ChildCount: occupancySetting.roomList[0].childCount,
        ChildAgeList: occupancySetting.roomList[0].childAgeList,
      },
    },
    CheckInDate: DateTimeHelper.getDateString(dateRange.From),
    CheckOutDate: DateTimeHelper.getDateString(dateRange.To),
    Currency: currencyCode,
    Nationality: nationalityInfo.code,
    SupplierIDList: [],
    PageNum: 0,
    CountPerPage: 0,
    LowestRateFilter: {
      LowestRateOnly: false,
    },
    IsPriceOnly: true,
    // LanguageCode: 'en-US',
  });

  useEffect(() => {
    // 清除过滤选项
    resetRatePlanFilter();

    return () => {
      prevScrollTop = 0;
    };
  }, []);
  const firstLoad = useRef(true);
  useEffect(() => {
    if (
      queryParams.sor_st &&
      !queryParams.sor_p &&
      firstLoad.current
    ) {
      const options = produce(searchOption, (draft) => {
        draft.BatchID = secureContext.bid;
        draft.HoistingHotelIDList = [hotelId];
        // draft.HotelIDList = [hotelId];
        draft.RealTimeFilter.IsRealTime = false;
        draft.Source = queryParams.sor_id;
        draft.SourceInfo = queryParams.sor_i;
        draft.SourceSite = queryParams.sor_st;
        draft.IsPriceOnly = false;
        draft.LowestRateFilter.LowestRateOnly = true;
        draft.PageNum = 1;
        draft.CountPerPage = 10;
      });
      RateSearchService.searchHotelPrice(options, queryParams.sor_id, null, {
        source: options.Source,
        sourceInfo: options.SourceInfo,
        sor_st: options.SourceSite,
      }).then((resp) => {
        let channelPriceInfo;
        if (resp.Data?.length) {
          channelPriceInfo = resp.Data[0].ChannelPriceInfo;
          if (channelPriceInfo) {
            setChannelPriceInfo(channelPriceInfo);
          }
        }
        loadHotelRatePrice(searchOption, channelPriceInfo);
      });
    } else {
      loadHotelRatePrice(searchOption);
    }
    firstLoad.current = false;
  }, [searchOption]);

  usePageScroll(({ scrollTop }) => {
    if (SCROLL_THREADHOLD > scrollTop) {
      prevScrollTop = scrollTop;
      return;
    }

    if (scrollTop > prevScrollTop && isShowCurrencyPicker) {
      toggleCurrencyPicker(false);
    }
    if (scrollTop < prevScrollTop && !isShowCurrencyPicker) {
      toggleCurrencyPicker(true);
    }
    prevScrollTop = scrollTop;
  });

  const [filteredRoomList, setFilteredRoomList] = useState<RoomViewInfo[]>([]);

  useEffect(() => {
    if (!hotelDetail) {
      return;
    }
    const newRoomList = roomList
      .map((room) => {
        const priceList = room.priceList
          .filter((price) => isMatchFilterOptions(price, ratePlanFilter))
          .slice(0, 9);
        const result: RoomViewInfo = {
          ...room,
          priceList,
        } as any;
        const roomContent = hotelDetail?.roomContentMap[room.didaRoomTypeId];
        if (roomContent) {
          result.imgSrc = roomContent.Images[0]?.Url;
          result.didaRoomTypeName =
            roomContent.DidaRoomTypeName || roomContent.DidaRoomTypeName_EN;
          result.content = roomContent;
        }
        return result;
      })
      .filter((room) => !!room.priceList.length);
    roomList.forEach((room, index) => {
      room.isShowRateList = index === 0;
    });
    setFilteredRoomList(newRoomList);
  }, [roomList, ratePlanFilter, hotelDetail]);

  function setRateSoldout(didaRoomTypeId: number, ratePlanMask: string) {
    const result = produce(filteredRoomList, (draft) => {
      const targetRoom = draft.find(
        (room) => room.didaRoomTypeId === didaRoomTypeId
      );
      const targetRatePlan = targetRoom.priceList.find(
        (price) => price.ratePlanMask === ratePlanMask
      );
      targetRatePlan.priceUnavailable = true;
      // warning & todo：分开调用toggleRatePlanChecking会覆盖setRateSoldout
      targetRatePlan.checking = false;
    });
    setFilteredRoomList(result);
  }

  function toggleRatePlanChecking(
    ratePlanPrice: SimplifiedRatePlanPrice,
    checking = false
  ) {
    const result = produce(filteredRoomList, (draft) => {
      const targetRoom = draft.find(
        (room) => room.didaRoomTypeId === ratePlanPrice.didaRoomTypeId
      );
      targetRoom.priceList.find(
        (price) => price.ratePlanMask === ratePlanPrice.ratePlanMask
      ).checking = checking;
    });
    setFilteredRoomList(result);
  }

  function toggleRoomRate(room: RoomViewInfo) {
    setFilteredRoomList(
      produce(filteredRoomList, (draft) => {
        draft.forEach((item) => {
          if (item.didaRoomTypeId === room.didaRoomTypeId) {
            item.isShowRateList = !item.isShowRateList;
          }
        });
      })
    );
  }

  function onOrder(ratePlanPrice: SimplifiedRatePlanPrice) {
    const ratePlan = ratePlanPrice;
    const options: Partial<FalconRate.RatePlanRateSearchOption> = {
      Nationality: nationalityInfo.code,
      HotelID: ratePlanPrice.hotelInfo?.HotelID,
      RoomOccupancyList: occupancySetting.roomList.map((room, index) => ({
        RoomNum: index + 1,
        Adults: [{ IsAdult: true }],
        AdultsCount: room.adultCount,
        ChildrenCount: room.childCount,
        Children: room.childAgeList.map((age) => ({
          Age: age,
          IsAdult: false,
        })),
      })),

      CheckIn: DateTimeHelper.getDateString(dateRange.From),
      CheckOut: DateTimeHelper.getDateString(dateRange.To),
      RoomCount: occupancySetting.roomCount,
      // WeChatOpenID: string;
      // IsReturnNetRate: boolean;

      Currency: currencyCode,
      WithHotelImage: false,
      RatePlanMask: ratePlan.ratePlanMask,
      ResponseID: ratePlan.responseId,

      /**在预订页执行的RatePlanRate查询 */
      ForOrder: false,
      /**
       * 酒店级别查询的SessionID
       * 当执行SearchForOrder进行新预定的时候，需要提供（方便日志关联）
       */
      HotelRateSessionID: ratePlan.sessionId,
      // LanguageCode: 'en-US',
      ChannelPriceInfo: ratePlan.channelPriceInfo,
    };

    if (queryParams.sor_id) {
      const extConnParams = UrlHelper.buildExtConnectionParams(queryParams);
      options.ExternalConnectInfo = {
        AppID: queryParams.sor_id,
        ExtConnectionParams: extConnParams,
      };
    }
    config.onLoading();
    toggleRatePlanChecking(ratePlanPrice, true);
    let isSoldout;
    RateSearchService.searchRatePlanPrice(
      options,
      true,
      null,
      queryParams.sor_id
    )
      .then(
        async (resp) => {
          if (!resp.Success) {
            setRateSoldout(ratePlan.didaRoomTypeId, ratePlan.ratePlanMask);
            isSoldout = true;
            config.onSoldout();
            return;
          }

          const newRatePlanPrice = RatePlanHelper.simplifyRatePlanPrice(
            resp.Data[0],
            resp.ResponseID,
            resp.SessionID
          );

          const changeResult = RatePlanHelper.checkPriceChange(
            ratePlanPrice,
            newRatePlanPrice
          );

          if (changeResult.hasChange) {
            const noticeList = priceChangeHelper.getNoticeList(changeResult);
            const confirmed = await config.priceChangeConfirm(noticeList);
            LoggerService.warn('PriceChange', changeResult);
            if (!confirmed) {
              return;
            }
            LoggerService.warn('PriceChangeConfirm', changeResult);
          }

          setRatePlanPriceCache(newRatePlanPrice);

          const payload: OrderFillinQueryParams = {
            rpm: newRatePlanPrice.ratePlanMask,
            resid: newRatePlanPrice.responseId,
            rprsid: newRatePlanPrice.sessionId,
            hrsid: ratePlan.sessionId,
            hid: hotelId,

            ci: DateTimeHelper.getDateString(dateRange.From),
            co: DateTimeHelper.getDateString(dateRange.To),
            rl: occupancySetting.roomList.map((room) => ({
              ac: room.adultCount,
              cc: room.childCount,
              cal: room.childAgeList,
            })),
            rc: occupancySetting.roomCount,
            na: nationalityInfo.code,
            et: Date.now(),
            cpi: newRatePlanPrice.channelPriceInfo,
            _rpt: newRatePlanPrice.priceSummary.RatePaymentType,
            sor_id: queryParams.sor_id,
            hotel_id: queryParams.hotel_id,
            fclid: queryParams.fclid,
            sor_clid: queryParams.sor_clid,
            cur: queryParams.cur,
          };
          navigateTo(
            UrlHelper.addPrefix('/order/fillin', appConfig.pathBase),
            payload
          );
        },
        (err) => {
          config.onError(err);
        }
      )
      .finally(() => {
        if (!isSoldout) {
          toggleRatePlanChecking(ratePlanPrice, false);
        }
        config.onFinally();
      });
  }

  function onSearchPayloadChange(payload: {
    dateRange: DateRangeModel;
    occupancySetting: OccupancySettingModel;
    destinationInfo: SuggestionOption;
    nationalityInfo: NationalityInfoModel;
  }) {
    setDestinationInfo(payload.destinationInfo);
    setDateRange(payload.dateRange);
    setOccupancySetting(payload.occupancySetting);
    setNationalityInfo(payload.nationalityInfo.code);

    const options: Partial<FalconHotelRate.SearchOption> = produce(
      searchOption,
      (draft) => {
        draft.CheckInDate = DateTimeHelper.getDateString(
          payload.dateRange.From
        );
        draft.CheckOutDate = DateTimeHelper.getDateString(payload.dateRange.To);

        draft.HoistingHotelIDList = null;
        draft.DestinationID = payload.destinationInfo.id;
        draft.Nationality = payload.nationalityInfo.code;
        draft.Currency = currencyCode;

        draft.RealTimeFilter.IsRealTime = true;
        draft.RealTimeFilter.RoomCount = payload.occupancySetting.roomCount;
        draft.RealTimeFilter.OccupancySetting.AdultCount =
          payload.occupancySetting.roomList[0].adultCount;
        draft.RealTimeFilter.OccupancySetting.ChildCount =
          payload.occupancySetting.roomList[0].childCount;
        draft.RealTimeFilter.OccupancySetting.ChildAgeList =
          payload.occupancySetting.roomList[0].childAgeList;

        if (
          payload.destinationInfo.category ===
          CoreSuggestion.SuggestionItemCategory.Hotel
        ) {
          draft.HotelIDList = [destinationInfo.id as any];
          draft.DestinationID = payload.destinationInfo.destinationID;
        }
      }
    );
    setSearchOption(options);
  }

  function searchOtherDestination(payload: {
    dateRange: DateRangeModel;
    occupancySetting: OccupancySettingModel;
    destinationInfo: SuggestionOption;
    nationalityInfo: NationalityInfoModel;
  }) {
    const queryPayload: Partial<HotelListQueryPayload> = {
      rid: payload.destinationInfo.id,
      ci: DateTimeHelper.getDateString(payload.dateRange.From),
      co: DateTimeHelper.getDateString(payload.dateRange.To),

      // ...priceFilter,

      rc: payload.occupancySetting.roomCount,
      rl: payload.occupancySetting.roomList.map((room) => ({
        ac: room.adultCount,
        cc: room.childCount,
        cal: room.childAgeList,
      })),
      na: payload.nationalityInfo.code,
      cur: queryParams.cur,
    };
    if (
      payload.destinationInfo.category ===
      CoreSuggestion.SuggestionItemCategory.Hotel
    ) {
      queryPayload.hid = payload.destinationInfo.id as any;
      queryPayload.rid = payload.destinationInfo.destinationID;
    }

    navigateTo(
      UrlHelper.addPrefix('/hotel/list', appConfig.pathBase),
      queryPayload
    );
  }

  return {
    hotelDetail,
    ratePlanFilter,
    setRatePlanFilter,
    searchOption,
    setSearchOption,
    queryParams,
    hotelId,
    bedTypeList,
    paymentTypeList,
    isLoadingPrice,
    progressPercent,
    loadHotelRatePrice,
    setRateSoldout,
    toggleRoomRate,
    filteredRoomList,
    dateRange,
    occupancySetting,
    nationalityInfo,
    destinationInfo,
    onDateRangeChange(dateRange: DateRangeModel) {
      setSearchOption(
        produce(searchOption, (draft) => {
          draft.CheckInDate = DateTimeHelper.getDateString(dateRange.From);
          draft.CheckOutDate = DateTimeHelper.getDateString(dateRange.To);
        })
      );
      setDateRange(dateRange);
    },
    onOccupancyChange(setting: OccupancySettingModel) {
      setSearchOption(
        produce(searchOption, (draft) => {
          draft.RealTimeFilter.RoomCount = setting.roomCount;
          draft.RealTimeFilter.OccupancySetting.AdultCount =
            setting.roomList[0].adultCount;
          draft.RealTimeFilter.OccupancySetting.ChildCount =
            setting.roomList[0].childCount;
          draft.RealTimeFilter.OccupancySetting.ChildAgeList =
            setting.roomList[0].childAgeList;
        })
      );
      setOccupancySetting(setting);
    },
    onNationalityChange(payload: NationalityInfoModel) {
      setSearchOption(
        produce(searchOption, (draft) => {
          draft.Nationality = payload.code;
        })
      );

      setNationalityInfo(payload.code);
    },
    onDestinationInfoChange(payload: SuggestionOption) {
      setSearchOption(
        produce(searchOption, (draft) => {
          draft.DestinationID = payload.destinationID;
        })
      );

      setDestinationInfo(payload);
    },
    onOrder,
    onSearchPayloadChange,
    searchOthers() {
      const params = UrlHelper.parseQueryParams(window.location.search);
      delete params.hid;
      navigateTo(
        UrlHelper.addPrefix('/hotel/list', appConfig.pathBase),
        params
      );
    },
    searchOtherDestination,
  };
}
