import { CoreHotel } from '@b2c/core';
import { FalconCommon } from '@b2c/services/common';
import { getDisplayInfo } from '@b2c/services/decorator';
import { BedTypeInfoModel } from '@b2c/services/rateplan';
import { useMemo } from 'react';
import { useLocale } from './use-locale';

export function useBedtypeDesc(
  bedType: FalconCommon.BedTypeModel,
  isAssured: boolean
) {
  const t = useLocale();

  return useMemo(() => {
    if (isAssured) {
      const displayInfo = getDisplayInfo(BedTypeInfoModel, bedType.ID);
      const bedTypeName = displayInfo?.name
        ? t(displayInfo.name)
        : bedType.Name;
      if ([CoreHotel.BedTypes.DoubleOrTwin].includes(bedType.ID)) {
        return t('Text_BedType_ArrangeOnArrival', { bedtype: bedTypeName });
      } else {
        return bedTypeName;
      }
    }
    return t('Text_BedTypeUnassured_Full');
  }, [bedType, isAssured, t]);
}
