import { useCancellationPolicyInfo, useLocale } from '@b2c/hooks';
import { SimplifiedRatePlanPrice } from '@b2c/services/rateplan';
import { Typography, TypographyType } from 'react-vant';
import { TypographyTextProps } from 'react-vant/es/typography/PropsType';

export function TypographyCancellation({
  ratePlanPrice,
  children,
  contentType,
  ...typoProps
}: Omit<TypographyTextProps, 'type'> & {
  ratePlanPrice: Pick<
    SimplifiedRatePlanPrice,
    'cancellationList' | 'timeZoneInfo' | 'priceSummary'
  >;
  contentType?: 'name' | 'desc';
}) {
  const t = useLocale();
  const { currentCancellationRuleInfo } =
    useCancellationPolicyInfo(ratePlanPrice);

  return (
    <Typography.Text
      type={currentCancellationRuleInfo.displayInfo.theme as TypographyType}
      {...typoProps}
    >
      {contentType === 'desc'
        ? currentCancellationRuleInfo.desc
        : t(currentCancellationRuleInfo.displayInfo.name)}
    </Typography.Text>
  );
}
