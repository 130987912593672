import { AuthenticationService } from '@b2c/services/auth';
import { AnalyticsHelper } from '@b2c/services/common';
import { FalconMembership } from '@b2c/services/membership';
import { useContext, useEffect, useState } from 'react';
import { AppContextInfoContext } from './app-context-info.context';
import { useBookingTokenInSession } from './use-booking-token-in-session';
import { useLocale } from './use-locale';
import { useNavigater } from './use-navigater';
import { useQueryParams } from './use-query-params';

export function useAccountLogin(onError: (message: string) => void) {
  const t = useLocale();

  const [submiting, setSubmiting] = useState(false);
  const {
    queryParams: { returnUrl },
  } = useQueryParams<{ returnUrl: string }>();
  const { appContext, loadAppContext } = useContext(AppContextInfoContext);
  const { navigateTo, navigateReplace } = useNavigater();

  const [bookingToken] = useBookingTokenInSession();

  function handlerReturnUrl() {
    let url = '/';
    if (returnUrl) {
      url = decodeURIComponent(returnUrl);
    }
    navigateReplace(url);
  }

  useEffect(() => {
    if (appContext?.userIdentityInfo?.isAuthenticated) {
      handlerReturnUrl();
    }
  }, [appContext]);

  function login($event: FalconMembership.UserSignInForm) {
    const payload: FalconMembership.UserSignInForm = {
      ...$event,
      // UserName: idenitfier,
      // Password: password,
      RememberMe: true,
    };

    if (bookingToken) {
      payload.BookingToken = bookingToken;
    }

    setSubmiting(true);
    AuthenticationService.login(payload)
      .then((resp) => {
        if (resp === FalconMembership.SignInStatus.Success) {
          loadAppContext();
          handlerReturnUrl();
          AnalyticsHelper.login();
        } else {
          let msg = t('Error_LoginFailed');
          switch (resp) {
            case FalconMembership.SignInStatus.LockedOut:
              msg = t('Error_LoginAccountLocked');
              break;
            case FalconMembership.SignInStatus.Failure:
              msg = t('Error_LoginAccountOrPasswordNotCorrect');
              break;
          }

          onError(msg);
        }
      })
      .catch(function (err) {
        onError(err.message || t('Error_LoginFailed'));
      })
      .finally(() => {
        setSubmiting(false);
      });
  }

  function navigateToRegister() {
    const { origin, pathname, search } = window.location;
    navigateTo(`${origin}${pathname.replace('login', 'register')}${search}`);
  }

  return {
    login,
    submiting,
    navigateToRegister,
  };
}
