import { FalconCommon } from '../common';

export class GeoCoordinate implements FalconCommon.GeoCoordinate {
  private _latitude: number;
  private _longitude: number;

  get Latitude(): number {
    return this._latitude;
  }
  set Latitude(value: number) {
    this.setLatitude(value);
  }

  get Longitude(): number {
    return this._longitude;
  }
  set Longitude(value: number) {
    this.setLongitude(value);
  }

  get lat(): number {
    return this.Latitude;
  }
  set lat(value: number) {
    this.setLatitude(value);
  }

  get lng(): number {
    return this.Longitude;
  }
  set lng(value: number) {
    this.setLongitude(value);
  }

  // Altitude: number;
  // HorizontalAccuracy: number;
  // VerticalAccuracy: number;
  // Speed: number;
  // Course: number;
  // IsUnknown: boolean;

  get gaodeCoordinate(): [number, number] {
    return [this._longitude, this._latitude];
  }

  get serviceCoordinate(): FalconCommon.GeoCoordinate {
    return {
      Latitude: this.Latitude,
      Longitude: this.Longitude,
    };
  }

  constructor(lat: number = null, lng: number = null) {
    this.setLatitude(lat);
    this.setLongitude(lng);
  }

  setLatitude(value: number) {
    this._latitude = this[1] = value;
  }

  setLongitude(value: number) {
    this._longitude = this[0] = value;
  }
}
