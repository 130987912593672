import { useLocale, usePaymentTypeInfo } from '@b2c/hooks';
import { SimplifiedRatePlanPrice } from '@b2c/services/rateplan';
import { Arrow } from '@react-vant/icons';
import { FunctionComponent } from 'react';
import { Cell, NoticeBar, Space, Typography } from 'react-vant';
import { CancellationPolicy } from '../cancellation-policy';
import { ModalService } from '../modal.service';
import { RatePlanPrice } from '../rate-plan-price/rate-plan-price';
import { TypographyBedtype } from '../typography-bedtype';
import { TypographyConfirmTime } from '../typography-confirm-time';
import { TypographyMealtype } from '../typography-mealtype';

export const RatePlanView: FunctionComponent<{
  ratePlanPrice: SimplifiedRatePlanPrice;
}> = ({ ratePlanPrice }) => {
  const t = useLocale();

  const ratePlan = ratePlanPrice;

  const paymentTypeNotice = usePaymentTypeInfo(ratePlanPrice?.paymentType);

  return (
    <>
      <Cell>
        <Space gap={10} block>
          <Typography.Text type="secondary">
            {t('Label_MealType')}:
          </Typography.Text>
          <TypographyMealtype value={ratePlan.mealInfo} />
        </Space>
        <Space gap={10} block>
          <Typography.Text type="secondary">
            <span>{t('Label_Bedtype')}:</span>
          </Typography.Text>
          <span className="text-highlight">
            <TypographyBedtype
              value={ratePlan.bedType}
              isAssured={ratePlan.isBedTypeAsured}
            ></TypographyBedtype>
          </span>
        </Space>
        <Space gap={10} block>
          <Typography.Text type="secondary">
            <span>{t('Label_CofirmTime')}:</span>
          </Typography.Text>
          <TypographyConfirmTime
            delay={ratePlan.confirmInMinutes}
          ></TypographyConfirmTime>
        </Space>
      </Cell>
      <Cell>
        <CancellationPolicy
          priceSummary={ratePlanPrice.priceSummary}
          cancellationList={ratePlanPrice.cancellationList}
          timeZoneInfo={ratePlanPrice.timeZoneInfo}
          showDetail
        ></CancellationPolicy>
      </Cell>
      <Cell>
        <RatePlanPrice ratePlanPrice={ratePlanPrice}></RatePlanPrice>
      </Cell>
      {!!ratePlan.valueAddDesc && (
        <Cell>
          <Typography.Text strong>
            {t('Label_ValueAddPriceBox')}
          </Typography.Text>
          <div>{ratePlan.valueAddDesc}</div>
        </Cell>
      )}

      {paymentTypeNotice && (
        <NoticeBar
          color="var(--mu-primary-color)"
          background="#ecf9ff"
          rightIcon={<Arrow />}
          scrollable={false}
          wrapable={true}
          text={paymentTypeNotice.title}
          onClick={() =>
            ModalService.alert(
              paymentTypeNotice.content,
              paymentTypeNotice.title
            )
          }
        ></NoticeBar>
      )}
    </>
  );
};
