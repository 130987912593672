import { OccupancySettingModel, useLocale } from '@b2c/hooks';
import { Info, Minus, Plus } from '@react-vant/icons';
import produce from 'immer';
import { FunctionComponent, useReducer, useState } from 'react';
import {
  Button,
  Cell,
  Field,
  Flex,
  NoticeBar,
  Picker,
  Popup,
  Space,
} from 'react-vant';
import { PopupDialog } from '../popup-dialog/popup-dialog';
import './occupancy-setting.less';

const AgeList = new Array(18)
  .fill('')
  .map((_, index) => (index ? index + '' : '<1'));

const MaxRoomCount = 5;
const MaxAdultCount = 10;
const MaxChildrenCount = 3;

export const OccupancySetting: FunctionComponent<{
  visible: boolean;
  onlyFirstRoom?: boolean;
  setting: OccupancySettingModel;
  onConfirm(setting: OccupancySettingModel): void;
  onClose(): void;
}> = (props) => {
  const [occupancySetting, dispatch] = useReducer(
    (state, action: { type: string; payload?: any }) => {
      const result = produce<OccupancySettingModel>(state, (draft) => {
        try {
          switch (action.type) {
            // case 'changeNationality':
            //   draft.nationality = action.payload;
            //   break;
            case 'addRoomCount':
              draft.roomCount++;
              draft.roomList.push({
                adultCount: 2,
                childCount: 0,
                childAgeList: [],
              });
              break;
            case 'reduceRoomCount':
              draft.roomCount--;
              while (draft.roomCount < draft.roomList.length) {
                draft.roomList.pop();
              }
              break;
            case 'addAdultCount':
              draft.roomList[action.payload].adultCount++;
              break;
            case 'reduceAdultCount':
              draft.roomList[action.payload].adultCount--;
              break;
            case 'reduceChildCount':
              draft.roomList[action.payload].childCount--;
              draft.roomList[action.payload].childAgeList.pop();
              break;
            case 'addChildCount':
              draft.roomList[action.payload].childCount++;
              draft.roomList[action.payload].childAgeList.push(0);
              break;
            case 'changeChildAge':
              draft.roomList[action.payload.roomIndex].childAgeList[
                action.payload.childIndex
              ] = action.payload.age;
          }
        } catch (e) {
          console.error(e);
        }
      });
      return result;
    },
    props.setting
  );

  const [changingAgeIndex, setChangingAgeIndex] = useState<{
    roomIndex: number;
    childIndex: number;
  }>(null);
  const t = useLocale();

  return (
    <PopupDialog
      title={t('Label_OccupancyInfo')}
      visible={props.visible}
      style={{
        backgroundColor: 'var(--mu-background-color)',
        height: props.onlyFirstRoom ? 'auto' : '60%',
      }}
      position="bottom"
      closeable={true}
      closeOnCancel={true}
      onClose={props.onClose}
      onConfirm={() => {
        if (props.onlyFirstRoom) {
          props.onConfirm({
            ...occupancySetting,
            roomList: occupancySetting.roomList.map(() => ({
              ...occupancySetting.roomList[0],
              childAgeList: occupancySetting.roomList[0].childAgeList && [
                ...occupancySetting.roomList[0].childAgeList,
              ],
            })),
          });
          return;
        }
        props.onConfirm(occupancySetting);
      }}
    >
      <Space direction="vertical" block className="occupancy-setting">
        {!props.onlyFirstRoom && (
          <NoticeBar
            wrapable
            leftIcon={<Info />}
            text={t('Warning_PriceChange_GuestCount')}
          />
        )}
        <Cell.Group>
          <Cell center title={t('Label_RoomCount')}>
            <Flex justify="end">
              <Button
                size="small"
                disabled={occupancySetting.roomCount < 2}
                className="change-btn"
                onClick={() => dispatch({ type: 'reduceRoomCount' })}
              >
                <Minus />
              </Button>
              <div className="count-content">{occupancySetting.roomCount}</div>
              <Button
                size="small"
                disabled={occupancySetting.roomCount >= MaxRoomCount}
                className="change-btn"
                onClick={() => dispatch({ type: 'addRoomCount' })}
              >
                <Plus />
              </Button>
            </Flex>
          </Cell>
        </Cell.Group>
        {occupancySetting.roomList
          .slice(0, props.onlyFirstRoom ? 1 : Infinity)
          .map((room, roomIndex) => (
            <Cell.Group
              key={roomIndex}
              title={
                props.onlyFirstRoom
                  ? t('Label_PerRoom')
                  : t('Label_RoomWithIndex', { index: roomIndex + 1 })
              }
            >
              <Cell
                center
                title={t('Label_AdultCount')}
                className="adult-count"
              >
                <Flex justify="end">
                  <Button
                    size="small"
                    disabled={room.adultCount < 2}
                    className="change-btn"
                    onClick={() =>
                      dispatch({ type: 'reduceAdultCount', payload: roomIndex })
                    }
                  >
                    <Minus />
                  </Button>
                  <div className="count-content">{room.adultCount}</div>
                  <Button
                    size="small"
                    disabled={room.adultCount >= MaxAdultCount}
                    className="change-btn"
                    onClick={() =>
                      dispatch({ type: 'addAdultCount', payload: roomIndex })
                    }
                  >
                    <Plus />
                  </Button>
                </Flex>
              </Cell>

              <Cell
                center
                title={t('Label_ChildrenCount')}
                className="child-count"
              >
                <Flex justify="end">
                  <Button
                    size="small"
                    disabled={room.childCount < 1}
                    className="change-btn"
                    onClick={() =>
                      dispatch({ type: 'reduceChildCount', payload: roomIndex })
                    }
                  >
                    <Minus />
                  </Button>
                  <div className="count-content">{room.childCount}</div>
                  <Button
                    size="small"
                    disabled={room.childCount >= MaxChildrenCount}
                    className="change-btn"
                    onClick={() =>
                      dispatch({ type: 'addChildCount', payload: roomIndex })
                    }
                  >
                    <Plus />
                  </Button>
                </Flex>
              </Cell>
              <div className="child-age-list">
                {room.childAgeList.map((age, childIndex) => (
                  <Field
                    label={t('Label_Occupancy_ChildWithIndex', {
                      index: childIndex + 1,
                    })}
                    key={'key' + childIndex}
                    onClick={() =>
                      setChangingAgeIndex({ roomIndex, childIndex })
                    }
                    align="right"
                    value={age ? age + '' : '<1'}
                  ></Field>
                ))}
              </div>
            </Cell.Group>
          ))}
      </Space>
      <Popup
        visible={changingAgeIndex !== null}
        position="bottom"
        onClose={() => setChangingAgeIndex(null)}
      >
        <Picker
          columns={AgeList}
          onConfirm={(index) => {
            dispatch({
              type: 'changeChildAge',
              payload: {
                age: index,
                ...changingAgeIndex,
              },
            });
            setChangingAgeIndex(null);
          }}
          onCancel={() => setChangingAgeIndex(null)}
        ></Picker>
      </Popup>
    </PopupDialog>
  );
};
