import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import './markdown.less';

export function Markdown(props: { content: string }) {
  return (
    <div className="mu-markdown">
      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
        {props.content}
      </ReactMarkdown>
    </div>
  );
}

export default Markdown;
