import { CoreCommon } from '@b2c/core';
import { useLocaleMessages } from '@b2c/hooks';
import { PropsWithChildren, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'react-vant';
import enUS from 'react-vant/es/locale/lang/en-US';
import jaJP from 'react-vant/es/locale/lang/ja-JP';
import zhCN from 'react-vant/es/locale/lang/zh-CN';

export function LocaleProvider(
  props: PropsWithChildren<{
    locale: CoreCommon.LanguageCode;
    defaultMessage: Record<string, string>;
  }>
) {
  const { localeMessages } = useLocaleMessages(
    props.locale,
    props.defaultMessage
  );

  const uiLibaryMessages = useMemo(() => {
    return {
      'en-US': enUS,
      'zh-CN': zhCN,
      ja: jaJP,
    }[localeMessages.code];
  }, [localeMessages.code]);

  return (
    <ConfigProvider locale={uiLibaryMessages}>
      <IntlProvider
        locale={localeMessages.code}
        messages={localeMessages.messages}
      >
        {props.children}
      </IntlProvider>
    </ConfigProvider>
  );
}
