export class BrowserHelper {
  public static get isInWeChatWebView(): boolean {
    return navigator.userAgent.indexOf('MicroMessenger') > -1;
  }

  /**
   * 检测是否移动端网页，如果非web环境则返回undefined
   */
  public static get isInWebView(): boolean {
    if (typeof navigator === 'undefined') {
      return undefined;
    }
    return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
      navigator.userAgent,
    );
  }

  static get isAppleProduct() {
    if (typeof navigator === 'undefined') {
      return undefined;
    }
    return navigator.userAgent.includes('Mac OS X');
  }
}
