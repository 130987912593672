import { CoreHotelBooking } from '@b2c/core';
import { BookingErrorType, FalconBooking } from '@b2c/services/booking';
import { getDisplayInfo } from '@b2c/services/decorator';
import { FalconPayment } from '@b2c/services/pay-channel';
import { useMemo } from 'react';
import { useLocale } from './use-locale';

export function useBookingFailReason(
  bookingDetail: FalconBooking.BookingDetailModel,
  paid: boolean
) {
  const t = useLocale();
  return useMemo(() => {
    if (bookingDetail?.BookingStatus !== CoreHotelBooking.StatusType.Failed) {
      return '';
    }

    if (paid) {
      return t('Notice_OrderFail_Refund');
    }

    let bookingFailNotice = '';
    switch (bookingDetail.PriceSummary.RatePaymentType) {
      case FalconPayment.RatePaymentTypeEnum.PayAtPropertyWithCreditCard:
        bookingFailNotice = t('Error_BookingFail_PaywithCreditCard');
        break;
      case FalconPayment.RatePaymentTypeEnum.PayAtPropertyWithoutCreditCard:
        bookingFailNotice = t('Error_BookingFail_Common');
        break;
      default: {
        const translateId = getDisplayInfo(
          BookingErrorType,
          bookingDetail.ErrorType
        )?.name;
        bookingFailNotice =
          (translateId && t(translateId)) || t('Error_BookingFail_Common');
      }
    }
    return bookingFailNotice;
  }, [bookingDetail, paid, t]);
}
