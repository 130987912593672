import { UrlHelper } from '../common';
import { StandardResponseModel } from './data.models';

type RequestOptions<T = any> = RequestInit & {
  postResultProcess?(result: T, response: Response);
};

export class HttpService {
  static serviceEndPoint: string = null;

  static getCookie(value) {
    try {
      const reg = new RegExp(`(^| )${value}=([^;]*)(;|$)`);
      const arr = document.cookie.match(reg);
      if (arr) {
        return unescape(arr[2]);
      }
    } catch (error) {
      return null;
    }
    return null;
  }

  private static popularRequestUrl(path: string, params: object) {
    if (this.serviceEndPoint === null) {
      throw 'Service Host Required';
    }

    let url = path;
    if (!path.startsWith('/assets/')) {
      url = this.serviceEndPoint + url;
    }
    url = UrlHelper.addParams(url, params);

    return url;
  }

  private static popularRequestOptions(options: RequestOptions) {
    if (!options) {
      options = {};
    }
    const fallbackHeaders: HeadersInit = {
      'Content-Type': 'application/json;charset=utf-8',
      'X-XSRF-TOKEN': this.getCookie('XSRF-TOKEN'),
      'fcn-path': window.location.pathname,
      'fcn-epoch-c-offset': (Date.now() / 1000).toFixed(),
    };
    options.headers = Object.assign(fallbackHeaders, options.headers);
    options.credentials = 'include';
    options.mode = 'cors';

    return options;
  }

  private static popularErrorResponseData<T = any>(
    resp: StandardResponseModel<T>
  ) {
    if (resp.Success) {
      return Promise.resolve(resp.Data);
    } else {
      return Promise.reject({
        code: resp.MessageCode,
        message: resp.Message,
      });
    }
  }

  private static popularHttpError(error) {
    return Promise.reject({
      code: 0,
      message: null,
    });
  }

  private static propularHttpResponse<T>(
    resp: Response,
    options: RequestOptions
  ) {
    const isHttpSuccess = [200, 204].includes(resp.status);
    if (isHttpSuccess) {
      if (resp.headers.get('content-type')?.includes('application/json')) {
        return resp
          .json()
          .then((resp) => this.popularErrorResponseData<T>(resp))
          .then((result) => {
            if (options.postResultProcess) {
              const postResult = options.postResultProcess(result, resp);
              if (typeof postResult !== 'undefined') {
                return postResult;
              }
            }
            return result;
          });
      }
      return resp.text();
    } else {
      switch (resp.status) {
        case 401:
          window.location.href =
            '/account/login?returnUrl=' + encodeURI(window.location.href);
      }
      const error = {
        code: resp.status,
        message: resp.statusText,
      };
      return Promise.reject(error);
    }
  }

  static get<Result, Param extends object = any>(
    apiEndpoint: string,
    params?: Param,
    options?: RequestOptions<Result>
  ): Promise<Result> {
    const url = this.popularRequestUrl(apiEndpoint, params);
    options = this.popularRequestOptions({
      ...options,
      method: 'GET',
    });
    const resultPromise = fetch(url, options).then(
      (resp) => this.propularHttpResponse<Result>(resp, options),
      (err) => this.popularHttpError(err)
    );
    return resultPromise;
  }

  static post<Result, Payload = any>(
    apiEndpoint: string,
    data?: Payload,
    options?: RequestOptions<Result>
  ): Promise<Result> {
    const url = this.popularRequestUrl(apiEndpoint, null);
    options = this.popularRequestOptions({
      ...options,
      method: 'POST',
      body: JSON.stringify(data),
    });
    const resultPromise = fetch(url, options).then(
      (resp) => this.propularHttpResponse<Result>(resp, options),
      (err) => this.popularHttpError(err)
    );

    return resultPromise;
  }

  static sendBeacon(apiEndpoint: string, payload: any) {
    try {
      if ('sendBeacon' in navigator) {
        const blob = new Blob([JSON.stringify(payload)], {
          type: 'application/json',
        });
        const url = this.popularRequestUrl(apiEndpoint, null);
        navigator.sendBeacon(url, blob);
      } else {
        HttpService.post(apiEndpoint, payload).catch(() => {});
      }
    } catch {}
  }
}
