import { FalconPayment } from '../pay-channel';
import { PaymentProcessingNetworkType } from '../pay-channel/falcon-payment.models';

function getMetadataKey() {
  return Symbol.for('BankCardNumberValidation');
}

let BankCardNumberValidationList: BankCardNumberValidationConfig[] = null;

export interface BankCardNumberValidationConfig {
  value?: FalconPayment.PaymentProcessingNetworkTypeEnum;
  maxLen: number;
  minLen: number;
  startsWith?: Array<string | [number, number]>;
}

export function BankCardNumberValidation(
  option: BankCardNumberValidationConfig
) {
  return Reflect.metadata(getMetadataKey(), Object.freeze(option));
}

export function getBankCardNumberValidation(
  issuerType: FalconPayment.PaymentProcessingNetworkTypeEnum
) {
  const propertyKey = Object.keys(PaymentProcessingNetworkType).find(
    (key) => PaymentProcessingNetworkType[key] === issuerType
  );
  const result = Reflect.getMetadata(
    getMetadataKey(),
    PaymentProcessingNetworkType,
    propertyKey || ''
  );
  return Object.assign({}, result);
}

export function getBankCardNumberValidationList() {
  if (!BankCardNumberValidationList) {
    BankCardNumberValidationList = Object.keys(
      PaymentProcessingNetworkType
    ).map((key) => {
      const config = getBankCardNumberValidation(
        PaymentProcessingNetworkType[key]
      ) as BankCardNumberValidationConfig;
      config.value = PaymentProcessingNetworkType[key];
      return config;
    });
  }
  return BankCardNumberValidationList;
}
