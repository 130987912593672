import { AppConfigContext, useLocale } from '@b2c/hooks';
import { StarFillIcon } from '@b2c/icons';
import { FalconContext } from '@b2c/services/auth';
import { PriceFilterQueryParams } from '@b2c/services/common';
import { getPropertyDisplayInfoList } from '@b2c/services/decorator';
import { FalconPayment, RatePaymentType } from '@b2c/services/pay-channel';
import { QuestionO } from '@react-vant/icons';
import classNames from 'classnames';
import {
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Divider,
  Flex,
  Input,
  Slider,
  Space,
  Typography,
} from 'react-vant';
import { ModalService } from '../modal.service';
import { Panel } from '../panel/panel';
import styles from './hotel-price-filter.module.less';

const MAX_PRICE = 1000;

export const HotelPriceFilter: FunctionComponent<{
  value: PriceFilterQueryParams;
  currencyInfo: FalconContext.AppCurrencyInfo;
  onConfirmChange: (data: PriceFilterQueryParams) => void;
}> = (props) => {
  const t = useLocale();
  const [ratingStarList, setRatingStarList] = useState<number[]>(
    props.value?.rsl ? [...props.value.rsl] : []
  );

  const appConfig = useContext(AppConfigContext);

  const [ratePaymentTypeList, setRatePaymentTypeList] = useState<number[]>(
    props.value?.rpt ? [...props.value.rpt] : []
  );

  const [priceRange, setPriceRange] = useState<[number, number]>([
    props.value?.min,
    props.value?.max,
  ]);

  const { Ratio: ratio } = props.currencyInfo;

  const exchangePrice = useCallback(
    (raw: number[], reverse = false): [number, number] => {
      const minVal =
        raw[0] && Math.round(reverse ? raw[0] * ratio : raw[0] / ratio);
      const maxVal =
        raw[1] && Math.round(reverse ? raw[1] * ratio : raw[1] / ratio);
      return [minVal, maxVal];
    },
    [ratio]
  );

  const paymentTypeOptions = useMemo(() => {
    const result = getPropertyDisplayInfoList(RatePaymentType);
    if (appConfig.ratePaymentTypeList?.length > 0) {
      return result.filter((item) =>
        appConfig.ratePaymentTypeList.includes(item.value)
      );
    }
    return result;
  }, [appConfig?.ratePaymentTypeList]);

  const StarInfoList = useMemo(() => {
    return [
      // {
      //   text: 'Unlimited',
      //   value: null,
      // },
      {
        text: <>&le;2</>, // t('Label_Star2'),
        value: 2,
      },
      {
        // text: t('Label_Star3'),
        value: 3,
      },
      {
        // text: t('Label_Star4'),
        value: 4,
      },
      {
        // text: t('Label_Star5'),
        value: 5,
      },
    ];
  }, []);

  // let { max: maxPrice, min: minPrice, rsl: ratingStarList } = priceFilter;

  const maxInputPrice = priceRange[1]
    ? Math.round(priceRange[1] / ratio)
    : null;
  const minInputPrice = Math.round(priceRange[0] / ratio);
  const [inputValue, setInputValue] = useState([minInputPrice, maxInputPrice]);

  // let maxValue = Math.round(MAX_PRICE / ratio);
  const minVal = Math.min(priceRange[0] / MAX_PRICE, 1);
  const maxVal = priceRange[1] ? Math.min(priceRange[1] / MAX_PRICE, 1) : 1;
  const [sliderValue, setSliderValue] = useState([minVal, maxVal]);

  const showRatingStarNotice = () => {
    ModalService.alert(
      t('Text_HotelStarExplaination'),
      t('Title_HotelStarExplaination')
    );
  };

  function toggleStar(index) {
    let starList = ratingStarList && [...ratingStarList];
    if (index === null) {
      starList = null;
    } else if (!starList) {
      starList = [index];
    } else if (starList.includes(index)) {
      starList = starList.filter((item) => item !== index);
    } else {
      starList = [...starList, index];
    }

    if (starList && (starList.length >= 4 || starList.length === 0)) {
      starList = null;
    }

    setRatingStarList(starList);
  }

  function toogleRatePaymentType(
    paymentType: FalconPayment.RatePaymentTypeEnum
  ) {
    const rptSet = new Set(ratePaymentTypeList);
    if (rptSet.has(paymentType)) {
      rptSet.delete(paymentType);
    } else {
      rptSet.add(paymentType);
    }

    if (rptSet.size >= paymentTypeOptions.length) {
      rptSet.clear();
    }

    setRatePaymentTypeList(Array.from(rptSet));
  }

  function clearChange() {
    setPriceRange([0, null]);
    setRatingStarList([]);
    setInputValue([0, null]);
    setSliderValue([0, 1]);
    setRatePaymentTypeList([]);
  }

  function confirmChange() {
    props.onConfirmChange({
      min: priceRange[0],
      max: priceRange[1],
      rsl: ratingStarList,
      rpt: ratePaymentTypeList,
    });
  }

  const mapPercent2Price = useCallback((value: number) => {
    const price = Math.round(value * MAX_PRICE);
    if (price >= MAX_PRICE) {
      return null;
    } else {
    }
    return Math.round(price);
  }, []);

  // const mapPrice2Percent = useCallback((price: number) => {
  //   if (price >= MAX_PRICE) {
  //     return 1;
  //   } else {
  //     return price / MAX_PRICE;
  //   }
  // }, []);

  function onSliderChange(value: [number, number]) {
    const [startValue, endValue] = value;
    const minPrice = mapPercent2Price(startValue);
    const maxPrice = mapPercent2Price(endValue);
    setPriceRange([minPrice, maxPrice]);
    setInputValue(exchangePrice([minPrice, maxPrice]));
    setSliderValue(value);
    // props.onSliderChange && props.onSliderChange(minPrice, maxPrice);
  }

  const onInputChange = (value: string, isMax = false) => {
    const validValue = value?.trim().replaceAll(/[^\d.]/g, '');
    const resolveValue = validValue ? parseFloat(validValue) : null;
    const newInputValue = [inputValue[0], inputValue[1]];
    if (isMax) {
      newInputValue[1] = resolveValue;
    } else {
      newInputValue[0] = resolveValue;
    }
    setInputValue(newInputValue);
  };

  const onInputBlur = () => {
    inputValue.sort((a, b) => {
      if (!a || !b) {
        return 0;
      }
      return a - b > 0 ? 1 : -1;
    });

    const exchangeInputValue = exchangePrice([...inputValue], true);
    setPriceRange(exchangeInputValue);

    setInputValue([...inputValue]);

    updateSliderValue(inputValue);
  };

  function updateSliderValue(range: number[]) {
    const maxValue = Math.round(MAX_PRICE / ratio);
    const minVal = Math.min(range[0] / maxValue, 1);
    const maxVal = range[1] ? Math.min(range[1] / maxValue, 1) : 1;
    setSliderValue([minVal, maxVal]);
  }

  return (
    <>
      <Panel>
        <Space direction="vertical" divider={<Divider />} gap={1} block>
          <div>
            <div className={styles.itemLabel}>
              {t('Title_YourBudget')} ({t('Text_PerNight')}):
            </div>
            <div className={styles.rangInputGroup}>
              <Flex>
                <Input
                  placeholder={t('Text_NoLimit')}
                  className={styles.input}
                  value={inputValue[0] ? inputValue[0] + '' : '0'}
                  onChange={(event) => onInputChange(event)}
                  onBlur={() => onInputBlur()}
                />
                <Input
                  placeholder={t('Text_NoLimit')}
                  className={styles.input}
                  value={inputValue[1] ? inputValue[1] + '' : ''}
                  onChange={(event) => onInputChange(event, true)}
                  onBlur={() => onInputBlur()}
                />
              </Flex>
            </div>
            <div className={classNames(styles.sliderWrapper)}>
              <Slider
                range
                step={0.1}
                max={1}
                value={sliderValue as [number, number]}
                onChange={onSliderChange}
              ></Slider>
            </div>
          </div>
          {paymentTypeOptions.length > 1 && (
            <div>
              <div className={styles.itemLabel}>
                {t('Label_PaymentOptions')}
              </div>
              <Space gap={10} wrap>
                {paymentTypeOptions.map((option) => (
                  <div key={option.value} className="mb-2">
                    <div
                      className={classNames(styles.starOption, {
                        [styles.active]: ratePaymentTypeList?.includes(
                          option.value
                        ),
                      })}
                      onClick={() => toogleRatePaymentType(option.value)}
                    >
                      <span>{t(option.name)}</span>
                    </div>
                  </div>
                ))}
              </Space>
            </div>
          )}
          <div className="">
            <div className={styles.itemLabel}>
              <Space onClick={showRatingStarNotice}>
                {t('Label_HotelStar')}
                <QuestionO />
              </Space>
            </div>
            <Flex gutter={10} wrap="wrap">
              {StarInfoList.map((starInfo) => (
                <Flex.Item span="6" key={starInfo.value} className="mb-2">
                  <div
                    className={classNames(styles.starOption, {
                      [styles.active]: ratingStarList?.includes(starInfo.value),
                    })}
                    onClick={() => toggleStar(starInfo.value)}
                  >
                    <Space gap={4} align="center">
                      <span>{starInfo.text || starInfo.value}</span>
                      <StarFillIcon />
                    </Space>
                  </div>
                </Flex.Item>
              ))}
            </Flex>
          </div>
        </Space>
      </Panel>
      <Divider></Divider>
      <Panel>
        <Flex justify="between" align="center" className={styles.footer}>
          <Flex.Item>
            <Typography.Text
              onClick={clearChange}
              size="lg"
              color="#032950"
              strong
            >
              {t('Button_ClearSelection')}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item>
            <Button
              type="primary"
              size="large"
              round
              className={styles.confirmBtn}
              onClick={confirmChange}
            >
              {t('Button_Confirm')}
            </Button>
          </Flex.Item>
        </Flex>
      </Panel>
    </>
  );
};
