import { CoreCommon } from '@b2c/core';
import { useLocale, useSpecialRequestText } from '@b2c/hooks';
import {
  DecoratorDisplayInfo,
  getPropertyDisplayInfoList,
} from '@b2c/services/decorator';
import { SpecialRequestTypeModel } from '@b2c/services/special-request';
import { Arrow } from '@react-vant/icons';
import {
  FunctionComponent,
  HTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Checkbox, Field, Flex, Typography } from 'react-vant';
import { CheckboxGroupInstance } from 'react-vant/lib/checkbox';
import { Panel } from './panel';
import { PopupDialog } from './popup-dialog';
export const SpecialRequestEditor: FunctionComponent<
  {
    supplierRequestIdList: CoreCommon.SpecialRequestType[];
    checkedList: CoreCommon.DidaSpecialRequest[];
    onConfirm(idList: CoreCommon.DidaSpecialRequest[]): void;
  } & HTMLAttributes<HTMLDivElement>
> = (props) => {
  const t = useLocale();
  const SpecialRequestList = useMemo(
    () => getPropertyDisplayInfoList(SpecialRequestTypeModel),
    []
  );

  const [visible, setVisible] = useState(false);

  const checkboxGroupRef = useRef<CheckboxGroupInstance>();

  const specialRequestText = useSpecialRequestText(props.checkedList);

  const [freeText, setFreeText] = useState('');

  const [requestTypeList, hasFreeTextType] = useMemo(() => {
    let availables: DecoratorDisplayInfo[] = [];
    let _hasFreeTextType = true;
    if (props.supplierRequestIdList) {
      const supplierRequestIdSet = new Set(props.supplierRequestIdList);
      _hasFreeTextType = supplierRequestIdSet.has(
        CoreCommon.SpecialRequestType.Other
      );
      if (!_hasFreeTextType) {
        availables = SpecialRequestList.filter((item) => {
          return supplierRequestIdSet.has(item.value);
        });
      }
    }

    // availables = availables.map((item) => item.clone());
    return [availables, _hasFreeTextType];
  }, [props.supplierRequestIdList]);

  const [selectedList, setSelectedList] = useState<number[]>([]);

  useEffect(() => {
    if (props.checkedList) {
      setSelectedList(props.checkedList.map((item) => item.SpecialRequestType));
    }
  }, [props.checkedList]);

  function confirmChecked() {
    let list: CoreCommon.DidaSpecialRequest[] = null;
    if (hasFreeTextType) {
      const value = freeText?.trim();
      if (value) {
        list = [
          {
            SpecialRequestType: CoreCommon.SpecialRequestType.Other,
            Value: value,
          },
        ];
      }
    } else {
      list = selectedList.map((item) => ({
        SpecialRequestType: item,
      }));
    }
    setVisible(false);
    props.onConfirm(list);
  }

  function clearChecked() {
    checkboxGroupRef.current?.toggleAll(false);
    setFreeText('');
  }

  return (
    <>
      <div onClick={() => setVisible(true)}>
        <Flex justify="between">
          <Flex.Item>
            <div className={props.className}>
              {specialRequestText || t('Text_None')}
            </div>
          </Flex.Item>
          <Flex.Item>
            <Arrow />
          </Flex.Item>
        </Flex>
      </div>
      <PopupDialog
        title={t('Label_SpecialRequest')}
        position="bottom"
        style={{ maxHeight: '80%' }}
        visible={visible}
        closeable
        onClose={() => setVisible(false)}
        onCancel={clearChecked}
        cancelButtonText={t('Button_ClearSelection')}
        onConfirm={confirmChecked}
      >
        {/* <NoticeBar
          leftIcon={<Warning />}
          wrapable
          text={t('Notice_SpecialRequestNotEnsure')}
        ></NoticeBar> */}
        {hasFreeTextType && (
          <Field
            type="textarea"
            maxLength={200}
            autoSize
            rows={3}
            showWordLimit
            value={freeText}
            onChange={(value) => setFreeText(value)}
            placeholder={t('Text_Placeholder_OtherRequestRequired')}
          ></Field>
        )}
        {!hasFreeTextType && (
          <Panel>
            <Checkbox.Group
              ref={checkboxGroupRef}
              direction="horizontal"
              defaultValue={selectedList}
              onChange={setSelectedList}
            >
              {requestTypeList.map((requestType) => (
                <Checkbox
                  key={requestType.value}
                  name={requestType.value}
                  shape="square"
                  className="mb-2 mr-4"
                  iconSize="1em"
                >
                  <span>{t(requestType.name)}</span>
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Panel>
        )}
        <Panel type="grey">
          <Typography.Text type="secondary">
            {t('Notice_SpecialRequestNotEnsure')}
            <Flex>
              {/* <Flex.Item style={{ lineHeight: '20px', marginRight: '4px' }}>
                <Warning />
              </Flex.Item> */}
              <Flex.Item></Flex.Item>
            </Flex>
          </Typography.Text>
        </Panel>
      </PopupDialog>
    </>
  );
};

export default SpecialRequestEditor;
