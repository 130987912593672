export class StringHelper {
  static isEmpty(input: string): boolean {
    return typeof input === 'undefined' || input === null || input.length === 0;
  }

  static getConsecutiveSubString(
    input: string,
    length: number,
    direction: null | 'forward' | 'reverse' = null
  ): string | null {
    if (length <= 0 || StringHelper.isEmpty(input) || input.length < length) {
      return null;
    }
    const forwardDetectEnabled = direction == null || direction === 'forward';
    const reverseDetectEnabled = direction == null || direction === 'reverse';

    const startPos = input.charCodeAt(0);
    let nextPos = startPos + 1;
    let prePos = startPos - 1;

    let forward_len = 1;
    let reverse_len = 1;

    for (let i = 1, j = input.length; i < input.length; i++, j--) {
      const curPos = input.charCodeAt(i);
      if (forwardDetectEnabled && curPos === nextPos) {
        forward_len++;
        if (forward_len === length) {
          return input.substr(i - length + 1, length);
        }

        // reset reverse
        reverse_len = 1;
        prePos = curPos;
      } else if (reverseDetectEnabled && curPos === prePos) {
        reverse_len++;
        if (reverse_len === length) {
          return input.substr(i - length + 1, length);
        }

        // reset forward
        forward_len = 1;
        nextPos = curPos;
      } else {
        if (j < length) {
          // interuption
          break;
        }
        // reset both
        nextPos = prePos = curPos;
        forward_len = reverse_len = 1;
      }
      nextPos++;
      prePos--;
    }
    return null;
  }

  static getRepeatingSubString(
    input: string,
    repeatingLen: number
  ): string | null {
    if (
      repeatingLen <= 0 ||
      StringHelper.isEmpty(input) ||
      input.length < repeatingLen
    ) {
      return null;
    }
    let tar: string = input[0];
    let len = 0;
    for (let i = 0, j = input.length; i < input.length; i++, j--) {
      const c: string = input[i];
      if (c === tar) {
        len++;
        if (len === repeatingLen) {
          return input.substr(i - repeatingLen + 1, repeatingLen);
        }
      } else {
        if (j < repeatingLen) {
          // interuption
          break;
        }

        // reset
        tar = c;
        len = 1;
      }
    }
    return null;
  }

  static chunk(str: string, size = 4) {
    str = str.trim();
    const newVal = [];
    do {
      newVal.push(str.slice(0, size));
      str = str.slice(size);
    } while (str.length > size);
    if (str.length) {
      newVal.push(str);
    }

    return newVal;
  }

  static toCamelCase(source: string, upper = true) {
    let result = source.replace(/[-_](\w)/g, (_, seed) => seed.toUpperCase());
    if (upper) {
      result = result.slice(0, 1).toUpperCase() + result.slice(1);
    }
    return result;
  }

  static split(
    source: string,
    key: string,
    ignoreCase = false
  ): { str: string; isKey: boolean }[] {
    return this.splitInternal(source, key, ignoreCase);
  }

  private static splitInternal(
    source: string,
    key: string,
    ignoreCase = true,
    arr = []
  ): { str: string; isKey: boolean }[] {
    if (!source || !key) {
      return [];
    }

    if (!arr) {
      arr = [];
    }
    // 不要用正则，特殊符号容易有BUG
    // const regExp = ignoreCase ? new RegExp(key, 'i') : new RegExp(key);
    // const execArray = regExp.exec(source);
    let index = -1;
    if (ignoreCase) {
      index = source.toLocaleUpperCase().indexOf(key.toLocaleUpperCase());
    } else {
      index = source.indexOf(key);
    }
    if (index > -1) {
      if (index > 0) {
        arr.push({ str: source.substr(0, index), isKey: false });
      }
      arr.push({ str: source.substr(index, key.length), isKey: true });
      arr.concat(
        this.splitInternal(
          source.substr(index + key.length),
          key,
          ignoreCase,
          arr
        )
      );
    } else {
      arr.push({ str: source, isKey: false });
    }
    return arr;
  }
}
