import { NationalityInfoModel } from '@b2c/services/nation';
import { useCallback, useContext, useMemo } from 'react';
import { CountryInfoContext } from './country-info.context';

export function useNationalityInfoList() {
  const nationInfoList = useContext(CountryInfoContext).countryInfoList;

  const nationalityInfoList = useMemo(() => {
    return nationInfoList.map((item) => ({
      code: item.code,
      name: item.nationality || item.name,
    }));
  }, [nationInfoList]);

  const nationalityIndex = useMemo(() => {
    const indexMap = nationalityInfoList.reduce<{
      [key: string]: NationalityInfoModel[];
    }>((result, item) => {
      const indexCode = item.code[0];
      if (!result[indexCode]) {
        result[indexCode] = [];
      }

      result[indexCode].push(item);

      return result;
    }, {});

    const indexList = Object.keys(indexMap)
      .sort()
      .map((indexChar) => ({
        indexChar,
        items: indexMap[indexChar],
      }));
    return indexList;
  }, [nationalityInfoList]);

  const searchNationality = useCallback(
    (keyword: string) => {
      return nationalityInfoList.filter((item) => {
        return [item.code, item.name]
          .join(' ')
          .toLocaleLowerCase()
          .includes(keyword.trim().toLocaleLowerCase());
      });
    },
    [nationalityInfoList]
  );

  return { nationalityInfoList, nationalityIndex, searchNationality };
}
