import { CoreCommon } from '@b2c/core';
import { CountryInfoModel } from '@b2c/services/nation';
import { useEffect, useState } from 'react';

const NationInfoLangs: CoreCommon.LanguageCode[] = ['zh-CN', 'en-US', 'ja-JP'];

export function useCountryInfoList(localeId: CoreCommon.LanguageCode) {
  const [countryInfoList, setCountryInfoList] = useState<CountryInfoModel[]>(
    []
  );

  useEffect(() => {
    const resourceFile = NationInfoLangs.includes(localeId)
      ? localeId
      : 'en-US';
    import(`./locale/countries.${resourceFile}.json`).then((module) => {
      const countryList = module.default.map((item) => {
        const countryInfo: Required<CountryInfoModel> = {
          name: item.n,
          code: item.c,
          nationality: item.na,
          pinyin: item.py,
          callingCode: item.cc,
          indexChar: item.ic,
        };
        Object.keys(countryInfo).forEach((key) => {
          if (typeof countryInfo[key] === 'undefined') {
            delete countryInfo[key];
          }
        });
        return countryInfo as CountryInfoModel;
      });
      if (process.env.NODE_ENV === 'development') {
        const noNameList = countryList.filter((item) => !item.name);
        if (noNameList.length > 0) {
          console.error('Countries without name found', noNameList);
        }
      }
      setCountryInfoList(
        countryList.sort((a, b) => {
          if ((a.pinyin || a.name) > (b.pinyin || b.name)) {
            return 1;
          } else {
            return -1;
          }
        })
      );
    });
  }, [localeId]);

  return countryInfoList;
}
