import { useLocale } from '@b2c/hooks';
import { AddressIcon } from '@b2c/icons';
import { SimplifiedHotelDetail } from '@b2c/services/hotel';
import { Arrow } from '@react-vant/icons';
import { FunctionComponent } from 'react';
import { Flex, Skeleton, Space, Typography } from 'react-vant';
import { RatingStar } from '../rating-star';

export const HotelDetailOverview: FunctionComponent<{
  hotelInfo: SimplifiedHotelDetail;
}> = ({ hotelInfo }) => {
  const t = useLocale();
  if (!hotelInfo) {
    return <Skeleton></Skeleton>;
  }
  return (
    <Space direction="vertical" block>
      <Typography.Text size="xl" strong>
        <span>{hotelInfo.Name}</span>
      </Typography.Text>
      <Space className="rating" align="center" justify="between" block>
        <div>
          {hotelInfo.StarRating > 0 && (
            <RatingStar value={hotelInfo.StarRating}></RatingStar>
          )}
        </div>
        <Typography.Link
          className="detail-anchor"
          onClick={() => {
            document
              .querySelector('#hotel-extra-info')
              ?.scrollIntoView({ behavior: 'smooth' });
          }}
        >
          <Flex align="center">
            <span>{t('Text_HotelExtraInfo')}</span>
            &nbsp;
            <Arrow />
          </Flex>
        </Typography.Link>
      </Space>
      <div>
        <Typography.Text size="sm">
          <Flex align="center">
            <AddressIcon style={{ flexShrink: 0, marginRight: '8px' }} />
            <Flex.Item>{hotelInfo.Address}</Flex.Item>
          </Flex>
        </Typography.Text>
      </div>
    </Space>
  );
};
