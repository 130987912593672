export enum PriceType {
    /**
     * 单价
     */
    UnitPrice = 0,
    /**
     * 总价
     */
    TotalPrice = 1,
}
