export function usePaymentStateCodeOptions(countryCode: string) {
  switch (countryCode) {
    case 'US':
      return [
        { code: 'AL', name: '美国亚拉巴马' },
        { code: 'AK', name: '美国阿拉斯加' },
        { code: 'AZ', name: '美国亚利桑那' },
        { code: 'AR', name: '美国阿肯色' },
        { code: 'AA', name: '美洲武装部队' },
        { code: 'AE', name: '欧洲武装部队' },
        { code: 'AP', name: '太平洋武装部队' },
        { code: 'CA', name: '美国加利福尼亚' },
        { code: 'CO', name: '美国科罗拉多' },
        { code: 'CT', name: '美国康涅狄格' },
        { code: 'DE', name: '美国特拉华' },
        { code: 'DC', name: '美国哥伦比亚特区' },
        { code: 'FL', name: '美国佛罗里达' },
        { code: 'GA', name: '美国佐治亚' },
        { code: 'HI', name: '美国夏威夷' },
        { code: 'ID', name: '美国爱达荷' },
        { code: 'IL', name: '美国伊利诺伊' },
        { code: 'IN', name: '美国印第安纳' },
        { code: 'IA', name: '美国艾奥瓦' },
        { code: 'KS', name: '美国堪萨斯' },
        { code: 'KY', name: '美国肯塔基' },
        { code: 'LA', name: '美国路易斯安纳' },
        { code: 'ME', name: '美国缅因' },
        { code: 'MD', name: '美国马里兰' },
        { code: 'MA', name: '美国马萨诸塞' },
        { code: 'MI', name: '美国密歇根' },
        { code: 'MN', name: '美国明尼苏达' },
        { code: 'MS', name: '美国密西西比' },
        { code: 'MO', name: '美国密苏里' },
        { code: 'MT', name: '美国蒙大拿' },
        { code: 'NE', name: '美国内布拉斯加' },
        { code: 'NV', name: '美国内华达' },
        { code: 'NH', name: '美国新罕布什尔' },
        { code: 'NJ', name: '美国新泽西' },
        { code: 'NM', name: '美国新墨西哥' },
        { code: 'NY', name: '美国纽约' },
        { code: 'NC', name: '美国北卡罗来纳' },
        { code: 'ND', name: '美国北达科他' },
        { code: 'OH', name: '美国俄亥俄' },
        { code: 'OK', name: '美国俄克拉何马' },
        { code: 'OR', name: '美国俄勒冈' },
        { code: 'PA', name: '美国宾夕法尼亚' },
        { code: 'RI', name: '美国罗得岛' },
        { code: 'SC', name: '美国南卡罗来纳' },
        { code: 'SD', name: '美国南达科他' },
        { code: 'TN', name: '美国田纳西' },
        { code: 'TX', name: '美国得克萨斯' },
        { code: 'UT', name: '美国犹他' },
        { code: 'VT', name: '美国佛蒙特' },
        { code: 'VA', name: '美国弗吉尼亚' },
        { code: 'WA', name: '美国华盛顿' },
        { code: 'WV', name: '美国西弗吉尼亚' },
        { code: 'WI', name: '美国威斯康星' },
        { code: 'WY', name: '美国怀俄明' },
      ].map((item) => item.code);
    case 'CA':
      return [
        { code: 'AB', name: '加拿大阿尔伯达' },
        { code: 'BC', name: '加拿大不列颠哥伦比亚' },
        { code: 'MB', name: '加拿大马尼托巴' },
        { code: 'NB', name: '加拿大新布伦兹维克' },
        { code: 'NL', name: '加拿大纽芬兰和拉布拉多' },
        { code: 'NT', name: '加拿大西北地区' },
        { code: 'NS', name: '加拿大新斯科舍' },
        { code: 'NU', name: '加拿大努纳武特' },
        { code: 'ON', name: '加拿大安大略' },
        { code: 'PE', name: '加拿大爱德华王子岛' },
        { code: 'QC', name: '加拿大魁北克' },
        { code: 'SK', name: '加拿大萨斯喀彻温' },
        { code: 'YT', name: '加拿大育空' },
      ].map((item) => item.code);
    case 'AU':
      return [
        {
          code: 'ACT',
          name: 'Australian Capital Territory，澳大利亚首都领地。',
        },

        { code: 'NT', name: 'Northern Territory，北领地。' },

        { code: 'SA', name: 'South Australia，南澳大利亚州，简称南澳州。' },

        { code: 'TAS', name: 'Tasmania，塔斯马尼亚州。' },

        { code: 'VIC', name: 'Victoria，维多利亚州。' },

        { code: 'WA', name: 'Western Australia，西澳大利亚州，简称西澳州。' },
      ].map((item) => item.code);
    default:
      return [];
  }
}
