export namespace FalconPayment {
  export enum RatePaymentTypeEnum {
    // 正常报价
    PrePay = 1,
    // PAH报价并且需要信用卡信息 这种填了信用卡的，下单后酒店会先扣掉预授权，实际扣款发生在客人到店
    PayAtPropertyWithCreditCard,
    // PAH报价并且不需要信用卡信息 这种不填信用卡的，只能是客人到店后才向酒店付款
    PayAtPropertyWithoutCreditCard,
  }

  export enum BankCardTypeEnum {
    NotSpecified = 0,
    DebitCard = 1,
    CreditCard = 2,
  }

  /// <summary>
  /// 支付处理网络
  /// </summary>
  export enum PaymentProcessingNetworkTypeEnum {
    NotSpecified = 0,
    VISA = 1,
    MASTER_CARD = 2,
    DISCOVER = 3,
    AMERICAN_EXPRESS = 4,
    JCB = 5,
    UNION_PAY = 6,
    CARTE_BLEUE = 7,
    DINERS_CLUB_INTL = 8,
  }

  export interface BankCardInputConditions {
    /**
     * 限制的银行卡类型（储蓄卡、信用卡等）
     * @default [CreditCard]
     * */
    RestrictedCardTypes: BankCardTypeEnum[];
    /**限制的PPN（PaymentProcessingNetwork），如Visa, JCB, UnionPay等
         * @default [PaymentProcessingNetworkTypeEnum.VISA,
                        PaymentProcessingNetworkTypeEnum.MASTER_CARD,
                        PaymentProcessingNetworkTypeEnum.AMERICAN_EXPRESS,
                        PaymentProcessingNetworkTypeEnum.JCB,
                        PaymentProcessingNetworkTypeEnum.CARTE_BLEUE,]
        */
    RestrictedPPNs: PaymentProcessingNetworkTypeEnum[];
    /**需要State/Province编号的区域（CountryCode） */
    StateOrProvinceCodeRequiredRegions: string[];
    /**
     * PPN对应的交易（处理）地区
     * @default null
     */
    PPNProcLocMap: Record<PaymentProcessingNetworkTypeEnum, string[]>;
    /**限制的发卡银行（目前暂时不支持）
     * @default ['Bank']
     */
    RestrictedIssuingBanks: string[];

    /**
     * @default false
     */
    IsNeedIssuingBank: boolean;
    /**
     * @default true
     */
    IsNeedPaymentCountry: boolean;
    /**
     * @default false
     */
    IsNeedPaymentAddress: boolean;
    /**
     * @default false
     */
    IsNeedPaymentZipCode: boolean;
  }

  export interface BankCardPaymentInfo {
    /**银行卡类型 */
    BankCardType: BankCardTypeEnum;
    /**所属 */
    PaymentProcessingNetwork: PaymentProcessingNetworkTypeEnum;
    /**发卡银行 */
    IssuingBank: string;
    /**卡号 */
    CardNumber: string;
    /**过期日期 */
    ExpireDate: string;
    /**CVV */
    CVV: string;
    /**持卡人名 */
    CardHolderName: string;
    /**持卡人邮箱 */
    CardHolderEmail: string;
    /**支付所在地国家 */
    PaymentCountryCode: string;
    /**
     * 支付所在地州/省编号
     */
    PaymentStateOrProvinceCode: string;
    /**支付所在地城市 */
    PaymentCity: string;
    /**支付所在地地址 */
    PaymentAddress: string;
    /**支付所在地邮编 */
    PaymentZipCode: string;
  }
}
