import { FalconBooking } from '@b2c/services/booking';
import { useContext, useMemo } from 'react';
import { AppContextInfoContext } from './app-context-info.context';

export function useReferencePriceInfo(
  priceSummary: Pick<FalconBooking.PriceSummary, 'Currency' | 'Price'>
) {
  const currencyInfo = useContext(AppContextInfoContext).appContext
    ?.currencyInfo;
  return useMemo(() => {
    if (
      !currencyInfo?.Reference ||
      !priceSummary ||
      priceSummary.Currency !== currencyInfo.Currency
    )
      return null;
    return {
      Currency: currencyInfo.Reference.Currency,
      Price:
        (priceSummary.Price * currencyInfo.Ratio) /
        currencyInfo.Reference.Ratio,
    };
  }, [currencyInfo, priceSummary]);
}
