import { CoreSuggestion } from '@b2c/core';
import { KeywordHelper } from '../common';
import { LanguageCode } from '../i18n';
import { SuggestionOption } from './suggestion-typeahead.model';

export class SuggestionSearchHelper {
  static readonly REGION_SEARCH_CATEGORIES = [
    CoreSuggestion.SuggestionItemCategory.City,
    CoreSuggestion.SuggestionItemCategory.Neighborhood,
    CoreSuggestion.SuggestionItemCategory.MultiCity,
    CoreSuggestion.SuggestionItemCategory.MultiRegion,
    CoreSuggestion.SuggestionItemCategory.HighLevelRegion,
    CoreSuggestion.SuggestionItemCategory.Province,
  ];

  static readonly SUB_REGION_SEARCH_CATEGORIES = [
    CoreSuggestion.SuggestionItemCategory.City,
    CoreSuggestion.SuggestionItemCategory.Neighborhood,
  ];

  static readonly HOTEL_SEARCH_CATEGORIES = [
    CoreSuggestion.SuggestionItemCategory.Hotel,
  ];

  static readonly REGION_SEARCH_BY_ID_CATEGORIES = [
    CoreSuggestion.SuggestionItemCategory.POI,
    CoreSuggestion.SuggestionItemCategory.POIS,
    CoreSuggestion.SuggestionItemCategory.City,
    CoreSuggestion.SuggestionItemCategory.Neighborhood,
    CoreSuggestion.SuggestionItemCategory.MultiCity,
    CoreSuggestion.SuggestionItemCategory.MultiRegion,
    CoreSuggestion.SuggestionItemCategory.HighLevelRegion,
    CoreSuggestion.SuggestionItemCategory.Province,
  ];

  static readonly AIRPORT_SEARCH_CATEGORIES = [
    CoreSuggestion.SuggestionItemCategory.Airport,
  ];

  static readonly POI_SEARCH_CATEGORIES = [
    CoreSuggestion.SuggestionItemCategory.POIS,
    CoreSuggestion.SuggestionItemCategory.POI,
  ];

  static readonly NEED_SEARCH_IN_PARENT_CATEGORIES = [
    CoreSuggestion.SuggestionItemCategory.City,
    CoreSuggestion.SuggestionItemCategory.Neighborhood,
    CoreSuggestion.SuggestionItemCategory.POIS,
    CoreSuggestion.SuggestionItemCategory.POI,
  ];

  static readonly SPECIAL_PARENT_ID_FOR_SEARCH = new Set([
    '77', // Hong Kong SAR
    '104', // Macau SAR
  ]);

  // TODO: 后台没有返回CoreSuggestion.SuggestionItemCategory，这里直接判断字符串
  static readonly IGNORE_DRILL_UP_CATEGORIES = [
    // CoreSuggestion.SuggestionItemCategory.Country,
    'Country',
    // CoreSuggestion.SuggestionItemCategory.Continent
    'Continent',
    // CoreSuggestion.SuggestionItemCategory.Province,
    'Province (State)',
  ];

  static shouldSearchInParentRegion(option: SuggestionOption) {
    // 部分City没有所属的MultiCity
    return (
      (SuggestionSearchHelper.NEED_SEARCH_IN_PARENT_CATEGORIES.includes(
        option.category
      ) ||
        SuggestionSearchHelper.SPECIAL_PARENT_ID_FOR_SEARCH.has(
          option.parentId
        )) &&
      option.parentId
    );
  }

  static createSuggestionQueryPayload(
    keyword: string,
    categories: CoreSuggestion.SuggestionItemCategory[],
    size: number,
    ids?: (string | number)[],
    parentID?: string
    // transferLocations?: string[],
  ): CoreSuggestion.SuggestionQueryPayload {
    const result: CoreSuggestion.SuggestionQueryPayload = {
      IDs: ids,
      Keyword: keyword,
      Size: size,
      Categories: categories,
      ParentID: parentID,
      Language: 'en-US',
      PaginationOption: { PageNum: -1 },
      WithMetadata: true,
      WithHierarchy: true,
      WithItemObject: true,
    };
    // result.TransferLocations = transferLocations;
    return result;
  }

  static getName(
    item: CoreSuggestion.SuggestionItem,
    lang: LanguageCode
  ): string {
    let name = null;
    if (lang === 'en-US') {
      if (item.Category === CoreSuggestion.SuggestionItemCategory.Hotel) {
        name = item.ItemObject.ChineseName;
      } else if (
        item.Category === CoreSuggestion.SuggestionItemCategory.Airport
      ) {
        name = item.ItemObject.Name_FullCN;
      } else {
        name = item.ItemObject.RegionNameLong_CN;
      }
    }
    return name ? name : item.DisplayOption.FullName;
  }

  static getOldName(
    suggestion: CoreSuggestion.SuggestionItem,
    keyword: string,
    lang: LanguageCode
  ) {
    let name = null;
    if (
      suggestion.Category === CoreSuggestion.SuggestionItemCategory.Hotel &&
      suggestion.ItemObject.AliasList
    ) {
      if (KeywordHelper.hasMatch(this.getName(suggestion, lang), keyword)) {
        return name;
      }
      const aliasArr = suggestion.ItemObject.AliasList as {
        Alias: string;
        Language: number;
        IsChecked: boolean;
      }[];

      const alias = aliasArr.find((item) =>
        KeywordHelper.hasMatch(item.Alias, keyword)
      );
      name = alias && alias.IsChecked && alias.Alias;
    }
    return name;
  }
}
