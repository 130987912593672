import {
  AppConfigContext,
  AppContextInfoContext,
  useLocale,
  useWebsitePolicies,
} from '@b2c/hooks';
import { DateTimeHelper } from '@b2c/services/common';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Flex, Space, Typography } from 'react-vant';
import { Container } from '../container';
import styles from './footer.module.less';

export function Footer(props: {
  brandName: 'MuseTrip' | 'HotelWhales';
  WebSiteName: string;
}) {
  const t = useLocale();
  const [showAll, setShowAll] = useState(false);
  const copyRightYear = DateTimeHelper.getString(new Date(), 'yyyy');
  const { appContext } = useContext(AppContextInfoContext);
  const { customerService } = useContext(AppConfigContext);

  const websitePolicies = useWebsitePolicies();

  const phoneActions = customerService.tels.map((item) => {
    return {
      code: item.code,
      name: `(${item.code}) ${item.number}`,
      value: item.number,
      subname: item.desc && t(item.desc, item),
    };
  });

  return (
    <div className={styles.footer}>
      <Container>
        <Space
          direction="vertical"
          block
          gap={10}
          divider={<Divider></Divider>}
        >
          <Flex gutter={20} wrap="wrap">
            <Flex.Item span={12}>
              <div className={styles.docList}>
                <h4 className={classNames(styles.docItem, styles.title)}>
                  {t('Title_ContactUS')}
                </h4>

                {phoneActions.map((item) => (
                  <div key={item.code} className={styles.docItem}>
                    <a href={'tel:' + item.value}>
                      {item.name}
                      {item.subname && (
                        <>
                          <br /> {item.subname}
                        </>
                      )}
                    </a>
                  </div>
                ))}
                <div className={styles.docItem}>
                  <a href={'mailto:' + customerService.email}>
                    {customerService.email}
                  </a>
                </div>
              </div>
            </Flex.Item>
            <Flex.Item span={12}>
              <div className={styles.docList}>
                <h4 className={classNames(styles.docItem, styles.title)}>
                  {t('Title_PolicyTerms')}
                </h4>
                {websitePolicies.map((item) => (
                  <div key={item.path} className={styles.docItem}>
                    <Link to={item.path}>{item.title}</Link>
                  </div>
                ))}
              </div>
            </Flex.Item>
            <Flex.Item span={12}>
              <div className={styles.docList}>
                <h4 className={classNames(styles.docItem, styles.title)}>
                  {t('Title_HelpCenter')}
                </h4>
                <div className={styles.docItem}>
                  <Link to={'/faq'}>{t('Title_FAQ')}</Link>
                </div>
              </div>
            </Flex.Item>
          </Flex>
          <div>
            {showAll ? (
              <>
                <p>
                  {t('Text_FooterExtra_1', {
                    brandName: props.brandName,
                  })}
                </p>
                <p>{t('Text_FooterExtra_2')}</p>
                <p>
                  {t('Text_FooterExtra_3')}

                  {props.brandName !== 'MuseTrip' && (
                    <Typography.Link onClick={() => setShowAll(false)}>
                      {t('Button_Fold')}
                    </Typography.Link>
                  )}
                </p>

                {props.brandName === 'MuseTrip' && (
                  <p>
                    {t('Text_FooterExtra_4', {
                      brandName: props.brandName,
                    })}
                    <Typography.Link onClick={() => setShowAll(false)}>
                      {t('Button_Fold')}
                    </Typography.Link>
                  </p>
                )}
              </>
            ) : (
              <Typography.Text
                ellipsis={{
                  rows: 2,
                  onExpend: () => setShowAll(true),
                  expandText: t('Button_ShowAll'),
                }}
              >
                {t('Text_FooterExtra_1', {
                  brandName: props.brandName,
                })}
              </Typography.Text>
            )}
          </div>
          <p className={styles.footerCopyright}>
            Copyright © 2021–{copyRightYear} {props.WebSiteName}.
            <br /> All rights reserved.
            {DateTimeHelper.isChinaTimezone() && appContext?.icp && (
              <>
                <br />
                <a href="https://beian.miit.gov.cn/">{appContext.icp}</a>
              </>
            )}
          </p>
        </Space>
      </Container>
    </div>
  );
}
