import { Arrow } from '@react-vant/icons';
import classNames from 'classnames';
import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import { Flex, Typography } from 'react-vant';
import './panel.less';

const BasePanel: FunctionComponent<
  Omit<HTMLAttributes<HTMLDivElement>, 'title'> &
    Partial<{
      title: ReactNode;
      subTitle: ReactNode;
      type: 'transparent' | 'grey';
      showArrow: boolean;
      arrowText: string;
      inset: boolean;
      showHeaderBorder?: boolean;
      shadowless?: boolean;
      onHeaderClick: () => void;
    }>
> = (props) => {
  const {
    title,
    subTitle,
    type,
    inset,
    showArrow,
    arrowText,
    showHeaderBorder = true,
    shadowless,
    onHeaderClick,
    className,
    ...restProps
  } = props;
  const headerStyles = showHeaderBorder
    ? null
    : {
        marginBottom: 0,
      };
  return (
    <div
      {...restProps}
      className={classNames('mu-panel', props.className, {
        'mu-panel--grey': type === 'grey',
        'mu-panel--transparent': type === 'transparent',
        'mu-panel--inset': inset,
        'mu-panel--shadowless': shadowless,
      })}
    >
      {props.title && (
        <Flex
          onClick={() => props.onHeaderClick?.call(null)}
          justify="between"
          className="mu-panel__header"
          style={headerStyles}
        >
          <Flex.Item>
            <Typography.Title className="mu-panel__title">
              {props.title}
            </Typography.Title>
            {props.subTitle && (
              <Typography.Text type="secondary" className="mu-panel__desc">
                {props.subTitle}
              </Typography.Text>
            )}
          </Flex.Item>

          {props.showArrow && (
            <Flex.Item>
              <Flex align="center">
                <span>{props.arrowText}</span>
                <Arrow />
              </Flex>
            </Flex.Item>
          )}
        </Flex>
      )}
      <div className="mu-panel__body">{props.children}</div>
    </div>
  );
};

const PanelTitle: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div {...props} className={classNames(className, 'mu-panel__title')}>
      {children}
    </div>
  );
};

export const Panel = Object.assign(BasePanel, {
  Title: PanelTitle,
});
