import { useBookingViewInfo, useLocale } from '@b2c/hooks';
import { FalconBooking } from '@b2c/services/booking';
import { QuestionO } from '@react-vant/icons';
import { useState } from 'react';
import { Cell, Flex, Space, Typography } from 'react-vant';
import { ModalService } from '../modal.service';
import { PopupDialog } from '../popup-dialog';
import { TypographyPrice } from '../typography-price';

export function BookingDetailPriceSummary({
  bookingDetail,
}: {
  bookingDetail: FalconBooking.BookingDetailModel;
}) {
  const t = useLocale();
  const [visible, setVisible] = useState(false);

  const viewInfo = useBookingViewInfo(bookingDetail);

  const priceDesc = (
    <>
      <TypographyPrice
        value={bookingDetail.PriceSummary}
        type="primary"
        strong
        size="lg"
      ></TypographyPrice>

      {viewInfo.excludedFeeCurrencyNotMatch && (
        <>
          &nbsp;+&nbsp;
          <TypographyPrice
            type="primary"
            value={viewInfo.totalExcludedCharges}
            priceKey="Amount"
            strong
            size="lg"
          ></TypographyPrice>
        </>
      )}
    </>
  );

  return (
    <Cell
      center
      titleStyle={{ flex: 'auto' }}
      title={
        viewInfo.isPayLater ? (
          <Space gap={10}>
            <Typography.Text strong>{t('Label_PayAtHotel')}</Typography.Text>
            {priceDesc}
          </Space>
        ) : (
          <Space block direction="vertical">
            <Space gap={10} align="center">
              <Typography.Text strong>
                {t('Label_PrepayOnline')}
              </Typography.Text>
              <TypographyPrice
                value={bookingDetail.PriceSummary}
                type="primary"
                strong
                size="lg"
              ></TypographyPrice>
            </Space>
            {bookingDetail.PriceSummary.ExcludedFees?.length > 0 && (
              <Space gap={10} align="center">
                <Typography.Text strong>
                  {t('Label_ExcludedCharges')}
                </Typography.Text>

                <TypographyPrice
                  strong
                  size="lg"
                  value={viewInfo.totalExcludedCharges}
                  priceKey="Amount"
                ></TypographyPrice>
              </Space>
            )}
          </Space>
        )
      }
      isLink
      onClick={() => setVisible(true)}
    >
      <PopupDialog
        closeable
        title={t('Label_PriceSummary')}
        position="bottom"
        visible={visible}
        onClose={() => setVisible(false)}
        onConfirm={() => setVisible(false)}
        showCancel={false}
      >
        <Cell>
          <Space gap={8} direction="vertical" block>
            <Flex align="center" justify="between">
              <Typography.Text strong size="lg">
                {t('Label_RoomPrice')}
              </Typography.Text>
              <TypographyPrice
                value={bookingDetail.PriceSummary}
                strong
                size="lg"
              ></TypographyPrice>
            </Flex>
            {bookingDetail.PriceSummary.IncludedFees?.map((item, index) => (
              <Flex align="center" justify="between" key={index}>
                <span>{item.FeeChargeType.Name}</span>
                <TypographyPrice
                  value={item}
                  priceKey="Amount"
                ></TypographyPrice>
              </Flex>
            ))}
          </Space>
        </Cell>
        {bookingDetail.PriceSummary.ExcludedFees?.length > 0 && (
          <Cell>
            <Space gap={8} direction="vertical" block>
              <Flex justify="between" align="center">
                <Typography.Text strong size="lg">
                  <Flex
                    align="center"
                    onClick={() =>
                      ModalService.alert(t('ToolTip_ExcludedCharges'))
                    }
                  >
                    <span>{t('Label_ExcludedCharges')}</span>
                    &nbsp;
                    <QuestionO />
                  </Flex>
                </Typography.Text>

                <TypographyPrice
                  strong
                  size="lg"
                  value={viewInfo.totalExcludedCharges}
                  priceKey="Amount"
                ></TypographyPrice>
              </Flex>
              <Space gap={8} direction="vertical" block>
                {bookingDetail.PriceSummary.ExcludedFees?.map((item, index) => (
                  <Flex align="center" justify="between" key={index}>
                    <span>{item.FeeChargeType.Name}</span>
                    <TypographyPrice
                      value={item}
                      priceKey="Amount"
                    ></TypographyPrice>
                  </Flex>
                ))}
              </Space>
            </Space>
          </Cell>
        )}

        <Cell>
          {viewInfo.isPayLater ? (
            <Flex justify="between" align="center">
              <Typography.Text strong size="lg">
                {t('Label_PayAtHotel')}
              </Typography.Text>
              <div>{priceDesc}</div>
            </Flex>
          ) : (
            <Flex justify="between" align="center">
              <Typography.Text strong size="lg">
                {t('Label_PrepayOnline')}
              </Typography.Text>
              <TypographyPrice
                value={bookingDetail.PriceSummary}
                type="primary"
                strong
                size="xl"
              ></TypographyPrice>
            </Flex>
          )}
        </Cell>
      </PopupDialog>
    </Cell>
  );
}
