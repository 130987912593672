import {
  DateTimeHelper,
  OrderFillinQueryParams,
  UrlHelper,
} from '@b2c/services/common';
import { FalconOrdering } from '@b2c/services/order';
import produce from 'immer';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContextInfoContext } from './app-context-info.context';
import { useCountryInfo } from './country-info';
import { useDateRange } from './use-date-range';
import { useOccupancySetting } from './use-occupancy-setting';
import { useQueryParams } from './use-query-params';

export function getOrderOptionCacheKey(rprsid: string) {
  // return `c_i_s_k_${rprsid}_${currencyCode}`;
  return `c_i_s_k_${rprsid}`;
}
export function getOrderOptionCache(
  rprsid: string
): Partial<FalconOrdering.OrderOption> {
  const cache = sessionStorage.getItem(getOrderOptionCacheKey(rprsid));
  if (!cache) {
    return null;
  }
  const result = JSON.parse(cache) as Partial<FalconOrdering.OrderOption>;

  const falllbackPrefix: string = result.Contact._PhonePrefix as any;
  if (typeof falllbackPrefix === 'string') {
    result.Contact._PhonePrefix = {
      CallingCode: falllbackPrefix.replace('+', ''),
      CountryCode: null,
    };
  }
  return result;
}
export function setOrderOptionCache(
  rprsid: string,
  orderOption: Partial<FalconOrdering.OrderOption>
) {
  const cacheKey = getOrderOptionCacheKey(rprsid);
  sessionStorage.setItem(cacheKey, JSON.stringify(orderOption));
}

export function useOrderOption() {
  const { queryParams } = useQueryParams<OrderFillinQueryParams>();

  const { appContext } = useContext(AppContextInfoContext);

  const [dateRange] = useDateRange();
  const [occupancySetting] = useOccupancySetting();
  const countryInfo = useCountryInfo(queryParams.na);

  let defaultOption = getOrderOptionCache(queryParams.rprsid);

  if (!defaultOption) {
    defaultOption = {
      Nationality: countryInfo?.code,
      HotelID: queryParams.hid,
      RoomOccupancyList: occupancySetting.roomList.map((room, index) => ({
        RoomNum: index + 1,
        Adults: [{ IsAdult: true }],
        AdultsCount: room.adultCount,
        ChildrenCount: room.childCount,
        Children: room.childAgeList.map((age) => ({
          Age: age,
          IsAdult: false,
        })),
      })),

      CheckIn: DateTimeHelper.getDateString(dateRange.From),
      CheckOut: DateTimeHelper.getDateString(dateRange.To),
      RoomCount: occupancySetting.roomCount,
      // WeChatOpenID: string;
      // IsReturnNetRate: boolean;

      Contact: {
        _PhonePrefix: countryInfo && {
          CallingCode: countryInfo?.callingCode,
          CountryCode: countryInfo.code,
        },
        Phone: '',
        Email: '',
      },

      Currency: appContext?.currencyInfo.Currency,
      WithHotelImage: false,
      RatePlanMask: queryParams.rpm,
      ResponseID: queryParams.resid,
      ChannelPriceInfo: queryParams.cpi,

      /**在预订页执行的RatePlanRate查询 */
      ForOrder: true,
      /**
       * 酒店级别查询的SessionID
       * 当执行SearchForOrder进行新预定的时候，需要提供（方便日志关联）
       */
      HotelRateSessionID: queryParams.hrsid,
      // LanguageCode: 'en-US',
    };
    // if (process.env.NODE_ENV === 'development') {
    //   Object.assign(defaultOption.Contact, {
    //     LastName: 'Lee',
    //     FirstName: 'Adam',
    //     Phone: '15889964258',
    //     Email: 'adamlee@didatravel.com',
    //   });
    // }
    if (queryParams.sor_id) {
      const extConnParams = UrlHelper.buildExtConnectionParams(queryParams);
      defaultOption.ExternalConnectInfo = {
        AppID: queryParams.sor_id,
        ExtConnectionParams: extConnParams,
      };
    }
    if (appContext.userIdentityInfo.profile) {
      defaultOption.Contact.Email = appContext.userIdentityInfo.profile.Email;
    }
  } else {
    defaultOption.Currency = appContext.currencyInfo.Currency;
  }

  const [orderOption, _setOrderOption] =
    useState<Partial<FalconOrdering.OrderOption>>(defaultOption);

  useEffect(() => {
    if (!queryParams.rprsid) {
      _setOrderOption(null);
      return;
    }
    const cache = getOrderOptionCache(queryParams.rprsid);
    if (cache) {
      _setOrderOption(cache);
    }
  }, [queryParams.rprsid]);

  useEffect(() => {
    if (!countryInfo) {
      return;
    }

    _setOrderOption(
      produce(orderOption, (draft) => {
        draft.Nationality = countryInfo.code;
        if (!draft.Contact._PhonePrefix) {
          draft.Contact._PhonePrefix = {
            CallingCode: countryInfo.callingCode,
            CountryCode: countryInfo.code,
          };
        }
      })
    );
  }, [countryInfo]);

  const setOrderOption = useCallback(
    (payload: Partial<FalconOrdering.OrderOption>) => {
      setOrderOptionCache(queryParams.rprsid, payload);
      _setOrderOption(payload);
    },
    []
  );

  return { orderOption, setOrderOption };
}
