import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';
import styles from './table.module.less';

type TableThemes = 'success' | 'warning' | 'danger' | string;

export const Table: FunctionComponent<
  PropsWithChildren<React.HTMLAttributes<HTMLTableElement>>
> = ({ className, ...props }) => {
  return (
    <table className={classNames(styles.table, className)} {...props}>
      {props.children}
    </table>
  );
};

export const TableHeader: FunctionComponent<PropsWithChildren> = (props) => {
  return <thead className={styles.tableHeader}>{props.children}</thead>;
};

export const TableBody: FunctionComponent<PropsWithChildren> = (props) => {
  return <tbody className={styles.tableBody}>{props.children}</tbody>;
};

export const TableRow: FunctionComponent<
  PropsWithChildren<{
    theme?: TableThemes;
  }>
> = (props) => {
  return (
    <tr className={classNames(styles.tableRow, styles[props.theme])}>
      {props.children}
    </tr>
  );
};

export const TableCell: FunctionComponent<PropsWithChildren> = (props) => {
  return <td className={styles.tableCell}>{props.children}</td>;
};
