import { getPropertyDisplayInfoList } from '../decorator';
import { CurrencyEnumModel, CurrencyModel } from './models';
import { CurrencyDirectory } from './models/currency-direct.model';

export class CurrencyHelper {
  private static _currencyList: CurrencyModel[];
  private static _currencyMap: Map<string, CurrencyModel>;
  private static _currencyDirectory: CurrencyDirectory;

  static get currencyList() {
    if (!this._currencyList) {
      this._currencyList = getPropertyDisplayInfoList(CurrencyEnumModel).sort(
        (a, b) => a.value.localeCompare(b.value)
      );
      this._currencyList.forEach((item) => Object.freeze(item));
    }
    return this._currencyList;
  }

  static get currencyMap() {
    if (!this._currencyMap) {
      this._currencyMap = this.currencyList.reduce((r, i) => {
        r.set(i.value, i)
        return r;
      }, new Map());
    }
    return this._currencyMap;
  }

  static get currencyDirectory() {
    if (this._currencyDirectory) {
      return this._currencyDirectory;
    }

    const CHAR_CODE_A = 'A'.charCodeAt(0);
    const CHAR_CODE_Z = 'Z'.charCodeAt(0);

    // 初始化CurrencyDirectoryStruct，使得每一个字母都有独立的位置
    const directory: CurrencyDirectory = [];
    for (let i = CHAR_CODE_A; i <= CHAR_CODE_Z; i++) {
      directory.push({
        index: i - CHAR_CODE_A,
        indexChar: String.fromCharCode(i),
        items: [],
      });
    }

    // 往目录添加数据
    this.currencyList.forEach((item) => {
      const index = item.value.charCodeAt(0) - CHAR_CODE_A;
      directory[index].items.push(item); // push to array
    });

    // 移除不存在的项目（但是保留其的索引占位）
    directory.forEach((item, idx) => {
      if (item.items.length === 0) {
        delete directory[idx];
      }
    });
    this._currencyDirectory = directory;
    return directory;
  }
}
