import {
  OccupancySettingQueryParams,
  RoomOccupancySettingQueryParams,
} from '@b2c/services/common';
import { useCallback, useMemo } from 'react';
import { useQueryParams } from './use-query-params';

export const MaxRoomCount = 5;
export const MaxAdultCount = 10;
export const MaxChildrenCount = 3;

export class RoomOccupancySettingModel {
  adultCount = 2;
  childCount = 0;
  childAgeList: number[] = [];
}

export class OccupancySettingModel {
  roomCount = 1;
  // nationality = 'US';
  roomList: RoomOccupancySettingModel[];
}

export const useOccupancySetting = function () {
  const { queryParams, updateQueryParams } = useQueryParams<
    OccupancySettingQueryParams & RoomOccupancySettingQueryParams
  >();

  const occupancySetting: OccupancySettingModel = useMemo(() => {
    const newVal = {
      roomCount: queryParams.rc || 1,
      roomList: [],
      // nationality: queryParams.na || 'US',
    };
    if (queryParams.rl) {
      newVal.roomList = queryParams.rl.map((room) => ({
        adultCount: room.ac || 2,
        childCount: room.cc || 0,
        childAgeList: room.cal ? [...room.cal] : [],
      }));
    } else {
      let cal = [];
      if (Array.isArray(queryParams.cal)) {
        cal = queryParams.cal;
      } else if (queryParams.cal) {
        cal = [queryParams.cal];
      }
      newVal.roomList = [
        {
          adultCount: +(queryParams.ac || 2),
          childCount: +(queryParams.cc || 0),
          childAgeList: cal.map((age) => +age || 0),
        },
      ];
    }

    newVal.roomList[0].childAgeList?.splice(newVal.roomList[0].childCount);

    return newVal;
  }, [queryParams]);

  const setOccupancySetting = useCallback((setting: OccupancySettingModel) => {
    updateQueryParams<OccupancySettingQueryParams>({
      rc: setting.roomCount,
      rl: setting.roomList.map((room) => ({
        ac: room.adultCount,
        cc: room.childCount,
        cal: room.childAgeList,
      })),
      // na: setting.nationality,
    });
  }, []);

  return [occupancySetting, setOccupancySetting] as [
    OccupancySettingModel,
    (settting: OccupancySettingModel) => void
  ];
};
