import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { OccupancySettingModel, useLocale } from '@b2c/hooks';
import { Button, Col, Popover, Row, Select } from 'antd';
import produce from 'immer';
import {
  FunctionComponent,
  PropsWithChildren,
  useReducer,
  useState,
} from 'react';
import styles from './occupancy-setting.module.less';

const AgeList = new Array(18).fill('').map((_, index) => index);

const MaxRoomCount = 5;
const MaxAdultCount = 10;
const MaxChildrenCount = 3;

type OccupancyActionType =
  | 'reduceRoomCount'
  | 'addRoomCount'
  | 'reduceAdultCount'
  | 'addAdultCount'
  | 'reduceChildCount'
  | 'addChildCount'
  | 'changeChildAge';

export const OccupancySetting: FunctionComponent<
  PropsWithChildren<{
    visible?: boolean;
    onlyFirstRoom?: boolean;
    setting: OccupancySettingModel;
    dropdownOffsetLeft?: number;
    onConfirm(setting: OccupancySettingModel): void;
    onClose?(): void;
  }>
> = (props) => {
  const [visible, setVisible] = useState(false);
  const [occupancySetting, dispatch] = useReducer(
    (state, action: { type: OccupancyActionType; payload?: any }) => {
      const result = produce<OccupancySettingModel>(state, (draft) => {
        try {
          switch (action.type) {
            case 'addRoomCount':
              draft.roomCount++;
              draft.roomList.push({
                adultCount: 2,
                childCount: 0,
                childAgeList: [],
              });
              break;
            case 'reduceRoomCount':
              draft.roomCount--;
              while (draft.roomCount < draft.roomList.length) {
                draft.roomList.pop();
              }
              break;
            case 'addAdultCount':
              draft.roomList[action.payload].adultCount++;
              break;
            case 'reduceAdultCount':
              draft.roomList[action.payload].adultCount--;
              break;
            case 'reduceChildCount':
              draft.roomList[action.payload].childCount--;
              draft.roomList[action.payload].childAgeList.pop();
              break;
            case 'addChildCount':
              draft.roomList[action.payload].childCount++;
              draft.roomList[action.payload].childAgeList.push(0);
              break;
            case 'changeChildAge':
              draft.roomList[action.payload.roomIndex].childAgeList[
                action.payload.childIndex
              ] = action.payload.age;
          }
        } catch (e) {
          console.error(e);
        }
      });
      return result;
    },
    props.setting
  );
  const t = useLocale();

  return (
    <Popover
      title={null}
      open={visible}
      onOpenChange={setVisible}
      trigger="click"
      placement="bottomLeft"
      align={{ offset: [props.dropdownOffsetLeft, 0] }}
      overlayClassName={styles.dropdown}
      content={
        <div className={`${styles.occupancySetting}`}>
          <div className={styles.innnerWrapper}>
            {/* {!props.onlyFirstRoom && (
            <Alert
              message={t('Warning_PriceChange_GuestCount')}
              type="warning"
              className="mb-2"
              showIcon
              closable
            />
          )} */}
            <Row justify="space-between">
              <Col>
                <span className="text-title">{t('Label_RoomCount')}</span>
              </Col>
              <Col>
                <Row align="middle">
                  <Button
                    disabled={occupancySetting.roomCount < 2}
                    className="change-btn"
                    size="small"
                    onClick={() => dispatch({ type: 'reduceRoomCount' })}
                  >
                    <MinusOutlined />
                  </Button>
                  <div className={styles.countContent}>
                    {occupancySetting.roomCount}
                  </div>
                  <Button
                    disabled={occupancySetting.roomCount >= MaxRoomCount}
                    className="change-btn"
                    size="small"
                    onClick={() => dispatch({ type: 'addRoomCount' })}
                  >
                    <PlusOutlined />
                  </Button>
                </Row>
              </Col>
            </Row>
            {occupancySetting.roomList
              .slice(0, props.onlyFirstRoom ? 1 : Infinity)
              .map((room, roomIndex) => (
                <div key={roomIndex}>
                  <div className="mt-2 mb-1">
                    {props.onlyFirstRoom
                      ? t('Label_PerRoom')
                      : t('Label_RoomWithIndex', { index: roomIndex + 1 })}
                  </div>
                  <Row justify="space-between" align="middle" className="mb-1">
                    <Col>
                      <span className="text-title">
                        {t('Label_AdultCount')}
                      </span>
                    </Col>
                    <Col>
                      <Row align="middle">
                        <Button
                          disabled={room.adultCount < 2}
                          className="change-btn"
                          size="small"
                          onClick={() =>
                            dispatch({
                              type: 'reduceAdultCount',
                              payload: roomIndex,
                            })
                          }
                        >
                          <MinusOutlined />
                        </Button>

                        <div className={styles.countContent}>
                          {room.adultCount}
                        </div>
                        <Button
                          disabled={room.adultCount >= MaxAdultCount}
                          className="change-btn"
                          size="small"
                          onClick={() =>
                            dispatch({
                              type: 'addAdultCount',
                              payload: roomIndex,
                            })
                          }
                        >
                          <PlusOutlined />
                        </Button>
                      </Row>
                    </Col>
                  </Row>

                  <Row justify="space-between" align="middle" className="mb-1">
                    <Col>
                      <span className="text-title">
                        {t('Label_ChildrenCount')}
                      </span>
                    </Col>
                    <Col>
                      <Row align="middle">
                        <Button
                          disabled={room.childCount < 1}
                          className="change-btn"
                          size="small"
                          onClick={() =>
                            dispatch({
                              type: 'reduceChildCount',
                              payload: roomIndex,
                            })
                          }
                        >
                          <MinusOutlined />
                        </Button>
                        <div className={styles.countContent}>
                          {room.childCount}
                        </div>
                        <Button
                          disabled={room.childCount >= MaxChildrenCount}
                          className="change-btn"
                          size="small"
                          onClick={() =>
                            dispatch({
                              type: 'addChildCount',
                              payload: roomIndex,
                            })
                          }
                        >
                          <PlusOutlined />
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={10} className="child-age-list">
                    {room.childAgeList.map((age, childIndex) => (
                      <Col key={childIndex} span="8">
                        <Select
                          style={{ width: '100%' }}
                          defaultValue={age}
                          size="small"
                          onChange={(value) => {
                            dispatch({
                              type: 'changeChildAge',
                              payload: {
                                age: value,
                                roomIndex,
                                childIndex,
                              },
                            });
                          }}
                        >
                          {AgeList.map((ageItem, ageItemIndex) => (
                            <Select.Option
                              key={ageItemIndex}
                              value={ageItem}
                              label={ageItem || '<1'}
                            >
                              {ageItem || '<1'}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
          </div>
          <Row justify="end" gutter={10} className="mt-3">
            <Col>
              <Button onClick={() => setVisible(false)}>
                {t('Button_Cancel')}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  if (props.onlyFirstRoom) {
                    props.onConfirm({
                      ...occupancySetting,
                      roomList: occupancySetting.roomList.map(() => ({
                        ...occupancySetting.roomList[0],
                        childAgeList: occupancySetting.roomList[0]
                          .childAgeList && [
                          ...occupancySetting.roomList[0].childAgeList,
                        ],
                      })),
                    });
                  } else {
                    props.onConfirm(occupancySetting);
                  }
                  setVisible(false);
                }}
              >
                {t('Button_Confirm')}
              </Button>
            </Col>
          </Row>
        </div>
      }
    >
      {props.children}
    </Popover>
  );
};

export default OccupancySetting;
