import { CoreHotelBooking } from '@b2c/core';
import { useLocale } from '@b2c/hooks';

export function TypographyGuestName({
  value,
}: {
  value: CoreHotelBooking.Guest;
}) {
  const t = useLocale();
  return (
    <>
      {t('Position_GuestName', {
        first: value.FirstName,
        last: value.LastName,
      })}
    </>
  );
}
