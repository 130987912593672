import { AnalyticsHelper } from '@b2c/services/common';
import { HttpService } from '@b2c/services/http';
import '@vant/touch-emulator';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app/app';
import { environment } from './environments/environment';

HttpService.serviceEndPoint = environment.serviceEndPoint;
AnalyticsHelper.init(environment.analytics);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <StrictMode>
  <BrowserRouter>
    {/* <IntlProvider locale={'en-US'} messages={messages}> */}
    <App />
    {/* </IntlProvider> */}
  </BrowserRouter>
  // </StrictMode>
);
