export function Enumerable(enumerable = true): Function {
  return function (target, propertyKey, descriptor) {
    descriptor =
      descriptor || Object.getOwnPropertyDescriptor(target, propertyKey);

    if (descriptor) {
      descriptor.enumerable = enumerable;
      Object.defineProperty(target, propertyKey, descriptor);
    } else {
      Object.defineProperty(target, propertyKey, {
        enumerable: enumerable,
      });
    }
  };
}
