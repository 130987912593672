import { useLocale } from '@b2c/hooks';
import {
  HotelFallbackIcon,
  LeftOutlineIcon,
  RightOutlineIcon,
} from '@b2c/icons';
import { SimplifiedHotelDetail } from '@b2c/services/hotel';
import classNames from 'classnames';
import React, { FunctionComponent, useRef, useState } from 'react';
import {
  Button,
  Flex,
  Image,
  ImagePreview,
  Popup,
  Space,
  Swiper,
  SwiperInstance,
  Typography,
} from 'react-vant';
import { Container } from '../container';
import styles from './hotel-detail-imgs.module.less';
import { ReactComponent as ImgIndicator } from './img-indicator.svg';

export const HotelDetailImgs: FunctionComponent<{
  hotelInfo: SimplifiedHotelDetail;
  style?: React.CSSProperties;
  switchIconStyle?: React.CSSProperties;
}> = ({ hotelInfo, style, ...respProps }) => {
  const t = useLocale();

  const [imgIndex, setImgIndex] = useState<number>(0);

  const swiperRef = useRef<SwiperInstance>();

  const [imgPopoverVisible, setImgPopoverVisible] = useState(false);

  if (!hotelInfo) {
    return <div className={styles.hotelImgWrapper}></div>;
  }
  return (
    <div className={styles.hotelImgWrapper} style={style}>
      {hotelInfo.hotelImageList.length === 0 ? (
        <Image
          className={styles.hotelImg}
          loadingIcon={<HotelFallbackIcon style={{ fontSize: '80px' }} />}
        />
      ) : (
        <div>
          <div onClick={() => setImgPopoverVisible(true)}>
            <Swiper
              ref={swiperRef}
              className={styles.hotelImg}
              onChange={setImgIndex}
            >
              {hotelInfo.hotelImageList.map((item, index) => (
                <Swiper.Item key={index}>
                  <Image lazyload fit="cover" src={item.ImageUrl_HD} />
                </Swiper.Item>
              ))}
            </Swiper>
          </div>
          <div
            className={styles.imgCountWrapper}
            onClick={() => setImgPopoverVisible(true)}
          >
            <Space gap={4}>
              <ImgIndicator className={styles.icon} />
              <span>
                {t('Text_ImgIndexAndTotal', {
                  index: imgIndex + 1,
                  totalCount: hotelInfo.hotelImageList.length,
                })}
              </span>
            </Space>
          </div>
          <div
            className={classNames(styles.switchIcon, styles.left)}
            style={respProps.switchIconStyle}
            onClick={() => swiperRef.current.swipePrev()}
          >
            <LeftOutlineIcon></LeftOutlineIcon>
          </div>
          <div
            className={classNames(styles.switchIcon, styles.right)}
            style={respProps.switchIconStyle}
            onClick={() => swiperRef.current.swipeNext()}
          >
            <RightOutlineIcon></RightOutlineIcon>
          </div>
        </div>
      )}
      <Popup
        visible={imgPopoverVisible}
        position="bottom"
        style={{ height: '100%' }}
      >
        <div className={styles.nav}>
          <div
            className={styles.navIcon}
            onClick={() => setImgPopoverVisible(false)}
          >
            <LeftOutlineIcon style={{ color: 'black' }}></LeftOutlineIcon>
          </div>
          <Typography.Text strong ellipsis size="lg">
            {hotelInfo.Name}
          </Typography.Text>
        </div>
        <div className={styles.navbackBtn}>
          <Container>
            <Button
              round
              block
              type="primary"
              size="large"
              onClick={() => setImgPopoverVisible(false)}
            >
              {t('Button_SelectYourRooms')}
            </Button>
          </Container>
        </div>
        <div className="rv-hairline--top"></div>
        {/* <NavBar
          title={hotelInfo.Name}
          leftText={t('Button_Back')}
          rightText="按钮"
          onClickRight={() => setImgPopoverVisible(false)}
        /> */}
        <Container>
          <Flex className={styles.imgGrid} gutter={10} wrap="wrap">
            {hotelInfo.hotelImageList.map((item, imgIndex) => (
              <Flex.Item span={12} key={imgIndex} className={styles.imgItem}>
                <Image
                  className={styles.img}
                  lazyload={true}
                  src={item.ImageUrl_HD}
                  fit="cover"
                  onClick={() =>
                    ImagePreview.open({
                      images: hotelInfo.hotelImageList.map(
                        (item) => item.ImageUrl_HD
                      ),
                      startPosition: imgIndex,
                      closeable: true,
                    })
                  }
                ></Image>
              </Flex.Item>
            ))}
          </Flex>
        </Container>
      </Popup>
    </div>
  );
};
