import { DateTimeHelper } from '@b2c/services/common';
import { differenceInDays, startOfDay } from 'date-fns';
import { useLocale } from './use-locale';

export function useNearCheckinNotice(checkinDay: Date | string) {
  const t = useLocale();

  const dates2Checkin = differenceInDays(
    DateTimeHelper.getDate(checkinDay),
    startOfDay(new Date())
  );
  if (dates2Checkin > 10) {
    return null;
  }
  if (dates2Checkin > 0) {
    return t('Notice_Checkin_Near1', { 0: dates2Checkin });
  }
  return t('Notice_Checkin_Near2');
}
