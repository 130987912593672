import { CoreCommon } from '@b2c/core';
import {
  AppContextInfoContext,
  AppCustomerSerivceSetting,
  useContextLanguage,
  useLocale,
  useLocaleList,
} from '@b2c/hooks';
import { useContext, useState } from 'react';
import { ActionSheet, Cell, Popup } from 'react-vant';
import { CurrencyPicker } from '../currency-picker';
import { MessageService } from '../message.service';

export function Sidebar(props: { config: AppCustomerSerivceSetting }) {
  const t = useLocale();
  const [showCurrencyPopup, setShowCurrencyPopup] = useState(false);
  const [langOptionsVisible, setLangOptionsVisible] = useState(false);
  const [phoneOptionsVisible, setPhoneOptionsVisible] = useState(false);
  const { appContext } = useContext(AppContextInfoContext);

  const { setLanguage, languageInfo } = useContextLanguage();

  function onLangChange(lang: CoreCommon.LanguageCode) {
    setLangOptionsVisible(false);
    const loadingReturn = MessageService.loading();
    setLanguage(lang)
      .then(() => {
        //
      })
      .finally(() => {
        loadingReturn.clear();
      });
  }

  const currency = appContext?.currencyInfo.Currency;

  const languageList = useLocaleList();

  const phoneActions = props.config.tels.map((item) => {
    return {
      name: `(${item.code}) ${item.number}`,
      value: item.number,
      subname: item.desc && t(item.desc, item),
    };
  });

  return (
    <>
      <Cell.Group
        title={t('Label_Settings')}
        border={false}
        style={{ marginTop: '60px' }}
      >
        <Cell
          border={false}
          onClick={() => setShowCurrencyPopup(true)}
          title={t('Label_Currrency')}
          value={currency}
          isLink
        ></Cell>
        {languageList?.length > 1 && (
          <>
            <Cell
              onClick={() => setLangOptionsVisible(true)}
              title={t('Label_Language')}
              value={languageInfo.name}
              isLink
            ></Cell>

            <ActionSheet
              actions={languageList}
              visible={langOptionsVisible}
              onSelect={(item, index) => {
                onLangChange(item['langCode']);
                // setShowPopup(true);
              }}
              onCancel={() => {
                setLangOptionsVisible(false);
                // setShowPopup(true);
              }}
            />
          </>
        )}
      </Cell.Group>
      <Cell.Group title={t('Label_HelpAndSupport')} border={false}>
        <Cell
          border={false}
          isLink
          title={t('Label_Email')}
          label={props.config.email}
          onClick={() => window.open('mailto:' + props.config.email)}
        ></Cell>
        <Cell
          border={false}
          title={t('Label_Phone')}
          isLink
          onClick={() => setPhoneOptionsVisible(true)}
        ></Cell>
      </Cell.Group>
      <ActionSheet
        actions={phoneActions}
        visible={phoneOptionsVisible}
        onSelect={(item, index) => {
          const phone = phoneActions[index].value;
          window.open('tel:' + phone);
        }}
        onCancel={() => {
          setPhoneOptionsVisible(false);
        }}
      />
      <Popup
        visible={showCurrencyPopup}
        closeOnPopstate
        position="right"
        style={{ width: '100%', height: '100%' }}
        closeable
        onClose={() => {
          setShowCurrencyPopup(false);
          // setShowPopup(true);
        }}
      >
        <div style={{ marginTop: '60px' }}></div>
        <CurrencyPicker
          onChange={() => {
            setShowCurrencyPopup(false);
            // setShowPopup(true);
          }}
        ></CurrencyPicker>
      </Popup>
    </>
  );
}
