import { HotelSortingType } from '@b2c/services/rate';
import { useMemo } from 'react';
import { useLocale } from './use-locale';

export type SortTypeInfo = {
  text: string;
  sortType: HotelSortingType;
  isAscending: boolean;
  value: number;
};

export function useSortTypeList() {
  const t = useLocale();
  const SortTypeList = useMemo<SortTypeInfo[]>(() => {
    return [
      {
        text: t('Text_PriceSort_Default'),
        sortType: HotelSortingType.Default,
        isAscending: false,
        value: 0,
      },
      {
        text: t('Text_PriceSort_PriceAsc'),
        sortType: HotelSortingType.Price,
        isAscending: true,
        value: 2,
      },
      {
        text: t('Text_PriceSort_PriceDesc'),
        sortType: HotelSortingType.Price,
        isAscending: false,
        value: 1,
      },
      {
        text: t('Text_PriceSort_StarDesc'),
        sortType: HotelSortingType.HotelRating,
        isAscending: false,
        value: 3,
      },
      {
        text: t('Text_PriceSort_StarAsc'),
        sortType: HotelSortingType.HotelRating,
        isAscending: true,
        value: 4,
      },
    ];
  }, [t]);

  return SortTypeList;
}
