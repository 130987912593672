import { CoreCommon } from '@b2c/core';
import { useEffect, useState } from 'react';
import { AppAvailLocaleList } from './use-locale-list';

export function useLocaleMessages(
  defaultLocale: CoreCommon.LanguageCode,
  defaultMessage: Record<string, string>
) {
  const locale: CoreCommon.LanguageCode = defaultLocale || 'en-US';

  const [localeMessages, setLocaleMessages] = useState<{
    code: string;
    messages: Record<string, string>;
  }>({
    code: AppAvailLocaleList.find((item) => item.langCode == locale)
      ?.langCodeShort,
    messages: defaultMessage,
  });

  useEffect(() => {
    const languageInfo = AppAvailLocaleList.find(
      (item) => item.langCode === locale
    );
    languageInfo.translationLoader().then((messages) => {
      setLocaleMessages({
        code: languageInfo.langCodeShort,
        messages: Object.assign({}, defaultMessage, messages),
      });
    });
  }, [locale]);

  return {
    localeMessages,
  };
}
