import SVGIcon from '../svg-icon';
import { ReactComponent as AddressComponent } from './svg/address.svg';
import { ReactComponent as BarsOutlineComponent } from './svg/bars-outline.svg';
import { ReactComponent as BedFillComponent } from './svg/bed-fill.svg';
import { ReactComponent as CalendarRingComponent } from './svg/calendar-ring.svg';
import { ReactComponent as CalendarComponent } from './svg/calendar.svg';
import { ReactComponent as ClockFillComponent } from './svg/clock-fill.svg';
import { ReactComponent as DownOutlineComponent } from './svg/down-outline.svg';
import { ReactComponent as EditComponent } from './svg/edit.svg';
import { ReactComponent as EmailFillComponent } from './svg/email-fill.svg';
import { ReactComponent as FacilityAirportShuttleComponent } from './svg/facility-airport-shuttle.svg';
import { ReactComponent as FacilityGymComponent } from './svg/facility-gym.svg';
import { ReactComponent as FacilityMeetingRoomComponent } from './svg/facility-meeting-room.svg';
import { ReactComponent as FacilityParkingComponent } from './svg/facility-parking.svg';
import { ReactComponent as FacilityRestaurantComponent } from './svg/facility-restaurant.svg';
import { ReactComponent as FacilitySwimmingPoolComponent } from './svg/facility-swimming-pool.svg';
import { ReactComponent as FacilityWifiComponent } from './svg/facility-wifi.svg';
import { ReactComponent as FilterOutlineComponent } from './svg/filter-outline.svg';
import { ReactComponent as FloorComponent } from './svg/floor.svg';
import { ReactComponent as HappyOutlineComponent } from './svg/happy-outline.svg';
import { ReactComponent as HeartComponent } from './svg/heart.svg';
import { ReactComponent as HotelFallbackComponent } from './svg/hotel-fallback.svg';
import { ReactComponent as HotelComponent } from './svg/hotel.svg';
import { ReactComponent as LeftOutlineComponent } from './svg/left-outline.svg';
import { ReactComponent as LightningComponent } from './svg/lightning.svg';
import { ReactComponent as NationalityComponent } from './svg/nationality.svg';
import { ReactComponent as NonSmokingComponent } from './svg/non-smoking.svg';
import { ReactComponent as OccupancyComponent } from './svg/occupancy.svg';
import { ReactComponent as PopupComponent } from './svg/popup.svg';
import { ReactComponent as RegionComponent } from './svg/region.svg';
import { ReactComponent as RightOutlineComponent } from './svg/right-outline.svg';
import { ReactComponent as RoomSquareComponent } from './svg/room-square.svg';
import { ReactComponent as SadOutlineComponent } from './svg/sad-outline.svg';
import { ReactComponent as SearchComponent } from './svg/search.svg';
import { ReactComponent as ShieldFillComponent } from './svg/shield-fill.svg';
import { ReactComponent as SortOutlineComponent } from './svg/sort-outline.svg';
import { ReactComponent as StarFillComponent } from './svg/star-fill.svg';
import { ReactComponent as TablewareComponent } from './svg/tableware.svg';
import { ReactComponent as TelephoneFillComponent } from './svg/telephone-fill.svg';
import { ReactComponent as UserFillComponent } from './svg/user-fill.svg';
import { ReactComponent as UserGroupFillComponent } from './svg/user-group-fill.svg';
import { ReactComponent as WindowComponent } from './svg/window.svg';
export const AddressIcon = (props) => (  <SVGIcon component={AddressComponent} {...props} />);
export const BarsOutlineIcon = (props) => (  <SVGIcon component={BarsOutlineComponent} {...props} />);
export const BedFillIcon = (props) => (  <SVGIcon component={BedFillComponent} {...props} />);
export const CalendarRingIcon = (props) => (  <SVGIcon component={CalendarRingComponent} {...props} />);
export const CalendarIcon = (props) => (  <SVGIcon component={CalendarComponent} {...props} />);
export const ClockFillIcon = (props) => (  <SVGIcon component={ClockFillComponent} {...props} />);
export const DownOutlineIcon = (props) => (  <SVGIcon component={DownOutlineComponent} {...props} />);
export const EditIcon = (props) => (  <SVGIcon component={EditComponent} {...props} />);
export const EmailFillIcon = (props) => (  <SVGIcon component={EmailFillComponent} {...props} />);
export const FacilityAirportShuttleIcon = (props) => (  <SVGIcon component={FacilityAirportShuttleComponent} {...props} />);
export const FacilityGymIcon = (props) => (  <SVGIcon component={FacilityGymComponent} {...props} />);
export const FacilityMeetingRoomIcon = (props) => (  <SVGIcon component={FacilityMeetingRoomComponent} {...props} />);
export const FacilityParkingIcon = (props) => (  <SVGIcon component={FacilityParkingComponent} {...props} />);
export const FacilityRestaurantIcon = (props) => (  <SVGIcon component={FacilityRestaurantComponent} {...props} />);
export const FacilitySwimmingPoolIcon = (props) => (  <SVGIcon component={FacilitySwimmingPoolComponent} {...props} />);
export const FacilityWifiIcon = (props) => (  <SVGIcon component={FacilityWifiComponent} {...props} />);
export const FilterOutlineIcon = (props) => (  <SVGIcon component={FilterOutlineComponent} {...props} />);
export const FloorIcon = (props) => (  <SVGIcon component={FloorComponent} {...props} />);
export const HappyOutlineIcon = (props) => (  <SVGIcon component={HappyOutlineComponent} {...props} />);
export const HeartIcon = (props) => (  <SVGIcon component={HeartComponent} {...props} />);
export const HotelFallbackIcon = (props) => (  <SVGIcon component={HotelFallbackComponent} {...props} />);
export const HotelIcon = (props) => (  <SVGIcon component={HotelComponent} {...props} />);
export const LeftOutlineIcon = (props) => (  <SVGIcon component={LeftOutlineComponent} {...props} />);
export const LightningIcon = (props) => (  <SVGIcon component={LightningComponent} {...props} />);
export const NationalityIcon = (props) => (  <SVGIcon component={NationalityComponent} {...props} />);
export const NonSmokingIcon = (props) => (  <SVGIcon component={NonSmokingComponent} {...props} />);
export const OccupancyIcon = (props) => (  <SVGIcon component={OccupancyComponent} {...props} />);
export const PopupIcon = (props) => (  <SVGIcon component={PopupComponent} {...props} />);
export const RegionIcon = (props) => (  <SVGIcon component={RegionComponent} {...props} />);
export const RightOutlineIcon = (props) => (  <SVGIcon component={RightOutlineComponent} {...props} />);
export const RoomSquareIcon = (props) => (  <SVGIcon component={RoomSquareComponent} {...props} />);
export const SadOutlineIcon = (props) => (  <SVGIcon component={SadOutlineComponent} {...props} />);
export const SearchIcon = (props) => (  <SVGIcon component={SearchComponent} {...props} />);
export const ShieldFillIcon = (props) => (  <SVGIcon component={ShieldFillComponent} {...props} />);
export const SortOutlineIcon = (props) => (  <SVGIcon component={SortOutlineComponent} {...props} />);
export const StarFillIcon = (props) => (  <SVGIcon component={StarFillComponent} {...props} />);
export const TablewareIcon = (props) => (  <SVGIcon component={TablewareComponent} {...props} />);
export const TelephoneFillIcon = (props) => (  <SVGIcon component={TelephoneFillComponent} {...props} />);
export const UserFillIcon = (props) => (  <SVGIcon component={UserFillComponent} {...props} />);
export const UserGroupFillIcon = (props) => (  <SVGIcon component={UserGroupFillComponent} {...props} />);
export const WindowIcon = (props) => (  <SVGIcon component={WindowComponent} {...props} />);