import { FalconCommon } from '../common';
import { FalconPayment } from '../pay-channel';
import { FalconRate } from '../rate';

export namespace FalconHotelRate {
  export interface SearchOption {
    DestinationID: string | null;

    RegionIDList: number[];
    HotelIDList: number[];
    HoistingHotelIDList: number[];

    CheckInDate: string;
    CheckOutDate: string;
    Nationality: string;

    PriceSearchFilter: Partial<PriceSearchFilter>;
    SortFilter: SortFilter;
    RealTimeFilter: RealtimeFilter;
    LowestRateFilter: LowestRateFilter;

    SupplierIDList: string[];
    BatchID: string;

    IsPriceOnly: boolean;
    IsNeedOnRequest: boolean;

    Currency: string;
    PageNum: number;
    CountPerPage: number;

    Source: string;
    SourceInfo: string;
    SourceSite: string;
    SearchIdx: number;
    ChannelPriceInfo: FalconRate.ThirdPartyRateInfo;

    // #region Server Side Populate
    // ClientUserAgent: string;
    // ClientIpAddr: string;
    // ClientID: string;
    // FalconUserID: string;
    // Suppliers: number[];
    // LanguageCode: string;
    // ThirdPartyRateInfo: FalconThirdPartyRateInfo;
    // #endregion
    RatePaymentTypeList: FalconPayment.RatePaymentTypeEnum[];
  }

  export interface Occupancy {
    AdultCount: number;
    ChildCount: number;
    ChildAgeList: number[];
  }

  export interface RealtimeFilter {
    IsRealTime: boolean;
    RoomCount: number;
    OccupancySetting: Occupancy;
    TimeOut?: number | null;
  }

  export interface LowestRateFilter {
    LowestRateOnly: boolean;
  }

  export interface PriceSearchFilter {
    BedTypeID: number;
    BreakfastTypeID: number;
    PersonCount: number;
    HotelKeyWord: string;
    StarRating: number[];
    MaxPrice: number;
    MinPrice: number;
    HotelChainCode: string[];
    Location: FalconCommon.GeoCoordinate;
    Radius: number;
  }

  export interface SortFilter {
    /**
     * Core.DataModel.Models.Internal.ChannelHotelRates.HotelSortingType
     * Default = -1,
     * HotelName = 0,
     * HotelRating = 1,
     * Price = 2
     */
    SortBy: number;

    IsAscending: boolean;
  }
}
