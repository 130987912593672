import { CoreCommon, CoreSuggestion } from '@b2c/core';
import {
  AppContextInfoContext,
  DestinationSearchHistoryContext,
  useDestinationSuggestion,
  useLocale,
} from '@b2c/hooks';
import { AddressIcon, HotelIcon, SearchIcon } from '@b2c/icons';
import { AnalyticsHelper } from '@b2c/services/common';
import { Destination, DestinationType } from '@b2c/services/destinations';
import { SuggestionOption } from '@b2c/services/suggestions';
import { DeleteO } from '@react-vant/icons';
import debounce from 'lodash/debounce';
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Cell,
  Empty,
  Flex,
  IndexBar,
  Loading,
  NoticeBar,
  Search,
  Space,
  Sticky,
  Typography,
} from 'react-vant';
import { Highlight } from '../highlight/highlight';
import { ModalService } from '../modal.service';
import styles from './destination-picker.module.less';

export const HOT_SEARCH_CITY_ID_LIST: string[] = [
  '104',
  // 曼谷
  '178236',
  // 普吉
  '6046393',
  // 苏梅岛
  '6035013',
  // 新加坡
  '3168',
  // 巴厘岛
  '602651',
  // 香港岛
  '184245',
  // 九龙
  '6059',
  // 铜锣湾
  '12124',
  // 台北
  '180030',
  // 东京
  '179900',
  // 大阪
  '179897',
  // 首尔
  '178308',
  //#region [移除 by https://tower.im/teams/191049/todos/94939/]
  // 洛杉矶
  // '178280',
  // 拉斯维加斯
  // '178276',
  // 纽约
  // '178293',
  // 伦敦
  // '178279',
  //#endregion
  // 巴黎
  '179898',
  //#region [移除 by https://tower.im/teams/191049/todos/94939/]
  // 罗马
  // '179899',
  //#endregion
  // 悉尼
  '178312',
  // 墨尔本
  '178283',
  // 奥克兰
  '178233',
  // 迪拜
  '1079',
];

const DestinationInfoLangs: CoreCommon.LanguageCode[] = [
  'zh-CN',
  'en-US',
  'ja-JP',
];

export const DestinationPicker: FunctionComponent<{
  destinationInfo: SuggestionOption;
  onSelect: (region: SuggestionOption) => void;
  onClose: () => void;
}> = ({ destinationInfo, ...props }) => {
  const t = useLocale();
  const [keyword, setKeyword] = useState<string>('');
  const {
    searchDestination,
    suggestionList,
    loading,
    noMatchingResult,
    clearSuggetionList,
  } = useDestinationSuggestion();

  const { appContext } = useContext(AppContextInfoContext);

  const [regionList, setRegionList] = useState<Destination[]>([]);
  const [regionListLoading, setRegionListLoading] = useState(false);
  useMemo(() => {
    const resourceFile = DestinationInfoLangs.includes(appContext?.locale)
      ? appContext?.locale
      : 'en-US';
    setRegionListLoading(true);
    import(`./locale/cities.${resourceFile}.json`)
      .then((module) => {
        setRegionList(
          module.default.sort((a, b) => {
            if ((a.pinyin || a.name) > (b.pinyin || b.name)) {
              return 1;
            } else {
              return -1;
            }
          })
        );
      })
      .finally(() => {
        setRegionListLoading(false);
      });
  }, [appContext?.locale]);

  const regionGroupList = useMemo(() => {
    if (!['zh-CN', 'en-US'].includes(appContext?.locale)) {
      return [];
    }
    const cityMap = regionList.reduce((result, item) => {
      const indexKey = (item.pinyin || item.name)[0].toUpperCase();
      if (!result[indexKey]) {
        result[indexKey] = [];
      }
      result[indexKey].push(item);
      return result;
    }, {} as { [key: string]: Destination[] });
    const groupList = Object.keys(cityMap)
      .sort()
      .map((key) => ({
        id: key,
        regions: cityMap[key],
      }));
    if (regionList.length > 0) {
      groupList.unshift({
        id: t('Label_Popular_Dest'),
        regions: HOT_SEARCH_CITY_ID_LIST.map((id) =>
          regionList.find((item) => item.id === id)
        ),
      });
    }
    return groupList;
  }, [regionList, t, appContext?.locale]);

  const indexList = useMemo(() => {
    const result = regionGroupList?.map((item) => item.id);
    return result;
  }, [regionGroupList]);

  const { clearHistoryList, historyList } = useContext(
    DestinationSearchHistoryContext
  );

  const suggetionSearchResult = useMemo<{
    keyword: string;
    options: SuggestionOption[];
  }>(() => {
    if (!suggestionList.length) {
      return {
        keyword: '',
        options: null,
      };
    }

    const regionSuggestion = suggestionList.find(
      (x) => x.type === DestinationType.region
    );
    const hotelSuggestion = suggestionList.find(
      (x) => x.type === DestinationType.hotel
    );
    return {
      keyword: regionSuggestion?.keyword || hotelSuggestion?.keyword,
      options: [].concat(
        regionSuggestion?.options || [],
        hotelSuggestion?.options || []
      ),
    };
  }, [suggestionList]);

  const researchDestination = useCallback(
    debounce(function (newKeyword: string) {
      searchDestination(newKeyword);
    }, 300),
    []
  );

  const onKeywordInput = useCallback((newKeyword: string) => {
    setKeyword(newKeyword);
    researchDestination(newKeyword);
  }, []);

  function onSuggestionSelect(region: SuggestionOption) {
    setKeyword('');
    const newRegion = { ...region };
    // updateHistoryList(newRegion);
    props.onSelect(newRegion);

    let selectType = 'hotel';
    if (region.category !== CoreSuggestion.SuggestionItemCategory.Hotel) {
      selectType = 'region';
    }
    AnalyticsHelper.selectContent(selectType, region.id);
  }

  const selectedRegionID = destinationInfo?.id || null;

  useEffect(() => {
    return () => {
      onKeywordInput('');
      clearSuggetionList();
    };
  }, []);

  const renderSuggestionItem = useCallback(
    (option: SuggestionOption, keyword: string) => {
      switch (option.category) {
        case CoreSuggestion.SuggestionItemCategory.Hotel:
          return (
            <Flex gutter={12} align="start">
              <Flex.Item>
                <Typography.Text size="lg">
                  <HotelIcon />
                </Typography.Text>
              </Flex.Item>
              <Flex.Item flex="1">
                <div>
                  <Typography.Text size="lg" strong>
                    <Highlight text={option.name} keyword={keyword}></Highlight>
                  </Typography.Text>
                </div>
                <div>
                  <Typography.Text size="sm" type="secondary">
                    {' '}
                    {option.address.detail}, {option.address.destinationName},
                    {option.address.countryName}
                  </Typography.Text>
                </div>
              </Flex.Item>
            </Flex>
          );
        default:
          return (
            <Flex gutter={12} align="start">
              <Flex.Item>
                <Typography.Text size="lg">
                  <AddressIcon />
                </Typography.Text>
              </Flex.Item>
              <Flex.Item flex="1">
                <div>
                  <Typography.Text size="lg" strong>
                    <Highlight text={option.name} keyword={keyword}></Highlight>
                  </Typography.Text>
                </div>
                <div>
                  <Typography.Text size="sm" type="secondary">
                    {t('Text_HotelCountNearby', {
                      hotelCount: option.source.Metadata.HotelCount,
                    })}
                  </Typography.Text>
                </div>
              </Flex.Item>
            </Flex>
          );
      }
    },
    []
  );

  return (
    <div>
      <div style={{ height: '54px' }}>
        <Sticky>
          <Search
            placeholder={t('Placeholder_Destination')}
            clearable={true}
            clearTrigger="always"
            leftIcon={loading ? <Loading size="16" /> : <SearchIcon />}
            autoFocus={true}
            value={keyword}
            onChange={onKeywordInput}
            showAction={true}
            onCancel={() => {
              props.onClose();
            }}
          />
        </Sticky>
      </div>
      {keyword && (
        <div className="scroll-view">
          {suggetionSearchResult.options?.length > 0 && (
            <Cell.Group className="list-box">
              {suggetionSearchResult.options?.map((option) => (
                <Cell
                  key={option.id}
                  onClick={() => onSuggestionSelect(option)}
                >
                  {renderSuggestionItem(option, suggetionSearchResult.keyword)}
                </Cell>
              ))}
            </Cell.Group>
          )}
          {noMatchingResult && (
            <Empty
              className="mt-5"
              description={t('Text_NotDestinationFound')}
            ></Empty>
          )}
        </div>
      )}
      {!keyword && (
        <div style={{ backgroundColor: 'var(--mu-background-color)' }}>
          {historyList.length > 0 && (
            <>
              <div className="rv-index-anchor">
                <Flex justify="between">
                  <Flex.Item>{t('Title_SearchHistory')}</Flex.Item>
                  <Flex.Item>
                    <DeleteO
                      onClick={() => {
                        ModalService.confirm({
                          message: t('Warning_DeleteSearchHistory'),
                          cancelText: t('Button_Cancel'),
                          confirmText: t('Button_Confirm'),
                        }).then((confirm) => {
                          if (confirm) {
                            clearHistoryList();
                          }
                        });
                      }}
                    />
                  </Flex.Item>
                </Flex>
              </div>
              <Cell>
                <Space block gap={10} wrap className={styles.tagList}>
                  {historyList.map((city) => (
                    <div
                      key={city.id}
                      onClick={() => onSuggestionSelect(city as any)}
                      className={styles.tag}
                    >
                      <Button
                        plain
                        size="small"
                        type={
                          selectedRegionID === city.id ? 'primary' : 'default'
                        }
                        block
                      >
                        <Typography.Text ellipsis>{city.name}</Typography.Text>
                      </Button>
                    </div>
                  ))}
                </Space>
              </Cell>
            </>
          )}
          {regionListLoading && <Loading></Loading>}
          {!regionListLoading && regionList?.length === 0 && (
            <NoticeBar>{t('Error_LoadFailed_Resource')}</NoticeBar>
          )}
          <IndexBar indexList={indexList} stickyOffsetTop={50}>
            {regionGroupList?.map((regionGroup) => (
              <div key={regionGroup.id}>
                <IndexBar.Anchor index={regionGroup.id}></IndexBar.Anchor>
                {regionGroup.regions.map((region) => (
                  <Cell
                    key={region.id}
                    onClick={() => {
                      onSuggestionSelect(region as any);
                    }}
                    title={region.name}
                  ></Cell>
                ))}
              </div>
            ))}
          </IndexBar>
        </div>
      )}
    </div>
  );
};
