import { useLocale } from '@b2c/hooks';
import { Cross } from '@react-vant/icons';
import { FunctionComponent, ReactNode, RefAttributes } from 'react';
import { Button, Cell, Flex, Popup, Space, Typography } from 'react-vant';
import { PopupProps } from 'react-vant/lib/popup';
import { PopupInstanceType } from 'react-vant/lib/popup/PropsType';
import './popup-dialog.less';
interface PopupDialogProps {
  cancelButtonText?: string;
  closeOnCancel?: boolean;
  confirmButtonText?: string;
  onCancel?(): void;
  onConfirm?(): void;
  showFooter?: boolean;
  showCancel?: boolean;
  footer?: ReactNode;
}
export const PopupDialog: FunctionComponent<
  PopupDialogProps & PopupProps & RefAttributes<PopupInstanceType>
> = ({
  title,
  closeable,
  showFooter = true,
  footer,
  showCancel = true,
  ...props
}) => {
  props.safeAreaInsetBottom = props.safeAreaInsetBottom ?? true;

  const t = useLocale();

  return (
    <Popup {...props} round={true}>
      <Flex
        direction="column"
        style={{ height: '100%' }}
        className="mu-popup-dialog"
      >
        {!!title && (
          <Flex.Item className="mu-popup-dialog__header">
            <Cell>
              <Space justify="between" block align="center">
                <Typography.Title strong level={4} style={{ marginBottom: 0 }}>
                  {title}
                </Typography.Title>
                {closeable && (
                  <Typography.Text size="lg">
                    <Cross onClick={props.onClose} />
                  </Typography.Text>
                )}
              </Space>
            </Cell>
          </Flex.Item>
        )}
        <Flex.Item flex="1" className="mu-popup-dialog__body">
          {props.children}
        </Flex.Item>
        {showFooter && (
          <Flex.Item>
            <Cell className="mu-popup-dialog__footer">
              {footer || (
                <Flex gutter={10} justify="around">
                  {showCancel && (
                    <Flex.Item span="10">
                      <Button
                        block
                        round
                        onClick={() => {
                          props.onCancel?.call(null);
                          if (props.closeOnCancel) {
                            props.onClose?.call(null);
                          }
                        }}
                      >
                        {props.cancelButtonText || t('Button_Cancel')}
                      </Button>
                    </Flex.Item>
                  )}
                  <Flex.Item span="14">
                    <Button
                      block
                      round
                      type="primary"
                      onClick={props.onConfirm}
                    >
                      {props.confirmButtonText || t('Button_OK')}
                    </Button>
                  </Flex.Item>
                </Flex>
              )}
            </Cell>
          </Flex.Item>
        )}
      </Flex>
    </Popup>
  );
};
