import { CoreSuggestion } from '@b2c/core';

export class Destination {
  id: string;
  name: string;
  pinyin?: string;
  type: CoreSuggestion.SuggestionItemCategory;
  order: number;
}

export enum DestinationType {
  region = 'region',
  hotel = 'hotel',
}
