import { CoreCommon, CoreHotelBooking } from '@b2c/core';
import { DateTimeHelper } from '../common';
import { CancellationHelper, CancellationStateType } from '../rateplan';
import { SpecialRequestTypeModel } from '../special-request';
import { FalconBooking } from './falcon-booking.d';

export class HotelBookingHelper {
  static normalize(bookingDetail: FalconBooking.BookingDetailModel) {
    if (!bookingDetail) {
      return bookingDetail;
    }

    this.autoFailWhenExpired(bookingDetail);

    bookingDetail.CancellationPolicies = CancellationHelper.normalize(
      bookingDetail.CancellationPolicies,
      bookingDetail.TimeZoneInfo
    );
  }

  static AutoFailedMinutes = 30;

  static getCancelInfo(booking: FalconBooking.BookingDetailModel): {
    stateFrom?: Date;
    stateTo?: Date;
    desc?: string;
    state: CancellationStateType;
  } {
    // 以下单时为准
    const policies = booking.CancellationPolicies;
    const timezone = booking.TimeZoneInfo?.OffsetExpression || '+08:00';
    policies.forEach((item) => {
      item.CancellationFromTimestampSeconds =
        +new Date(item.CancellationFromTimeLocal + timezone) / 1000;
    });
    if (!policies || policies.length === 0) {
      return {
        state: CancellationStateType.NoneRefundable,
      };
    }

    const bookingDate = Math.round(
      DateTimeHelper.convertToLocalTime(booking.OrderTime).getTime() / 1000
    );
    if (bookingDate < policies[0].CancellationFromTimestampSeconds) {
      // 下单时未到取消政策生效日期
      const lastFreeCancelDate = new Date(
        policies[0].CancellationFromTimeLocal
      );
      return {
        stateTo: lastFreeCancelDate,
        state: CancellationStateType.FreeCancell,
      };
    }

    // 下单时已有取消政策生效
    // 只要下单时不是扣全款的都是付费取消，就算多级付费取消最后是扣全款
    // 下单时扣全款那就是不可退改
    const temp = policies.filter(
      (x) => bookingDate >= x.CancellationFromTimestampSeconds
    );
    const lastPolicy = temp[temp.length - 1];
    if (lastPolicy.Amount < booking.PriceSummary.Price) {
      return {
        state: CancellationStateType.Pay2cancell,
      };
    } else {
      return {
        state: CancellationStateType.NoneRefundable,
      };
    }
  }

  static parseSpecialRequest(
    rawContent: string
  ): CoreCommon.DidaSpecialRequest[] {
    if (!rawContent) {
      return null;
    }
    if (rawContent.startsWith('FMT_SPEC:[') && rawContent.endsWith(']')) {
      // process special requests
      const json = rawContent.substring(9);
      const list = JSON.parse(json) as CoreCommon.DidaSpecialRequest[];
      return list.filter(Boolean);
    } else {
      return [
        {
          Value: rawContent,
          SpecialRequestType: SpecialRequestTypeModel.Other,
        },
      ];
    }
  }

  static checkCancellable(bookingDetail: FalconBooking.BookingDetailModel) {
    // if (bookingDetail.BookingStatus == CoreHotelBooking.StatusType.Canceled) {
    //   return false;
    // }
    if (bookingDetail.BookingStatus !== CoreHotelBooking.StatusType.Confirmed) {
      return false;
    }
    const lastCancelRule =
      bookingDetail.CancellationPolicies[
        bookingDetail.CancellationPolicies.length - 1
      ];

    const lastCancelTime = new Date(
      lastCancelRule.CancellationFromTimestampSeconds * 1000
    );
    if (
      new Date() >= lastCancelTime &&
      bookingDetail.PriceSummary.Price <= lastCancelRule.Amount
    ) {
      return false;
    }

    return true;
  }

  static autoFailWhenExpired(bookingDetail: FalconBooking.BookingDetailModel) {
    // return this.bookingDetailProcessor(raw);
    if (!bookingDetail) {
      return;
    }
    if (!bookingDetail.OrderTime.includes('+')) {
      bookingDetail.OrderTime += '+08:00';
    }
    if (
      [
        CoreHotelBooking.StatusType.Pending,
        CoreHotelBooking.StatusType.Booked,
      ].includes(bookingDetail.BookingStatus)
    ) {
      if (
        Date.now() - +new Date(bookingDetail.OrderTime) >
        this.AutoFailedMinutes * 60 * 1000
      ) {
        bookingDetail.BookingStatus = CoreHotelBooking.StatusType.Failed;
        bookingDetail.ErrorType =
          CoreHotelBooking.ErrorType.BookingPaymentExpired;
      }
    }
  }
}
