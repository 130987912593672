import { CoreHotelBooking } from '@b2c/core';
import { useLocale, useNationalityInfo } from '@b2c/hooks';
import { FalconBooking } from '@b2c/services/booking';
import { DateTimeHelper } from '@b2c/services/common';
import { Divider, Flex, Space, Typography } from 'react-vant';
import { CancellationPolicy } from '../cancellation-policy';
import { TypographyBedtype } from '../typography-bedtype';
import { TypographyDateRange } from '../typography-date-range';
import { TypographyDateTime } from '../typography-date-time';
import { TypographyMealtype } from '../typography-mealtype';
import { TypographyPrice } from '../typography-price';
import { BookingGuestInfo } from './booking-guest-Info';
import { TypographyBookingStatus } from './typography-booking-status';

export function BookingDetailOverview({
  bookingDetail,
}: {
  bookingDetail: FalconBooking.BookingDetailModel;
}) {
  const t = useLocale();
  const nationalityInfo = useNationalityInfo(bookingDetail.Nationality);

  const isCanceled =
    bookingDetail.BookingStatus === CoreHotelBooking.StatusType.Canceled;

  return (
    <>
      <TypographyBookingStatus
        strong
        size="xl"
        value={bookingDetail.BookingStatus}
      ></TypographyBookingStatus>
      <Divider></Divider>
      <Space direction="vertical" block gap={8}>
        <TypographyDateRange
          strong
          size="lg"
          value={{
            From: DateTimeHelper.getDate(bookingDetail.CheckInDate),
            To: DateTimeHelper.getDate(bookingDetail.CheckOutDate),
          }}
        ></TypographyDateRange>
        <Typography.Text strong>
          {bookingDetail.StaticInfo.RoomTypeName || t('Label_Others')}
          &nbsp;&times;&nbsp;
          {t('Text_RoomWithCount', { count: bookingDetail.RoomCount })}
        </Typography.Text>

        <Space block gap={[8, 18]} wrap>
          <TypographyMealtype
            value={bookingDetail.RoomList[0].RateList[0].MealInfo}
            showIcon
          />
          <TypographyBedtype
            value={bookingDetail.StaticInfo.BedType}
            isAssured={bookingDetail.StaticInfo.IsBedTypeAssured}
            showIcon
          />
        </Space>
        {!isCanceled && (
          <CancellationPolicy
            priceSummary={bookingDetail.PriceSummary}
            cancellationList={bookingDetail.CancellationPolicies}
            timeZoneInfo={bookingDetail.TimeZoneInfo}
          ></CancellationPolicy>
        )}
      </Space>
      <Divider />
      <Space direction="vertical" block gap={8}>
        {isCanceled && (
          <Flex>
            <Flex.Item span={8}>
              <Typography.Text type="secondary">
                {t('Label_CancelTime')}
              </Typography.Text>
            </Flex.Item>
            <Flex.Item span={18}>
              <TypographyDateTime
                type="warning"
                value={bookingDetail.BookingCancelTime}
              ></TypographyDateTime>
            </Flex.Item>
          </Flex>
        )}
        {isCanceled && (
          <Flex>
            <Flex.Item span={8}>
              <Typography.Text type="secondary">
                {t('Label_ChargeAmount')}
              </Typography.Text>
            </Flex.Item>
            <Flex.Item span={18}>
              <TypographyPrice
                type="warning"
                value={bookingDetail.CancelConfirmInfo}
                priceKey="Amount"
              ></TypographyPrice>
            </Flex.Item>
          </Flex>
        )}
        <Flex>
          <Flex.Item span={8}>
            <Typography.Text type="secondary">
              {t('Label_GuestName')}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item span={18}>
            <BookingGuestInfo bookingDetail={bookingDetail}></BookingGuestInfo>
          </Flex.Item>
        </Flex>
        {nationalityInfo && (
          <Flex>
            <Flex.Item span={8}>
              <Typography.Text type="secondary">
                {t('Label_Nationality')}
              </Typography.Text>
            </Flex.Item>
            <Flex.Item span={18}>{nationalityInfo.name}</Flex.Item>
          </Flex>
        )}
        {!!bookingDetail.ContractInfo.ContactEmail && (
          <Flex>
            <Flex.Item span={8}>
              <Typography.Text type="secondary">
                {t('Label_Email')}
              </Typography.Text>
            </Flex.Item>
            <Flex.Item span={18}>
              {bookingDetail.ContractInfo.ContactEmail}
            </Flex.Item>
          </Flex>
        )}
        {!!bookingDetail.ContractInfo.ContactPhone && (
          <Flex>
            <Flex.Item span={8}>
              <Typography.Text type="secondary">
                {t('Label_ContactPhone')}
              </Typography.Text>
            </Flex.Item>
            <Flex.Item span={18}>
              {bookingDetail.ContractInfo.ContactPhone}
            </Flex.Item>
          </Flex>
        )}
        <Flex>
          <Flex.Item span={8}>
            <Typography.Text type="secondary">
              {t('Label_OrderTime')}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item span={18}>
            <TypographyDateTime
              value={bookingDetail.OrderTime}
            ></TypographyDateTime>
          </Flex.Item>
        </Flex>
        <Flex>
          <Flex.Item span={8}>
            <Typography.Text type="secondary">
              {t('Label_BookingNumber')}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item span={18}>{bookingDetail.BookingNumber}</Flex.Item>
        </Flex>
      </Space>
    </>
  );
}
