class _EMPTY_ARRAY_CLASS {}

export class ObjectHelper {
  /**
   * @deprecated use clone for more accurate and more efficient operation
   * @param obj
   */
  static deepCopy<T>(obj: T): T {
    return <T>JSON.parse(JSON.stringify(obj), this.dateReviver);
  }

  static toArray<T>(value: T | T[]): T[] {
    if (Array.isArray(value)) {
      return value;
    }

    if (value === undefined || value === null) {
      return [];
    } else {
      return [value];
    }
  }

  private static dateReviver(key, value) {
    let result;
    if (typeof value === 'string') {
      result =
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(
          value
        );
      if (result) {
        return new Date(
          Date.UTC(
            +result[1],
            +result[2] - 1,
            +result[3],
            +result[4],
            +result[5],
            +result[6]
          )
        );
      }
    }
    return value;
  }

  static assign(...args) {
    return args.reduce((item1, item2) => {
      if (!item2) {
        return item1;
      }
      for (const key in item2) {
        if (typeof item2[key] !== 'undefined' || item2[key] !== null) {
          item1[key] = item2[key];
        }
      }
      return item1;
    });
  }

  static getStructor(obj: any) {
    return Object.keys(obj).map((key) => {
      const value = obj[key];
      const result = {
        name: key,
        value: value,
        type: '',
      };
      if (Array.isArray(value)) {
        result.type = 'array';
      } else {
        result.type = typeof value;
      }
    });
  }

  //#region clone
  /**
   * clone for an existing object (default mode is deep clone)
   * @param o
   * @param deep
   */
  static clone<T>(o: T, deep = true): T {
    if (ObjectHelper.isNotAnObject(o)) {
      return o;
    }
    if (!deep) {
      if (Array.isArray(o)) {
        const cloned: any = o.map((i) => ObjectHelper.clone(i, false));
        return cloned;
      }
      if (o instanceof Date) {
        return new Date(o) as any;
      }
      return Object.assign({}, o);
    }
    return ObjectHelper.deepClone(o);
  }

  private static deepClone<T>(o: T): T {
    if (ObjectHelper.isNotAnObject(o)) {
      return o;
    } else if (Array.isArray(o)) {
      // deep clone app
      return o.map((i) => ObjectHelper.deepClone(i)) as any;
    } else if (o instanceof Date) {
      return new Date(o) as any;
    } else {
      // o is an object
      const cloned: any = {};
      for (const key in o) {
        // eslint-disable-next-line no-prototype-builtins,@typescript-eslint/ban-types
        if ((o as Object).hasOwnProperty(key)) {
          const val = o[key];
          const clonedVal = ObjectHelper.deepClone(val);
          cloned[key] = clonedVal;
        }
      }
      return cloned;
    }
  }

  private static isNotAnObject(o: any) {
    return typeof o !== 'object' || o === null;
  }
  //#endregion

  //#region diff
  /**
   * Calculate the differences between two objects and return the change set
   * @param a
   * @param b
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  static diff<T extends Object>(
    a: T,
    b: T
  ): {
    path: string;
    a: any;
    b: any;
  }[] {
    if (a === b) {
      return [];
    }
    const aIsNullOrUndefined = a === undefined || a === null;
    const bIsNullOrUndefined = b === undefined || b === null;
    if (!aIsNullOrUndefined && !bIsNullOrUndefined) {
      const typeofa: string = typeof a;
      if (typeofa !== typeof b) {
        throw new Error(`cannot execute diff for different types of object`);
      }
      if (typeofa !== 'object' || a instanceof Date) {
        throw new Error(`cannot execute diff for non-object values`);
      }
    }
    const aFlattened = ObjectHelper.flatten(ObjectHelper.deepClone(a));
    const bFlattened = ObjectHelper.flatten(ObjectHelper.deepClone(b));
    const bItemByPath = bFlattened.reduce<{
      [path: string]: { path: string; val: any };
    }>((d, v) => {
      d[v.path] = v;
      return d;
    }, {});
    const diffResult: {
      path: string;
      a: any;
      b: any;
    }[] = [];
    aFlattened.forEach((aItem) => {
      const bItem = bItemByPath[aItem.path];
      if (!bItem) {
        diffResult.push({
          path: aItem.path,
          a: aItem.val,
          b: undefined,
        });
      } else {
        let isTheSame = false;
        if (bItem.val instanceof Date && aItem.val instanceof Date) {
          isTheSame = bItem.val.getTime() === aItem.val.getTime();
        } else if (
          (bItem.val instanceof _EMPTY_ARRAY_CLASS &&
            aItem.val instanceof _EMPTY_ARRAY_CLASS) ||
          bItem.val === aItem.val
        ) {
          isTheSame = true;
        }
        if (!isTheSame) {
          diffResult.push({
            path: aItem.path,
            a: aItem.val,
            b: bItem.val,
          });
        }
        delete bItemByPath[aItem.path];
      }
    });
    Object.values(bItemByPath).forEach((bItem) => {
      diffResult.push({
        path: bItem.path,
        a: undefined,
        b: bItem.val,
      });
    });
    return diffResult;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private static flatten(
    o: object,
    base = '',
    ctx: { path: string; val: any }[] = []
  ): { path: string; val: any }[] {
    if (ObjectHelper.isNotAnObject(o)) {
      ctx.push({ path: base, val: o });
    } else if (Array.isArray(o)) {
      if (o.length === 0) {
        ctx.push({ path: base, val: new _EMPTY_ARRAY_CLASS() });
      } else {
        base = ObjectHelper.getObjectPathPrefix(base);
        o.forEach((val, idx) => {
          ObjectHelper.flatten(val, `${base}-:[${idx}]`, ctx);
        });
      }
    } else if (o instanceof Date) {
      ctx.push({ path: base, val: o });
    } else {
      base = ObjectHelper.getObjectPathPrefix(base);
      for (const key in o) {
        // eslint-disable-next-line no-prototype-builtins
        if (o.hasOwnProperty(key)) {
          const val = (o as any)[key];
          ObjectHelper.flatten(val, `${base}${key}`, ctx);
        }
      }
    }
    return ctx;
  }

  private static getObjectPathPrefix(base: string) {
    if (base === '') {
      return '';
    }
    return base + '.';
  }
  //#endregion

  static isKeysEqual<T = any>(a: T, b: T, compareKeys: (keyof T)[]) {
    return !compareKeys.some((key) => a[key] !== b[key]);
  }
}
