import {
  AppConfigContext,
  AppConfigModel,
  AppContextInfoContext,
  CountryInfoContext,
  DestinationSearchHistoryContext,
  QueryParamsContext,
  useAppContext,
  useCountryInfoList,
  useDestinationSearchHistory,
  useExceptionListener,
  useQueryParams,
} from '@b2c/hooks';
import { DeepLinkQueryParams } from '@b2c/services/common';
import { PropsWithChildren, useEffect } from 'react';
import { LocaleProvider } from './locale-provider';

export function ContextProviders(
  props: PropsWithChildren<{ appConfig: AppConfigModel; defaultMessage: any }>
) {
  const { loadAppContext, appContext } = useAppContext(
    props.appConfig.defaultLocale,
    props.appConfig.defaultCurrency
  );
  const destinationSearchHistory = useDestinationSearchHistory();

  const countryInfoList = useCountryInfoList(appContext?.locale);

  useEffect(() => {
    loadAppContext();
  }, []);

  useExceptionListener();

  const locale = appContext?.locale || props.appConfig.defaultLocale;

  const { queryParams, updateQueryParams } = useQueryParams();
  const deeplinkQueryParams = queryParams as DeepLinkQueryParams;
  const showLoginGuide =
    !deeplinkQueryParams.sor_id || deeplinkQueryParams.sor_ms === '1';

  let result = props.children;
  result = (
    <DestinationSearchHistoryContext.Provider value={destinationSearchHistory}>
      {result}
    </DestinationSearchHistoryContext.Provider>
  );
  result = (
    <LocaleProvider locale={locale} defaultMessage={props.defaultMessage}>
      {result}
    </LocaleProvider>
  );
  result = (
    <QueryParamsContext.Provider
      value={{ queryParams, updateQueryParams, showLoginGuide }}
    >
      {result}
    </QueryParamsContext.Provider>
  );
  result = (
    <CountryInfoContext.Provider
      value={{
        countryInfoList,
      }}
    >
      {result}
    </CountryInfoContext.Provider>
  );
  result = (
    <AppContextInfoContext.Provider
      value={{
        appContext,
        loadAppContext,
      }}
    >
      {result}
    </AppContextInfoContext.Provider>
  );

  result = (
    <AppConfigContext.Provider value={props.appConfig}>
      {result}
    </AppConfigContext.Provider>
  );

  return result;
}
