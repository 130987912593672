import { StarFillIcon } from '@b2c/icons';
import { FunctionComponent } from 'react';

export interface IRatingStarProps {
  value: number;
}

export const RatingStar: FunctionComponent<IRatingStarProps> = (props) => {
  return (
    <span
      style={{ color: 'var(--mu-rating-star-color)', whiteSpace: 'nowrap' }}
    >
      {new Array(Math.floor(props.value)).fill('').map((_, index) => (
        <StarFillIcon key={index} style={{ marginRight: '3px' }} />
      ))}
    </span>
  );
};
