// import { SigningHelper } from './signing.helper';
import { HttpService } from '../http';
import { FalconContext } from './falcon-context';

export class ApplicationContextService {
  /**
   * 获取应用上下文
   *
   * @returns
   * @memberof ApplicationContextService
   */
  static getContext(
    options?: { Currency: string; LanguageCode: string },
    contextHandler?: (ctx: FalconContext.AppContextInfo) => void
  ) {
    return HttpService.get<FalconContext.AppContextInfo>(
      '/Config/AppContext/Get',
      options
      // {
      //   cache: true,
      //   ttl: 10 * 60,
      // }
    ).then((ctx) => {
      ctx.__responseTime__ = new Date();
      if (contextHandler) {
        contextHandler(ctx);
      }
      return ctx;
    });
  }

  static establishSecureTunnel(
    options: FalconContext.SecurityContextEstablishOption
  ) {
    return HttpService.post<FalconContext.SecurityContextEstablishResult>(
      '/Config/SecureContext/Establish',
      options
    );
  }
}
