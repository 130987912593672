import { FunctionComponent } from 'react';

export interface SVGIconProps {
  component: FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export function SVGIcon({
  component: IconElement,
  ...restProps
}: SVGIconProps & React.SVGProps<SVGSVGElement>) {
  return <IconElement width="1em" height="1em" {...restProps} />;
}

export default SVGIcon;
