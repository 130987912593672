import { CoreCommon } from '@b2c/core';

export interface PayChannelViewModel {
  type: CoreCommon.PayChannelTypeEnum;
  name: string;
  icon: string;
}

export interface WechatPayChannelViewModel extends PayChannelViewModel {
  currency?: string;
  balance?: number;
}

export const PAY_CHANNEL_LIST: PayChannelViewModel[] = [
  {
    type: CoreCommon.PayChannelTypeEnum.Nuvei,
    name: 'Nuvei',
    icon: 'Nuvei',
  },
];
