import { StringHelper } from '../common';
import { getBankCardNumberValidationList } from '../decorator/bank-card-number-validation';
import { getValidateOptions } from './validate';
import {
  PatternConfigExtractInfo,
  PatternConfigType,
  ValidateError,
  ValidateErrorType,
  ValidateOptions,
} from './validate.models';

export class ValidateHelper {
  /**
   * 只能使用英文字母（a~z，不区分大小写）、数字（0~9）以及连接符（-）, 连字符不能放在开头或结尾
   * @description Domain reg exp of validate helper
   */
  static readonly domainRegExp =
    /^([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9}$/;
  static readonly EmailRegExp =
    /^([0-9a-zA-Z]([\S]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
  static readonly PhoneRegExp = /^\+?[\d\s-]+$/;
  static readonly UserNameRegExp = /^[A-Za-z\s]+$/;
  static readonly verificationCodeRegExp = /^[\d]{1,}$/;

  static isUserName(value: string) {
    if (!value) {
      return false;
    }
    return this.UserNameRegExp.test(value.trim());
  }

  static isEmail(value: string) {
    if (!value) {
      return false;
    }
    return this.EmailRegExp.test(value.trim());
  }

  static hasWhiteSpace(value: string) {
    if (!value) {
      return false;
    }
    return /\s/.test(value?.trim());
  }

  static isPhone(value: string) {
    value = value?.trim();
    if (!value) {
      return false;
    }
    if (!this.PhoneRegExp.test(value)) {
      return false;
    }
    const digitalLen = value.replace(/[^\d]/g, '').length;
    if (digitalLen < 7 || digitalLen > 20) {
      return false;
    }
    return true;
  }

  static isVerificationCode(value: string) {
    value = value?.trim();

    if (!value) {
      return false;
    }
    if (!this.verificationCodeRegExp.test(value)) {
      return false;
    }
    return true;
  }

  static isCreditCardNumber(value = '') {
    if (/[^0-9-\s]+/.test(value)) return false;
    // The Luhn Algorithm. It's so pretty.
    let nCheck = 0,
      // nDigit = 0,
      bEven = false;
    value = value.replace(/\D/g, '');

    for (let n = value.length - 1; n >= 0; n--) {
      const cDigit = value.charAt(n);
      let nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 == 0;
  }

  /**
   * 校验持卡人名，ps:这里抄的是nuvei的校验逻辑
   * @param value
   * @returns boolean
   */
  static isCreditCardHolderName(value = '') {
    const matchResult = value.match(/[\^_~\\/<>[\]{}]+/g);
    value = value.replace(/[\s]/g, '');
    if (!matchResult) {
      if (value.match(/[\d]{4,}/) || value.match(/^[0-9]{1,3}$/)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  // static isCVV(value = '') {
  //   return /^\d{3}$/.test(value.trim());
  // }

  static checkValidaty(target: object): ValidateError {
    for (const targetKey of Object.keys(target)) {
      const validateOptions: ValidateOptions | boolean = getValidateOptions(
        target,
        targetKey
      );

      if (!validateOptions) {
        continue;
      }

      const targetValue = target[targetKey];
      let error: ValidateError = null;
      if (validateOptions === true) {
        error = this.checkValidaty(targetValue);
        if (error) {
          error.errorPath.unshift(targetKey);
          return error;
        }
      } else {
        error = this._checkValidaty(
          targetValue,
          validateOptions,
          targetKey,
          target
        );
        if (error) {
          return error;
        }
      }
    }

    return null;
  }

  static validate(val: string | boolean, options: ValidateOptions) {
    return this._checkValidaty(val, options);
  }

  static getCardIssuer(cardNumber: string) {
    if (!cardNumber) {
      return null;
    }
    const configList = getBankCardNumberValidationList();

    cardNumber = cardNumber.replaceAll(' ', '');
    const matchedConfig = configList.find((config) => {
      return config.startsWith.some((item) => {
        if (typeof item === 'string' && cardNumber.startsWith(item)) {
          return true;
        }
        if (Array.isArray(item)) {
          const startValue = parseInt(
            cardNumber.slice(0, (item[0] + '').length),
            0
          );
          if (startValue >= item[0] && startValue <= item[1]) {
            return true;
          }
        }
      });
    });
    return matchedConfig?.value;
  }

  private static _checkValidaty(
    targetValue: string | boolean,
    validateOptions: ValidateOptions,
    targetKey = '',
    root?: object
  ): ValidateError {
    if (typeof targetValue === 'string') {
      targetValue = targetValue.trim();
    }

    if (
      validateOptions.required &&
      validateOptions.required[0] &&
      !targetValue
    ) {
      return {
        errorType: ValidateErrorType.required,
        errorMessage: validateOptions.required[1] || `${targetKey}不能为空`,
        errorPath: [targetKey],
      };
    }

    if (
      validateOptions.requireTrue &&
      validateOptions.requireTrue[0] &&
      targetValue !== true
    ) {
      return {
        errorType: ValidateErrorType.requireTrue,
        errorMessage: validateOptions.requireTrue[1] || `${targetKey}必须选中`,
        errorPath: [targetKey],
      };
    }

    if (!targetValue) {
      return null;
    }

    targetValue = String(targetValue);

    if (
      validateOptions.maxLength &&
      validateOptions.maxLength[0] &&
      targetValue.length > validateOptions.maxLength[0]
    ) {
      return {
        errorType: ValidateErrorType.maxLength,
        errorMessage:
          validateOptions.maxLength[1] ||
          `${targetKey}长度不能超过${validateOptions.maxLength[0]}`,
        errorPath: [targetKey],
      };
    }
    if (
      validateOptions.minLength &&
      validateOptions.minLength[0] &&
      targetValue.length < validateOptions.minLength[0]
    ) {
      return {
        errorType: ValidateErrorType.minLength,
        errorMessage:
          validateOptions.minLength[1] ||
          `${targetKey}长度不能小于${validateOptions.minLength[0]}`,
        errorPath: [targetKey],
      };
    }

    if (validateOptions.pattern !== undefined) {
      const rule = this.extractPatternConfigInfo(validateOptions.pattern);
      const result = this.validatePattern(targetValue, targetKey, rule);
      if (result != null) {
        return result;
      }
    }

    if (validateOptions.patterns?.length > 0) {
      for (let i = 0; i < validateOptions.patterns.length; i++) {
        const rule = this.extractPatternConfigInfo(validateOptions.patterns[i]);
        const result = this.validatePattern(targetValue, targetKey, rule);
        if (result != null) {
          return result;
        }
      }
    }
    if (
      validateOptions.email &&
      validateOptions.email[0] &&
      !this.isEmail(targetValue)
    ) {
      return {
        errorType: ValidateErrorType.email,
        errorMessage: validateOptions.email[1] || '邮箱格式错误',
        errorPath: [targetKey],
      };
    }
    if (
      validateOptions.noSpace &&
      validateOptions.noSpace[0] &&
      targetValue.indexOf(' ') > -1
    ) {
      return {
        errorType: ValidateErrorType.noSpace,
        errorMessage: validateOptions.noSpace[1] || `${targetKey}不能包含空格`,
        errorPath: [targetKey],
      };
    }
    if (validateOptions.maxContinuousStr !== undefined) {
      const config = validateOptions.maxContinuousStr;
      const result = StringHelper.getConsecutiveSubString(
        targetValue,
        config.length + 1,
        config.direction
      );
      if (result !== null) {
        return {
          errorType: ValidateErrorType.maxContinuousStr,
          errorMessage:
            config.errMessage ||
            `${targetKey}不得包含连续${config.length}个相邻字符，当前'${result}'`,
          errorPath: [targetKey],
        };
      }
    }
    if (validateOptions.maxRepeatStr !== undefined) {
      const config = validateOptions.maxRepeatStr;
      const result = StringHelper.getRepeatingSubString(
        targetValue,
        config.length + 1
      );
      if (result !== null) {
        return {
          errorType: ValidateErrorType.maxContinuousStr,
          errorMessage:
            config.errMessage ||
            `${targetKey}不得包含连续${config.length}个相同字符，当前'${result}'`,
          errorPath: [targetKey],
        };
      }
    }
    if (
      root !== undefined &&
      root !== null &&
      validateOptions.compareFieldEquals !== undefined
    ) {
      const paths = validateOptions.compareFieldEquals[0];
      let ref = root;
      for (let i = 0; i < paths.length; i++) {
        ref = root[paths[i]];
        if (ref === undefined) {
          break;
        }
      }
      if (ref !== undefined) {
        const fieldEquals = targetValue === new String(ref).toString();
        if (fieldEquals !== validateOptions.compareFieldEquals[1]) {
          return {
            errorType: ValidateErrorType.compareFieldEquals,
            errorMessage:
              validateOptions.compareFieldEquals[2] ||
              `${targetKey}的值应该与\`${paths.join('.')}\`的值${
                validateOptions.compareFieldEquals[1] ? '相同' : '不相同'
              }`,
            errorPath: [targetKey],
          };
        }
      }
    }
    return null;
  }
  private static validatePattern(
    targetValue: string,
    targetKey: string,
    rule: PatternConfigExtractInfo
  ): ValidateError | null {
    for (let j = 0; j < rule.patterns.length; j++) {
      const pattern = rule.patterns[j];
      if (!pattern.test(targetValue)) {
        return {
          errorType: ValidateErrorType.pattern,
          errorMessage: rule.errMessage || `${targetKey}格式错误`,
          errorPath: [targetKey],
        };
      }
    }
    return null;
  }

  private static extractPatternConfigInfo(
    config: PatternConfigType
  ): PatternConfigExtractInfo {
    const patterns: RegExp[] = [];

    if (Array.isArray(config[0])) {
      config[0].forEach((item) => {
        if (typeof item === 'string') {
          patterns.push(new RegExp(item, 'ig'));
        } else {
          patterns.push(item);
        }
      });
    } else if (typeof config[0] === 'string') {
      patterns.push(new RegExp(config[0], 'ig'));
    } else {
      patterns.push(config[0]);
    }

    return {
      patterns: patterns,
      errMessage: config[1],
    };
  }
}
