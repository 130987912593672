import { FalconBooking } from '@b2c/services/booking';
import {
  AnalyticsHelper,
  DeepLinkQueryParams,
  UrlHelper,
} from '@b2c/services/common';
import { FalconOrdering, HotelOrderService } from '@b2c/services/order';
import { FalconPayment } from '@b2c/services/pay-channel';
import { FalconRate } from '@b2c/services/rate';
import { useState } from 'react';
import { useLocale } from './use-locale';
import { useNavigater } from './use-navigater';
import { useQueryParams } from './use-query-params';

export function useBookingActions(config: {
  urlPrefix?: string;
  onError: (content: string, title?: string) => void;
  onRedirect?: () => void;
  onRatePlanPriceChange?(
    newRatePlanPrice: FalconRate.HotelRatePlanPriceModel
  ): void;
}) {
  const t = useLocale();
  const { navigateReplace } = useNavigater();

  const [creating, setCreating] = useState(false);
  const [paying, setPaying] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const { queryParams } = useQueryParams<DeepLinkQueryParams>();

  const payBooking = function (
    bookingDetail: FalconBooking.BookingInfoBase,
    orderToken: string,
    paymentInfo?: FalconPayment.BankCardPaymentInfo
  ) {
    setPaying(true);
    // todo
    // MessageService.loading();
    const urlPath = UrlHelper.addPrefix('/order/result', config.urlPrefix);

    const orderResultUrlBase = UrlHelper.addParams(window.origin + urlPath, {
      bookingNumber: bookingDetail.BookingNumber,
      token: orderToken,
      _rpt: bookingDetail.PriceSummary.RatePaymentType,
      fclid: queryParams.fclid,
      hotel_id: queryParams.hotel_id,
    });
    const orderResultUrlSuccess = UrlHelper.addParams(orderResultUrlBase, {
      success: true,
    });

    AnalyticsHelper.beginCheckout(bookingDetail);

    HotelOrderService.payBooking(
      bookingDetail,
      orderToken,
      orderResultUrlSuccess,
      orderResultUrlBase,
      paymentInfo
    )
      .then((resp) => {
        switch (resp.MessageCode) {
          case FalconOrdering.MessageCodeEnum.Success:
          case FalconOrdering.MessageCodeEnum.Success_WaitingBookingConfirm:
            navigateReplace(orderResultUrlBase);
            break;
          case FalconOrdering.MessageCodeEnum
            .Success_BookingProcessAlreadyFinished:
            navigateReplace(orderResultUrlBase);
            break;
          case FalconOrdering.MessageCodeEnum
            .Error_RiskControl_HighRiskBehavior:
            navigateReplace(urlPath, {
              code: FalconOrdering.MessageCodeEnum
                .Error_RiskControl_HighRiskBehavior,
            });
            break;
          case FalconOrdering.MessageCodeEnum.Error_PreBook_PriceNotAvailable:
            config.onError(
              t('Error_PriceNotAvailable'),
              t('Title_BookingFailed_WithIndex', { 0: 0 })
            );
            break;
          case FalconOrdering.MessageCodeEnum.Success_ActionRequired:
            {
              const wrapper = document.createElement('div');
              wrapper.innerHTML = resp.ReqiredActionInfo.ThirdPartyFormHtml;
              wrapper.style.display = 'none';
              document.documentElement.append(wrapper);
              wrapper.querySelector('form')?.submit();
              setRedirecting(true);
              config.onRedirect?.call(null);
            }
            break;
          default:
            config.onError(resp.Message);
        }
      })
      .catch((err) => {
        const message = err?.message || t('Error_TryAgainLater');
        config.onError(message, t('Title_BookingFailed_WithIndex', { 0: 1 }));
      })
      .finally(() => {
        // MessageService.clear();
        setPaying(false);
      });
  };

  const createBooking = function (
    orderOption: Partial<FalconOrdering.OrderOption>,
    sessionId: string,
    appId: string,
    paymentInfo: FalconPayment.BankCardPaymentInfo
  ) {
    const urlPath = UrlHelper.addPrefix('/order/result', config.urlPrefix);

    setCreating(true);
    HotelOrderService.createBooking(orderOption, sessionId, appId)
      .then(
        (resp) => {
          switch (resp.MessageCode) {
            case FalconOrdering.MessageCodeEnum
              .Error_RiskControl_HighRiskBehavior:
              navigateReplace(urlPath, {
                code: FalconOrdering.MessageCodeEnum
                  .Error_RiskControl_HighRiskBehavior,
              });
              break;
            case FalconOrdering.MessageCodeEnum.Success_PayChannelNeeded:
              // navigateReplace(urlPath, {
              //   bookingNumber: resp.BookingSnapshot.BookingNumber,
              //   token: resp.BookingSnapshot.BookingToken,
              // });

              history.replaceState(
                null,
                '',
                UrlHelper.addParams(window.origin + urlPath, {
                  bookingNumber: resp.BookingSnapshot.BookingNumber,
                  token: resp.BookingSnapshot.BookingToken,
                  _rpt: resp.BookingSnapshot.PriceSummary.RatePaymentType,
                  fclid: queryParams.fclid,
                  hotel_id: queryParams.hotel_id,
                })
              );
              AnalyticsHelper.createBooking(resp.BookingSnapshot);
              payBooking(
                resp.BookingSnapshot,
                resp.BookingSnapshot.BookingToken,
                paymentInfo
              );
              break;
            case FalconOrdering.MessageCodeEnum.Error_PreBook_PriceNotAvailable:
              config.onError(t('Title_BookingFailed_WithIndex', { 0: 2 }));
              break;
            case FalconOrdering.MessageCodeEnum.Error_PreBook_PriceChanged:
            case FalconOrdering.MessageCodeEnum
              .Error_PreBook_RatePlanInfoChanged:
            case FalconOrdering.MessageCodeEnum
              .Error_PreBook_CancellationChanged:
            case FalconOrdering.MessageCodeEnum.Error_PreBook_OtherInfoChanged:
              config.onRatePlanPriceChange?.call(null, resp.HotelRatePlanPrice);
              config.onError(t('Error_PreBook_OtherInfoChanged'));
              break;
            case FalconOrdering.MessageCodeEnum
              .Error_PreBook_FailedToCreateBooking:
              return Promise.reject(null);
            default:
              config.onError(resp.Message);
          }
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((err) => {
        const message =
          err?.message || err?.Message || t('Error_TryAgainLater');
        config.onError(message, t('Title_BookingFailed_WithIndex', { 0: 3 }));
      })
      .finally(() => {
        setCreating(false);
      });
  };

  return {
    payBooking,
    paying,
    redirecting,
    createBooking,
    creating,
    processing: paying || redirecting || creating,
  };
}
