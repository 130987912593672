import {
  DateRangeModel,
  DateRangeQueryParams,
  DateTimeHelper,
} from '@b2c/services/common';
import { startOfDay } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useQueryParams } from './use-query-params';

const DATE_RANGE_STAYDAY_OFFSET = 1;
function getDefaultDateRange(): DateRangeModel {
  const fromDate = DateTimeHelper.getRelateDate(DATE_RANGE_STAYDAY_OFFSET);
  const toDate = DateTimeHelper.getRelateDate(1, fromDate);
  return {
    From: fromDate,
    To: toDate,
  };
}

export const useDateRange = function () {
  const { queryParams, updateQueryParams } =
    useQueryParams<DateRangeQueryParams>();

  const dateRange = useMemo<DateRangeModel>(() => {
    if (!queryParams.ci || !queryParams.co) {
      return getDefaultDateRange();
    }
    const result = {
      From: DateTimeHelper.getDate(queryParams.ci),
      To: DateTimeHelper.getDate(queryParams.co),
    };
    if (result.From < startOfDay(new Date())) {
      return getDefaultDateRange();
    }
    return result;
  }, [queryParams.ci, queryParams.co]);

  const setDateRange = useCallback((newRange: DateRangeModel) => {
    updateQueryParams<DateRangeQueryParams>({
      ci: DateTimeHelper.getDateString(newRange.From),
      co: DateTimeHelper.getDateString(newRange.To),
    });
  }, []);

  return [dateRange, setDateRange] as [
    DateRangeModel,
    (newRange: DateRangeModel) => void
  ];
};
