import { CoreCommon, CoreSuggestion } from '@b2c/core';
import { GeoCoordinate } from '../geography';
import { HttpService } from '../http';
import { SuggestionSearchHelper } from './suggestion-search-helper';

export class SuggestionService {
  /**
   * 建议搜索
   * @param payloads 搜索条件
   */
  static multiplSearch(
    payloads: CoreSuggestion.SuggestionQueryPayload[],
    signal: AbortSignal
  ) {
    return HttpService.post<
      CoreCommon.PaginationWrapper<CoreSuggestion.SuggestionItem>[]
    >('/StaticContent/Suggestion/MultiSearch', payloads, {
      signal,
    }).then((result) => {
      result.forEach((r) => {
        if (r.Data) {
          r.Data.forEach((x) => {
            this.processSuggestionItem(x);
          });
        }
      });
      return result;
    });
  }

  /**
   * 搜索指定区域
   * @param regionID 区域ID
   */
  static searchRegion(regionID: string) {
    const regionQuery = SuggestionSearchHelper.createSuggestionQueryPayload(
      null,
      SuggestionSearchHelper.REGION_SEARCH_BY_ID_CATEGORIES,
      1,
      [regionID]
    );
    return HttpService.post<
      CoreCommon.PaginationWrapper<CoreSuggestion.SuggestionItem>
    >('/StaticContent/Suggestion/Search', regionQuery).then((result) => {
      if (result.Data) {
        result.Data.forEach((x) => this.processSuggestionItem(x));
      }
      return result.Data.length === 1 ? result.Data[0] : null;
    });
  }

  static serachRegionByHotelId(hotelId: number | string) {
    const regionQuery = SuggestionSearchHelper.createSuggestionQueryPayload(
      null,
      SuggestionSearchHelper.HOTEL_SEARCH_CATEGORIES,
      1,
      [hotelId]
    );
    return HttpService.post<
      CoreCommon.PaginationWrapper<CoreSuggestion.SuggestionItem>
    >('/StaticContent/Suggestion/Search', regionQuery).then((result) => {
      if (result.Data) {
        result.Data.forEach((x) => this.processSuggestionItem(x));
      }
      return result.Data.length === 1 ? result.Data[0] : null;
    });
  }

  private static processSuggestionItem(item: CoreSuggestion.SuggestionItem) {
    if (item) {
      item.ID = item.IDString;
      if (item.Metadata) {
        if (item.Metadata.Location) {
          item.Metadata.Location = new GeoCoordinate(
            item.Metadata.Location.Latitude,
            item.Metadata.Location.Longitude
          );
        }
      }
    }
  }
}
