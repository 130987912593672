// import ReactMarkdown from 'react-markdown';
import { lazy } from 'react';
// import rehypeRaw from 'rehype-raw';
// import remarkGfm from 'remark-gfm';

export const MarkdownLazy = lazy(() => {
  return Promise.all([
    import('react-markdown').then((i) => i.default),
    import('remark-gfm').then((i) => i.default),
    import('rehype-raw').then((i) => i.default),
  ]).then(([RMarkdown, remarkGfm, rehypeRaw]) => ({
    default: (props: { content: string }) => (
      <RMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
        {props.content}
      </RMarkdown>
    ),
  }));
});

export default MarkdownLazy;
