import { CoreCommon } from '@b2c/core';
import produce from 'immer';
import { FalconBooking } from '../booking';
import { HotelBookingHelper } from '../booking/hotel-booking.helper';
import { HttpService } from '../http';
import { FalconPayment } from '../pay-channel';
import { FalconOrdering } from './falcon-order.d';

export class HotelOrderService {
  static createBooking(
    option: Partial<FalconOrdering.OrderOption>,
    sessionId: string,
    appId: string
  ) {
    const headers = {};

    if (sessionId) {
      headers['fcn-ss'] = sessionId;
    }

    if (appId) {
      headers['fcn-ext-app'] = appId;
    }

    option = produce(option, (draft) => {
      const contactInfo = draft.Contact;
      if (contactInfo._PhonePrefix) {
        contactInfo.Phone = `+${contactInfo._PhonePrefix.CallingCode}-${contactInfo.Phone}`;
      }
    });

    return HttpService.post<FalconOrdering.ResultModel>(
      '/Ordering/Order/Submit',
      option,
      { headers }
    );
  }

  static payBooking(
    bookingInfo: FalconBooking.BookingInfoBase,
    bookingOrderToken: string,
    returnUrl: string,
    backUrl?: string,
    paymentInfo?: FalconPayment.BankCardPaymentInfo
  ) {
    let payChannelType = CoreCommon.PayChannelTypeEnum.Nuvei;

    if (
      bookingInfo.StaticInfo.RatePaymentType !==
      FalconPayment.RatePaymentTypeEnum.PrePay
    ) {
      payChannelType = CoreCommon.PayChannelTypeEnum.PropertyCollect;
    }

    const headers = {
      'fcn-ss': bookingInfo.OrderSessionID,
    };

    if (
      bookingInfo.StaticInfo.RatePaymentType ===
      FalconPayment.RatePaymentTypeEnum.PayAtPropertyWithCreditCard
    ) {
      const paymentInfoPayload = produce(paymentInfo, (draft) => {
        if (typeof draft.ExpireDate === 'string') {
          if (/^\d{2}\/\d{2}$/.test(draft.ExpireDate)) {
            const [month, year] = draft.ExpireDate.split('/');
            draft.ExpireDate = `20${year}-${month}-01`;
          }
        }

        const DefautPaymentInfo: Partial<FalconPayment.BankCardPaymentInfo> = {
          BankCardType: FalconPayment.BankCardTypeEnum.CreditCard,
        };

        Object.keys(DefautPaymentInfo).forEach((key) => {
          if (typeof draft[key] === 'undefined') {
            draft[key] = DefautPaymentInfo[key];
          }
        });
        Object.keys(draft).forEach((key) => {
          if (typeof draft[key] === 'string') {
            draft[key] = draft[key].trim();
          }
        });
        draft.CardHolderName = encodeURIComponent(draft.CardHolderName);
        draft.CardNumber = draft.CardNumber.replaceAll(' ', '');
      });
      headers['fcn-sdata'] = JSON.stringify(paymentInfoPayload);
    }

    const payload: Partial<FalconOrdering.OrderOption> = {
      BookingNumber: bookingInfo.BookingNumber,
      BookingToken: bookingOrderToken,
      PaymentInfo: {
        PayChannel: payChannelType,
        PaymentReturnUrl: returnUrl,
        PaymentBackUrl: backUrl || location.href,
        Currency: bookingInfo.PriceSummary.Currency,
        TotalAmount: bookingInfo.PriceSummary.Price,
      },
    };

    return HttpService.post<FalconOrdering.ResultModel>(
      '/Ordering/Order/Submit',
      payload,
      { headers }
    );
  }

  static checkOrder(option: FalconOrdering.OrderStatusCheckOption) {
    return HttpService.post<FalconBooking.BookingDetailModel>(
      '/Ordering/Order/CheckOrder',
      option
    ).then((raw) => {
      HotelBookingHelper.normalize(raw);
      return raw;
    });
  }
}
