import { ChannelPriceInfoQueryParams } from '@b2c/services/common';
import { FalconRate } from '@b2c/services/rate';
import { useCallback, useMemo } from 'react';
import { useQueryParams } from './use-query-params';

export function useChannelPriceInfo() {
  const { queryParams, updateQueryParams } =
    useQueryParams<ChannelPriceInfoQueryParams>();
  const info: FalconRate.ThirdPartyRateInfo = useMemo(() => {
    if (
      queryParams.sor_id &&
      queryParams.sor_c &&
      queryParams.sor_p &&
      queryParams.sor_rt
    ) {
      return {
        Source: queryParams.sor_id,
        SourceSite: queryParams.sor_st,
        RoomTypeID: queryParams.sor_rt,
        RatePlanTypeID: queryParams.sor_rp,
        Currency: queryParams.sor_c,
        TotalPrice: queryParams.sor_p,
        TotalIncludedFee: queryParams.sor_p0,
        TotalExcludedFee: queryParams.sor_p1,
      };
    }
    return null;
  }, [queryParams]);

  const setChannelPriceInfo = useCallback(
    (priceInfo: FalconRate.ThirdPartyRateInfo) => {
      updateQueryParams<ChannelPriceInfoQueryParams>({
        sor: priceInfo?.Source,
        sor_id: priceInfo?.Source,
        sor_c: priceInfo?.Currency,
        sor_p: priceInfo?.TotalPrice,
        sor_rt: priceInfo?.RoomTypeID,
        sor_rp: priceInfo?.RatePlanTypeID,
        sor_p0: priceInfo?.TotalIncludedFee,
        sor_p1: priceInfo?.TotalExcludedFee,
      });
    },
    []
  );

  return <
    [
      FalconRate.ThirdPartyRateInfo,
      (priceInfo: FalconRate.ThirdPartyRateInfo) => void
    ]
  >[info, setChannelPriceInfo];
}
