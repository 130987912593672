import {
  useLocale,
  useRatePlanPriceDesc,
  useReferencePriceInfo,
} from '@b2c/hooks';
import { SimplifiedHotelDetail } from '@b2c/services/hotel';
import { SimplifiedRatePlanPrice } from '@b2c/services/rateplan';
import { QuestionO } from '@react-vant/icons';
import { Divider, Flex, Space, Typography } from 'react-vant';
import { ModalService } from '../modal.service';
import { TypographyPrice } from '../typography-price';

export function RatePlanPrice(props: {
  ratePlanPrice: SimplifiedRatePlanPrice;
  hotelDetail?: SimplifiedHotelDetail;
}) {
  const t = useLocale();
  const ratePlanPrice = props.ratePlanPrice;

  const ratePlanPriceDesc = useRatePlanPriceDesc(props.ratePlanPrice);
  const referencePriceInfo = useReferencePriceInfo(ratePlanPrice?.priceSummary);

  return (
    <Space
      block
      direction="vertical"
      gap="0px"
      divider={<Divider dashed></Divider>}
    >
      <div>
        <div>
          <Flex justify="between">
            <Flex.Item>
              <Typography.Text strong>{t('Label_RoomPrice')}</Typography.Text>
              <Typography.Text type="secondary" size="sm">
                &nbsp; (
                {t('Text_RoomWithCount', {
                  count: ratePlanPrice.roomCount,
                })}{' '}
                *{' '}
                {t('Text_NightWithCount', {
                  nightCount: ratePlanPrice.nightCount,
                })}
                )
              </Typography.Text>
            </Flex.Item>
            <Flex.Item style={{ textAlign: 'right' }}>
              <TypographyPrice
                value={ratePlanPrice.priceSummary}
                priceKey="Price"
                strong
              ></TypographyPrice>
            </Flex.Item>
          </Flex>
          {ratePlanPrice.nightCount > 1 && (
            <Flex justify="end">
              <Typography.Text type="secondary" size="sm">
                {t('Text_PricePerNight', {
                  price: t.formatPrice({
                    Currency: ratePlanPrice.priceSummary.Currency,
                    Price:
                      ratePlanPrice.priceSummary.PriceBeforeTax /
                      ratePlanPrice.nightCount,
                  }),
                })}
              </Typography.Text>
            </Flex>
          )}
        </div>
        {ratePlanPrice.priceSummary.IncludedFees?.map((item, index) => (
          <Flex justify="between" key={index}>
            <Flex.Item>
              <Typography.Text>{item.FeeChargeType.Name}</Typography.Text>
            </Flex.Item>
            <Flex.Item>
              <TypographyPrice value={item} priceKey="Amount"></TypographyPrice>
            </Flex.Item>
          </Flex>
        ))}
        {ratePlanPrice.priceSummary.TaxAndFee > 0 && (
          <Flex justify="between">
            <Flex.Item>
              <Typography.Text>{t('Label_TaxsAndFees')} </Typography.Text>
            </Flex.Item>
            <Flex.Item>
              <TypographyPrice
                value={ratePlanPrice.priceSummary}
                priceKey="TaxAndFee"
              ></TypographyPrice>
            </Flex.Item>
          </Flex>
        )}
      </div>
      {ratePlanPrice.totalExcludedCharges && (
        <div>
          <Flex justify="between">
            <Typography.Text
              strong
              onClick={() => ModalService.alert(t('ToolTip_ExcludedCharges'))}
            >
              <Flex align="center">
                {t('Label_ExcludedCharges')}&nbsp;
                <QuestionO />
              </Flex>
            </Typography.Text>
            <div style={{ textAlign: 'right' }}>
              <TypographyPrice
                value={ratePlanPrice.totalExcludedCharges}
                priceKey="Amount"
                strong
              ></TypographyPrice>
            </div>
          </Flex>
          {ratePlanPrice.priceSummary.ExcludedFees.map((item, index) => (
            <Flex key={index} justify="between" align="center">
              <span>{item.FeeChargeType.Name}</span>
              <span>
                <TypographyPrice
                  value={item}
                  priceKey="Amount"
                ></TypographyPrice>
              </span>
            </Flex>
          ))}
        </div>
      )}
      <div>
        <Flex justify="between">
          <Flex.Item>
            <Typography.Text strong>{t('Label_TotalPrice')}</Typography.Text>
          </Flex.Item>
          <Flex.Item style={{ textAlign: 'right' }}>
            {ratePlanPrice.excludedFeeCurrencyNotMatchUserCurrency ? (
              <>
                <TypographyPrice
                  value={ratePlanPrice.priceSummary}
                  strong
                ></TypographyPrice>{' '}
                &nbsp;+&nbsp;
                <TypographyPrice
                  value={ratePlanPrice.totalExcludedCharges}
                  priceKey="Amount"
                  strong
                ></TypographyPrice>
              </>
            ) : (
              <TypographyPrice
                value={{
                  Price: ratePlanPrice.totalPrice,
                  Currency: ratePlanPrice.priceSummary.Currency,
                }}
                strong
              ></TypographyPrice>
            )}

            {referencePriceInfo && (
              <div style={{ marginTop: '-4px' }}>
                &asymp;&nbsp;
                <Typography.Text>
                  <TypographyPrice value={referencePriceInfo}></TypographyPrice>
                </Typography.Text>
              </div>
            )}
          </Flex.Item>
        </Flex>

        {ratePlanPrice.showCurrencyExchagneDesc && (
          <div>{ratePlanPriceDesc.currencyExchangeDesc}</div>
        )}
      </div>
    </Space>
  );
}
