import { FunctionComponent, useEffect, useState } from 'react';
// import './nationality-picker.scss';
import { useLocale, useNationalityInfoList } from '@b2c/hooks';
import { NationalityInfoModel } from '@b2c/services/nation';
import { Cell, Empty, IndexBar, Search, Sticky } from 'react-vant';

export const NationalityPicker: FunctionComponent<{
  value?: string;
  onSelect(value: NationalityInfoModel): void;
  onCancel(): void;
}> = (props) => {
  const [keyword, setKeyword] = useState<string>('');

  const [resultList, setResultList] = useState<NationalityInfoModel[]>([]);
  const t = useLocale();

  const { nationalityIndex, searchNationality } = useNationalityInfoList();

  useEffect(() => {
    updateKeyword('');
  }, []);

  function updateKeyword(rawKeyword: string) {
    const currentKeyword = (rawKeyword || '').trim();
    setKeyword(currentKeyword);
    setResultList(!currentKeyword ? [] : searchNationality(currentKeyword));
  }

  function select(nationalty: NationalityInfoModel) {
    updateKeyword('');
    props.onSelect(nationalty);
  }

  return (
    <>
      <Sticky position="top">
        <Search
          placeholder={t('Placeholder_NationalitySearch')}
          clearable
          showAction
          onCancel={props.onCancel}
          onChange={updateKeyword}
        ></Search>
      </Sticky>
      <div style={{ backgroundColor: 'var(--mu-background-color)' }}>
        {keyword ? (
          <Cell.Group>
            {resultList.map((nation) => (
              <Cell
                key={nation.code}
                onClick={select.bind(this, nation)}
                title={nation.code + '  ' + nation.name}
              ></Cell>
            ))}
            {resultList.length < 1 && (
              <Empty
                className="nationality-item"
                description={t('Text_NationalityNotFount')}
              ></Empty>
            )}
          </Cell.Group>
        ) : (
          <IndexBar sticky={true} stickyOffsetTop={50}>
            {nationalityIndex.map((dir) => (
              <div key={dir.indexChar}>
                <IndexBar.Anchor index={dir.indexChar} />
                {dir.items.map((nation) => (
                  <Cell
                    title={nation.code + '  ' + nation.name}
                    key={nation.code}
                    onClick={() => select(nation)}
                  ></Cell>
                ))}
              </div>
            ))}
          </IndexBar>
        )}
      </div>
    </>
  );
};
