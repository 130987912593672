import { CoreCommon } from '@b2c/core';
import { HttpService } from '../http';
import { FalconBooking } from './falcon-booking.d';
import { HotelBookingHelper } from './hotel-booking.helper';

export class HotelBookingService {
  /**
   * 订单查询接口
   * @param option 搜索参数
   */
  static search(option: Partial<FalconBooking.SearchOption>) {
    return HttpService.post<
      CoreCommon.PaginationWrapper<FalconBooking.BookingDetailModel>
    >('/Booking/BookingData/Search', option).then((raw) => {
      raw.Data.map((item) => {
        HotelBookingHelper.normalize(item);
      });
      return raw;
    });
  }

  // /**
  //  * 检查关联单订单号
  //  *
  //  * @param {string} clientReference 客户订单号
  //  * @returns {HttpObservable<boolean>}
  //  * @memberof BookingService
  //  */
  // checkRelatedClientReference(clientReference: string) {
  //   return HttpService.get<boolean>(
  //     '/HotelBookingApi/CheckRelatedClientReference',
  //     {
  //       clientReference,
  //     },
  //   );
  // }

  /**
   * 获取订单详情
   * @param bookingNumber 订单ID
   */
  static getBookingDetail(bookingNumber: string, token: string) {
    return HttpService.post<FalconBooking.BookingDetailModel>(
      '/Booking/BookingData/Get',
      {
        BookingNumber: bookingNumber,
        Token: token,
      }
    ).then((raw) => {
      HotelBookingHelper.normalize(raw);
      return raw;
    });
  }

  /**
   * 取消订单（信息获取）
   * @param bookingNumber
   */
  static preCancel(bookingNumber: string, token: string) {
    return HttpService.post<FalconBooking.PreCancelResult>(
      '/Ordering/Order/PreCancel',
      {
        bookingNumber,
        token,
      },
      {
        postResultProcess(result, resp) {
          result.__SessionID = resp.headers.get('fcn-ss');
        },
      }
    );
  }

  /**
   * 确认取消订单（会触发取消逻辑）
   */
  static cancelConfirm(
    preCancelResult: FalconBooking.PreCancelResult,
    reason: string,
    token: string
  ) {
    return HttpService.post<FalconBooking.CancelResult>(
      '/Ordering/Order/Cancel',
      {
        BookingNumber: preCancelResult.BookingNumber,
        CancelConfirmID: preCancelResult.CancelConfirmID,
        Reason: reason,
        Token: token,
      },
      {
        headers: {
          'fcn-ss': preCancelResult.__SessionID,
        },
      }
    );
  }
}
