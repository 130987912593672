import { Toast } from 'react-vant';

export class MessageService {
  static success(message: string) {
    return Toast.success({
      message,
    });
  }
  static info(message: string) {
    return Toast.info({
      message,
    });
  }
  static error(message: string) {
    return Toast.fail({
      message,
    });
  }
  static loading(message = 'Loading...', mask = true) {
    return Toast.loading({
      message,
      closeOnClickOverlay: !mask,
      duration: 0,
    });
  }
}
