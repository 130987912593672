import { debounce } from 'lodash';
import { useEffect } from 'react';
export function usePageScroll(
  callback: (option: { scrollTop: number; isUp: boolean }) => void
) {
  useEffect(() => {
    let _scrollTop = 0;
    const onscroll = debounce(function () {
      const scrollTop = document.documentElement.scrollTop;
      callback.call(null, {
        scrollTop: scrollTop,
        isUp: _scrollTop < scrollTop,
      });
      _scrollTop = scrollTop;
    }, 300);

    document.addEventListener('scroll', onscroll, { passive: true });

    return function () {
      document.removeEventListener('scroll', onscroll);
    };
  }, []);
}
