import { Display, t } from '@b2c/services/decorator';

export interface CurrencyModel {
  name: string;
  value: string;
}
export class CurrencyEnumModel {
  @Display({ name: t('CurrencyName_AED') }) static AED = 'AED';
  @Display({ name: t('CurrencyName_AUD') }) static AUD = 'AUD';
  @Display({ name: t('CurrencyName_BHD') }) static BHD = 'BHD';
  @Display({ name: t('CurrencyName_BND') }) static BND = 'BND';
  @Display({ name: t('CurrencyName_BRL') }) static BRL = 'BRL';
  @Display({ name: t('CurrencyName_CAD') }) static CAD = 'CAD';
  @Display({ name: t('CurrencyName_CHF') }) static CHF = 'CHF';
  @Display({ name: t('CurrencyName_CNY') }) static CNY = 'CNY';
  @Display({ name: t('CurrencyName_DKK') }) static DKK = 'DKK';
  @Display({ name: t('CurrencyName_EUR') }) static EUR = 'EUR';
  @Display({ name: t('CurrencyName_FJD') }) static FJD = 'FJD';
  @Display({ name: t('CurrencyName_GBP') }) static GBP = 'GBP';
  @Display({ name: t('CurrencyName_HKD') }) static HKD = 'HKD';
  @Display({ name: t('CurrencyName_IDR') }) static IDR = 'IDR';
  @Display({ name: t('CurrencyName_INR') }) static INR = 'INR';
  @Display({ name: t('CurrencyName_JPY') }) static JPY = 'JPY';
  @Display({ name: t('CurrencyName_KRW') }) static KRW = 'KRW';
  @Display({ name: t('CurrencyName_MAD') }) static MAD = 'MAD';
  @Display({ name: t('CurrencyName_MOP') }) static MOP = 'MOP';
  @Display({ name: t('CurrencyName_MYR') }) static MYR = 'MYR';
  @Display({ name: t('CurrencyName_NOK') }) static NOK = 'NOK';
  @Display({ name: t('CurrencyName_NZD') }) static NZD = 'NZD';
  @Display({ name: t('CurrencyName_PHP') }) static PHP = 'PHP';
  @Display({ name: t('CurrencyName_QAR') }) static QAR = 'QAR';
  @Display({ name: t('CurrencyName_RUB') }) static RUB = 'RUB';
  @Display({ name: t('CurrencyName_SCR') }) static SCR = 'SCR';
  @Display({ name: t('CurrencyName_SEK') }) static SEK = 'SEK';
  @Display({ name: t('CurrencyName_SGD') }) static SGD = 'SGD';
  @Display({ name: t('CurrencyName_THB') }) static THB = 'THB';
  @Display({ name: t('CurrencyName_TWD') }) static TWD = 'TWD';
  @Display({ name: t('CurrencyName_USD') }) static USD = 'USD';
  @Display({ name: t('CurrencyName_VND') }) static VND = 'VND';
  @Display({ name: t('CurrencyName_ZAR') }) static ZAR = 'ZAR';
}
