import { CheckOutlined } from '@ant-design/icons';
import { CoreHotelBooking } from '@b2c/core';
import { TOP_COUNTRY_LIST, useCallingCode, useLocale } from '@b2c/hooks';
import { CountryInfoModel } from '@b2c/services/nation';
import classNames from 'classnames';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Cell, Empty, Flex, IndexBar, Popup, Search, Sticky } from 'react-vant';
import styles from './calling-code-picker.module.less';

export function CallingCodePicker(props: {
  value?: CoreHotelBooking.PhonePrefix;
  onSelect(value: CoreHotelBooking.PhonePrefix): void;
  children: (
    countryInfo: CountryInfoModel,
    actions: {
      open: () => void;
    }
  ) => ReactNode;
}) {
  const t = useLocale();

  const [popupVisible, setPopupVisible] = useState(false);

  const [keyword, setKeyword] = useState<string>('');

  const { callingCodeIndex, indexList, callingCodeList } = useCallingCode();

  const [countryInfo, setCountryInfo] = useState<CountryInfoModel>();

  useEffect(() => {
    if (props.value?.CountryCode) {
      const newInfo = callingCodeList.find(
        (item) => item.code === props.value.CountryCode
      );

      setCountryInfo(newInfo);
      return;
    }
    const curCode = props.value?.CallingCode?.replace('+', '');
    if (countryInfo && countryInfo?.callingCode === curCode) {
      return;
    }
    const list = callingCodeList
      .filter((item) => item.callingCode === curCode)
      .sort((a, b) => {
        return TOP_COUNTRY_LIST.includes(b.code) ? 1 : -1;
      });

    setCountryInfo(list[0]);
  }, [props.value, callingCodeList, countryInfo]);

  useEffect(() => {
    updateKeyword('');
  }, []);

  const resultList = useMemo(() => {
    if (!keyword) {
      return null;
    }
    const lcKeyword = keyword.toLocaleLowerCase();
    return callingCodeList.filter((item) => {
      const searchContent = ['+' + item.callingCode, item.name, item.code].join(
        ' '
      );
      return searchContent.toLocaleLowerCase().includes(lcKeyword);
    });
  }, [keyword, callingCodeList]);

  function updateKeyword(rawKeyword: string) {
    const currentKeyword = (rawKeyword || '').trim();
    setKeyword(currentKeyword);
    // setResultList(!currentKeyword ? [] : searchNationality(currentKeyword));
  }

  function select(countryInfo: CountryInfoModel) {
    updateKeyword('');
    props.onSelect({
      CallingCode: countryInfo.callingCode,
      CountryCode: countryInfo.code,
    });
    setPopupVisible(false);
    setCountryInfo(countryInfo);
  }

  function renderCallinCodeItem(
    { code, name, callingCode }: CountryInfoModel,
    checked: boolean
  ) {
    return (
      <Flex justify="between">
        <div>
          <span>{name}</span>

          <span className={styles.code}>+{callingCode}</span>
        </div>
        {checked && (
          <span>
            <CheckOutlined className={styles.icon} />
          </span>
        )}
      </Flex>
    );
  }

  return (
    <>
      {props.children(countryInfo, {
        open: () => {
          setPopupVisible(true);
        },
      })}
      <Popup
        position="bottom"
        style={{ height: '100%' }}
        visible={popupVisible}
      >
        <Sticky position="top">
          <Search
            placeholder={t('Placeholder_CallingCodeSearch')}
            clearable
            showAction
            onCancel={() => setPopupVisible(false)}
            onChange={updateKeyword}
          ></Search>
        </Sticky>
        <div style={{ backgroundColor: 'var(--mu-background-color)' }}>
          {resultList ? (
            <Cell.Group>
              {resultList.map((country) => (
                <Cell
                  className={classNames(styles.cell, {
                    [styles.selected]: country === countryInfo,
                  })}
                  key={country.code}
                  onClick={() => select(country)}
                  title={renderCallinCodeItem(country, country === countryInfo)}
                ></Cell>
              ))}
              {resultList.length < 1 && (
                <Empty
                  className="nationality-item"
                  description={t('Text_CallingCode_NoResult')}
                ></Empty>
              )}
            </Cell.Group>
          ) : (
            <>
              {countryInfo && (
                <Cell.Group title={t('Label_CurrentSelection')}>
                  <Cell
                    style={{ paddingRight: '30px' }}
                    className={classNames(styles.cell, styles.selected)}
                    title={renderCallinCodeItem(countryInfo, true)}
                    onClick={() => select(countryInfo)}
                  ></Cell>
                </Cell.Group>
              )}
              <IndexBar
                indexList={indexList}
                sticky={true}
                stickyOffsetTop={50}
              >
                {callingCodeIndex.map((dir) => (
                  <div key={dir[0]}>
                    <IndexBar.Anchor index={dir[0]} />
                    {dir[1].map((country, index) => (
                      <Cell
                        className={classNames(styles.cell, {
                          [styles.selected]: country === countryInfo,
                        })}
                        key={country.code}
                        title={renderCallinCodeItem(
                          country,
                          country === countryInfo
                        )}
                        // value={}
                        onClick={() => select(country)}
                      ></Cell>
                    ))}
                  </div>
                ))}
              </IndexBar>
            </>
          )}
        </div>
      </Popup>
    </>
  );
}

export default CallingCodePicker;
