import { CoreHotelBooking } from '@b2c/core';
import { useLocale } from '@b2c/hooks';
import { HotelBookingStatusModel } from '@b2c/services/booking';
import { DecoratorDisplayInfo, getDisplayInfo } from '@b2c/services/decorator';
import { useMemo } from 'react';
import { Typography, TypographyType } from 'react-vant';
import { TypographyTextProps } from 'react-vant/es/typography/PropsType';

export function TypographyBookingStatus({
  value,
  contentType,
  children,
  ...respProps
}: {
  value: CoreHotelBooking.StatusType;
  contentType?: 'name' | 'desc';
  children?: JSX.Element | ((displayInfo: DecoratorDisplayInfo) => JSX.Element);
} & Omit<TypographyTextProps, 'type' | 'children'>) {
  const t = useLocale();

  const bookingStatusInfo = useMemo(() => {
    return getDisplayInfo(HotelBookingStatusModel, value);
  }, [value]);

  if (!bookingStatusInfo) {
    return null;
  }

  if (typeof children === 'function') {
    return children(bookingStatusInfo);
  }

  return (
    <Typography.Text
      type={bookingStatusInfo.theme as TypographyType}
      {...respProps}
    >
      {t(bookingStatusInfo[contentType || 'desc'])}
    </Typography.Text>
  );
}
