import { useHotelPolicy, useLocale } from '@b2c/hooks';
import { SimplifiedHotelDetail } from '@b2c/services/hotel';
import { FunctionComponent, ReactNode, useMemo } from 'react';
import { Cell, Space, Tabs, Typography } from 'react-vant';
import styles from './hotel-extra-info.module.less';

export const HotelExtraInfo: FunctionComponent<{
  hotelDetail: SimplifiedHotelDetail;
  onClose?: () => void;
}> = ({ hotelDetail, onClose }) => {
  const t = useLocale();

  const hotelPolicyInfo = useHotelPolicy(hotelDetail);

  const tabPanes = useMemo(() => {
    const result: {
      title: string;
      content: ReactNode;
    }[] = [];
    if (hotelPolicyInfo.description) {
      result.push({
        title: t('Label_HotelExtra_Detail'),
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: hotelPolicyInfo.description || '',
            }}
          ></div>
        ),
      });
    }
    if (hotelPolicyInfo.policyDescription) {
      result.push({
        title: t('Label_HotelExtra_Policy'),
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: hotelPolicyInfo.policyDescription || '',
            }}
          ></div>
        ),
      });
    }
    if (hotelPolicyInfo.hotelAmenities.length > 0) {
      result.push({
        title: t('Label_HotelExtra_Facilities'),
        content: (
          <Space wrap gap={8}>
            {hotelPolicyInfo.hotelAmenities.map((attr) => (
              <Space gap={4} key={attr.id}>
                <span>&bull;</span>
                {attr.desc}
              </Space>
            ))}
          </Space>
        ),
      });
    }
    if (hotelPolicyInfo.roomAmenities.length > 0) {
      result.push({
        title: t('Label_HotelExtra_Room'),
        content: (
          <Space wrap gap={8}>
            {hotelPolicyInfo.roomAmenities.map((attr) => (
              <Space gap={4} key={attr.id}>
                <span>&bull;</span>
                {attr.desc}
              </Space>
            ))}
          </Space>
        ),
      });
    }

    return result;
  }, [hotelPolicyInfo]);

  return (
    <div className={styles.wrapper}>
      <Tabs border sticky scrollspy>
        {tabPanes.map((item) => (
          <Tabs.TabPane key={item.title} title={item.title}>
            <Cell>
              <Typography.Title level={5}>{item.title}</Typography.Title>
              {item.content}
            </Cell>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};
