import { CoreCommon } from '@b2c/core';
import { HotelBookingHelper } from '@b2c/services/booking';
import {
  getDisplayInfo,
  getPropertyDisplayInfoList,
} from '@b2c/services/decorator';
import { SpecialRequestTypeModel } from '@b2c/services/special-request';
import { useMemo } from 'react';
import { useLocale } from './use-locale';

export function useSpecialRequestText(
  specialRequests: CoreCommon.DidaSpecialRequest[] | string
) {
  const t = useLocale();
  const SpecialRequestList = useMemo(
    () => getPropertyDisplayInfoList(SpecialRequestTypeModel),
    []
  );

  const curSpecialRequestList = useMemo(() => {
    if (typeof specialRequests === 'string') {
      return HotelBookingHelper.parseSpecialRequest(specialRequests);
    }
    return specialRequests;
  }, [specialRequests]);

  const specialRequestText = useMemo(() => {
    return curSpecialRequestList
      ?.filter((item) =>
        SpecialRequestList.some(
          (request) => request.value === item.SpecialRequestType
        )
      )
      .map((item) => {
        if (item.SpecialRequestType === CoreCommon.SpecialRequestType.Other) {
          return item.Value;
        }
        return t(
          getDisplayInfo(SpecialRequestTypeModel, item.SpecialRequestType).name
        );
      })
      .join(' / ');
  }, [curSpecialRequestList, t]);

  return specialRequestText;
}
