import { format, intervalToDuration } from 'date-fns';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';

export interface ICountdownProps {
  deadline: Date;
  expiredMessage?: ReactNode;
  onFinish?(): void;
  format: string | ((duration: Duration, distance: number) => any);
}

export const Countdown: FunctionComponent<ICountdownProps> = (props) => {
  const [timeDiff, setTimeDiff] = useState<{
    duration: Duration;
    distance: number;
  }>();
  const [expired, setExpired] = useState(false);
  useEffect(() => {
    let timer = null;
    const _callback = () => {
      const nowTime = new Date();
      if (props.deadline < nowTime) {
        props.onFinish?.call(null);
        setExpired(true);
        clearTimeout(timer);
        timer = null;
        setTimeDiff(null);
        return true;
      } else {
        setTimeDiff({
          duration: intervalToDuration({
            start: nowTime,
            end: props.deadline,
          }),
          distance: -nowTime + +props.deadline,
        });
        timer = setTimeout(_callback, 1000);
      }
      return false;
    };
    _callback();

    return () => {
      timer = clearInterval(timer);
    };
  }, [props.deadline]);
  if (timeDiff) {
    if (typeof props.format === 'string') {
      return <span>{format(timeDiff.distance, props.format)}</span>;
    }
    if (typeof props.format === 'function') {
      return props.format(timeDiff.duration, timeDiff.distance);
    }
  }
  return expired ? props.expiredMessage : null;
};
