import { useLocale } from '@b2c/hooks';
import { FalconBooking, HotelBookingStatusModel } from '@b2c/services/booking';
import { DateRangeTypeEnum, DateTimeWindow } from '@b2c/services/common';
import { getPropertyDisplayInfoList } from '@b2c/services/decorator';
import { FunctionComponent, useMemo, useState } from 'react';
import { Button, Form, Input, NavBar, Popup } from 'react-vant';
import hooks from 'react-vant/es/hooks';
import { Container } from '../container';
import { Select } from '../select';

export const BookingListFilter: FunctionComponent<{
  children(options: { onClick(): any }): React.ReactNode;
  onConfirm(value: Partial<FalconBooking.SearchOption>): void;
}> = (props) => {
  const t = useLocale();

  const [formRef, formActions] = hooks.useFormSmart();

  const [filterDialogVisible, setFilterDialogVisible] = useState(false);

  // const dateRangeTypeList = useMemo(() => {
  //   return getPropertyDisplayInfoList(DateRangeTypeModel).map((item) => {
  //     return {
  //       ...item,
  //       name: t(item.name),
  //     };
  //   });
  // }, []);

  const bookingStatusTypeList = useMemo(() => {
    return getPropertyDisplayInfoList(HotelBookingStatusModel).map((item) => {
      return {
        ...item,
        name: t(item.name),
      };
    });
  }, []);

  // const maxDate = DateTimeHelper.getRelateDate(-360);
  // const [dateRangeType, setDateRangeType] = useState(0);

  function submitChange(values: any) {
    const searchOption: Partial<FalconBooking.SearchOption> = {
      BookingNumber: values.BookingNumber,
      // FalconUserID: string,
      // BookingStatusList: number[],
      // HotelIDList: number[],
      ContactEmail: values.ContactEmail,
      // CheckInDateWindow: DateTimeWindow,
      // CheckOutDateWindow: DateTimeWindow,
      // OrderTimeWindow: DateTimeWindow,
      // CancelTimeWindow: DateTimeWindow,

      WithFullInfo: true,
    };
    if (values.BookingStatus > 0) {
      searchOption.BookingStatusList = [values.BookingStatus];
    }

    const dateRange: DateTimeWindow = values.DateRange && {
      BeginTime: values.DateRange[0],
      EndTime: values.DateRange[1],
    };

    switch (values.DateRangeType) {
      case DateRangeTypeEnum.Arrival:
        searchOption.CheckInDateWindow = dateRange;
        break;
      case DateRangeTypeEnum.Booking:
        searchOption.OrderTimeWindow = dateRange;
        break;
      case DateRangeTypeEnum.Departure:
        searchOption.CheckOutDateWindow = dateRange;
        break;
      // case DateRangeTypeEnum.FirstCancellationDate:
      //   searchOption.CancelTimeWindow = dateRange;
      //   break;
    }
    setFilterDialogVisible(false);
    props.onConfirm(searchOption);
  }

  function resetSearchOption() {
    formActions.clear();
  }

  return (
    <>
      {props.children({
        onClick() {
          setFilterDialogVisible(true);
        },
      })}
      <Popup
        position="bottom"
        visible={filterDialogVisible}
        onClose={() => setFilterDialogVisible(false)}
        style={{ backgroundColor: 'var(--mu-background-color)' }}
      >
        <NavBar
          title={t('Label_FilterBooking')}
          rightText={t('Button_Reset')}
          leftText={t('Button_Cancel')}
          onClickRight={() => resetSearchOption()}
          onClickLeft={() => setFilterDialogVisible(false)}
        />
        <div className="booking-search mt-2">
          <Form
            ref={formRef as any}
            onFinish={submitChange}
            className="section-panel"
            footer={
              <Container className="search-btn-container">
                <Button type="primary" nativeType="sumbit" block>
                  {t('Button_Search')}
                </Button>
              </Container>
            }
          >
            {/* <Form.Item
              key="DateRangeType"
              label={t('Label_DateTimeType')}
              name="DateRangeType"
              customField
            >
              <Select
                placeholder={t('Label_All')}
                options={dateRangeTypeList}
                title={t('Label_DateTimeType')}
                // value={dateRangeType}
                // onChange={(value) => {
                //   console.log('date range type change', value);
                //   setDateRangeType(value);
                // }}
              />
            </Form.Item>

            <Form.Item
              label={t('Label_DateTimeRange')}
              name="DateRange"
              customField
            >
              <DateRangeInput
                maxDate={new Date()}
                minDate={maxDate}
              ></DateRangeInput>
            </Form.Item> */}
            <Form.Item label={t('Label_BookingNumber')} name="BookingNumber">
              <Input
                type="text"
                clearable
                placeholder={t('Placeholder_BookingSearch_BookingNumber')}
              />
            </Form.Item>
            {/* <Form.Item label={t('Label_HotelName')} name="HotelName">
              <Input
                type="text"
                clearable
                placeholder={t('Placeholder_BookingSearch_HotelName')}
              />
            </Form.Item> */}

            <Form.Item label={t('Label_BookingStatus')} name="BookingStatus">
              <Select
                placeholder={t('Label_All')}
                options={bookingStatusTypeList}
                title={t('Label_BookingStatus')}
              ></Select>
            </Form.Item>
          </Form>
        </div>
      </Popup>
    </>
  );
};
