import { HotelDetailQueryPayload } from '@b2c/services/common';
import {
  HotelInfoHelper,
  HotelStaticService,
  SimplifiedHotelDetail,
} from '@b2c/services/hotel';
import { useEffect, useState } from 'react';
import { useLocale } from './use-locale';
import { useQueryParams } from './use-query-params';

type HotelDetailCacheModel = { key: string; value: SimplifiedHotelDetail }[];

const getStorageKey = (hotelId, lang) => {
  return hotelId + '_' + lang;
};

const getHotelDetailFromCache = (hotelId: string | number, lang: string) => {
  const storage = sessionStorage.getItem('hotelDetail');
  if (storage) {
    const hotelDetailList = JSON.parse(storage) as HotelDetailCacheModel;

    return hotelDetailList.find(
      (item) => item.key === getStorageKey(hotelId, lang)
    )?.value;
  }
  return null;
};

const setHotelDetailToCache = (detail: SimplifiedHotelDetail, lang: string) => {
  const storage = sessionStorage.getItem('hotelDetail');
  const storageKey = getStorageKey(detail.HotelID, lang);
  let hotelDetailList = [];
  if (storage) {
    hotelDetailList = JSON.parse(storage) as HotelDetailCacheModel;
  }
  hotelDetailList = hotelDetailList.filter((item) => item.key !== storageKey);
  hotelDetailList.unshift({ key: storageKey, value: detail });
  sessionStorage.setItem(
    'hotelDetail',
    JSON.stringify(hotelDetailList.slice(0, 5))
  );
};

export function useHotelDetail(hotelId?: number) {
  const [hotelDetail, setHotelDetail] = useState<SimplifiedHotelDetail>();
  const { queryParams } = useQueryParams<HotelDetailQueryPayload>();
  const t = useLocale();

  function loadHotelInfo(hotelId: number) {
    if (!hotelId) {
      return;
    }

    const hotelDetail = getHotelDetailFromCache(hotelId, t.locale);
    if (hotelDetail) {
      setHotelDetail(hotelDetail);
      return;
    }

    return HotelStaticService.getHotelDetail({
      HotelID: hotelId,
      WithRoomContent: true,
      WithHotelImageList: true,
      WithBookingNotice: true,
      WithMainHotelID: true,
      WithAttributeList: true,
      WithDetailCollectionList: true,
      WithCustomizeDescriptionList: true,
      WithFacilitySetting: true,
      WithImportNotice: true,
      WithSellPoint: true,
      WithMetadata: true,
      // WithRoomImages: true,
      // WithHotelLabel: true,
      // WithHotelAlias: true
    }).then(
      (resp) => {
        const detail = HotelInfoHelper.simplifyHotelStatic(resp);
        setHotelDetail(detail);
        setHotelDetailToCache(detail, t.locale);
      },
      (error) => {
        // todo
        console.error(error);
        // error.message && ModalService.error(error.message);
      }
    );
  }

  const mergedHotelId = hotelId || +queryParams.hid;

  useEffect(() => {
    loadHotelInfo(mergedHotelId);
  }, [mergedHotelId, t.locale]);

  return { hotelDetail, reloadHotelInfo: () => loadHotelInfo(mergedHotelId) };
}
