import { SuggestionOption } from '@b2c/services/suggestions';
// import { createModel } from 'hox';
import { createContext, useEffect, useState } from 'react';

const DestinationSearchHistory = 'DestinationSearchHistory';

const RememberSearchHistoryCount = 6;

export function useDestinationSearchHistory() {
  const [historyList, setHistoryList] = useState<SuggestionOption[]>([]);

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const restoreHistoryList = () => {
        const list = JSON.parse(
          localStorage.getItem(DestinationSearchHistory) || '[]'
        ) as SuggestionOption[];
        setHistoryList(list.slice(0, RememberSearchHistoryCount));
      };

      const onStorageChange = (event: StorageEvent) => {
        if (event.key === DestinationSearchHistory) {
          restoreHistoryList();
        }
      };

      window.addEventListener('storage', onStorageChange);
      restoreHistoryList();
      return () => {
        window.removeEventListener('storage', onStorageChange);
      };
    }
  }, []);

  function updateHistoryList(region: SuggestionOption) {
    const newHistoryList = [
      region,
      ...historyList.filter((item) => item.id !== region.id),
    ].slice(0, RememberSearchHistoryCount);
    setHistoryList(newHistoryList);
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(
        DestinationSearchHistory,
        JSON.stringify(newHistoryList)
      );
    }
  }

  function clearHistoryList() {
    setHistoryList([]);
    localStorage.removeItem(DestinationSearchHistory);
  }

  return {
    updateHistoryList,
    clearHistoryList,
    historyList,
  };
}

export const DestinationSearchHistoryContext = createContext<
  Partial<ReturnType<typeof useDestinationSearchHistory>>
>({});
