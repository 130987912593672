import { DateTimeHelper } from '@b2c/services/common';
import { getDisplayInfo } from '@b2c/services/decorator';
import {
  CancellationStateType,
  SimplifiedRatePlanPrice,
} from '@b2c/services/rateplan';
import { useMemo } from 'react';
import { useLocale } from './use-locale';

export function useCancellationPolicyInfo(
  ratePlanPrice: Pick<
    SimplifiedRatePlanPrice,
    'cancellationList' | 'timeZoneInfo' | 'priceSummary'
  >
) {
  const t = useLocale();

  const { cancellationList, timeZoneInfo, priceSummary } = ratePlanPrice;

  const cancellationInfo = useMemo(() => {
    let timeZone: {
      desc: string;
      offsetHour: number;
    } = null;
    if (timeZoneInfo?.OffsetExpression) {
      const offsetHour = Math.floor(timeZoneInfo.OffsetSeconds / 60 / 60);
      let desc = 'GMT';
      if (offsetHour > 0) {
        desc += '+';
      }
      desc += offsetHour;
      timeZone = {
        desc,
        offsetHour,
      };
    }

    const isLocalTimezone =
      timeZoneInfo.OffsetSeconds === -new Date().getTimezoneOffset() * 60;
    cancellationList[0].CancellationFromTime ===
      cancellationList[0].CancellationFromTimeLocal;
    const dateTimeSuffix = isLocalTimezone
      ? ''
      : ` ${timeZone.desc}(${t('Text_HotelLocalTime')})`;

    const cancellationRuleInfoList = cancellationList.map(
      (item, index, thisArr) => {
        const isNonRefundable = item.Amount >= priceSummary.Price;
        const state = isNonRefundable
          ? CancellationStateType.NoneRefundable
          : CancellationStateType.Pay2cancell;
        const dateDesc = t('Text_Cancellation_After', {
          datetime: DateTimeHelper.getDateTimeString(
            item.CancellationFromTimeLocal
          ),
        });
        let ruleDesc = '';
        let priceDesc = '';
        if (isNonRefundable) {
          ruleDesc = t('Text_Cancellation_NonRefundable');
          priceDesc = t('Text_RatePlanRate_NonRefundable');
        } else {
          priceDesc = t.formatPrice({
            ...priceSummary,
            Price: item.Amount,
          });
          const nextRule = thisArr[index + 1];

          ruleDesc = nextRule
            ? t('Text_Cancellation_PayCancelBefore', {
                0:
                  DateTimeHelper.getString(
                    nextRule.CancellationFromTimeLocal,
                    'MM-dd HH:mm'
                  ) + dateTimeSuffix,
                1: priceDesc,
              })
            : t('Text_Cancellation_PayCancelAfter', {
                0:
                  DateTimeHelper.getString(
                    cancellationList[index].CancellationFromTimeLocal,
                    'MM-dd HH:mm'
                  ) + dateTimeSuffix,
                1: priceDesc,
              });
        }
        return {
          // fromLocal: item.CancellationFromTimeLocal,
          // from: item.CancellationFromTime,
          fromTimestamp: item.CancellationFromTimestampSeconds,
          // toLocal: thisArr[index + 1]?.CancellationFromTimeLocal,
          // to: thisArr[index + 1]?.CancellationFromTime,
          toTimestamp: thisArr[index + 1]?.CancellationFromTimestampSeconds,
          state,
          isCurrent: false,
          displayInfo: getDisplayInfo(CancellationStateType, state),
          desc: ruleDesc,
          dateDesc,
          priceDesc,
        };
      }
    );
    cancellationRuleInfoList.unshift({
      // from: null,
      // fromLocal: null,
      fromTimestamp: null,
      // to: cancellationList[0].CancellationFromTime,
      // toLocal: cancellationList[0].CancellationFromTimeLocal,
      toTimestamp: cancellationList[0].CancellationFromTimestampSeconds,
      state: CancellationStateType.FreeCancell,
      isCurrent: false,
      displayInfo: getDisplayInfo(
        CancellationStateType,
        CancellationStateType.FreeCancell
      ),
      desc: t('Text_Cancellation_FreeCancelBefore', {
        0:
          DateTimeHelper.getString(
            cancellationList[0].CancellationFromTimeLocal,
            'MM-dd HH:mm'
          ) + dateTimeSuffix,
      }),
      dateDesc: t('Text_Cancellation_Before', {
        datetime: DateTimeHelper.getDateTimeString(
          cancellationList[0].CancellationFromTimeLocal
        ),
      }),
      priceDesc: t('Text_Cancellation_FreeCancel'),
    });

    const nowTimestamp = Date.now() / 1000;
    const currentCancellationRuleInfo = cancellationRuleInfoList.find(
      (item, index) => {
        if (item.fromTimestamp && nowTimestamp < item.fromTimestamp) {
          return false;
        }
        if (item.toTimestamp && nowTimestamp > item.toTimestamp) {
          return false;
        }
        return true;
      }
    );

    currentCancellationRuleInfo.isCurrent = true;

    return {
      cancellationRuleInfoList,
      currentCancellationRuleInfo,
      timeZone,
    };
  }, [cancellationList, priceSummary, timeZoneInfo, t]);

  return cancellationInfo;
}
