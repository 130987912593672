export namespace CoreHotelBooking {
  export interface HotelBooking {
    BookingID: string;
    ClientID: string;
    UserID: string;
    Status: number;
    StatusEnum: StatusType;
    HotelID: number;
    HotelName: string;
    RatePlanName: string;
    CityCode: string;
    BedTypeID: number;
    BreakfastID: number;
    BookType?: number;
    MaxOccupancy?: number;
    CheckInDate: Date;
    CheckOutDate: Date;
    OrderDate: Date;
    TotalPrice: number;
    TotalNetRate: number;
    Currency: string;
    RoomCount: number;
    TotalSupplement?: number;
    ContactFirstName: string;
    ContactLastName: string;
    ContactPhone: string;
    ContactEmail: string;
    CustomerRequest: string;
    ClientReference: string;
    BookingGroupReference: string;
    ClientReferenceOriginal: string;
    IsGroupedBooking: boolean;
    SupplierBookingRemarks: string;
    SupplierBookingReference: string;
    Nationality: string;
    HotelConfirmationCode: string;
    BookingCancelTime?: Date;
  }

  export enum StatusType {
    /**
     * 前端忽略
     */
    // PreBook = 0,
    /**
     *  已成单， 供应商未确认
     */
    Booked = 1,
    /**
     * 已成单， 供应商已确认
     */
    Confirmed = 2,
    Canceled = 3,
    Failed = 4,
    /**
     * 已成单， 系统跟供应商确认中，只会存在很短的时间
     */
    Pending = 5,
    /**
     * 二次确认订单，等待供应商确认中
     *  */
    OnRequest = 6,

    Unsettled = 99,
    NoSpecified = -1,
  }

  export enum ErrorType {
    //
    // 摘要:
    //     未指定
    // [InternationalizationDescription("NotSpecified", LanguageType.en_US)]
    // [InternationalizationDescription("未指定", LanguageType.zh_CN)]
    NotSpecified = 0,
    //
    // 摘要:
    //     未知异常
    // [InternationalizationDescription("Unexpected error.", LanguageType.en_US)]
    // [InternationalizationDescription("未知异常", LanguageType.zh_CN)]
    UnexpectedError = 3000,
    //
    // 摘要:
    //     订单信息不正确
    // [InternationalizationDescription("Incorrect booking information.", LanguageType.en_US)]
    // [InternationalizationDescription("订单信息不正确", LanguageType.zh_CN)]
    IncorrectBookingInformation = 3001,
    //
    // 摘要:
    //     客户订单号不正确
    // [InternationalizationDescription("Incorrect reference No.", LanguageType.en_US)]
    // [InternationalizationDescription("客户订单号不正确", LanguageType.zh_CN)]
    IncorrectReferenceNo = 3002,
    //
    // 摘要:
    //     订单号不正确
    // [InternationalizationDescription("Incorrect booking ID.", LanguageType.en_US)]
    // [InternationalizationDescription("订单号不正确", LanguageType.zh_CN)]
    IncorrectBookingID = 3003,
    //
    // 摘要:
    //     订单取消确认号不正确
    // [InternationalizationDescription("Incorrect cancellation confirm ID.", LanguageType.en_US)]
    // [InternationalizationDescription("订单取消确认号不正确", LanguageType.zh_CN)]
    IncorrectCancelConfirmID = 3004,
    //
    // 摘要:
    //     入住人信息不正确
    // [InternationalizationDescription("Incorrect occupancy information specified in one room.", LanguageType.en_US)]
    // [InternationalizationDescription("入住人信息不正确", LanguageType.zh_CN)]
    IncorrectOccupancyInfo = 3005,
    //
    // 摘要:
    //     订单超时未确认
    // [InternationalizationDescription("Booking expired.", LanguageType.en_US)]
    // [InternationalizationDescription("订单超时未确认", LanguageType.zh_CN)]
    BookingExpired = 3006,
    //
    // 摘要:
    //     订单取消确认超时
    // [InternationalizationDescription("Cancellation expired.", LanguageType.en_US)]
    // [InternationalizationDescription("订单取消确认超时", LanguageType.zh_CN)]
    CancelExpired = 3007,
    //
    // 摘要:
    //     入住房间内的 RoomNum 不正确，必须是从 1 开始的连续数字
    // [InternationalizationDescription("Incorrect number of rooms specified in the guest list.", LanguageType.en_US)]
    // [InternationalizationDescription("入住房间内的 RoomNum 不正确，必须是从 1 开始的连续数字", LanguageType.zh_CN)]
    IncorrectNumOfRooms = 3008,
    //
    // 摘要:
    //     城市号不正确
    // [InternationalizationDescription("Incorrect city code", LanguageType.en_US)]
    // [InternationalizationDescription("城市号不正确", LanguageType.zh_CN)]
    IncorrectCityCode = 3009,
    //
    // 摘要:
    //     更新订单状态失败
    // [InternationalizationDescription("Failed to update status", LanguageType.en_US)]
    // [InternationalizationDescription("更新订单状态失败", LanguageType.zh_CN)]
    FailedToUpdateStatus = 3010,
    //
    // 摘要:
    //     订单联系人必填
    // [InternationalizationDescription("Contact info missing", LanguageType.en_US)]
    // [InternationalizationDescription("订单联系人必填", LanguageType.zh_CN)]
    NoContactInfo = 3011,
    //
    // 摘要:
    //     数据库错误
    // [InternationalizationDescription("DB error", LanguageType.en_US)]
    // [InternationalizationDescription("数据库错误", LanguageType.zh_CN)]
    DBError = 3012,
    //
    // 摘要:
    //     订单状态不符合预期
    // [InternationalizationDescription("Incorrect booking status", LanguageType.en_US)]
    // [InternationalizationDescription("订单状态不符合预期", LanguageType.zh_CN)]
    IncorrectStatus = 3013,
    //
    // 摘要:
    //     额度已超过限制
    // [InternationalizationDescription("Not enough credit", LanguageType.en_US)]
    // [InternationalizationDescription("额度已超过限制", LanguageType.zh_CN)]
    NotEnoughCredit = 3014,
    //
    // 摘要:
    //     库存或价格不正确
    // [InternationalizationDescription("Availability or price invalid", LanguageType.en_US)]
    // [InternationalizationDescription("库存或价格不正确", LanguageType.zh_CN)]
    AvailabilityOrPriceInvalid = 3015,
    //
    // 摘要:
    //     订单确认失败
    // [InternationalizationDescription("Failed to confirm booking", LanguageType.en_US)]
    // [InternationalizationDescription("订单确认失败", LanguageType.zh_CN)]
    FailedToConfirmBooking = 3016,
    //
    // 摘要:
    //     订单已经是确认状态
    // [InternationalizationDescription("Booking is already confirmed.", LanguageType.en_US)]
    // [InternationalizationDescription("订单已经是确认状态", LanguageType.zh_CN)]
    BookingConfirmed = 3017,
    //
    // 摘要:
    //     订单已经是取消状态
    // [InternationalizationDescription("Booking is already canceled.", LanguageType.en_US)]
    // [InternationalizationDescription("订单已经是取消状态", LanguageType.zh_CN)]
    BookingCanceled = 3018,
    //
    // 摘要:
    //     客户订单号重复
    // [InternationalizationDescription("Same client reference detected", LanguageType.en_US)]
    // [InternationalizationDescription("客户订单号重复", LanguageType.zh_CN)]
    DuplicateBooking = 3019,
    //
    // 摘要:
    //     订单还在处理中请等待处理结果
    // [InternationalizationDescription("Booking is still being processed", LanguageType.en_US)]
    // [InternationalizationDescription("订单还在处理中请等待处理结果", LanguageType.zh_CN)]
    PendingBooking = 3020,
    //
    // 摘要:
    //     关房
    // [InternationalizationDescription("It's stop sell", LanguageType.en_US)]
    // [InternationalizationDescription("关房", LanguageType.zh_CN)]
    IsStopSell = 3021,
    //
    // 摘要:
    //     入住时间不正确
    // [InternationalizationDescription("The checkin date is invalid", LanguageType.en_US)]
    // [InternationalizationDescription("入住时间不正确", LanguageType.zh_CN)]
    IsInvalidCheckIn = 3022,
    //
    // 摘要:
    //     EanRequest 不能为空
    // [InternationalizationDescription("EanRequest should not be NULL", LanguageType.en_US)]
    // [InternationalizationDescription("EanRequest 不能为空", LanguageType.zh_CN)]
    EanRequestIsNull = 3023,
    //
    // 摘要:
    //     CEAir 取消失败
    // [InternationalizationDescription("CEAir cancel failed.", LanguageType.en_US)]
    // [InternationalizationDescription("CEAir 取消失败", LanguageType.zh_CN)]
    FailedToCancelCEAir = 3024,
    //
    // 摘要:
    //     客户订单号过长
    // [InternationalizationDescription("ClientReference is too long.", LanguageType.en_US)]
    // [InternationalizationDescription("客户订单号过长", LanguageType.zh_CN)]
    ClientReferenceExceedMaxLength = 3025,
    //
    // 摘要:
    //     客户订单号未提供
    // [InternationalizationDescription("ClientReference is required.", LanguageType.en_US)]
    // [InternationalizationDescription("客户订单号未提供", LanguageType.zh_CN)]
    ClientReferenceNotProvided = 3026,
    //
    // 摘要:
    //     在订单中无法找到一个或多个附加服务
    // [InternationalizationDescription("One or more value-add items are not exist in the actual booking", LanguageType.en_US)]
    // [InternationalizationDescription("在订单中无法找到一个或多个附加服务", LanguageType.zh_CN)]
    ValueAddItemNotMatch = 3027,
    //
    // 摘要:
    //     更新客户订单号失败
    // [InternationalizationDescription("Failed to update client reference.", LanguageType.en_US)]
    // [InternationalizationDescription("更新客户订单号失败", LanguageType.zh_CN)]
    FailedToUpdateClientReference = 3028,
    //
    // 摘要:
    //     关联订单填的 ClientRefernce， 在 didabooking.dbo.HotelBookings 找不到记录，无法作为主单单号，客服有可能乱填
    // [InternationalizationDescription("Related booking ID does not exist.", LanguageType.en_US)]
    // [InternationalizationDescription("关联订单号不正确", LanguageType.zh_CN)]
    RelatedBookingIDNotExist = 3029,
    //
    // 摘要:
    //     关联订单填的 ClientRefernce， 在 didabooking.dbo.HotelBookingsRelationship 是子单单号，所有子单必须填相同的父单单号，子单无法同时是另一个订单的父单
    // [InternationalizationDescription("Related booking ID cannot be under current client", LanguageType.en_US)]
    // [InternationalizationDescription("关联单的单号无法作为主单号", LanguageType.zh_CN)]
    RelatedBookingIDCannotBeMainBookingID = 3030,
    //
    // 摘要:
    //     无法取消已过入住日的订单
    // [InternationalizationDescription("Order can't be cancelled when it's after CheckIn", LanguageType.en_US)]
    // [InternationalizationDescription("无法取消已过入住日的订单", LanguageType.zh_CN)]
    CancelAfterCheckIn = 3031,
    //
    // 摘要:
    //     取消 SHCHQHB 失败
    // [InternationalizationDescription("FailedToCancelSHCHQHB", LanguageType.en_US)]
    // [InternationalizationDescription("取消 SHCHQHB 失败", LanguageType.zh_CN)]
    FailedToCancelSHCHQHB = 3032,
    //
    // 摘要:
    //     订单是待确认订单
    // [InternationalizationDescription("Booking pre confirm", LanguageType.en_US)]
    // [InternationalizationDescription("订单是待确认订单", LanguageType.zh_CN)]
    BookingPreConfirm = 3033,
    //
    // 摘要:
    //     在订单中无法找到一个或多个优惠券
    // [InternationalizationDescription("One or more coupon items are not exist in the acutal booking.", LanguageType.en_US)]
    // [InternationalizationDescription("在订单中无法找到一个或多个优惠券", LanguageType.zh_CN)]
    CouponConsumeRecordNotMatch = 3034,
    //
    // 摘要:
    //     拦截测试订单
    // [InternationalizationDescription("Reject booking", LanguageType.en_US)]
    // [InternationalizationDescription("疑似测试订单，拒单", LanguageType.zh_CN)]
    BlockTestBooking = 3035,
    //
    // 摘要:
    //     订单超时未支付 (Shopping)
    // [InternationalizationDescription("Booking payment expired. (For Shopping only so far)", LanguageType.en_US)]
    // [InternationalizationDescription("订单超时未支付 (暂时只有 Shopping 用得上)", LanguageType.zh_CN)]
    BookingPaymentExpired = 3036,
    //
    // 摘要:
    //     在订单中无法找到一个或多个优惠券
    // [InternationalizationDescription("Failed to save Coupon consume record.", LanguageType.en_US)]
    // [InternationalizationDescription("保存优惠券消费记录出错", LanguageType.zh_CN)]
    CouponConsumeRecordSaveFailed = 3037,
    //
    // 摘要:
    //     入住人姓名有误
    // [InternationalizationDescription("Guest name now allow for this booking", LanguageType.en_US)]
    // [InternationalizationDescription("入住人姓名有误", LanguageType.zh_CN)]
    GuestNameNotAllow = 3038,
    //
    // 摘要:
    //     下单价格超过 RP报价容忍范围(有可能是价格欺骗逻辑造成, 本质上是属于变价的一种)
    // [InternationalizationDescription("Booking price out of tolerance (might be caused by price fraud)", LanguageType.en_US)]
    // [InternationalizationDescription("下单价格超过 RP报价容忍范围(有可能是价格欺骗逻辑造成, 本质上是属于变价的一种)", LanguageType.zh_CN)]
    PriceOutOfTolerance = 3040,
    //
    // 摘要:
    //     下单时没有报价信息, 组装成 DB model 失败
    // [InternationalizationDescription("No RatePlanRate provided, create db model failed", LanguageType.en_US)]
    // [InternationalizationDescription("下单时没有报价信息, 组装成 DB model 失败", LanguageType.zh_CN)]
    NoRatePlanRateProvided = 3050,
    //
    // 摘要:
    //     待确认订单确认超时
    // [InternationalizationDescription("OnRequest booking confirm timeout", LanguageType.en_US)]
    // [InternationalizationDescription("待确认订单确认超时", LanguageType.zh_CN)]
    OnRequestBookingConfirmTimeOut = 3060,
    //
    // 摘要:
    //     待确认订单确认失败
    // [InternationalizationDescription("OnRequest booking confirm fail", LanguageType.en_US)]
    // [InternationalizationDescription("待确认订单确认失败", LanguageType.zh_CN)]
    OnRequestBookingConfirmFailed = 3070,
    //
    // 摘要:
    //     回流单
    // [InternationalizationDescription("Block round trip booking", LanguageType.en_US)]
    // [InternationalizationDescription("回流单 - 拒单", LanguageType.zh_CN)]
    BlockRoundTripBooking = 3080,
    //
    // 摘要:
    //     回流单
    // [InternationalizationDescription("Cancel failed general", LanguageType.en_US)]
    // [InternationalizationDescription("取消失败", LanguageType.zh_CN)]
    CancelFailed_General = 4000,
    InvalidReorderClient = 6001,
    ReorderNewSuccessFaildToCancelOld = 80001,
    ReorderFaildToCreateRelationShip = 80002,
    ReorderOrginalBookingCancelledReorderBookingNeedtobeCancelled = 80003,
    ReorderCantChangeOrginalBookingID = 80004,
    ReorderCancellationIsStrict = 80005,
  }

  export enum BookType {
    Normal = 0,
    Postpay = 1,
  }

  export interface RoomOccupancy {
    RoomNum: number;
    AdultsCount: number;
    ChildrenCount: number;
    Adults: Guest[];
    Children: Guest[];
  }
  export interface Guest {
    FirstName?: string;
    LastName?: string;
    Gendar?: string;
    Phone?: string;
    Address?: string;
    Email?: string;
    Age?: number;
    IDType?: string;
    ID?: string;
    IsAdult?: boolean;
  }

  export interface Contact {
    LastName?: string;
    FirstName?: string;
    Email?: string;
    Phone?: string;
    // 前端临时字段
    _PhonePrefix?: PhonePrefix;
  }

  // 前端临时自定义Modal
  export interface PhonePrefix {
    CallingCode: string;
    CountryCode: string;
  }
}
