import { useLocale } from '@b2c/hooks';
import { Typography } from 'react-vant';
import { TypographyTextProps } from 'react-vant/es/typography/PropsType';

export function TypographyPrice<T extends { Currency: string }>(
  props: TypographyTextProps & {
    value: T;
    priceKey?: keyof T;
  }
) {
  const t = useLocale();
  const { value, priceKey, ...restProps } = props;
  return (
    <Typography.Text
      {...restProps}
      style={{
        '--rv-typography-primary-color': 'var(--mu-price-primary-color)',
      }}
    >
      {t.formatPrice(value, priceKey)}
    </Typography.Text>
  );
}
