import { Display, t } from '../../decorator';

export enum DateRangeTypeEnum {
  All = 0,
  Booking = 1,
  Arrival = 2,
  Departure = 3,
  // FirstCancellationDate = 4,
}

export class DateRangeTypeModel {
  @Display({
    name: t('Label_All'),
  })
  static All = 0;

  @Display({
    name: t('Label_OrderTime'),
  })
  static Bookin = 1;
  @Display({
    name: t('Label_CheckinDay'),
  })
  static Arrival = 2;

  @Display({
    name: t('Label_CheckoutDay'),
  })
  static Departure = 3;

  // @Display({
  //   name: 'Last Free Cancel',
  // })
  // static FirstCancellationDate = 4;
}
