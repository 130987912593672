import { UrlHelper } from '@b2c/services/common';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigater } from './use-navigater';

export function useQueryParams<T>() {
  const location = useLocation();

  const { navigateReplace } = useNavigater();
  const [qp, setQp] = useState<T>(UrlHelper.parseQueryParams(location.search));

  useEffect(() => {
    const result = UrlHelper.parseQueryParams<T>(location.search);
    if (!isEqual(qp, result)) {
      // console.trace('update query params');
      navigateReplace(location.pathname, result);
      setQp(result);
    }
  }, [location.search]);

  const updateQueryParams = useCallback(<T = any>(params: T = null) => {
    navigateReplace(location.pathname, UrlHelper.mergeQueryParams(params));
  }, []);

  return {
    queryParams: qp,
    updateQueryParams,
  };
}
