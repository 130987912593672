import {
  Table as _table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from './table';

const TableNamespace = Object.assign(_table, {
  Row: TableRow,
  Cell: TableCell,
  Body: TableBody,
  Header: TableHeader,
});

export const Table = TableNamespace;
