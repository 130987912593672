import { addSeconds } from 'date-fns';
import { FalconBooking } from '../booking';
import { DateTimeHelper, FalconCommon } from '../common';

export class CancellationHelper {
  static normalize<T extends FalconCommon.DayCancellation>(
    rawCancellationList: T[],
    timeZoneInfo: FalconCommon.TimeZoneInfo
  ) {
    const cancellationList = rawCancellationList.map((item) => ({ ...item }));

    cancellationList.forEach((item) => {
      // 后端返回的是字符串~~
      item.CancellationFromTimestampSeconds -= 0;
      // 后端给的时间戳不准，这里结合时区自己转一下
      if (timeZoneInfo) {
        let offsetExpression = timeZoneInfo.OffsetExpression;
        if (timeZoneInfo.OffsetSeconds === 0) {
          offsetExpression = '+' + offsetExpression;
        }
        item.CancellationFromTimestampSeconds =
          +new Date(item.CancellationFromTimeLocal + offsetExpression) / 1000;
      }
      // +1s
      if (
        new Date(+item.CancellationFromTimestampSeconds * 1000).getSeconds() ===
        59
      ) {
        item.CancellationFromTimestampSeconds += 1;
        item.CancellationFromTime = DateTimeHelper.getString(
          addSeconds(new Date(item.CancellationFromTime), 1),
          'yyyy-MM-dd HH:mm:ss'
        ).replace(' ', 'T');
        item.CancellationFromTimeLocal = DateTimeHelper.getString(
          addSeconds(new Date(item.CancellationFromTimeLocal), 1),
          'yyyy-MM-dd HH:mm:ss'
        ).replace(' ', 'T');
      }
    });
    return cancellationList;
  }

  static exchangeCurrency(
    cancellationList: FalconCommon.DayCancellation[],
    basic: FalconBooking.PriceSummary,
    target: FalconBooking.PriceSummary
  ) {
    const ratio = basic.Price / target.Price;
    cancellationList.forEach((item) => {
      item.Amount /= ratio;
      item.Amount = Math.floor(item.Amount * 100) / 100;
      item.Currency = target.Currency;
    });
  }
}
