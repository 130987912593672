import { debounce } from 'lodash';
import { useEffect } from 'react';

export function usePageShow(callback: () => void) {
  useEffect(() => {
    const _callback = debounce(function () {
      callback.call(null);
    }, 300);

    document.addEventListener('visibilitychange', _callback);

    return function () {
      document.removeEventListener('visibilitychange', _callback);
    };
  }, []);
}
