import { CoreCommon } from '../common';

export namespace CoreHotel {
  export interface HotelStaticDetail extends HotelStatic {
    AmenitiesDescription: string;
    // AmenitiesDescription_CN: string;
    AreaAttractions: string;
    // AreaAttractions_CN: string;
    BusinessAmenitiesDescription: string;
    // BusinessAmenitiesDescription_CN: string;
    Description: string;
    // Description_CN: string;
    DiningDescription: string;
    // DiningDescription_CN: string;
    /**
     * @deprecated 和MandatoryFeesDescription是一样的东西，已经在service层合并到一起
     */
    FeesDescription: string;
    // FeesDescription_CN: string;
    LocationDescription: string;
    // LocationDescription_CN: string;
    /**
     * 到店后由酒店收取的费用
     */
    MandatoryFeesDescription: string;
    // MandatoryFeesDescription_CN: string;
    NationalRatingsDescription: string;
    // NationalRatingsDescription_CN: string;
    PolicyDescription: string;
    // PolicyDescription_CN: string;
    RoomsDescription: string;
    // RoomsDescription_CN: string;
    WhatToExpect: string;
    // WhatToExpect_CN: string;
    HotelAttributeList?: HotelAttributeDescription[];
    HotelMeta: HotelMeta;
    ImportNotice: string;
    // ImportNotice_CN: string;
    MainHotelID?: number;
  }

  export interface HotelAttributeDescription {
    AttributeID: number;
    AppendTxt: string;
    AttributeDescription: string;
    SubType: string;
    Type: string;
  }

  export interface SupplierHotelDestination {
    HotelID: number;
    SupplierID: number;
    SupplierHotelID: string;
    DestinationID: string;
    CountryCode: string;
  }

  export interface HotelMeta {
    HotelID: number;
    Email: string;
    WebsiteUrl: string;
  }
  export interface HotelCustomizedSetting {
    TripAdvisorRating: number;
    TripAdvisorRatingUrl: string;
    TripAdvisorReviewCount: number;
    InternetTypeList: number[];
    IsOnPromotion: boolean;
  }

  export interface HotelCustomizeDescription {
    Proposal: string;
    Overview: string;
    Information: string;
    NearbyInterests: string;
    Transportations: string;
    HasWifi: boolean;
  }

  export interface HotelImage {
    ImageCaption: string;
    ImageUrl: string;
    ImageUrl_HD: string;
    ImageOrder: number;
  }

  export interface HotelRoomImage {
    HotelID: number;
    RoomTypeID: number;
    RoomTypeImage: string;
  }

  export interface HotelStatic {
    HotelID: number;
    Name: string;
    // Name_EN: string;
    // Name_CN: string;
    ChainName: string;
    ChainCodeID: string;
    // BrandName: string;
    Address: string;
    // Address_CN: string;
    // AddressFull_CN: string;
    AddressFull_EN: string;
    CityCode: number;
    CityName: string;
    // CityName_CN: string;
    /**
     * 酒店标签
     */
    Tags: string[];
    // Tags_CN: string[];
    /**
     * 酒店卖点
     */
    DidaHotelSellingPoint: string;
    // DidaHotelSellingPoint_CN: string;
    Description: string;
    // Description_CN: string;
    DestinationID: string;
    /**
     * warning: 这是一个临时字段
     * 后端返回的DestinationID是一个bigint，json解析时会被截断,
     * 在不改变后端原有字段的情况下，返回一个对应字符串
     */
    DestinationIDStr: string;
    DestinationName: string;
    // DestinationName_CN: string;
    StateCode: string;
    CountryCode: string;
    ZipCode: string;
    StarRating: number;
    Phone: string;
    Longitude: number;
    Latitude: number;
    AirportCode: string;
    PropertyCategory: string;
    Distance: number;
    CustomizeDescription: HotelCustomizeDescription;
    HotelImageList: HotelImage[];
    CustomizedSetting: HotelCustomizedSetting;
    RoomImages: HotelRoomImage[];
    IsFavorited: boolean;
    FacilitySetting: HotelFacilitySetting;

    AliasList: HotelAlias[];
  }

  export interface HotelFacilitySetting {
    Wifi?: boolean;
    Parking?: boolean;
    Restaurant?: boolean;
    Pool?: boolean;
    Gym?: boolean;
    MeetingRoom?: boolean;
    AirportTransfer?: boolean;
  }

  export interface HotelStaticInfoSnapshotModel {
    HotelID: string;
    Name: string;
    Name_EN: string;
    // Name_CN: string;
    StarRating?: number;
    ImgUrl: string;
    Address: string;
    Phone: string;
    // Distance?: number;
    FacilitySetting: HotelFacilitySetting;
    // Favorite: boolean;
  }

  export interface RoomTypeContent {
    DidaRoomTypeID: number;
    DidaRoomTypeName_EN: string;
    DidaRoomTypeName: string;
    Images: RoomImage[];
    MaxOccupancy: number;
    Size: string;
    HasWindow: boolean;
    Floor: string;
    HasWifi: boolean;
    Facilities: RoomFacility[];

    HasProperty: boolean;
  }
  export interface RoomFacility {
    Caption: string;
    Description: string;
  }
  export interface RoomImage {
    IsDefault: boolean;
    Url: string;
  }

  export interface RoomTypeSearchResult {
    HotelID: number;
    Rooms: RoomTypeContent[];
  }

  export interface HotelAlias {
    Alias: string;

    Language: CoreCommon.LanguageCode;

    IsChecked: boolean;
  }

  export interface HotelChainModel {
    ChainCodeID: number;
    ChainName: string;
  }

  export interface BookingNotice {
    CheckInOut: string;

    CheckInOut_CN: string;

    Notice: string;

    Notice_CN: string;

    ImportNotice: string;

    ImportNotice_CN: string;
  }

  export enum BedTypes {
    None = 0,
    OneSingle = 1,
    OneDouble = 2,
    OneTwin = 3,
    ThreeSingle = 4,
    OneDoubleOneSingle = 5,
    TwoDouble = 6,
    OneDoubleOneTwin = 7,
    TwoTwin = 8,
    DoubleOrTwin = 9,
    OneDoubleOneSofa = 10,
    OneSingleOneSofa = 11,
    TwoSingleOneSofa = 14,
    ThreeSingleOneSofa = 15,
    FourSingleOneSofa = 16,
    TwoDoubleOneSofa = 17,
    OneDoubleTwoSofa = 18,
    OneDoubleOneSingleOneSofa = 21,
    OneDoubleTwoSingleOneSofa = 22,
    OneDoubleTwoSingleOneDoubleSofa = 23,
    OneSofa = 24,
    OneSofaOneBunk = 25,
    OneDoubleTwoBunk = 26,
    OneDoubleOneSingleOneBunk = 27,
    OneDoubleOneBunk = 28,
    OneBunk = 31,
    TwoBunk = 32,
    ThreeBunk = 35,
    FourBunk = 36,
    TwoDoubleOneBunk = 37,
    TwoSingleOneBunk = 38,
    TwoSingleTwoBunk = 39,
    OneSingleOneBunk = 40,
    ThreeSingleOneBunk = 41,
    OneDoubleTwoSingleOneBunk = 42,
    OneDoubleFourBunk = 43,
    TwoDoubleOneSingle = 44,
    TwoDoubleTwoSingle = 45,
    OneDoubleThreeSingle = 46,
    OneDoubleFourSingle = 47,
    ThreeDouble = 48,
    FourDouble = 49,
    TwoSingle = 52,
    FourSingle = 53,
    SemiDouble = 54,
  }
}
