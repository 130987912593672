export namespace CoreCommon {
  export enum LanguageType {
    zh_CN = 'zh-CN',
    en_US = 'en-US',
    ja_JP = 'ja-JP',
    _zh_CN = 'zh_CN',
    _en_US = 'en_US',
    _ja_JP = 'ja_JP',

    // test affected
  }

  export type LanguageCode = 'en-US' | 'ja-JP' | 'zh-CN';

  export enum SpecialRequestType {
    Non_Smoking = 1,
    Smoking = 2,
    Double_Bed = 3,
    Twin_Bed = 4,
    Separate_Beds = 5,
    Baby_Cot = 6,
    Internet = 7,
    Bathtub = 8,
    Air_Condition = 9,
    Arrive_immediately = 10,
    Early_Check_In = 11,
    Early_Check_In_With_Time = 12,
    Early_Check_Out = 13,
    Late_Check_In = 14,
    Late_Check_In_With_Time = 15,
    Connecting_Room = 16,
    Adjacent_Rooms = 17,
    Quiet_Room = 18,
    Honeymoon = 19,
    Disabled_Facilities_Room = 20,
    Higher_Floor = 21,
    Lower_Floor = 22,
    Ground_Floor = 23,
    Same_Floor = 24,
    No_Voucher = 25,
    Flight_Time = 26,
    Flight_Num = 27,
    Frequent_Guest_Number = 28,
    Extended_stay_in_original_room = 29,
    Late_Check_Out = 30,
    Other = 31,
  }

  export interface DidaSpecialRequest {
    SpecialRequestType: SpecialRequestType;
    Value?: string;
  }

  export enum MealType {
    RoomOnly = 1,
    Breakfast = 2,
    HalfBoard = 3,
    FullBoard = 4,
    AllInclusive = 5,
    Dinner = 6,
    BreakfastAndDinner = 7,
    BreakfastAndLunch = 8,
  }

  export interface MealTypeInfo {
    ID: MealType;
    Name: string;
  }

  export interface MealInfoModel {
    MealType?: MealTypeInfo;
    MealAmount?: number;
  }
  export enum Breakfasts {
    NoBreakfast = 1,
    WithBreakfast = 2,
  }
  export interface BreakfastType {
    ID: Breakfasts;
    Name: string;
  }

  export enum PayChannelTypeEnum {
    // Null"
    // 不需要支付方式"
    Null = 0,

    // DidaShoppingBalance"
    // 额度支付"
    DidaShoppingBalance = 3,

    /// <summary>
    /// 原本是微信支付的，后来改成了扫码支付的名称
    /// 暂时没有勇气去全部替换了
    /// </summary>
    // WeChat"
    // 扫码支付"
    WeChat = 1,

    // Alipay"
    // 支付宝支付"
    Alipay = 2,

    // BaoFoo"
    // 网银支付"
    BaoFoo = 4,

    // Oceanpayment"
    // 钱海支付"
    Oceanpayment = 5,

    // DeferredPayment"
    // 延时支付"
    DeferredPayment = 6,

    // WeChatMinAppPay"
    // 微信小程序支付"
    WeChatMinAppPay = 7,

    // Nuvei"
    // Nuvei"
    Nuvei = 8,

    // NuveiAPI"
    // NuveiAPI"
    NuveiAPI = 9,

    // PropertyCollect"
    // PropertyCollect"
    PropertyCollect = 10,
  }

  export interface PaginateOption {
    PageSize: number;
    PageNum: number;
  }
  export interface PaginationBaseModel {
    PageNum: number;
    PageSize: number;
    TotalAmount: number;
  }

  export interface PaginationWrapper<T> extends PaginationBaseModel {
    Data: T[];
    PageNum: number;
    PageSize: number;
  }
}
