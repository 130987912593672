import { FalconPayment } from '@b2c/services/pay-channel';
import { useMemo } from 'react';
import { useLocale } from './use-locale';

export function usePaymentTypeInfo(
  ratePaymentType: FalconPayment.RatePaymentTypeEnum
) {
  const t = useLocale();
  const paymentTypeNotice = useMemo(() => {
    switch (ratePaymentType) {
      case FalconPayment.RatePaymentTypeEnum.PayAtPropertyWithoutCreditCard:
        return {
          label: t('Text_PayLaterWithoutCredit'),
          title: t('Text_PayLaterWithoutCredit_DescContent'),
          content: t('Text_PayLaterWithoutCredit_DescTitle'),
        };
      case FalconPayment.RatePaymentTypeEnum.PayAtPropertyWithCreditCard:
        return {
          label: t('Text_PayLaterWithCredit'),
          title: t('Text_PayLaterWithCredit_DescTitle'),
          content: t('Text_PayLaterWithCredit_DescContent'),
        };
      default:
        return null;
    }
  }, [ratePaymentType, t]);

  return paymentTypeNotice;
}
