import { CoreCommon } from '@b2c/core';
import { Display } from '../decorator';
export class PayChannelTypeModel {
  @Display({
    name: 'All',
    theme: 'default',
  })
  static All = CoreCommon.PayChannelTypeEnum.Null;

  // @Display({
  //   name: '支付宝',
  //   desc: 'Alipay',
  // })
  // static Alipay = 2;
  //
  // @Display({
  //   name: '微信支付',
  //   theme: 'Wechatpay',
  // })
  // static WeChatMinAppPay = 7;
  @Display({
    name: 'Nuvei',
    theme: 'Nuvei',
  })
  static Nuvei = CoreCommon.PayChannelTypeEnum.Nuvei;

  @Display({
    name: 'Property Collect',
    theme: 'Property Collect',
  })
  PropertyCollect = CoreCommon.PayChannelTypeEnum.PropertyCollect;
}
