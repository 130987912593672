import { CoreCommon } from '@b2c/core';
import { FalconContext } from '@b2c/services/auth';
import { FalconPayment } from '@b2c/services/pay-channel';
import { createContext } from 'react';

export type AppCustomerSerivceSetting = {
  email: string;
  tels: {
    code: string;
    number: string;
    desc?: string;
  }[];
};

export type AppConfigModel = {
  // defaultPhonePrefix?: string;
  brandName?: string;
  ratePaymentTypeList?: FalconPayment.RatePaymentTypeEnum[];
  defaultLocale?: CoreCommon.LanguageCode;
  defaultCurrency?: string;
  defaultNationality?: string;
  specifyNationality?: string;
  customerService?: AppCustomerSerivceSetting;
  pathBase?: string;
  // polices: {
  //   title: string;
  //   path: string;
  // }[];
};

export const AppContextInfoContext = createContext<{
  appContext: FalconContext.AppContextInfo;
  loadAppContext: (
    currencyCode?: string,
    languageCode?: CoreCommon.LanguageCode
  ) => Promise<FalconContext.AppContextInfo | void>;
}>({
  appContext: null,
  loadAppContext: null,
});

export const AppConfigContext = createContext<AppConfigModel>({
  customerService: {
    email: null,
    tels: [],
  },
  // polices: [],
});
