import { useContext, useMemo } from 'react';
import { CountryInfoContext } from './country-info.context';

export function useCountryInfo(countryCode: string) {
  const nationInfoList = useContext(CountryInfoContext).countryInfoList;

  const countryInfo = useMemo(() => {
    return nationInfoList.find((item) => item.code === countryCode);
  }, [countryCode, nationInfoList]);

  return countryInfo;
}
