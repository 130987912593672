import { CoreHotelBooking } from '@b2c/core';
import { FalconBooking, HotelBookingHelper } from '@b2c/services/booking';
import { getDisplayInfo } from '@b2c/services/decorator';
import { FalconPayment, RatePaymentType } from '@b2c/services/pay-channel';
import { RatePlanHelper } from '@b2c/services/rateplan';
import { addMinutes } from 'date-fns';
import { useLocale } from './use-locale';

export function useBookingViewInfo(
  bookingDetail: FalconBooking.BookingDetailModel
) {
  const t = useLocale();
  if (!bookingDetail) {
    return {};
  }

  if (process.env.NODE_ENV === 'development') {
    // bookingDetail.StaticInfo.RatePaymentType = 2;
  }

  const isBooked =
    bookingDetail.BookingStatus === CoreHotelBooking.StatusType.Booked;

  const paymentTypeInfo = getDisplayInfo(
    RatePaymentType,
    bookingDetail.StaticInfo.RatePaymentType
  );

  let payLaterDesc = '';
  let isPayLater = true;
  switch (bookingDetail?.StaticInfo.RatePaymentType) {
    case FalconPayment.RatePaymentTypeEnum.PayAtPropertyWithCreditCard:
      payLaterDesc = t('Text_PayLaterWithCredit');
      break;
    case FalconPayment.RatePaymentTypeEnum.PayAtPropertyWithoutCreditCard:
      payLaterDesc = t('Text_PayLaterWithoutCredit');
      break;
    default:
      isPayLater = false;
  }

  const isCancellable = HotelBookingHelper.checkCancellable(bookingDetail);

  let expiredTime: Date = null;
  if (
    [
      CoreHotelBooking.StatusType.Pending,
      CoreHotelBooking.StatusType.Booked,
    ].includes(bookingDetail?.BookingStatus)
  ) {
    const orderTime = bookingDetail.OrderTime;
    const now = new Date(orderTime);
    expiredTime = addMinutes(now, HotelBookingHelper.AutoFailedMinutes);
  }

  const totalExcludedCharges = RatePlanHelper.getTotalChargeInfo(
    bookingDetail.PriceSummary.ExcludedFees
  );

  const excludedFeeCurrencyNotMatch =
    totalExcludedCharges?.Amount > 0 &&
    totalExcludedCharges.Currency !== bookingDetail.PriceSummary.Currency;

  let totalPrice = bookingDetail.PriceSummary.Price;
  if (!excludedFeeCurrencyNotMatch && totalExcludedCharges) {
    totalPrice += totalExcludedCharges.Amount;
  }

  return {
    bookingNumber: bookingDetail.BookingNumber,
    isBooked,
    isCanceled:
      bookingDetail.BookingStatus === CoreHotelBooking.StatusType.Canceled,
    paymentTypeInfo,
    payLaterDesc,
    isPayLater,
    isCancellable,
    expiredTime,
    totalPrice,
    totalExcludedCharges,
    excludedFeeCurrencyNotMatch,
  };
}
