const storageKey = 'cur_bno';
export function useBookingTokenInSession() {
  const bookingToken = sessionStorage.getItem(storageKey);

  const setBookingToken = (token: string) => {
    sessionStorage.setItem(storageKey, token);
  };

  return [bookingToken, setBookingToken] as [string, typeof setBookingToken];
}
