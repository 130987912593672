import { HttpService } from '@b2c/services/http';
import { useEffect, useRef, useState } from 'react';
export function useMarkdownInfo(fileName: string) {
  // const t = useLocale()

  const instanceId = useRef('doc_' + Date.now());
  const [list, setList] = useState<
    {
      title: string;
      id: string;
      sub: {
        title: string;
        content: string;
        id: string;
      }[];
    }[]
  >([]);

  useEffect(() => {
    HttpService.get<string>(fileName).then((resp) => {
      const list = [];
      ('\n' + resp)
        .split('\n## ')
        .filter((item) => Boolean(item.trim()))
        .forEach((item) => {
          const contentIndex = item.indexOf('\n');

          const subList = [];
          const listItem = {
            title: item.slice(0, contentIndex).replaceAll('\\', ''),
            sub: subList,
          };

          item
            .slice(contentIndex)
            .split('\n### ')
            .filter((item) => Boolean(item.trim()))
            .forEach((h3) => {
              const subContentIndex = h3.indexOf('\n');
              subList.push({
                title: h3.slice(0, subContentIndex).replaceAll('\\', ''),
                content: h3.slice(subContentIndex),
              });
            });

          list.push(listItem);
        });

      list.forEach((item, index) => {
        item.id = `${instanceId.current}_${index}`;
        item.sub.forEach((subItem, subIndex) => {
          subItem.id = `${item.id}_${subIndex}`;
        });
      });

      setList(list);
    });
  }, [fileName]);

  return list;
}
