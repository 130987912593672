import { useMarkdownInfo } from '@b2c/hooks';
import { RightOutlineIcon } from '@b2c/icons';
import { MarkdownLazy } from '@b2c/ui-pc';
import classNames from 'classnames';
import { Suspense, useRef, useState } from 'react';
import { Flex, Popover, PopoverInstance, Space } from 'react-vant';
import styles from './reactive-doc.module.less';

export function ReactiveDoc(props: { name: string }) {
  // const t = useLocale()

  const list = useMarkdownInfo(`/assets/docs/${props.name}.md`);

  const popover = useRef<PopoverInstance>(null);

  const [openList, setOpenList] = useState<string[]>([]);

  const [currentItem, setCurrentItem] = useState<{
    title: string;
  }>();

  const [sideNavOpen, setSideNavOpen] = useState(false);

  function scrollTo(id: string) {
    document.querySelector(`#${id}`).scrollIntoView({
      behavior: 'smooth',
    });
  }

  return (
    <div>
      <Space gap={16} block direction="vertical">
        <div className={styles.popover}>
          <Popover
            placement="bottom-start"
            ref={popover}
            onOpen={() => setSideNavOpen(true)}
            onClose={() => setSideNavOpen(false)}
            reference={
              <div
                className={classNames(styles.sideNav, {
                  [styles.opened]: sideNavOpen,
                })}
              >
                <Flex justify="between" align="center">
                  <div className={styles.value}>
                    {(currentItem || list[0])?.title}
                  </div>
                  <div className={styles.toggle}>
                    <RightOutlineIcon className={styles.icon} />
                  </div>
                </Flex>
              </div>
            }
          >
            <div className={styles.dropdown}>
              {list.map((item, itemIndex) => (
                <div
                  className={classNames(styles.item)}
                  key={item.id}
                  onClick={() => {
                    scrollTo(item.id);
                    setCurrentItem(item);
                    setSideNavOpen(false);
                    popover.current?.hide();
                  }}
                >
                  <span>{item.title}</span>
                </div>
              ))}
            </div>
          </Popover>
        </div>
        {list.map((item) => (
          <div className={styles.cell} key={item.id} id={item.id}>
            <div className={styles.title}>{item.title}</div>
            {item.sub.map((subItem, subIdx) => (
              <div
                className={classNames(styles.item, {
                  [styles.opened]: openList.includes(subItem.id),
                })}
                key={subItem.id}
              >
                <div
                  className={styles.heading}
                  onClick={() => {
                    if (openList.includes(subItem.id)) {
                      setOpenList(openList.filter((i) => i !== subItem.id));
                    } else {
                      setOpenList([...openList, subItem.id]);
                    }
                  }}
                >
                  <Flex justify="between" align="center">
                    <Flex gutter={8}>
                      <Flex.Item>{subIdx + 1}.</Flex.Item>
                      <Flex.Item>
                        <span>{subItem.title}</span>
                      </Flex.Item>
                    </Flex>
                    <div className={styles.toggle}>
                      <RightOutlineIcon className={styles.icon} />
                    </div>
                  </Flex>
                </div>

                <div className={styles.contentWrapper}>
                  <div className={styles.content}>
                    <Suspense>
                      <MarkdownLazy content={subItem.content}></MarkdownLazy>
                    </Suspense>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </Space>
    </div>
  );
}
