import { createContext } from 'react';

export const QueryParamsContext = createContext<{
  queryParams: any;
  updateQueryParams: <T>(params?: T) => void;
  showLoginGuide?: boolean;
}>({
  queryParams: {},
  updateQueryParams: () => {},
});
