import { CoreCommon } from '@b2c/core';
import { useContext, useMemo } from 'react';
import { AppContextInfoContext } from './app-context-info.context';
import { AppAvailLocaleList } from './use-locale-list';

export function useContextLanguage() {
  const { appContext, loadAppContext } = useContext(AppContextInfoContext);

  const locale = appContext?.locale || 'en-US';

  const languageInfo = useMemo(() => {
    return (
      AppAvailLocaleList.find((item) => item.langCode === locale) ||
      AppAvailLocaleList[0]
    );
  }, [locale]);

  function setLanguage(lang: CoreCommon.LanguageCode) {
    return loadAppContext(null, lang);
  }

  return {
    // languageList,
    setLanguage,
    languageInfo,
  };
}
