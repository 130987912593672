import { AppContextInfoContext, useLocale, useQueryParams } from '@b2c/hooks';
import { CurrencyHelper, CurrencyModel } from '@b2c/services/currency';
import { FunctionComponent, useContext, useMemo } from 'react';
import { Cell } from 'react-vant';

// const hotCurrencyList = ['USD', 'EUR', 'CNY']
//   .map((key) => CurrencyHelper.currencyMap.get(key))
//   .filter((item) => !!item);

export const CurrencyPicker: FunctionComponent<{
  onChange(): void;
}> = (props) => {
  const { loadAppContext, appContext } = useContext(AppContextInfoContext);
  const t = useLocale();

  const { updateQueryParams } = useQueryParams();

  function updateCurrency(currency: string) {
    updateQueryParams({
      cur: currency,
    });
    setTimeout(() => {
      window.location.reload();
    });
  }

  const allCurrencyList = useMemo(() => {
    return appContext.currencyList
      .map((item) => CurrencyHelper.currencyMap.get(item))
      .filter((item) => !!item);
  }, [appContext]);

  const renderCurrencyList = (currencyList: CurrencyModel[]) => {
    return (
      <>
        {currencyList.map((currency) => (
          <Cell
            key={currency.value}
            onClick={() => updateCurrency(currency.value)}
          >
            <span
              className={
                currency.value === appContext.currencyInfo.Currency
                  ? 'text-color'
                  : 'text-default'
              }
            >
              {currency.value}&nbsp;&nbsp;{t(currency.name)}
            </span>
          </Cell>
        ))}
      </>
    );
  };

  return (
    <div>
      {/* <Cell.Group title={t('Lable_PopularCurrency')}>
        {renderCurrencyList(hotCurrencyList)}
      </Cell.Group> */}
      <Cell.Group title={t('Label_AllCurrency')}>
        {renderCurrencyList(allCurrencyList)}
      </Cell.Group>
    </div>
  );
};
