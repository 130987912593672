export namespace FalconMembership {
  export interface UserPasswordResetForm {
    Email: string;

    ResetToken: string;

    NewPassword: string;
  }

  export interface UserSignInForm {
    UserName: string;
    Password: string;
    RememberMe: boolean;
    BookingToken: string;
  }

  export interface UserChangeEmailForm {
    Email: string;

    Token: string;
  }
  export interface UserChangePasswordForm {
    OldPassword: string;

    NewPassword: string;
  }

  export enum PasswordResetResultType {
    Success = 1,
    Failed = 2,
    UserNotFound = 3,
    TokenNotCorrectOrExpired = 4,
  }

  export interface PasswordResetResult {
    ResultType: PasswordResetResultType;
    Message: string;
  }

  export enum SignInStatus {
    /**Sign in was successful */
    Success,

    /**User is locked out */
    LockedOut,

    /**Sign in requires addition verification (i.e. two factor) */
    RequiresVerification,

    /**Sign in failed (username/password not correct) */
    Failure,

    /**Internal error */
    Exception,
  }

  export interface UserRegisterPayload {
    UserName: string;

    Email: string;

    Password: string;

    EmailToken: string;

    BookingToken: string;
  }

  export interface UserProfileModel {
    UserID: string;
    UserName: string;
    Email: string;
    EmailConfirmed: boolean;
    Status: number;
  }
  export enum EmailChangeResultType {
    Success = 1,
    Failed = 2,
    UserNotFound = 3,
    TokenNotCorrectOrExpired = 4,
  }

  export enum PasswordChangeResultType {
    Success = 1,
    Failed = 2,
    UserNotFound = 3,
    OldPasswordNotMatch = 4,
  }
  export enum UserStatusTypeEnum {
    /**新创建用户状态 */
    Init = 0,
    Active = 1,
    Locked = 2,
  }
  export interface UserBookingBindingPayload {
    // UserID: string;
    BookingNumber: string;
    // SiteDomainName: string;
    //     BindingOptions: UserBookingBindingOptions;
    // }
    // export interface UserBookingBindingOptions {
    //     /**是否忽略联系信息一致性 */
    //     IgnoreValidateContactInfo: boolean;
    //     /**是否允许变更绑定目标 */
    //     AllowBindingOverwrite: boolean;
  }
}
