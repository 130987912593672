import { ValidateOptions } from './validate.models';

const validateMetadataKey = Symbol('Validate');

export function Validate(config: ValidateOptions | boolean) {
  return Reflect.metadata(validateMetadataKey, config);
}

export function getValidateOptions(
  target: any,
  propertyKey: string,
): ValidateOptions | boolean {
  return Reflect.getMetadata(validateMetadataKey, target, propertyKey);
}
