import { CoreCommon } from './common';

export namespace CoreSuggestion {
  export interface SuggestionItem {
    ID: string;
    IDString: string;
    Metadata: SuggestionItemMetadata;
    HierarchyInfo: SuggestionItemHierarchy;
    DisplayOption: SuggestionDisplayOption;
    Category: SuggestionItemCategory;
    SubClass: string;
    Score: number;
    Distance: number;
    Popularity: number;
    SearchCount: number;
    IsDestination: boolean;
    ItemObject: any;
    ParentRegionIDString?: string;
  }

  export interface SuggestionItemMetadata {
    HotelCount: number;
    Location: {
      Latitude: number;
      Longitude: number;
    };
    TransferLocation: {
      LocationTypeID: number;
      LocationCode: string;
    }[];
  }

  export interface SuggestionItemHierarchy {
    Country: SuggestionHierarchyCountry;
  }
  export interface SuggestionHierarchyCountry {
    CountryCode: string;
    CountryID: number;
    CountryName: string;
    // CountryName_CN: string;
    CountryPinYin: string;
    IsActive: boolean;
  }
  export interface SuggestionDisplayOption {
    FullName: string;
    ShortName: string;
    DisplayName: string;
  }
  export enum SuggestionItemCategory {
    Hotel = 0,
    POI = 1,
    POIS = 2,
    Neighborhood = 3,
    City = 4,
    MultiCity = 5,
    MultiRegion = 6,
    Province = 7,
    Country = 8,
    Continent = 9,
    TrainStation = 10,
    MetroStation = 11,
    HighLevelRegion = 12,
    Airport = 13,
    Unknown = 100,
  }
  export interface SuggestionQueryPayload {
    DocID?: string;
    IDs: (number | string)[];
    Keyword: string;
    ParentID: string;
    Categories: SuggestionItemCategory[];
    // TransferLocations: string[];
    // DestinationOnly: boolean;
    WithMetadata: boolean;
    WithHierarchy: boolean;
    WithItemObject: boolean;
    Language: CoreCommon.LanguageCode;
    Size: number;
    PaginationOption: SuggestionQueryPaginationPayload;
  }
  export interface SuggestionQueryPaginationPayload {
    PageNum: number;
  }
}
