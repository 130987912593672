import { CoreSuggestion } from '@b2c/core';
import { DestinationQueryParams } from '@b2c/services/common';
import { DestinationSearchHelper } from '@b2c/services/destinations';
import { SuggestionOption, SuggestionService } from '@b2c/services/suggestions';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DestinationSearchHistoryContext } from './use-destination-search-history';
import { useQueryParams } from './use-query-params';

let instanceCount = 0;

export const useDestinationInfo = function () {
  const { queryParams, updateQueryParams } =
    useQueryParams<DestinationQueryParams>();
  const { hotelId } = useParams<{ hotelId: string }>();

  const [destinationInfo, setDestinationInfo] =
    useState<SuggestionOption>(null);

  const { updateHistoryList } = useContext(DestinationSearchHistoryContext);

  useEffect(() => {
    instanceCount++;
    if (instanceCount > 1) {
      console.error("Don't invoke useDesination more than one time");
    }
    return () => {
      instanceCount--;
    };
  }, []);

  useEffect(() => {
    if (hotelId) {
      restoreDestinationInfo(hotelId, (destId) =>
        SuggestionService.serachRegionByHotelId(destId)
      );
      return;
    }

    let rId = null;
    let hId = null;
    if (!destinationInfo) {
      if (queryParams.hid) {
        hId = queryParams.hid;
      } else {
        rId = queryParams.rid;
      }
    } else if (
      destinationInfo.category === CoreSuggestion.SuggestionItemCategory.Hotel
    ) {
      hId = queryParams.hid;
    } else {
      rId = queryParams.rid;
    }

    if (rId && rId !== destinationInfo?.id) {
      restoreDestinationInfo(rId, (destId) =>
        SuggestionService.searchRegion(destId)
      );
    }
    if (hId && hId !== destinationInfo?.id) {
      restoreDestinationInfo(hId, (destId) =>
        SuggestionService.serachRegionByHotelId(destId)
      );
    }
  }, [queryParams.rid, queryParams.hid]);

  async function restoreDestinationInfo(
    destId: string,
    serviceCall: (destId: string) => Promise<CoreSuggestion.SuggestionItem>
  ) {
    if (destinationInfo?.id === destId) {
      return;
    }
    setDestinationInfo({
      id: destId,
      name: '',
    } as any);
    try {
      const sugguestion = await serviceCall(destId);
      if (sugguestion) {
        const option = DestinationSearchHelper.toOption(sugguestion);
        setDestinationInfo(option);
        updateHistoryList(option);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return {
    destinationInfo,
    setDestinationInfo: (region: SuggestionOption) => {
      const params: DestinationQueryParams = {
        rid: region.id,
      };
      if (region.category === CoreSuggestion.SuggestionItemCategory.Hotel) {
        params.rid = region.destinationID;
        params.hid = region.id as any;
      }
      updateQueryParams<DestinationQueryParams>(params);
      setDestinationInfo(region);
    },
    restoreDestinationInfo,
  };
};
