import { useLocale, useNavigater } from '@b2c/hooks';
import { FalconBooking } from '@b2c/services/booking';
import { Flex, Space, Typography } from 'react-vant';
import { RatingStar } from '../rating-star';

import { PhoneO } from '@react-vant/icons';
export function BookingHotelOverview({
  bookingDetail,
}: {
  bookingDetail: FalconBooking.BookingDetailModel;
}) {
  const t = useLocale();
  const { navigateTo, navigateReplace } = useNavigater();
  const gotoHotelDetail = (hotelId: number) => {
    navigateTo('/hotel/detail', { hid: hotelId });
  };
  return (
    <>
      <Flex justify="between">
        <Typography.Title
          strong
          onClick={() => gotoHotelDetail(bookingDetail.StaticInfo.HotelID)}
        >
          {bookingDetail.StaticInfo.HotelName}
        </Typography.Title>
      </Flex>
      <Space direction="vertical" block gap={8}>
        {bookingDetail.StaticInfo.HotelStarRating && (
          <Flex>
            <Flex.Item span={6}>
              <Typography.Text type="secondary">
                {t('Label_StarRating')}
              </Typography.Text>
            </Flex.Item>
            <Flex.Item span={18}>
              <RatingStar value={bookingDetail.StaticInfo.HotelStarRating} />
            </Flex.Item>
          </Flex>
        )}
        <Flex>
          <Flex.Item span={6}>
            <Typography.Text type="secondary">
              {t('Label_Address')}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item span={18}>
            {bookingDetail.StaticInfo.HotelAddress},{' '}
            {bookingDetail.StaticInfo.HotelDestinationName}
          </Flex.Item>
        </Flex>
        <Flex>
          <Flex.Item span={6}>
            <Typography.Text type="secondary">
              {t('Label_Phone')}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item span={18}>
            <Flex align="center">
              {bookingDetail.StaticInfo.HotelPhone}{' '}
              <Typography.Link
                href={'tel:' + bookingDetail.StaticInfo.HotelPhone}
                style={{ lineHeight: 1 }}
              >
                &nbsp;
                <PhoneO />
              </Typography.Link>
            </Flex>
          </Flex.Item>
        </Flex>
        <Flex>
          <Flex.Item span={6}>
            <Typography.Text type="secondary">
              {t('Label_ZipCode')}
            </Typography.Text>
          </Flex.Item>
          <Flex.Item span={18}>
            {bookingDetail.StaticInfo.HotelZipCode || 'None'}
          </Flex.Item>
        </Flex>
      </Space>
    </>
  );
}
