export namespace FalconCommon {
  export enum MessageCodes {
    Success = 20000,

    ValidationError = 30000,

    AccessError_NeedLogin = 31001,
    AccessError_PermissionDeny = 31002,
    AccessError_InsecureRequest = 31003,

    ProcessError = 40000, // not specific
    ProcessError_Timeout = 41000,
    ProcessError_ServiceError = 42000,
    ProcessError_ServiceError_IncorrectSecureData = 42001, // BID, RESPONSE_ID, etc...
  }

  export interface DayCancellation {
    /**
     * PaH返回的是酒店时间
     */
    CancellationFromTime: string;

    /**酒店当地时间 */
    CancellationFromTimeLocal: string;

    CancellationFromTimestampSeconds: number;

    Currency: string;

    Amount: number;

    // /**
    //  * @deprecated replace with CancellationFromTime
    //  */
    // FromDate: string;
    // /**
    //  * @deprecated replace with Amount
    //  */
    // Price: number;
    // /**
    //  * @deprecated replace with Amount
    //  */
    // NetRate: number;
  }

  export class GeoCoordinate {
    Latitude: number;
    Longitude: number;
  }

  export class TimeZoneInfo {
    OffsetExpression: string;
    OffsetSeconds: number;
  }

  export interface BedTypeModel {
    ID: BedTypes;
    DefaultOccupancy: number;
    Name: string;
    BedLevel: number;
  }
}
