import { useMemo } from 'react';

const ELEMENT_CONNECT_CODE = '__';
const MODIFIER_CONNECT_CODE = '--';

function bem(block: string) {
  const blockCls = block;
  return Object.assign(blockCls, {
    toString() {
      return blockCls;
    },
    el: (element: string, modifier?: string) => {
      let elementCls = blockCls + ELEMENT_CONNECT_CODE + element;
      if (modifier) {
        elementCls = elementCls + MODIFIER_CONNECT_CODE + modifier;
      }
      return Object.assign(elementCls, {
        toString() {
          return elementCls;
        },
        mo(modifier: string) {
          const modifierCls = elementCls + ELEMENT_CONNECT_CODE + modifier;
          return modifierCls;
        },
      });
    },
    mo(modifier: string) {
      return blockCls + MODIFIER_CONNECT_CODE + modifier;
    },
  });
}
/**
 * create BEM style classa name
 * @param block
 * @returns
 */
export function useBEMBlock(block: string) {
  const blockCls = useMemo(() => bem(block), [block]);
  return blockCls;
}
