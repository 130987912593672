import { OccupancySettingModel, useLocale } from '@b2c/hooks';
import { DateRangeModel, DateTimeHelper } from '@b2c/services/common';
import { NationalityInfoModel } from '@b2c/services/nation';
import { FunctionComponent, useMemo, useState } from 'react';
import { Calendar, Cell, Flex, Popup, Typography } from 'react-vant';
import { NationalityPicker } from '../nationality-picker/nationality-picker';
import { OccupancySetting } from '../occupancy-setting/occupancy-setting';
import { Panel } from '../panel/panel';
import { TypographyDate } from '../typography-date';
import { TypographyDateTime } from '../typography-date-time';
import styles from './search-panel.module.less';

export const SearchPanel: FunctionComponent<{
  occupancySetting?: OccupancySettingModel;
  dateRange: DateRangeModel;
  nationalityInfo?: NationalityInfoModel;
  /**
   * 仅展示第一间过滤条件，用于查询酒店报价
   */
  onlyFirstRoom?: boolean;
  inset?: boolean;
  onDateRangeChange?(dateRange: DateRangeModel);
  onOccupancyChange?(occupancySetting: OccupancySettingModel);
  onNationalityChange?(nationalityInfo: NationalityInfoModel);
}> = (props) => {
  const { dateRange, occupancySetting, nationalityInfo } = props;
  const [showCalendar, setShowCalendar] = useState(false);
  const [occupancyPopupVisible, setOccupancyPopupVisible] = useState(false);
  const t = useLocale();

  const occupancydesc = useMemo(() => {
    if (!occupancySetting) {
      return [];
    }
    let ocucpancydesc = '';
    if (props.onlyFirstRoom) {
      const itemList = [
        t('Text_RoomWithCount', {
          count: occupancySetting.roomCount,
        }),
        t('Text_AdultWithCount', {
          count: occupancySetting.roomList[0].adultCount,
        }),
        // occupancySetting.nationality,
      ];
      if (occupancySetting.roomList[0].childCount > 0) {
        itemList.push(
          t('Text_ChildrenWithCount', {
            count: occupancySetting.roomList[0].childCount,
          })
        );
      }
      ocucpancydesc = itemList.join(', ');
    } else {
      ocucpancydesc = t('Text_RoomWithCount', {
        count: occupancySetting.roomCount,
      });
    }

    return ocucpancydesc;
  }, [occupancySetting, t, props.onlyFirstRoom]);

  const [nationalityVisible, setNationalityVisible] = useState(false);

  const dateCount = DateTimeHelper.getDateDiff(dateRange.From, dateRange.To);

  return (
    <Panel
      className={styles.searchPanel}
      inset={props.inset}
      style={
        props.inset
          ? { paddingTop: 0, paddingBottom: 0, overflow: 'hidden' }
          : null
      }
    >
      <Cell isLink={!!props.onDateRangeChange} style={{ alignItems: 'center' }}>
        <Flex
          style={{ lineHeight: '24px' }}
          className={styles.staydate}
          onClick={() => props.onDateRangeChange && setShowCalendar(true)}
          justify="between"
          align="end"
          gutter={6}
        >
          <Flex.Item>
            <Typography.Text type="secondary">
              {t('Label_Checkin')}
            </Typography.Text>
            <div>
              <TypographyDate value={dateRange.From}></TypographyDate>
            </div>
          </Flex.Item>
          <Flex.Item>
            <div hidden>Room Night</div>
            <div className={styles.seperater}>
              <Typography.Text type="secondary">
                {t('Text_NightCountDesc', { nightCount: dateCount })}
              </Typography.Text>
            </div>
          </Flex.Item>
          <Flex.Item>
            <Typography.Text type="secondary">
              {t('Label_Checkout')}
            </Typography.Text>
            <div>
              <TypographyDate value={dateRange.To}></TypographyDate>
            </div>
          </Flex.Item>
        </Flex>
      </Cell>
      {props.occupancySetting && (
        <>
          <Cell
            isLink={!!props.onOccupancyChange}
            title={
              <Typography.Text type="secondary">
                {t('Label_Occupancy')}
              </Typography.Text>
            }
            titleStyle={{ flex: 'unset' }}
            onClick={() => {
              props.onOccupancyChange && setOccupancyPopupVisible(true);
            }}
          >
            <Typography.Text strong size="lg">
              {occupancydesc}
            </Typography.Text>
          </Cell>
          <OccupancySetting
            visible={occupancyPopupVisible}
            setting={occupancySetting}
            onlyFirstRoom={props.onlyFirstRoom}
            onConfirm={(setting) => {
              props.onOccupancyChange?.call(null, setting);
              setOccupancyPopupVisible(false);
            }}
            onClose={() => setOccupancyPopupVisible(false)}
          ></OccupancySetting>
        </>
      )}
      {props.nationalityInfo && (
        <>
          <Cell
            isLink={!!props.onOccupancyChange}
            title={
              <Typography.Text type="secondary">
                {t('Label_Nationality')}
              </Typography.Text>
            }
            titleStyle={{ flex: 'unset' }}
            onClick={() =>
              props.onOccupancyChange && setNationalityVisible(true)
            }
          >
            <Typography.Text strong size="lg">
              {nationalityInfo.name}
            </Typography.Text>
          </Cell>
          <Popup
            visible={nationalityVisible}
            position="bottom"
            style={{ height: '100%' }}
          >
            <NationalityPicker
              onSelect={(event) => {
                props.onNationalityChange?.call(null, event);
                setNationalityVisible(false);
              }}
              onCancel={() => setNationalityVisible(false)}
            ></NationalityPicker>
          </Popup>
        </>
      )}

      <Calendar
        visible={showCalendar}
        type="range"
        maxRange={28}
        color="var(--rv-brand-color)"
        title={t('Label_SelectDates')}
        defaultValue={[dateRange.From, dateRange.To]}
        formatMonthTitle={(date) => (
          <TypographyDateTime
            value={date}
            format="MMMM yyyy"
          ></TypographyDateTime>
        )}
        formatter={(day) => {
          if (day.type === 'start') {
            day.bottomInfo = t('Text_Calendar_Checkin');
          } else if (day.type === 'end') {
            day.bottomInfo = t('Text_Calendar_Checkout');
          }
          return day;
        }}
        onConfirm={(dates) => {
          props.onDateRangeChange?.call(null, {
            From: dates[0],
            To: dates[1],
          });

          setShowCalendar(false);
        }}
        onClose={() => {
          setShowCalendar(false);
        }}
      />
    </Panel>
  );
};
