import { CoreCommon, CoreHotelBooking } from '@b2c/core';
import { FalconCommon } from '@b2c/services/common';
import { FalconBooking } from '../booking';
import { FalconHotel } from '../hotel';
import { FalconPayment } from '../pay-channel';

export namespace FalconRate {
  export interface RatePlanRateModel {
    StayDate: string;
    MealInfo: MealInfoModel;
    PriceSummary: FalconBooking.PriceSummary;
  }

  export interface RatePlanPriceModel {
    RoomNum: number;
    RatePlan: RatePlanModel;
    // RatePlanRateExtra: EanRateResponse;
    RateList: RatePlanRateModel[];
    //Supplement: number;
    //TotalCount: number;
    PriceSummary: FalconBooking.PriceSummary;
    ReferencePriceSummary: FalconBooking.PriceSummary;
    CancellationList: FalconCommon.DayCancellation[];
    CancellationNeedReGet: boolean;
    AllowGuestNameInChinese: boolean | null;
    TimeZoneInfo: FalconCommon.TimeZoneInfo;
    ChannelPriceInfo: FalconRate.ThirdPartyRateInfo;
    BankCardInputConditions: FalconPayment.BankCardInputConditions;
  }
  export interface RatePlanModel {
    DisplayName: string;
    ValueAddList: ValueAddModel[];
    SpecialPromotionList: PromotionModel[];
    IsHourlyRoom: boolean;
    OnRequestLimitTimeMinutes: number;
    IsOnRequest: boolean;
    BedTypeAssured: boolean;
    OTAForbidden: boolean;
    IsPackage: boolean;
    IsSaleByDida: boolean;
    IsHotSale: boolean;
    MealInfo: MealInfoModel;
    BreakfastType: CoreCommon.BreakfastType;
    BedType: FalconCommon.BedTypeModel;
    // RatePlanName_CN: string;
    RatePlanName: string;
    RoomTypeName: string;
    // DidaRoomTypeName_CN: string;
    DidaRoomTypeName: string;
    DidaRoomTypeID: number | null;
    RatePlanMask: string;
    DidaRatePlanID: string;
    // DisplayName_CN: string;
    ExtraInfoIDList: number[];

    SupportedSpecialRequestTypeList: number[];
    RatePaymentType: FalconPayment.RatePaymentTypeEnum;
  }
  export interface RatePlanRateSearchOption {
    //: HotelBookingConfirmOptions
    // #region [class:HotelBookingConfirmOptions]
    // CouponConsumeRecordList: CouponConsumeRecordInfoModel[];
    ValueAddList?: FalconRate.ValueAddModel[];
    IsNeedHourlyRoom: boolean;
    IsNeedOnRequest: boolean;
    // PayID: string;
    // PromotionID: string;
    // IsLowestPrice: boolean;
    // IsTransactionEnabled: boolean;
    // PayChannel: PayChannelTypeEnum;
    Nationality: string;
    // BookType: number;
    // IsGroupBooking: boolean;
    // ClientReference: string;
    SpecialRequests: CoreCommon.DidaSpecialRequest[];
    //      //CustomerRequest: string;
    //Insurance: Insurance;
    HotelID: number | string;
    Contact: CoreHotelBooking.Contact;
    RoomOccupancyList: CoreHotelBooking.RoomOccupancy[];
    // BookingId: string;
    // EanRequest: EanRequest;
    // SpecifiedActivityIDList: number[];
    // ChannelParameter: string;

    CheckIn: string;
    CheckOut: string;
    RoomCount: number;
    // WeChatOpenID: string;
    // IsReturnNetRate: boolean;
    // #endregion

    Currency: string;
    WithHotelImage: boolean;
    RatePlanMask: string;
    ResponseID: string;

    /**在预订页执行的RatePlanRate查询 */
    ForOrder: boolean;
    /**
     * 酒店级别查询的SessionID
     * 当执行SearchForOrder进行新预定的时候，需要提供（方便日志关联）
     */
    HotelRateSessionID: string;
    // LanguageCode: LanguageCode;

    ChannelPriceInfo?: FalconRate.ThirdPartyRateInfo;

    ExternalConnectInfo?: FalconRate.ExternalConnectInfo;
  }

  export interface ExternalConnectInfo {
    AppID: string;
    ExtConnectActionTicket?: string;
    ExtBookingNumber?: string;
    RatePlanID?: string;
    ExtConnectionParams: string;
  }

  export interface HotelPriceSearchResult
    extends CoreCommon.PaginationWrapper<HotelRatePlanPriceModel> {
    Success: boolean;
    ErrorCode: number;

    ReferenceNo: string;
    /**
     * @deprecated use Data instead
     */
    HotelPriceList: HotelRatePlanPriceModel[];
    TotalCount: number;
    HoistedCount: number;
    ResponseID: string;
    SessionID: string;
  }

  export interface BookingNotice {
    CheckInOut: string;
    Notice: string;
    ImportNotice: string;
  }

  export interface HotelRatePlanPriceModel {
    // : HotelRatePlanPriceModel
    Hotel: FalconHotel.HotelStaticDetailModel;
    PriceSummary: FalconBooking.PriceSummary;
    ReferencePriceSummary: FalconBooking.PriceSummary;
    CheckInInstructions: string;
    BookingNotice: BookingNotice;
    SpecialPromotionList: FalconRate.PromotionModel[];
    ValueAddList: FalconRate.ValueAddModel[];
    /**价格来源 */
    PriceSource: string;
    /**渠道价格信息 */
    ChannelPriceInfo: ThirdPartyRateInfo;

    RatePlanPriceList: RatePlanPriceModel[];
  }

  export interface ThirdPartyRateInfo {
    Source: string;
    SourceSite: string;
    RoomTypeID: number;
    RatePlanTypeID?: number;
    Currency: string;
    TotalPrice: number;
    TotalExcludedFee?: number;
    TotalIncludedFee?: number;
  }

  export interface ValueAddModel {
    ValueAddItemID: number;
    Content: string;
    Hint: string;
  }

  export interface PromotionModel {
    PromotionID: number;
    PromotionType: number;
    Name: string;
    Amount: number;
    Currency: string;
  }

  export interface RatePlanPriceSearchResult extends HotelPriceSearchResult {
    _ignore?: void;
  }

  export enum SearchRespCodeEnum {
    // #region [2xxxx] - 成功分类
    Success = 20000,
    // #endregion

    // #region [4xxxx] - 错误分类
    // [Description("Unknown Error")]
    UnknownError = 40000,

    // #region [41xxx] - 处理流程的错误

    // #endregion

    // #region [42xxx] - Dida价格搜索错误类型（此处的Code根据Core.DataModel.Models.Internal.ChannelHotelRates.RateErrorType转译）
    Error_RateSearch_UnexpectedError = 42000,
    Error_RateSearch_NoHotelOrDestination = 42001,
    Error_RateSearch_NoHotelFound = 42002,
    Error_RateSearch_IncorrectDate = 42003,
    Error_RateSearch_IncorrectRoomCount = 42004,
    Error_RateSearch_NoAvailableRoom = 42005,
    Error_RateSearch_NoRatePlanFound = 42006,
    Error_RateSearch_IncorrectRoomNum = 42007,
    Error_RateSearch_IncorrectChildAgeList = 42008,
    Error_RateSearch_CheckAvailabilityTimeout = 42009,
    Error_RateSearch_IncorrectHotelID = 42010,
    Error_RateSearch_IncorrectOccupancy = 42011,
    Error_RateSearch_IncorrectDateRange = 42012,
    Error_RateSearch_ClientReferenceExceedMaxLength = 42013,
    Error_RateSearch_ClientReferenceNotProvided = 42014,
    Error_RateSearch_IncorrectRangeForStayDate = 42015,
    Error_RateSearch_EanRequestIsNull = 42016,
    Error_RateSearch_ClientAuthFailed = 42017,
    Error_RateSearch_CurrencyNotSupported = 42018,
    Error_RateSearch_LimitationOfDelivery = 42019,
    Error_RateSearch_IncorrectRatePlanID = 42020,
    Error_RateSearch_IncorrectHotelCount = 42021,
    Error_RateSearch_LimitedCall = 42022,
    Error_RateSearch_NationalityNeeded = 42023,
    Error_RateSearch_IncorrectRealTimeAndOccupancy = 42024,
    Error_RateSearch_NationalityForbiden = 42025,
    Error_RateSearch_IncorrectCityCode = 42026,
    Error_RateSearch_CityCodeShouldWithLowestPriceOnly = 42027,
    Error_RateSearch_OutOfMemoryException = 42028,
    Error_RateSearch_HotelStopSell = 42029,
    Error_RateSearch_PriceNotApplicable = 42030,
    Error_RateSearch_IncorrectNationality = 42031,
    Error_RateSearch_RatePlanLocked = 42050,
    // #endregion

    // #region [43xxx] - Dida价格搜索预订相关错误类型（此处的Code根据Core.DataModel.Models.Internal.ChannelHotelRates.RateErrorType转译）
    Error_RateOrder_UnexpectedError = 43000,
    Error_RateOrder_InvalidOrginalBookingID = 43001,
    Error_RateOrder_InvalidReorderClient = 43002,
    // #endregion
    // #endregion
  }
}
