import { HttpService } from '../http';
import { FalconFontendLogModel, LogLevel } from './log.models';

export class LoggerService {
  /**
   * 将日志发送到服务器并写到阿里的日志里面去
   * 请不要滥用此方法
   * @param log
   */
  static sendLog(log: Partial<FalconFontendLogModel>) {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    // 浏览器安全策略会导致跨域时referrer头读不到完整链接
    log.location = location.href;

    HttpService.sendBeacon('/Environment/Frontend/Log', log);
  }

  static error(error, extra?: any) {
    // todo
    const level = /(test|local|dev)/.test(location.hostname)
      ? LogLevel.Debug
      : LogLevel.Error;

    this.sendLog({
      level: level,
      message: error.message,
      trace: error.stack,
      extra,
    });
  }

  static warn(message, extra?: any) {
    this.sendLog({
      level: LogLevel.Warn,
      message,
      extra,
    });
  }

  static info(message, extra?: any) {
    this.sendLog({
      level: LogLevel.Info,
      message,
      extra,
    });
  }
}
