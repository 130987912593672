import { CoreHotelBooking } from '@b2c/core';
import { FalconBooking } from '@b2c/services/booking';
import { OrderResultQueryParams } from '@b2c/services/common';
import { HotelOrderService } from '@b2c/services/order';
import { useEffect, useRef, useState } from 'react';
import { useNavigater } from './use-navigater';
import { useQueryParams } from './use-query-params';

const REALOAD_BOOKING_INTERVAL = 3;

export function useOrderInfo(options: {
  reloadUntilConfirmed: boolean;
  retryTimesOnFailed: number;
  onFail?(error): void;
}) {
  const { navigateTo } = useNavigater();
  const { queryParams } = useQueryParams<OrderResultQueryParams>();

  const [bookingDetail, setBookingDetail] =
    useState<FalconBooking.BookingDetailModel>();

  const [loading, setLoading] = useState(false);

  const bookingNumber = queryParams.bookingNumber;
  const orderingToken = queryParams.token;

  let shouldTryReload = true;
  let timer = null;
  const failedTryRemains = useRef(options.retryTimesOnFailed);

  useEffect(() => {
    return () => {
      shouldTryReload = false;
      timer && clearTimeout(timer);
    };
  }, []);

  const checkOrder = (bookingNumber: string) => {
    setLoading(true);
    return HotelOrderService.checkOrder({
      BookingNumber: bookingNumber,
      Token: orderingToken,
      NeedFullBookingInfo: true,
    })
      .then((resp) => {
        // if (condown > 0) {
        //   condown--;
        //   resp.BookingStatus = CoreHotelBooking.StatusType.Pending;
        // }
        const bookingStatus = resp.BookingStatus;
        switch (bookingStatus) {
          case CoreHotelBooking.StatusType.Canceled:
            navigateTo('/booking/detail', {
              bookingNumber: resp.BookingNumber,
              infoToken: resp.BookingInfoToken,
              cancelToken: resp.BookingCancelToken,
            });
            break;
          case CoreHotelBooking.StatusType.Pending:
          case CoreHotelBooking.StatusType.Booked:
          case CoreHotelBooking.StatusType.OnRequest:
            if (options.reloadUntilConfirmed && shouldTryReload) {
              console.log(
                `wait ${REALOAD_BOOKING_INTERVAL} seconds then reload booking`
              );
              timer = setTimeout(() => {
                checkOrder(bookingNumber).catch(
                  options.onFail || console.error
                );
              }, REALOAD_BOOKING_INTERVAL * 1000);
            }
            break;
          case CoreHotelBooking.StatusType.Failed:
            if (failedTryRemains.current > 0) {
              failedTryRemains.current--;
              resp.BookingStatus = CoreHotelBooking.StatusType.Pending;
              timer = setTimeout(() => {
                checkOrder(bookingNumber).catch(
                  options.onFail || console.error
                );
              }, REALOAD_BOOKING_INTERVAL * 1000);
            }
            break;
          case CoreHotelBooking.StatusType.Confirmed:
          default:
            break;
        }
        setBookingDetail(resp);
        return resp;
      })
      .finally(() => {
        setLoading(false);
        // MessageService.clear();
      });
  };

  useEffect(() => {
    if (!bookingNumber) {
      setBookingDetail(null);
      return;
    }
    const sessionCache = sessionStorage.getItem(
      `BookingSnapshot_${bookingNumber}`
    );
    if (sessionCache) {
      setBookingDetail(JSON.parse(sessionCache));
    } else {
      checkOrder(bookingNumber).catch((err) => {
        options.onFail ? options.onFail(err) : console.error(err);
      });
    }
  }, [bookingNumber]);

  return {
    bookingDetail,
    orderingToken,
    loading,
  };
}
