import { CoreCommon } from '@b2c/core';
import { ApplicationContextService, FalconContext } from '@b2c/services/auth';
import { AnalyticsHelper, CurrencyInfoQueryParams } from '@b2c/services/common';
import { useState } from 'react';
import { useQueryParams } from './use-query-params';

const CurrencyStorageKey = 'app_currency';
const LocaleStorageKey = 'app_locale';

function nomalizeLanguageCode(rawCode: string): CoreCommon.LanguageCode {
  return (
    {
      en: 'en-US',
      ja: 'ja-JP',
    }[rawCode] || (rawCode as any)
  );
}

function getBrowserContextLocale() {
  const storageLocale = localStorage.getItem(
    LocaleStorageKey
  ) as CoreCommon.LanguageCode;
  if (storageLocale) {
    return storageLocale;
  }
  return nomalizeLanguageCode(navigator.language);
}

export const useAppContext = function (
  defaultLanguageType: CoreCommon.LanguageCode = 'en-US',
  defaultCurrency = 'USD'
) {
  const [appContext, setAppContext] = useState<FalconContext.AppContextInfo>();
  const { queryParams } = useQueryParams<CurrencyInfoQueryParams>();

  function loadAppContext(
    currencyCode?: string,
    languageCode?: CoreCommon.LanguageCode
  ) {
    if (!currencyCode) {
      currencyCode =
        appContext?.currencyInfo.Currency ||
        queryParams.cur ||
        localStorage.getItem(CurrencyStorageKey) ||
        defaultCurrency;
    }
    if (!languageCode) {
      languageCode =
        appContext?.locale || getBrowserContextLocale() || defaultLanguageType;
    }
    return ApplicationContextService.getContext({
      Currency: currencyCode,
      LanguageCode: languageCode,
    }).then((resp) => {
      localStorage.setItem(CurrencyStorageKey, resp.currencyInfo.Currency);
      localStorage.setItem(LocaleStorageKey, resp.locale);
      setAppContext(resp);
      if (resp.userID) {
        AnalyticsHelper.setUserId(resp.userID);
      }
      return resp;
    });
  }

  return {
    appContext,
    setAppContext,
    loadAppContext,
  };
};
