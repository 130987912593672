import { Display, Enumerable, t } from '../decorator';
export class SpecialRequestTypeModel {
  @Display({
    name: t('SpecialRequest_NonSmokingRoom'),
  })
  static Non_Smoking = 1;

  @Display({
    name: t('SpeicalRequest_SmokingRoom'),
  })
  static Smoking = 2;

  @Display({
    name: t('SpecialRequest_DoubleRoom'),
  })
  static Double_Bed = 3;

  @Display({
    name: t('SpecialRequest_TwinBed'),
  })
  static Twin_Bed = 4;

  @Display({
    name: t('SpecialRequest_SeparateBeds'),
  })
  static Separate_Beds = 5;

  @Display({
    name: t('SpecialRequest_BabyCot'),
  })
  static Baby_Cot = 6;

  @Display({
    name: t('SpecialRequest_InternetAccess'),
  })
  static Internet = 7;

  @Display({
    name: t('SpecialRequest_Bathtub'),
  })
  static Bathtub = 8;

  @Display({
    name: t('SpecialRequest_Aircondition'),
  })
  static Air_Condition = 9;

  @Display({
    name: t('SpecialRequest_ArriveImmediately'),
  })
  static Arrive_immediately = 10;

  @Display({
    name: t('SpecialRequest_EarlyCheckin'),
  })
  static Early_Check_In = 11;

  @Enumerable(false)
  @Display({
    name: t('SpecialRequest_EarlyCheckinAround'),
  })
  static Early_Check_In_With_Time = 12;

  @Display({
    name: t('SpecialRequest_EarlyCheckout'),
  })
  static Early_Check_Out = 13;

  @Display({
    name: t('SpecialRequest_LateCheckin'),
  })
  static Late_Check_In = 14;

  @Enumerable(false)
  @Display({
    name: t('SpecialRequest_LateCheckinAround'),
  })
  static Late_Check_In_With_Time = 15;

  @Display({
    name: t('SpecialRequest_ConnectingRoom'),
  })
  static Connecting_Room = 16;

  @Display({
    name: t('SpecialRequest_AdjacentRooms'),
  })
  static Adjacent_Rooms = 17;

  @Display({
    name: t('SpecialRequest_RuietRoom'),
  })
  static Quiet_Room = 18;

  @Display({
    name: t('SpecialRequest_HoneymoonRoom'),
  })
  static Honeymoon = 19;

  @Display({
    name: t('SpecialRequest_DisabledFacilitiesRoom'),
  })
  static Disabled_Facilities_Room = 20;

  @Display({
    name: t('SpecialRequest_HigherFloorRoom'),
  })
  static Higher_Floor = 21;

  @Display({
    name: t('SpecialRequest_LowerFloorRoom'),
  })
  static Lower_Floor = 22;

  @Display({
    name: t('SpecialRequest_RroundFloorRoom'),
  })
  static Ground_Floor = 23;

  @Display({
    name: t('SpecialRequest_SameFloorRooms'),
  })
  static Same_Floor = 24;

  @Display({
    name: t('SpecialRequest_NoVoucher'),
  })
  static No_Voucher = 25;

  @Enumerable(false)
  @Display({
    name: t('SpecialRequest_FlightTime'),
  })
  static Flight_Time = 26;

  @Enumerable(false)
  @Display({
    name: t('SpecialRequest_FlightNum'),
  })
  static Flight_Num = 27;

  @Enumerable(false)
  @Display({
    name: t('SpecialRequest_RrequentGuestNumber'),
  })
  static Frequent_Guest_Number = 28;

  @Display({
    name: t('SpecialRequest_StayInOriginalRoom'),
  })
  static Extended_stay_in_original_room = 29;

  @Display({
    name: t('SpecialRequest_LateCheckout'),
  })
  static Late_Check_Out = 30;

  @Display({
    name: t('SpecialRequest_OtherRequest'),
  })
  static Other = 31;
}
