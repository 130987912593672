import { Display, t } from '../../decorator';
export class CancellationStateType {
  @Display({
    name: t('Text_RatePlanRate_ViewCancellationPolicies'),
    theme: 'info',
    desc: '',
  })
  static NeedReget = 0;

  @Display({
    name: t('Text_Cancellation_FreeCancel'),
    theme: 'success',
    desc: '',
  })
  static FreeCancell = 1;

  @Display({
    name: t('Text_RatePlanRate_IsInCancelRule'),
    theme: 'warning',
    desc: '',
  })
  static Pay2cancell = 2;

  @Display({
    name: t('Text_RatePlanRate_NonRefundable'),
    theme: 'danger',
    desc: '',
  })
  static NoneRefundable = 3;
}
