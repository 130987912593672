import { SimplifiedRatePlanPrice } from '@b2c/services/rateplan';
import { useMemo } from 'react';
import { useLocale } from './use-locale';

export function useRatePlanPriceDesc(ratePlanPrice: SimplifiedRatePlanPrice) {
  const t = useLocale();

  return useMemo(() => {
    if (!ratePlanPrice?.hotelPriceSummary) {
      return null;
    }
    let hotelPriceDesc = t.formatPrice({
      ...ratePlanPrice.hotelPriceSummary,
      Price: ratePlanPrice.totalHotelPrice,
    });
    if (ratePlanPrice.excludedFeeCurrencyNotMatchHotelCurrency) {
      hotelPriceDesc +=
        ' + ' +
        t.formatPrice(ratePlanPrice.totalHotelExcludedCharges, 'Amount');
    }
    const currencyExchangeDesc = t('Notice_PayAtProperty_ExchangeRate', {
      0: t.formatPrice({
        ...ratePlanPrice.priceSummary,
        Price: ratePlanPrice.totalPrice,
      }),

      1: hotelPriceDesc,
    });
    return {
      hotelPriceDesc,
      currencyExchangeDesc,
    };
  }, [ratePlanPrice, t]);
}
