import { FalconMembership } from '.';
import { FalconContext } from '../auth';
import { HttpService } from '../http';
export class MembershipService {
  static updateCurrencySetting(currencyCode: string) {
    return HttpService.post<FalconContext.AppCurrencyInfo>(
      `/Membership/AccountApi/UpdateCurrencySetting`,
      { currencyCode }
    );
  }

  static updateLanguageSetting(lang: string) {
    return HttpService.post<boolean>(
      `/Membership/AccountApi/UpdateLanguageSetting`,
      { lang }
    );
  }

  // others
  static resendEmailConfirmation() {
    return HttpService.post<true>(
      '/Membership/AccountApi/ResendEmailConfirmation',
      {}
    );
  }

  static changePassword(payload: FalconMembership.UserChangePasswordForm) {
    return HttpService.post<FalconMembership.PasswordChangeResultType>(
      '/Membership/Manage/ChangePassword',
      payload
    );
  }

  static changeEmail(payload: FalconMembership.UserChangeEmailForm) {
    return HttpService.post<FalconMembership.EmailChangeResultType>(
      '/Membership/Manage/ChangeEmail',
      payload
    );
  }

  static requestChangeEmailToken(email: string) {
    return HttpService.post<{ Email: string }>(
      '/Membership/Manage/RequestEmailChangeToken',
      { Email: email }
    );
  }

  static register(form: FalconMembership.UserRegisterPayload) {
    return HttpService.post<boolean>(`/Membership/User/Register`, form);
  }

  static getRegisterToken(email: string) {
    return HttpService.post('/Membership/User/GetRegisterToken', {
      email,
    });
  }

  static BindHotelBooking(bookingNumber: string) {
    const payload: FalconMembership.UserBookingBindingPayload = {
      BookingNumber: bookingNumber,
    };
    return HttpService.post(
      '/Membership/UserResource/BindHotelBooking',
      payload
    );
  }
}
