// eslint-disable-next-line @typescript-eslint/no-unused-vars

import { AppRouteConfigContext } from '@b2c/hooks';
import { ContextGuard, ContextProviders } from '@b2c/ui';
import DefaultLocaleMessages from 'locales/en-US.json';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loading } from 'react-vant';
import { AppConfig } from './app.config';
import { Layout } from './layout/layout';
import routes from './routes';

export function App() {
  return (
    <ContextProviders
      defaultMessage={DefaultLocaleMessages}
      appConfig={AppConfig}
    >
      <Routes>
        {routes.map((item, index) => {
          const { data = {}, guardOption = {} } = item;
          return (
            <Route
              key={index}
              path={item.path}
              element={
                <AppRouteConfigContext.Provider value={data}>
                  <Layout theme={item.data?.theme}>
                    <ContextGuard guardOption={guardOption} title={item.title}>
                      <Suspense fallback={<Loading />}>
                        <item.component />
                      </Suspense>
                    </ContextGuard>
                  </Layout>
                </AppRouteConfigContext.Provider>
              }
            />
          );
        })}
      </Routes>
    </ContextProviders>
  );
}

export default App;
