import { useLocale } from '@b2c/hooks';
import { FalconBooking } from '@b2c/services/booking';
import { DateTimeHelper } from '@b2c/services/common';
import { Cell, Flex, Space, Typography } from 'react-vant';
import { TypographyBedtype } from '../typography-bedtype';
import { TypographyDateRange } from '../typography-date-range';
import { TypographyDateTime } from '../typography-date-time';
import { TypographyGuestName } from '../typography-guest-name';
import { TypographyMealtype } from '../typography-mealtype';
export interface BookingOverviewProps {
  bookingDetail: FalconBooking.BookingDetailModel;
  inset?: boolean;
}
export function BookingOverview({
  bookingDetail,
  inset,
}: BookingOverviewProps) {
  const t = useLocale();

  const guestList = bookingDetail?.RoomList.reduce((r, room) => {
    room.Occupancy.AdultList.forEach((guest) => {
      if (guest.FirstName && guest.LastName) {
        r.push(guest);
      }
    });
    room.Occupancy.ChildList?.forEach((guest) => {
      if (guest.FirstName && guest.LastName) {
        r.push(guest);
      }
    });
    return r;
  }, []);

  return (
    <Cell.Group inset={inset}>
      <Cell className="item">
        <Space direction="vertical" gap={4} block>
          <TypographyDateRange
            value={{
              From: DateTimeHelper.getDate(bookingDetail.CheckInDate),
              To: DateTimeHelper.getDate(bookingDetail.CheckOutDate),
            }}
            hideSummary
          ></TypographyDateRange>
          <Typography.Text strong>
            {bookingDetail.StaticInfo.RoomTypeName || t('Label_Others')}
            &nbsp;&middot;&nbsp;
            {t('Text_RoomWithCount', { count: bookingDetail.RoomCount })}
            &nbsp;&middot;&nbsp;
            {t('Text_NightWithCount', {
              nightCount: DateTimeHelper.getDateDiff(
                bookingDetail.CheckInDate,
                bookingDetail.CheckOutDate
              ),
            })}
          </Typography.Text>
          <Space block gap={[4, 18]} wrap>
            <TypographyMealtype
              showIcon
              value={bookingDetail.RoomList[0].RateList[0].MealInfo}
            />
            <TypographyBedtype
              value={bookingDetail.StaticInfo.BedType}
              showIcon
              isAssured={bookingDetail.StaticInfo.IsBedTypeAssured}
            ></TypographyBedtype>
          </Space>
          <div>
            <Typography.Text strong>{t('Label_BookingInfo')}</Typography.Text>
            <Flex>
              <Flex.Item span={8}>
                <Typography.Text type="secondary">
                  {t('Label_Guests')}
                </Typography.Text>{' '}
              </Flex.Item>
              <Flex.Item span={16}>
                <Space divider="|" wrap>
                  {guestList.map((item, index) => (
                    <TypographyGuestName
                      value={item}
                      key={index}
                    ></TypographyGuestName>
                  ))}
                </Space>
              </Flex.Item>
            </Flex>
            <Flex>
              <Flex.Item span={8}>
                <Typography.Text type="secondary">
                  {t('Label_ContactPhone')}
                </Typography.Text>
              </Flex.Item>
              <Flex.Item span={16}>
                {bookingDetail.ContractInfo.ContactPhone}
              </Flex.Item>
            </Flex>
            {bookingDetail.ContractInfo.ContactEmail && (
              <Flex>
                <Flex.Item span={8}>
                  <Typography.Text type="secondary">
                    {t('Label_ContactEmail')}
                  </Typography.Text>
                </Flex.Item>
                <Flex.Item span={16}>
                  {bookingDetail.ContractInfo.ContactEmail}
                </Flex.Item>
              </Flex>
            )}
            <Flex>
              <Flex.Item span={8}>
                <Typography.Text type="secondary">
                  {t('Label_BookingNumber')}
                </Typography.Text>
              </Flex.Item>
              <Flex.Item span={16}>{bookingDetail.BookingNumber}</Flex.Item>
            </Flex>
            <Flex>
              <Flex.Item span={8}>
                <Typography.Text type="secondary">
                  {t('Label_OrderTime')}
                </Typography.Text>
              </Flex.Item>
              <Flex.Item span={16}>
                <TypographyDateTime
                  value={bookingDetail.OrderTime}
                ></TypographyDateTime>
              </Flex.Item>
            </Flex>
          </div>
        </Space>
      </Cell>
    </Cell.Group>
  );
}
