import { HttpService } from '../http';
import { FalconMembership } from '../membership';

export class AuthenticationService {
  static login(credential: FalconMembership.UserSignInForm) {
    return HttpService.post<FalconMembership.SignInStatus>(
      '/membership/User/Signin',
      credential
    );
  }

  static logout() {
    return HttpService.post<any>('/Membership/User/SignOut', null);
  }

  /**
   * 重置密码
   */
  static resetPassword(payload: FalconMembership.UserPasswordResetForm) {
    return HttpService.post<FalconMembership.PasswordResetResultType>(
      '/Membership/Manage/ResetPassword ',
      payload
    );
  }

  /**
   * 找回密码
   * @param email 已验证的注册邮箱
   */
  static requestPasswordResetToken(email: string) {
    return HttpService.post<boolean>(
      '/Membership/Manage/RequestPasswordResetToken',
      {
        Email: email,
      }
    );
  }
}
