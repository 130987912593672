import { KeywordHelper, StringHelper } from '@b2c/services/common';
import { FunctionComponent } from 'react';
import styles from './highlight.module.less';

export interface HighlightProps {
  text: string;
  keyword?: string;
  highlightClassName?: string;
  isDisabled?: boolean;
}

export const Highlight: FunctionComponent<HighlightProps> = ({
  text,
  keyword,
  highlightClassName,
  isDisabled,
}) => {
  const showHighlight = !isDisabled && text;
  highlightClassName = highlightClassName || styles.highlight;
  const hasHighlight =
    showHighlight && keyword && KeywordHelper.hasMatch(text, keyword);

  return (
    <>
      {!hasHighlight && <span>{text}</span>}
      {hasHighlight &&
        KeywordHelper.getMatchs(text, keyword, true).map((m, index) => {
          const items = m.key ? StringHelper.split(m.word, m.key, true) : [];
          return (
            <span key={index}>
              {m.key ? (
                <>
                  {items.map((item, index) => (
                    <span
                      className={item.isKey ? highlightClassName : ''}
                      key={index}
                    >
                      {item.str}
                    </span>
                  ))}
                  &nbsp;
                </>
              ) : (
                <span>{m.word}&nbsp;</span>
              )}
            </span>
          );
        })}
    </>
  );
};
