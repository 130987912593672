import { CoreCommon, CoreSuggestion } from '@b2c/core';
import { SuggestionOption, SuggestionOptionGroup } from '../suggestions';
import { DestinationType } from './destination.model';

export class DestinationSearchHelper {
  static readonly REGION_SEARCH_CATEGORIES = [
    CoreSuggestion.SuggestionItemCategory.City,
    CoreSuggestion.SuggestionItemCategory.MultiCity,
    CoreSuggestion.SuggestionItemCategory.MultiRegion,
    CoreSuggestion.SuggestionItemCategory.POIS,
    CoreSuggestion.SuggestionItemCategory.HighLevelRegion,
    CoreSuggestion.SuggestionItemCategory.Province,
  ];

  static readonly SUB_REGION_SEARCH_CATEGORIES = [
    CoreSuggestion.SuggestionItemCategory.City,
    CoreSuggestion.SuggestionItemCategory.Neighborhood,
    CoreSuggestion.SuggestionItemCategory.POIS,
    CoreSuggestion.SuggestionItemCategory.POI,
  ];

  static readonly HOTEL_SEARCH_CATEGORIES = [
    CoreSuggestion.SuggestionItemCategory.Hotel,
  ];

  static readonly REGION_SEARCH_BY_ID_CATEGORIES = [
    CoreSuggestion.SuggestionItemCategory.POI,
    CoreSuggestion.SuggestionItemCategory.POIS,
    CoreSuggestion.SuggestionItemCategory.Neighborhood,
    CoreSuggestion.SuggestionItemCategory.City,
    CoreSuggestion.SuggestionItemCategory.MultiCity,
    CoreSuggestion.SuggestionItemCategory.MultiRegion,
    CoreSuggestion.SuggestionItemCategory.HighLevelRegion,
    CoreSuggestion.SuggestionItemCategory.Province,
  ];

  static createPayload(
    keyword: string,
    categories: CoreSuggestion.SuggestionItemCategory[],
    size: number,
    ids?: string[],
    parentId?: string
  ): CoreSuggestion.SuggestionQueryPayload {
    return {
      IDs: ids,
      Keyword: keyword,
      Size: size,
      Categories: categories,
      ParentID: parentId ? parentId : undefined,
      Language: 'en-US',
      PaginationOption: { PageNum: -1 },
      WithMetadata: true,
      WithHierarchy: true,
      WithItemObject: true,
    };
  }

  static toOption(item: CoreSuggestion.SuggestionItem): SuggestionOption {
    let destinationID = null;
    let destinationName = null;
    if (item.Category === CoreSuggestion.SuggestionItemCategory.Hotel) {
      destinationID = item.ItemObject.DestinationID;
      destinationName = item.ItemObject.DestinationName;
    }
    // let name =
    //   item.Category === CoreSuggestion.SuggestionItemCategory.Hotel
    //     ? item.ItemObject.ChineseName
    //     : item.ItemObject.RegionNameLong;
    // name = name || item.DisplayOption.FullName;

    const address = {
      destinationName: item.ItemObject.DestinationName,
      countryName: item.ItemObject.CountryName,
      detail: item.ItemObject.Address || item.ItemObject.Address,
    };
    if (!address.destinationName || !address.countryName) {
      address.destinationName = item.ItemObject.DestinationName;
      address.countryName = item.ItemObject.CountryName;
    }

    return {
      id: item.ID,
      destinationID,
      destinationName,
      name: item.DisplayOption.FullName,
      // nameOld:
      shortname: item.DisplayOption.DisplayName || item.ItemObject.RegionName,
      address: address,
      category: item.Category,
      source: item,
      parentId: item.ParentRegionIDString,
      location: item.Metadata.Location,
    };
  }

  static toOptions(
    keyword: string,
    maxHotelSize: number,
    maxRegionSize: number,
    responses: CoreCommon.PaginationWrapper<CoreSuggestion.SuggestionItem>[]
  ): SuggestionOptionGroup[] {
    const result: SuggestionOptionGroup[] = [];
    const items: SuggestionOption[] = [];
    const regionItems: SuggestionOption[] = [];
    const hotelItems: SuggestionOption[] = [];

    responses.forEach((response) => {
      response.Data.forEach((item) => {
        items.push(DestinationSearchHelper.toOption(item));
      });
    });

    items.forEach((item) => {
      item.category === CoreSuggestion.SuggestionItemCategory.Hotel
        ? hotelItems.push(item)
        : regionItems.push(item);
    });

    const maxSize = maxHotelSize + maxRegionSize;
    // 酒店不足区域来凑
    const regionSize =
      hotelItems.length < maxHotelSize
        ? maxSize - hotelItems.length
        : maxRegionSize;
    // 区域不足酒店来凑
    const hotelSize =
      regionItems.length < maxRegionSize
        ? maxSize - regionItems.length
        : maxHotelSize;
    if (regionItems.length > 0) {
      result.push({
        type: DestinationType.region,
        keyword: keyword,
        options: regionItems.slice(0, Math.min(regionSize, regionItems.length)),
      });
    }
    if (hotelItems.length > 0) {
      result.push({
        type: DestinationType.hotel,
        keyword: keyword,
        options: hotelItems.slice(0, Math.min(hotelSize, hotelItems.length)),
      });
    }

    return result;
  }
}
