import {
  AppContextInfoContext,
  SecureContextInfoContext,
  useLocale,
  useSecureContext,
} from '@b2c/hooks';
import { PropsWithChildren, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Loading } from 'react-vant';

export function ContextGuard(
  props: PropsWithChildren<{
    guardOption: Partial<{
      auth: boolean;
      secure: boolean;
      /**
       * @default true
       */
      context: boolean;
    }>;
    title: string;
  }>
) {
  const t = useLocale();
  const { appContext } = useContext(AppContextInfoContext);
  const { getSecureContext, establishSecureTunnel, clearSecureContext } =
    useSecureContext();
  const secureContext = getSecureContext();
  const guardOption = props.guardOption;

  useEffect(() => {
    if (props.title) {
      const translatedTitle = t(props.title);
      document.title = translatedTitle;
      if (process.env.NODE_ENV === 'development') {
        document.title = '[m]' + translatedTitle;
      }
    }
  }, [props.title, t]);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (guardOption.secure) {
      if (appContext) {
        establishSecureTunnel();
      }
    } else {
      clearSecureContext();
    }
  }, [appContext, guardOption]);

  if (guardOption) {
    let contextNotReady = guardOption.context !== false && !appContext;
    contextNotReady = contextNotReady || (guardOption.secure && !secureContext);
    if (contextNotReady) {
      return (
        <Flex justify="center" align="center" style={{ height: '80vh' }}>
          <Loading>{t('Text_Loading')}</Loading>
        </Flex>
      );
    }

    if (guardOption.auth && !appContext?.userIdentityInfo.isAuthenticated) {
      setTimeout(() => {
        window.location.replace(
          `${
            window.location.origin
          }/account/login?returnUrl=${encodeURIComponent(window.location.href)}`
        );
      }, 0);
      return <></>;
    }
  }

  return (
    <SecureContextInfoContext.Provider value={secureContext}>
      <div>{props.children}</div>
    </SecureContextInfoContext.Provider>
  );
}

export default ContextGuard;
