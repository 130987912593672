import { ArrayHelper } from '@b2c/services/common';
import { useContext, useMemo } from 'react';
import { CountryInfoContext } from './country-info';
import { useLocale } from './use-locale';

export const TOP_COUNTRY_LIST = ['US', 'JP', 'IT', 'FR', 'GB'];

export function useCallingCode() {
  const t = useLocale();

  const countryInfoList = useContext(CountryInfoContext).countryInfoList;

  const callingCodeList = countryInfoList.filter((item) =>
    Boolean(item.callingCode)
  );

  const [callingCodeIndex, indexList] = useMemo(() => {
    // if (process.env.NODE_ENV === 'development') {
    // const callingCodeMap = countryInfoList.reduce((r, i) => {
    //   if (r[i.callingCode]) {
    //     r[i.callingCode].push(i);
    //   } else {
    //     r[i.callingCode] = [i];
    //   }
    //   return r;
    // }, {} as Record<string, CountryInfoModel[]>);
    // const sameCodeList = Array.from(Object.values(callingCodeMap)).filter(
    //   (item) => item.length > 1
    // );
    // console.log('same calling code', sameCodeList);

    // }
    const missList = countryInfoList.filter((item) => !item.callingCode);
    if (missList.length > 0) {
      console.warn('missing calling code', missList);
    }
    const result = ArrayHelper.toIndexList(
      callingCodeList,
      (i) => i.indexChar || (i.pinyin?.slice(0, 1) || i.name[0])?.toUpperCase()
    );

    result.unshift([
      t('Label_IndexBar_Top'),

      TOP_COUNTRY_LIST.map((code) =>
        callingCodeList.find((item) => item.code === code)
      ).filter(Boolean),
    ]);
    return [result, result.map((item) => item[0])];
  }, [callingCodeList, t]);

  return { callingCodeIndex, indexList, callingCodeList };
}
