import 'reflect-metadata';

function getMetadataKey() {
  return Symbol.for('Display');
}

// type ThemeType =
//     | 'none'
//     | 'default'
//     | 'primary'
//     | 'info'
//     | 'warning'
//     | 'danger'
//     | 'success'
//     | 'disabled'

export interface DecoratorDisplayInfo {
  name: string;
  theme?: string;
  key?: string;
  value?: any;
  desc?: string;
  /**
   * 预览图片url
   */
  priview?: string;
}

export const t = (a) => a;

export function Display(config: DecoratorDisplayInfo) {
  return Reflect.metadata(getMetadataKey(), config);
}

export function DisplayName(name: string) {
  return Reflect.metadata(getMetadataKey(), {
    name,
  });
}

export function getDisplayInfo<T = any>(
  target: any,
  propertyValue: T
): DecoratorDisplayInfo {
  const propertyKey = Object.keys(target).find(
    (key) => target[key] === propertyValue
  );
  const result = Reflect.getMetadata(
    getMetadataKey(),
    target,
    propertyKey || ''
  );
  return Object.assign({}, result);
}

export function getDisplayInfoByKey(target: any, propertyKey: string) {
  const result = Reflect.getMetadata(getMetadataKey(), target, propertyKey);
  return Object.assign({}, result) as DecoratorDisplayInfo;
}

export function getPropertyDisplayInfoList(target) {
  return Object.keys(target).map((key) => {
    const displayInfo = getDisplayInfoByKey(target, key);
    return {
      ...displayInfo,
      value: target[key],
      key,
    };
  });
}

export function getPropertyDisplayName(target: any, propertyKey: string) {
  const displayInfo = Reflect.getMetadata(
    getMetadataKey(),
    target,
    propertyKey
  );
  return displayInfo && displayInfo.name;
}
